import React from "react";
import {
  MoneyCollectOutlined,
  BankOutlined,
  UserOutlined,
  DollarOutlined,
  FileSearchOutlined,
  UsergroupAddOutlined,
  BarsOutlined,
  UserAddOutlined,
  FileTextOutlined,
  NotificationOutlined,
  FilePdfOutlined,
  ApartmentOutlined,
  ContainerOutlined,
  ProjectOutlined,
  FacebookOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  SettingOutlined,
  DownloadOutlined,
  AccountBookOutlined,
  ForkOutlined,
  ReadOutlined,
  VideoCameraOutlined,
  TransactionOutlined,
  PlayCircleFilled,
  CodeOutlined,
  StockOutlined,
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
  BarChartOutlined,
  ScheduleOutlined,
  WalletOutlined,
  QrcodeOutlined,
  SyncOutlined,
} from "@ant-design/icons";

//Marketing
import MarketingCampaignManagement from "../screens/marketing/campaignManagement";

import ChangePassword from "../screens/customerService/changePassword";

//customer service
import CSDepositManagement from "../screens/customerService/depositManagement";
import CSDepositManagement2 from "../screens/customerService/depositManagement2";
import CSDepositManagement3 from "../screens/customerService/depositManagementV3";
import CSDepositManagement4 from "../screens/customerService/depositManagementV4";
import CSWithdrawManagement from "../screens/customerService/withdrawManagement";
import CSWithdrawManagement2 from "../screens/customerService/withdrawManagement2";
import CSWithdrawManagement3 from "../screens/customerService/withdrawManagementV3";
import CSWithdrawManagement4 from "../screens/customerService/withdrawManagementV4";
import CSWithdrawManagement5 from "../screens/customerService/withdrawManagementV5";
import CSMemberManagement from "../screens/customerService/memberManagement";
import CSMemberManagementV2 from "../screens/customerService/memberManagementV2";
import CSBankManagement from "../screens/customerService/bankManagement";
import CSBankManagementV2 from "../screens/customerService/bankMachine";
import CSBindSMS from "../screens/customerService/bindSMS";
import CSBindScbConnect from "../screens/customerService/bindScbConnect";

// import CSSystemHealth from "../screens/customerService/systemHealth";
import CSManualDepositManagement from "../screens/customerService/manualDepositManagement";
import CSCommWithdrawList from "../screens/customerService/commWithdrawList";
import CSCommWithdrawList2 from "../screens/customerService/commWithdrawList2";
import RedPacketSummaryReport from "../screens/shareholder/report/reportRedpacketSummary";
import DashboardMonitor from "../screens/customerService/report/dashboardmonitor";
import ReportBankTrxn from "../screens/customerService/report/reportBankTrxn";
import ReportBankTrxnBankApp from "../screens/customerService/report/reportBankTrxnBankAppV2";
import ReportBankStatement from "../screens/customerService/report/reportBankStatement";
import ReportBankStatementBankApp from "../screens/customerService/report/reportBankStatementBankAppV2";
import MarqueeManagement from "../screens/customerService/marqueeManagement";
import OtpCheck from "../screens/customerService/otpCheck";
import MaintenanceManagement from "../screens/customerService/maintenanceManagement";
import ReportDownline from "../screens/customerService/report/reportDownline";
import UserFeedbackManagement from "../screens/customerService/userFeedbackManagement";
import AccountRecon from "../screens/customerService/report/reportAccountRecon";
import FundTransfer from "../screens/customerService/fundTransfer";
import RefCommApproval from "../screens/customerService/refCommApproval";
import CSWithdrawMonitor from "../screens/customerService/withdrawMonitor";
import ReportOperationModule from "../screens/customerService/report/reportOperationsOld";
import CSWithdrawResort from "../screens/customerService/withdrawResort";
import ReportDailySales from "../screens/customerService/report/reportDailySales";
import ReportMasterData from "../screens/customerService/report/reportMasterData";
import ReportMonitor from "../screens/customerService/report/reportMonitor";
import NewsModal from "../screens/customerService/news_modal";
import GameProviderManagement from "../screens/customerService/settings/gameProviderManagement";
import RegVsDepReport from "../screens/customerService/report/reportRegVsDep";
import ReportDailyTrxnStats from "../screens/customerService/report/reportDailyTrxnStats";
import ReportVipPlayer from "../screens/customerService/report/reportVipPlayer";
import ReportFortuneWheel from "../screens/customerService/report/reportFortuneWheel";
import GameStats from "../screens/customerService/report/gameStats";
import ReportDailyBankStats from "../screens/customerService/report/reportDailyBankStats";
import ReportDailyBankTrxnStats from "../screens/customerService/report/reportDailyBankTrxnStats";
import ReportPlayer from "../screens/customerService/report/reportPlayer";
import ReportTopList from "../screens/customerService/report/reportTopList";
import ReportAgent from "../screens/customerService/report/reportAgent";
import ReportCustomerDep from "../screens/customerService/report/reportCustomerDep";
import PageSetting from "../screens/customerService/pageSetting";
import BindGameUser from "../screens/customerService/bindGameUser";
// import ReportDepSystemVsManual from "../screens/customerService/report/reportDepSystemVsManual";

//company
import CompanyShareHolderManagement from "../screens/company/shareHolderManagement";
import CompanyShareholderManagementV2 from "../screens/company/shareholderManagementV2";
import CompanyCSManagement from "../screens/company/customerServiceManagement";
import CompanyBankManagement from "../screens/company/bankManagement";
import CompanyWinLose from "../screens/company/winLose";
import CompanyReport from "../screens/company/report/company";
import Notification from "../screens/company/notification";
import ReportShareholderCompany from "../screens/company/report/reportShareholderV2";
import AdministratorPage from "../screens/company/administrationPage";
import PromotionPage from "../screens/company/promotionPage";
import FortuneWheel from "../screens/customerService/fortuneWheel";
import UserAccessTemplate from "../screens/company/userAccessTemplate";
import BoMaintenanceSetting from "../screens/company/boMaintenanceSetting";
import BoDeploymentSetting from "../screens/company/boDeploymentSetting";
import Maintenance from "../screens/company/maintenance";

//shareholder
import AgentManagement from "../screens/shareholder/agentManagement";
import DownlineManagement from "../screens/shareholder/downlineManagement";
import DownlineManagementV2 from "../screens/shareholder/downlineManagementV2";
import Shareholder from "../screens/shareholder/report/shareholder";
import DownlineReport from "../screens/shareholder/report/downline";
import OperationReport from "../screens/shareholder/report/reportOperation";
import BankBalanceReport from "../screens/shareholder/report/bankbalance";
import CommBalanceReport from "../screens/shareholder/report/commbalance";
import ReportShareholder from "../screens/shareholder/report/reportShareholderV2";
import ReportPlayerShareholder from "../screens/shareholder/report/reportPlayerShareholder";
import ReportSettlement from "../screens/shareholder/report/reportSettlement";

//agent
import AgentReportComm from "../screens/agent/commission";
import AgentMemberManagement from "../screens/agent/memberManagement";
import TransferCreditByMobileNo from "../screens/agent/transferCreditByMobileNo";
import UploadTopupReceipt from "../screens/agent/uploadTopupReceipt";
import QRcode from "../screens/agent/qrCode";

//maintenance
import Movie from "../screens/maintenance/movie";
import AdultMovie from "../screens/maintenance/adultMovie";

//root
import CompanyManagement from "../screens/root/companyManagement";

import {
  // externalFileNotFile,
  InternalFileNotFile,
} from "../screens/error/error";
import Logout from "../screens/logout/logout";
import CompanyProfile from "../screens/company/companyProfile";
import CompanySetting from "../screens/company/companySetting";
import { ThaiBaht } from "../common/components/ThaiBaht";
import OnboardDomain from "../screens/company/onboardDomain";
import DepositLedger from "../screens/customerService/report/reportDepositLedger";
import ModifyColorPage from "../screens/company/modifyColorPage";
import ReportDailyTrxnStatsv2 from "../screens/admin/reportDailyTrxnStatsv2";
import BrandManagement from "../screens/admin/brandManagement";
import BankVerifyStats from "../screens/admin/bankVerifyStats";
import DepositLedgerV2 from "../screens/admin/reportDepositLedgerV2";
import WithdrawLedger from "../screens/admin/reportWithdrawLedger";
import ReportSmsCosting from "../screens/admin/reportSmsCosting";
import ReportCompanyProfit from "../screens/admin/reportCompanyProfit";
import BankVerifySum from "../screens/admin/bankVerifySum";
import BankVerifyDetails from "../screens/admin/bankVerifyDetails";
import BlacklistManagement from "../screens/customerService/blacklistManagement";

const iconSize = {
  fontSize: window.innerWidth === 280 || window.innerWidth === 320 ? null : 20,
};

const rotate180Deg = {
  transform: "rotate(180deg)",
};

export const logout = [
  {
    key: 0,
    title: "Logout",
    menutitle: "",
    path: "/logout",
    exact: true,
    component: () => <Logout />,
  },
];

export const changePassword = [
  {
    key: 0,
    title: "Change Password",
    menutitle: "Change Password",
    path: "/changePassword",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <ChangePassword />,
  },
];

export const root = [
  {
    key: 0,
    title: "Company Management",
    menutitle: "",
    path: "/",
    exact: true,
    icon: <FileSearchOutlined style={iconSize} />,
    component: () => <CompanyManagement />,
  },
  {
    key: 1,
    menutitle: "",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const mainMenu = [
  {
    key: 0,
    icon: <BankOutlined style={iconSize} />,
  },
  {
    key: 1,
    icon: <ScheduleOutlined style={iconSize} />,
  },
  {
    key: 2,
    icon: <UserOutlined style={iconSize} />,
  },
  {
    key: 3,
    icon: <BarChartOutlined style={iconSize} />,
  },
  {
    key: 4,
    icon: <SettingOutlined style={iconSize} />,
  },
  {
    key: 5,
    icon: <UsergroupAddOutlined style={iconSize} />,
  },
  {
    key: 6,
    icon: <BarsOutlined style={iconSize} />,
  },
];

export const shareholder = [
  {
    key: 0,
    title: "Shareholder Report",
    menutitle: "",
    path: "/shareholder",
    exact: true,
    icon: <FileTextOutlined style={iconSize} />,
    component: () => <Shareholder />,
  },
  {
    key: 1,
    title: "Downline Report",
    menutitle: "",
    path: "/downlineReport",
    exact: true,
    icon: <FilePdfOutlined style={iconSize} />,
    component: () => <DownlineReport />,
  },
  {
    key: 2,
    title: "Downline Management",
    menutitle: "",
    path: "/downlineManagement",
    exact: true,
    icon: <UsergroupAddOutlined style={iconSize} />,
    component: () => <DownlineManagement />,
  },
  {
    key: 3,
    title: "Agent Management",
    menutitle: "",
    path: "/agentManagement",
    exact: true,
    icon: <UserAddOutlined style={iconSize} />,
    component: () => <AgentManagement />,
  },
  {
    key: 4,
    title: "Operations Report",
    menutitle: "Operations Report",
    path: "/OperationReport",
    exact: true,
    icon: <ContainerOutlined style={iconSize} />,
    component: () => <OperationReport />,
  },
  {
    key: 5,
    title: "Bank Balance Report",
    menutitle: "Bank Balance Report",
    path: "/BankBalanceReport",
    exact: true,
    icon: <BankOutlined style={iconSize} />,
    code: [], // includes == true
    component: () => <BankBalanceReport />,
  },
  {
    key: 6,
    title: "Comm. Balance Report",
    menutitle: "Comm. Balance Report",
    path: "/commBalance",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    code: [], // includes == true
    component: () => <CommBalanceReport />,
  },
  {
    key: 7,
    title: "Red Packet Summary Report",
    menutitle: "Red Packet Summary Report",
    path: "/RedPacketSummaryReport",
    exact: true,
    icon: <ContainerOutlined style={iconSize} />,
    component: () => <RedPacketSummaryReport />,
  },
  {
    key: 8,
    title: "Transfer Credit",
    menutitle: "Transfer Credit",
    path: "/TransferCreditByMobileNo",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    // code : ['MKB'],
    component: () => <TransferCreditByMobileNo />,
  },
  {
    key: 9,
    title: "Upload Receipt",
    menutitle: "Upload Receipt",
    path: "/UploadTopupReceipt",
    exact: true,
    icon: <WalletOutlined style={iconSize} />,
    // code : ['MKB'],
    component: () => <UploadTopupReceipt />,
  },
  {
    key: 10,
    title: "Downline Management",
    menutitle: "Downline Management V2",
    path: "/downlineManagementV2",
    exact: true,
    icon: <UsergroupAddOutlined style={iconSize} />,
    component: () => <DownlineManagementV2 />,
  },
  {
    key: 11,
    title: "Shareholder Report",
    menutitle: "Shareholder Report",
    path: "/",
    exact: true,
    icon: <WalletOutlined style={iconSize} />,
    component: () => <ReportShareholder />,
  },
  {
    key: 12,
    title: "Player Report",
    menutitle: "Player Report",
    path: "/reportPlayerShareholder",
    exact: true,
    icon: <WalletOutlined style={iconSize} />,
    component: () => <ReportPlayerShareholder />,
  },
  {
    key: 13,
    title: "Settlement Report",
    menutitle: "Settlement Report",
    path: "/reportSettlement",
    exact: true,
    icon: <WalletOutlined style={iconSize} />,
    component: () => <ReportSettlement />,
  },
  {
    key: 404,
    menutitle: "",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const agent = [
  {
    key: 0,
    title: "Commission Report",
    menutitle: "",
    path: "/agentReportComm",
    exact: true,
    icon: <ProjectOutlined style={iconSize} />,
    component: () => <AgentReportComm />,
  },
  {
    key: 1,
    title: "Member List",
    menutitle: "Member List",
    path: "/memberManagement",
    exact: true,
    icon: <UserOutlined style={iconSize} />,
    component: () => <AgentMemberManagement />,
  },
  {
    key: 2,
    title: "Transfer Credit",
    menutitle: "Transfer Credit",
    path: "/TransferCreditByMobileNo",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <TransferCreditByMobileNo />,
  },
  {
    key: 3,
    title: "Upload Receipt",
    menutitle: "Upload Receipt",
    path: "/UploadTopupReceipt",
    exact: true,
    icon: <WalletOutlined style={iconSize} />,
    component: () => <UploadTopupReceipt />,
  },
  {
    key: 4,
    title: "QR Page",
    menutitle: "QR Page",
    path: "/",
    exact: true,
    icon: <QrcodeOutlined style={iconSize} />,
    component: () => <QRcode />,
  },
  {
    key: 404,
    menutitle: "",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const company = [
  {
    key: 0,
    title: "Daily Transaction Stats",
    menutitle: "Daily Transaction Stats",
    path: "/",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportDailyTrxnStatsv2 />,
  },
  {
    key: 1,
    title: "Bank Verification Stats",
    menutitle: "Bank Verification Stats",
    path: "/bankVerifyStats",
    exact: true,
    component: () => <BankVerifyStats />,
  },
  {
    key: 2,
    title: "Deposit Ledger Report",
    menutitle: "Deposit Ledger Report",
    path: "/reportDepositLedger",
    exact: true,
    component: () => <DepositLedgerV2 />,
  },
  {
    key: 3,
    title: "Withdraw Ledger Report",
    menutitle: "Withdraw Ledger Report",
    path: "/reportWithdrawLedger",
    exact: true,
    component: () => <WithdrawLedger />,
  },
  {
    key: 4,
    title: "SMS Costing Report",
    menutitle: "SMS Costing Report",
    path: "/reportSmsCosting",
    exact: true,
    component: () => <ReportSmsCosting />,
  },
  {
    key: 5,
    title: "Company Profit Report",
    menutitle: "Company Profit Report",
    path: "/reportCompanyProfit",
    exact: true,
    component: () => <ReportCompanyProfit />,
  },
  {
    key: 6,
    title: "Bank Verify Summary Report",
    menutitle: "Daily Bank Verify Name report",
    path: "/bankVerifySum",
    exact: true,
    component: () => <BankVerifySum />,
  },
  {
    key: 7,
    title: "Bank Verify Detail Report",
    menutitle: "Bank Verify Detail Report",
    path: "/bankVerifyDetails",
    exact: true,
    component: () => <BankVerifyDetails />,
  },
  {
    key: 404,
    menutitle: "",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const customerService = [
  {
    key: 0,
    title: "Deposit Management",
    menutitle: "",
    path: "/",
    exact: true,
    icon: <MoneyCollectOutlined style={iconSize} />,
    component: () => <CSDepositManagement />,
  },
  {
    key: 1,
    title: "Withdraw Management",
    menutitle: "Withdraw Auto (Old)",
    path: "/withdrawManagement",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <CSWithdrawManagement />,
  },
  {
    key: 2,
    title: "Withdraw Commission",
    menutitle: "",
    path: "/withdrawCommList",
    exact: true,
    icon: <ContainerOutlined style={iconSize} />,
    component: () => <CSCommWithdrawList />,
  },
  {
    key: 3,
    title: "Member",
    menutitle: "Member",
    path: "/memberManagement",
    exact: true,
    icon: <UserOutlined style={iconSize} />,
    component: () => <CSMemberManagement />,
  },
  {
    key: 4,
    title: "Bank Management",
    menutitle: "",
    path: "/bankManagementV2",
    exact: true,
    icon: <BankOutlined style={iconSize} />,
    component: () => <CSBankManagementV2 />,
  },
  // {
  //   key: 4,
  //   title: "Bank Management",
  //   menutitle:"",
  //   path: "/bankManagement",
  //   exact: true,
  //   icon: <BankOutlined style={iconSize} />,
  //   component: () => <CSBankManagement />,
  // },
  {
    key: 5,
    title: "Manual Deposit",
    menutitle: "",
    path: "/manualDepositManagement",
    exact: true,
    icon: <FileTextOutlined style={iconSize} />,
    component: () => <CSManualDepositManagement />,
  },
  {
    key: 6,
    title: "Commission Withdraw",
    menutitle: "",
    path: "/commWithdrawList",
    exact: true,
    icon: <ContainerOutlined style={iconSize} />,
    component: () => <CSCommWithdrawList />,
  },
  {
    key: 7,
    title: "Deposit Management (Manual)",
    menutitle: "Deposit",
    path: "/depositManagement2",
    exact: true,
    icon: <MoneyCollectOutlined style={iconSize} />,
    component: () => <CSDepositManagement2 />,
  },
  {
    key: 8,
    title: "Withdraw Management (Manual)",
    menutitle: "Withdraw Manual (Old)",
    path: "/withdrawManagement2",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <CSWithdrawManagement2 />,
  },
  {
    key: 9,
    title: "Withdraw Commission (Manual)",
    menutitle: "Withdraw Comm.",
    path: "/withdrawCommList2",
    exact: true,
    icon: <ContainerOutlined style={iconSize} />,
    component: () => <CSCommWithdrawList2 />,
  },
  {
    key: 10,
    title: "Dashboard Monitor",
    menutitle: "Dashbd. Monitor",
    path: "/DashboardMonitor",
    exact: true,
    icon: <ContainerOutlined style={iconSize} />,
    component: () => <DashboardMonitor />,
  },
  {
    key: 11,
    title: "View Member",
    menutitle: "View Member",
    path: "/memberManagementV2",
    exact: true,
    icon: <UserOutlined style={iconSize} />,
    component: () => <CSMemberManagementV2 />,
  },
  {
    key: 12,
    title: "Bank Transaction",
    menutitle: "Bank Transaction",
    path: "/reportBankTrxn",
    exact: true,
    icon: <ExclamationCircleOutlined style={iconSize} />,
    component: () => <ReportBankTrxn />,
  },
  {
    key: 13,
    title: "Marquee Management",
    menutitle: "Marquee Management",
    path: "/marqueeManagement",
    exact: true,
    icon: <EditOutlined style={iconSize} />,
    component: () => <MarqueeManagement />,
  },
  {
    key: 14,
    title: "System Settings",
    menutitle: "System Settings",
    path: "/systemManagement",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <MaintenanceManagement />,
  },
  {
    key: 15,
    title: "Downline Report",
    menutitle: "Downline Report",
    path: "/reportDownline",
    exact: true,
    icon: <ForkOutlined style={{ ...iconSize, ...rotate180Deg }} />,
    component: () => <ReportDownline />,
  },
  {
    key: 16,
    title: "User Feedback",
    menutitle: "User Feedback",
    path: "/userFeedbackManagement",
    exact: true,
    icon: <DownloadOutlined style={iconSize} />,
    component: () => <UserFeedbackManagement />,
  },
  {
    key: 17,
    title: "Acc. Recon",
    menutitle: "Acc. Recon",
    path: "/AccountRecon",
    exact: true,
    icon: <AccountBookOutlined style={iconSize} />,
    component: () => <AccountRecon />,
  },
  {
    key: 18,
    title: "Approved Transactions",
    menutitle: "Approved Trxn",
    path: "/ReportBankStatement",
    exact: true,
    icon: <ReadOutlined style={iconSize} />,
    component: () => <ReportBankStatement />,
  },
  {
    key: 19,
    title: "Referral Invite Comm. Approval",
    menutitle: "Ref. Comm. Approval",
    path: "/RefCommApproval",
    exact: true,
    icon: <TransactionOutlined style={iconSize} />,
    component: () => <RefCommApproval />,
  },
  {
    key: 20,
    title: "News Module",
    menutitle: "News Module",
    path: "/newsModalManagement",
    exact: true,
    icon: <ReadOutlined style={iconSize} />,
    component: () => <NewsModal />,
  },
  {
    key: 21,
    title: "Op. Module",
    menutitle: "Operations Module",
    path: "/opModuleManagement",
    exact: true,
    icon: <ReadOutlined style={iconSize} />,
    component: () => <ReportOperationModule />,
  },
  {
    key: 22,
    title: "Withdraw Resort",
    menutitle: "With. Re-sorting",
    path: "/withdrawResort",
    exact: true,
    icon: <CodeOutlined style={iconSize} />,
    component: () => <CSWithdrawResort />,
  },
  {
    key: 23,
    title: "Bank Transaction (V2)",
    menutitle: "Bank Transaction (V2)",
    path: "/reportBankTrxnBankApp",
    exact: true,
    icon: <ExclamationCircleOutlined style={iconSize} />,
    component: () => <ReportBankTrxnBankApp />,
  },
  {
    key: 24,
    title: "Approved Trxn (V2)",
    menutitle: "Approved Trxn (V2)",
    path: "/ReportBankStatementBankApp",
    exact: true,
    icon: <ReadOutlined style={iconSize} />,
    component: () => <ReportBankStatementBankApp />,
  },
  {
    key: 25,
    title: "Daily Sales",
    menutitle: "Daily Sales",
    path: "/ReportDailySales",
    exact: true,
    icon: <StockOutlined style={iconSize} />,
    component: () => <ReportDailySales />,
  },
  {
    key: 26,
    title: "Master Data",
    menutitle: "Master Data",
    path: "/ReportMasterData",
    exact: true,
    icon: <StockOutlined style={iconSize} />,
    component: () => <ReportMasterData />,
  },
  {
    key: 27,
    title: "CS Monitor",
    menutitle: "CS Monitor",
    path: "/ReportMonitor",
    exact: true,
    icon: <InsertRowAboveOutlined style={iconSize} />,
    component: () => <ReportMonitor />,
  },
  {
    key: 28,
    title: "Notification",
    menutitle: "Notification",
    path: "/notification",
    exact: true,
    icon: <NotificationOutlined style={iconSize} />,
    component: () => <Notification />,
  },
  {
    key: 29,
    title: "Game Provider",
    menutitle: "Game Provider",
    path: "/GameProviderMgmt",
    exact: true,
    icon: <NotificationOutlined style={iconSize} />,
    component: () => <GameProviderManagement />,
  },
  {
    key: 30,
    title: "Register Vs Deposit",
    menutitle: "Register Vs Deposit",
    path: "/RegVsDepReport",
    exact: true,
    icon: <ReadOutlined style={iconSize} />,
    component: () => <RegVsDepReport />,
  },
  {
    key: 31,
    title: "Deposit Management (Manual)",
    menutitle: "Deposit Management",
    path: "/depositManagement3",
    exact: true,
    icon: <MoneyCollectOutlined style={iconSize} />,
    code: ["MKB"],
    component: () => <CSDepositManagement3 />,
  },
  {
    key: 32,
    title: "Withdraw Management (Manual)",
    menutitle: "Withdraw Management",
    path: "/withdrawManagementV3",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    code: ["MKB"],
    component: () => <CSWithdrawManagement3 />,
  },
  {
    key: 33,
    title: "Deposit Management (Manual)",
    menutitle: "Deposit",
    path: "/depositManagementV4",
    exact: true,
    icon: <MoneyCollectOutlined style={iconSize} />,
    component: () => <CSDepositManagement4 />,
  },
  {
    key: 34,
    title: "Daily Transaction Stats",
    menutitle: "Daily Transaction Stats",
    path: "/ReportDailyTrxnStats",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportDailyTrxnStats />,
  },
  {
    key: 35,
    title: "Agent Report",
    menutitle: "Agent Report",
    path: "/ReportAgent",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportAgent />,
  },
  {
    key: 36,
    title: "Withdraw Management",
    menutitle: "Withdraw Management",
    path: "/withdrawManagementV4",
    exact: true,
    icon: <MoneyCollectOutlined style={iconSize} />,
    component: () => <CSWithdrawManagement4 />,
  },
  {
    key: 37,
    title: "Daily Bank Balance Stats",
    menutitle: "Daily Bank Balance Stats",
    path: "/ReportDailyBankStats",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportDailyBankStats />,
  },
  {
    key: 38,
    title: "Daily Bank Transaction Stats",
    menutitle: "Daily Bank Transaction Stats",
    path: "/ReportDailyBankTrxnStats",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    code: ["OGT", "TKC"], // includes == false
    component: () => <ReportDailyBankTrxnStats />,
  },
  {
    key: 39,
    title: "OTP Check",
    menutitle: "OTP Check",
    path: "/OtpCheck",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <OtpCheck />,
  },
  {
    key: 40,
    title: "Top List Report",
    menutitle: "Top List Report",
    path: "/ReportTopList",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportTopList />,
  },
  {
    key: 41,
    title: "Player Report",
    menutitle: "Player Report",
    path: "/ReportPlayer",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportPlayer />,
  },
  {
    key: 42,
    title: "Vip Player Report",
    menutitle: "Vip Player Report",
    path: "/ReportVipPlayer",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportVipPlayer />,
  },
  {
    key: 43,
    title: "Fortune Wheel Report (By Date)",
    menutitle: "Fortune Wheel Report",
    path: "/ReportFortuneWheel",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportFortuneWheel />,
  },
  {
    key: 44,
    title: "Fund Transfer",
    menutitle: "Fund Transfer",
    path: "/fundTransfer",
    exact: true,
    icon: <MoneyCollectOutlined style={iconSize} />,
    component: () => <FundTransfer />,
  },
  {
    key: 45,
    title: "Game Stats",
    menutitle: "Game Stats",
    path: "/gameStats",
    exact: true,
    icon: <MoneyCollectOutlined style={iconSize} />,
    component: () => <GameStats />,
  },
  // {
  //   key: 46,
  //   title: "Auto Vs Manual Deposit",
  //   menutitle: "Auto Vs Manual Deposit",
  //   path: "/reportDepSystemVsManual",
  //   exact: true,
  //   icon: <MoneyCollectOutlined style={iconSize} />,
  //   code : ['TKH'],
  //   component: () => <ReportDepSystemVsManual />,
  // },
  {
    key: 46,
    title: "Withdraw Management",
    menutitle: "Withdraw Management",
    path: "/withdrawManagementV5",
    exact: true,
    icon: <MoneyCollectOutlined style={iconSize} />,
    // code : ['TKH'],
    component: () => <CSWithdrawManagement5 />,
  },
  {
    key: 47,
    title: "Customer Deposit Report",
    menutitle: "Customer Deposit Report",
    path: "/reportCustomerDep",
    exact: true,
    icon: <MoneyCollectOutlined style={iconSize} />,
    component: () => <ReportCustomerDep />,
  },
  {
    key: 48,
    title: "Page Setting",
    menutitle: "Page Setting",
    path: "/pageSetting",
    exact: true,
    icon: <MoneyCollectOutlined style={iconSize} />,
    // code : ['TKH'],
    component: () => <PageSetting />,
  },
  {
    key: 49,
    title: "Bind User (7Game)",
    menutitle: "Bind User (7Game)",
    path: "/bindGameUser",
    exact: true,
    icon: <SyncOutlined style={iconSize} />,
    component: () => <BindGameUser />,
  },
  {
    key: 50,
    title: "Fortune Wheel",
    menutitle: "Fortune Wheel",
    path: "/fortuneWheel",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <FortuneWheel />,
  },
  {
    key: 51,
    title: "Onboard Domain",
    menutitle: "Onboard Domain",
    path: "/onboardDomain",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <OnboardDomain />,
  },
  {
    key: 52,
    title: "Bind SMS",
    menutitle: "Bind SMS",
    path: "/bindSMS",
    exact: true,
    icon: <BankOutlined style={iconSize} />,
    component: () => <CSBindSMS />,
  },
  {
    key: 53,
    title: "Bind SCB Connect",
    menutitle: "Bind SCB Connect",
    path: "/bindScbConnect",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <CSBindScbConnect />,
  },
  {
    key: 54,
    title: "Deposit Ledger Report",
    menutitle: "Deposit Ledger Report",
    path: "/reportDepositLedger",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <DepositLedger />,
  },
  {
    key: 55,
    title: "BO User",
    menutitle:"BO User",
    path: "/bouser",
    exact: true,
    icon: <UserOutlined style={iconSize} />,
    component: () => <CompanyCSManagement />,
  },
  {
    key: 404,
    menutitle: "",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const customerServiceWithdraw = [
  // {
  //   key: 0,
  //   title: "Withdraw Monitor",
  //   menutitle:"Withdraw Monitor",
  //   path: "/",
  //   exact: true,
  //   //icon: <ThaiBaht style={iconSize} />,
  //   icon: <DollarOutlined style={iconSize} />,
  //   component: () => <CSWithdrawMonitor />,
  // },
  {
    key: 1,
    title: "Withdraw Management",
    menutitle: "Withdraw Management",
    path: "/",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <CSWithdrawManagement5 />,
  },
  {
    key: 2,
    title: "CS Monitor",
    menutitle: "CS Monitor",
    path: "/ReportMonitor",
    exact: true,
    icon: <InsertRowAboveOutlined style={iconSize} />,
    component: () => <ReportMonitor />,
  },
  {
    key: 404,
    menutitle: "",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const marketing = [
  {
    key: 0,
    title: "FB Account",
    menutitle: "FB Account",
    path: "/campaignManagement",
    exact: true,
    icon: <FacebookOutlined style={iconSize} />,
    component: () => <MarketingCampaignManagement />,
  },
  {
    key: 1,
    title: "Daily Transaction Stats",
    menutitle: "Daily Transaction Stats",
    path: "/",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportDailyTrxnStats />,
  },
  {
    key: 2,
    title: "Agent Report",
    menutitle: "Agent Report",
    path: "/ReportAgent",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportAgent />,
  },
  {
    key: 404,
    menutitle: "",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const maintenance = [
  {
    key: 0,
    title: "Movie",
    menutitle: "Movie",
    path: "/",
    exact: true,
    icon: <VideoCameraOutlined style={iconSize} />,
    component: () => <Movie />,
  },
  {
    key: 1,
    title: "Adult Movie",
    menutitle: "Adult Movie",
    path: "/AdultMovie",
    exact: true,
    icon: <PlayCircleFilled style={iconSize} />,
    component: () => <AdultMovie />,
  },
];

export const reporter = [
  {
    key: 0,
    title: "Daily Transaction Stats",
    menutitle: "Daily Transaction Stats",
    path: "/",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportDailyTrxnStatsv2 />,
  },
  {
    key: 1,
    title: "Bank Verification Stats",
    menutitle: "Bank Verification Stats",
    path: "/bankVerifyStats",
    exact: true,
    component: () => <BankVerifyStats />,
  },
  {
    key: 2,
    title: "Deposit Ledger Report",
    menutitle: "Deposit Ledger Report",
    path: "/reportDepositLedger",
    exact: true,
    component: () => <DepositLedgerV2 />,
  },
  {
    key: 3,
    title: "Withdraw Ledger Report",
    menutitle: "Withdraw Ledger Report",
    path: "/reportWithdrawLedger",
    exact: true,
    component: () => <WithdrawLedger />,
  },
  {
    key: 4,
    title: "SMS Costing Report",
    menutitle: "SMS Costing Report",
    path: "/reportSmsCosting",
    exact: true,
    component: () => <ReportSmsCosting />,
  },
  {
    key: 404,
    menutitle: "",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const ext_finance = [
  {
    key: 0,
    title: "Daily Transaction Stats",
    menutitle: "Daily Transaction Stats",
    path: "/",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportDailyTrxnStatsv2 />,
  },
  {
    key: 1,
    title: "Bank Verification Stats",
    menutitle: "Bank Verification Stats",
    path: "/bankVerifyStats",
    exact: true,
    component: () => <BankVerifyStats />,
  },
  {
    key: 2,
    title: "Deposit Ledger Report",
    menutitle: "Deposit Ledger Report",
    path: "/reportDepositLedger",
    exact: true,
    component: () => <DepositLedgerV2 />,
  },
  {
    key: 3,
    title: "Withdraw Ledger Report",
    menutitle: "Withdraw Ledger Report",
    path: "/reportWithdrawLedger",
    exact: true,
    component: () => <WithdrawLedger />,
  },
  {
    key: 4,
    title: "SMS Costing Report",
    menutitle: "SMS Costing Report",
    path: "/reportSmsCosting",
    exact: true,
    component: () => <ReportSmsCosting />,
  },
  {
    key: 5,
    title: "Company Profit Report",
    menutitle: "Company Profit Report",
    path: "/reportCompanyProfit",
    exact: true,
    component: () => <ReportCompanyProfit />,
  },
  {
    key: 404,
    menutitle: "",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const admin = [
  {
    key: 0,
    title: "Daily Transaction Stats",
    menutitle: "Daily Transaction Stats",
    path: "/",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportDailyTrxnStatsv2 />,
  },
  {
    key: 1,
    title: "Brand Management",
    menutitle: "Brand Management",
    path: "/brandManagement",
    exact: true,
    component: () => <BrandManagement />,
  },
  {
    key: 2,
    title: "Bank Verification Stats",
    menutitle: "Bank Verification Stats",
    path: "/bankVerifyStats",
    exact: true,
    component: () => <BankVerifyStats />,
  },
  {
    key: 3,
    title: "Deposit Ledger Report",
    menutitle: "Deposit Ledger Report",
    path: "/reportDepositLedger",
    exact: true,
    component: () => <DepositLedgerV2 />,
  },
  {
    key: 4,
    title: "Withdraw Ledger Report",
    menutitle: "Withdraw Ledger Report",
    path: "/reportWithdrawLedger",
    exact: true,
    component: () => <WithdrawLedger />,
  },
  {
    key: 5,
    title: "BO User",
    menutitle:"BO User",
    path: "/bouser",
    exact: true,
    // icon: <UserOutlined style={iconSize} />,
    component: () => <CompanyCSManagement />,
  },
  {
    key: 6,
    title: "SMS Costing Report",
    menutitle: "SMS Costing Report",
    path: "/reportSmsCosting",
    exact: true,
    component: () => <ReportSmsCosting />,
  },
  {
    key: 7,
    title: "Company Profit Report",
    menutitle: "Company Profit Report",
    path: "/reportCompanyProfit",
    exact: true,
    component: () => <ReportCompanyProfit />,
  },
  {
    key: 8,
    title: "Bank Verify Summary Report",
    menutitle: "Daily Bank Verify Name report",
    path: "/bankVerifySum",
    exact: true,
    component: () => <BankVerifySum />,
  },
  {
    key: 9,
    title: "Bank Verify Detail Report",
    menutitle: "Bank Verify Detail Report",
    path: "/bankVerifyDetails",
    exact: true,
    component: () => <BankVerifyDetails />,
  },
  {
    key: 10,
    title: "Blacklist Management",
    menutitle: "Blacklist Management",
    path: "/blacklistManagement",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <BlacklistManagement />,
  },
  {
    key: 404,
    menutitle: "",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const generalPage = [
  {
    key: 0,
    title: "Daily Transaction Stats",
    menutitle: "Daily Transaction Stats",
    path: "/dailyTrxnStats",
    exact: true,
    icon: <DollarOutlined style={iconSize} />,
    component: () => <ReportDailyTrxnStatsv2 />,
  },
  {
    key: 1,
    title: "Deposit Ledger Report",
    menutitle: "Deposit Ledger Report",
    path: "/reportDepositLedger",
    exact: true,
    component: () => <DepositLedgerV2 />,
  },
  {
    key: 2,
    title: "Withdraw Ledger Report",
    menutitle: "Withdraw Ledger Report",
    path: "/reportWithdrawLedger",
    exact: true,
    component: () => <WithdrawLedger />,
  },
  {
    key: 3,
    title: "SMS Costing Report",
    menutitle: "SMS Costing Report",
    path: "/reportSmsCosting",
    exact: true,
    component: () => <ReportSmsCosting />,
  },
  {
    key: 4,
    title: "Company Profit Report",
    menutitle: "Company Profit Report",
    path: "/reportCompanyProfit",
    exact: true,
    component: () => <ReportCompanyProfit />,
  },
  {
    key: 5,
    title: "Bank Verify Summary Report",
    menutitle: "Daily Bank Verify Name report",
    path: "/bankVerifySum",
    exact: true,
    component: () => <BankVerifySum />,
  },
  {
    key: 6,
    title: "Bank Verify Detail Report",
    menutitle: "Bank Verify Detail Report",
    path: "/bankVerifyDetails",
    exact: true,
    component: () => <BankVerifyDetails />,
  },
  {
    key: 7,
    title: "Brand Management",
    menutitle: "Brand Management",
    path: "/brandManagement",
    exact: true,
    component: () => <BrandManagement />,
  },
  {
    key: 8,
    title: "BO User",
    menutitle:"BO User",
    path: "/bouser",
    exact: true,
    // icon: <UserOutlined style={iconSize} />,
    component: () => <CompanyCSManagement />,
  },
  {
    key: 9,
    title: "Blacklist Management",
    menutitle: "Blacklist Management",
    path: "/blacklistManagement",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <BlacklistManagement />,
  },
  {
    key: 10,
    title: "User Access Template",
    menutitle: "User Access Template",
    path: "/userAccessTemplate",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <UserAccessTemplate />,
  },
  {
    key: 11,
    title: "BO Maintenance Setting",
    menutitle: "BO Maintenance Setting",
    path: "/boMaintenanceSetting",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <BoMaintenanceSetting />,
  },
  {
    key: 12,
    title: "BO Deployment Setting",
    menutitle: "BO Deployment Setting",
    path: "/boDeploymentSetting",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <BoDeploymentSetting />,
  },
  {
    key: 997,
    title: "Maintenance",
    menutitle: "Maintenance",
    path: "/maintenance",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <Maintenance />
  },
  {
    key: 998,
    title: "Bank Verification Stats",
    menutitle: "Bank Verification Stats",
    path: "/bankVerifyStats",
    exact: true,
    component: () => <BankVerifyStats />,
  },
  {
    key: 999,
    title: "Change Password",
    menutitle: "Change Password",
    path: "/changePassword",
    exact: true,
    icon: <SettingOutlined style={iconSize} />,
    component: () => <ChangePassword />,
  },
  {
    key: 404,
    menutitle: "",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const route = {
  /**
   * others
   */
  Default: "/",
  All: "*",
  AgentManagement: "/agentManagement",
  Dashboard: "/dashboard",
  Login: "/login",
  Logout: "/logout",

  /**
   * agent
   */
  Agent: "/agent",
  AgentProfile: "/agentProfile",
  QRcode: "/qrCode",
  AgentReportComm: "/agentReportComm",

  /**
   * share holder
   */
  SH: "/shareHolder",
  SHDashboard: "/dashboard",
  SHProfile: "/profile",
  SHDownlineManagement: "/downlineManagement",
  SHDownlineManagementV2: "/downlineManagementV2",
  SHAgentManagement: "/agentManagement",
  SHPaymentManagement: "/paymentManagement",
  SHWinLose: "/winLose",
  SHCommission: "/commission",
  SHGameInfo: "/gameInfo",
  SHManagement: "/shareHolderManagement",
  SHReportComm: "/commBalance",
  SHReportPlayerShareholder: "/reportPlayerShareholder",
  SHReportSettlement: "/reportSettlement",

  /*
   *customer service
   */
  CS: "/customerService",
  CSDashboard: "/dashboard",
  CSProfile: "/profile",
  CSDepositManagement: "/depositManagement",
  CSDepositManagement2: "/depositManagement2",
  CSDepositManagement3: "/depositManagement3",
  CSDepositManagement4: "/depositManagement4",
  CSWithdrawMonitor: "/withdrawMonitor",
  CSWithdrawManagement: "/withdrawManagement",
  CSWithdrawManagement2: "/withdrawManagement2",
  CSWithdrawManagement3: "/withdrawManagement3",
  CSWithdrawManagement4: "/withdrawManagement4",
  CSWithdrawManagement5: "/withdrawManagement5",
  CSMemberManagement: "/memberManagement",
  CSMemberManagementV2: "/memberManagementV2",
  CSSystemHealth: "/systemHealth",
  CSBankManagement: "/bankManagement",
  CSBankManagementV2: "bankManagementV2",
  CSBindSMS: "/bindSMS",
  CSTopUpManagement: "/topUpManagement",
  CSManualDepositManagement: "/manualDepositManagement",
  ReportBankTrxn: "/reportBankTrxn",
  ReportBankTrxnBankApp: "/reportBankTrxnBankApp",
  ReportBankStatement: "/reportBankStatement",
  MarqueeManagement: "/marqueeManagement",
  OtpCheck: "/otpCheck",
  MaintenanceManagement: "/maintenanceManagement",
  NewsModalManagement: "/newsModalManagement",
  FundTransfer: "/fundTransfer",
  OpsModuleManagement: "/opModuleManagement",
  ReportDailySales: "/reportDailySales",
  ReportMasterData: "/reportMasterData",
  ReportMonitor: "/reportMonitor",
  ReportDailyTrxnStats: "/reportDailyTrxnStats",
  ReportDailyTrxnStatsv2: "/reportDailyTrxnStatsv2",
  ReportVipPlayer: "/reportVipPlayer",
  ReportFortuneWheel: "/reportFortuneWheel",
  GameStats: "/gameStats",
  ReportDailyBankStats: "/reportDailyBankStats",
  ReportDailyBankTrxnStats: "/reportDailyBankTrxnStats",
  ReportPlayer: "/reportPlayer",
  ReportTopList: "/ReportTopList",
  ReportAgents: "/reportAgent",
  ReportCustomerDep: "/reportCustomerDep",
  PageSetting: "/pageSetting",
  BindGameUser: "/bindGameUser",
  ReportDepositLedger: "/reportDepositLedger",

  // ReportDepSystemVsManual: "/reportDepSystemVsManual",
  /*
   *Marketing
   */
  MarketingCampaignManagement: "/campaignManagement",

  // Admin
  BrandManagement: "/brandManagement",


  /*
   *Maintenance
   */
  MovieManagement: "/MovieManagement",

  /*
   *company
   */
  Company: "/company",
  CDashboard: "/dashboard",
  CProfile: "/profile",
  CShareHolderManagement: "/shareHolderManagement",
  CCSManagement: "/customerServiceManagement",
  CSetting: "/setting",
  CBankManagement: "/bankManagement",
  CPaymentManagement: "/paymentManagement",
  CWinLose: "/WinLose",
  CCommision: "/commission",
  CGameInfo: "/gameInfo",
  CSupplierGameInfo: "/supplierGameInfo",
  FortuneWheel: "/fortuneWheel",
  CSBindSMS: "/bindSMS",
  UserAccessTemplate: "/userAccessTemplate",

  /*
   *root
   */
  Rompany: "/company",
  RDashboard: "/dashboard",
  RProfile: "/profile",
  RShareHolderManagement: "/shareHolderManagement",
  RCSManagement: "/customerServiceManagement",
  RSetting: "/setting",
  RBankManagement: "/bankManagement",
  RPaymentManagement: "/paymentManagement",
  RWinLose: "/WinLose",
  RCommision: "/commission",
  RGameInfo: "/gameInfo",
  RSupplierGameInfo: "/supplierGameInfo",
  RUserManagement: "/userManagement",

  /**
   * general
   */
  GFTransferCredit: "/TransferCreditByMobileNo",
  GFUploadTopupReceipt: "/UploadTopupReceipt",
  ChangePassword: "/changePassword"
};
