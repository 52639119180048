import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import style from "../../resource/style/style";
import { Button, Card, Col, DatePicker, Form, Modal, PageHeader, Row, Select, Spin, Statistic, Tabs } from "antd";
import { Table } from "ant-table-extensions";
import {
    SearchOutlined,
    CloseOutlined,
    CheckOutlined,
    ReloadOutlined,
    FileExcelOutlined,
  } from "@ant-design/icons";
import moment from "moment";
import { Option } from "antd/lib/mentions";
import {  getBankVeriStatus, getBrandList, getBrandListV2, reportDepositLedgerV2 } from "../../config/networkConfig";
import { alertData, sharedErrorAlert, sharedFailAlert } from "../../common/constants/sharedMsgBox";
import { settings } from "../../config/appConfig";
import { isMobile } from "react-device-detect";
import FormItem from "antd/lib/form/FormItem";
import { brandOwnerVw } from "../../library/permission";

export default function DepositLedgerV2() {
    const { role, userId, username } = useSelector((state) => state.login);
    const {TabPane} = Tabs;

    // Data from db state
    const [accountList, setAccountList] = useState([]);
    const [depositList, setDepositList] = useState([]);
    const [totalDepositAmount, setTotalDepositAmount] = useState(0);
    const [allReport, setAllReport] = useState([]);


    // Picker state
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [type, setType] = useState("thisMonth");

    // retrieve brand
    const [brand, setBrand] = useState([]);
    const [brandV2, setBrandV2] = useState([]);
    const [brandNum, setBrandNum] = useState('0');
    const [brandData, setBrandData] = useState([]);
    const [filteredBrandData, setFilteredBrandData] = useState([]);


    // export data
    const [exptBrand, setExptBrand] = useState([]);
    const [exptBrandList, setExptBrandList] = useState([]);
    const [showExptModal, setShowExptModal] = useState(false);
    const [exptBrandData, setExptBrandData] = useState([]);
    const [exptBrandNum, setExptBrandNum] = useState('');
    const [exptBrandAll, setExptBrandAll] = useState([]);
    const [todayDate, setTodayDate] = useState("");

    // bank status card
    const [bankStatLdg, setBankStatLdg] = useState(false);
    const [bankStatReloadLdg, setBankStatReloadLdg] = useState(false);
    const [bankStatSuccessCount, setBankStatSuccessCount] = useState(0);
    const [bankStatFailCount, setBankStatFailCount] = useState(0);

    // Loader state
    const [tableLoading, setTableLoading] = useState(false);

    // useEffects
    useEffect(() => {
        retrieveBrand();
        retrieveBankStats();
        getTodayDate();
      }, []);

    useEffect(() => {
        pickerWithType()
    },[type])

    // Table Data
    const columns = [
        {
          ellipsis: true,
          title: "Deposit Time",
          dataIndex: "depositTime",
          width: 100,
          sorter: (a, b) => new Date(a.depositTime) - new Date(b.depositTime),
        },
        {
          ellipsis: true,
          title: "To Account",
          dataIndex: "toAccount",
          width: 80,
          filters : accountList,
          onFilter: (value, record) => record.toAccount === value,
        },
        {
          ellipsis: true,
          title: "Bank Details",
          dataIndex: "bankDetails",
          width: 80,
        },
        {
          ellipsis: true,
          title: "Amount",
          dataIndex: "amount",
        //   align: "center",
          width: 50,
          render: (text) => {
            return Number(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }
        },
        {
          ellipsis: true,
          title: "Receive By API",
          dataIndex: "receivedByAPI",
          editable: true,
          width: 80,
          align: "center",
          render: (record) => {
            switch (record) {
                case '0':
                  return <CloseOutlined style={{ color: "red"}} />;
                case '1':
                  return <CheckOutlined style={{ color: "green"}} />; // Unicode for check mark: &#x2714;
            }
          },
        },
        {
          ellipsis: true,
          title: "Received By SMS",
          dataIndex: "receivedBySMS",
          editable: true,
          width: 80,
          align: "center",
          render: (record) => {
            switch (record) {
                case '0':
                  return <CloseOutlined style={{ color: "red"}} />;
                case '1':
                  return <CheckOutlined style={{ color: "green"}} />; // Unicode for check mark: &#x2714;
            }
          },
        },
        {
          ellipsis: false,
          title: "Received By SCB Connect",
          dataIndex: "receivedBySCB",
          editable: true,
          width: 80,
          align: "center",
          render: (record) => {
            switch (record) {
                case '0':
                  return <CloseOutlined style={{ color: "red"}} />;
                case '1':
                  return <CheckOutlined style={{ color: "green"}} />; // Unicode for check mark: &#x2714;
            }
          },
        },
        
    ];

    // Functions
    const handlestartDate = (date) => {
        setType("custom")
        setStartDate(date)
    }

    const handleendDate = (date) => {
        setType("custom")
        setEndDate(date)
    }

    const pickerWithType = () => {
        if (type === 'custom'){
            setStartDate(startDate);
            setEndDate(endDate);
        }
        if (type === 'today'){
          let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
          setStartDate(moment().set('date', today))
          setEndDate(moment().set('date', today))
        } 
        if (type === 'yesterday'){
          let yesterday = new Date(new Date().getTime());
          yesterday.setDate(yesterday.getDate()-1);
          let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
          let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
          let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
          yestMonth = yestMonth - 1
    
          setStartDate(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
          setEndDate(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
        }
        if (type === 'thisWeek'){
          let today = new Date();
          let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
          let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
          let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
          let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
              firstMonthOfWeek = firstMonthOfWeek - 1; 
          let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
          let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
          let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
          let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
              lastMonthOfWeek = lastMonthOfWeek - 1;
          setStartDate(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
          setEndDate(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
        }
        if (type === 'thisMonth'){
          let date = new Date(), y = date.getFullYear(), m = date.getMonth();
          let firstDay = new Date(y, m, 1);
          let lastDay = new Date(y, m + 1, 0);
    
          let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
    
          let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
          lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
          month = month - 1
    
          setStartDate(moment().set({'year': year, 'month': month, 'date': 1}))
          setEndDate(moment().set({'year': year, 'month': month, 'date': lastDay}))
        }
        if (type === 'lastMonth'){
          let date = new Date(), y = date.getFullYear(), m = date.getMonth();
          let firstDay = new Date(y, m-1, 1);
          let lastDay = new Date(y, m, 0);
    
          let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
          let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})
    
          let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
          lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
          month = month - 1
    
          setStartDate(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
          setEndDate(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
        }
    };

    const handleSearch = () => {
        if(!brand || brand == '') {
            alertData.title = "Field Required";
            alertData.msg = "Please select Brand !";
            sharedErrorAlert(alertData);
            return;
        }

        if (!startDate) {
            alertData.title = "Field Required";
            alertData.msg = "Please select Start Date !";
            sharedErrorAlert(alertData);
            return;
        }

        if (!endDate) {
            alertData.title = "Field Required";
            alertData.msg = "Please select End Date !";
            sharedErrorAlert(alertData);
            return;
        }

        if (endDate < startDate || startDate > endDate) {
            alertData.title = "Error";
            alertData.msg = "Please select a valid Date Range !";
            sharedErrorAlert(alertData);
            return;
        }
        
        getDepositLedger()
    }

    // ==================== New export functions for brands ======================== //

    const exportButtonRef = useRef(null);

    const onClickExportBtn = ()=> {
        if (allReport.length === 0 ) {
        alertData.title = "Error";
        alertData.msg = "No data to download !";
        sharedErrorAlert(alertData);
        return;
        }
        if (depositList.length === 0 ) {
        alertData.title = "Error";
        alertData.msg = "No data to download !";
        sharedErrorAlert(alertData);
        return;
        }
        const exportButton = document.getElementById("export-button-1");
        if (exportButton) {
            console.log("Export button found:");
            exportButton.click();
        } else {
            console.error("Export button not found!");
        }
    }
    
    const onCloseExportModal = () => {
        setShowExptModal(false);
        setExptBrand('')
    };

    const handleChangeExptBrand = (e) => {
        if(e){
            const foundBrand = exptBrandAll.find(brand => brand.brandName === e);
            handleTabClick(foundBrand.key);
            setExptBrandList(foundBrand);
            setExptBrand(foundBrand.brandName)
        } else {
            setExptBrand('');
        }
      }

    const handleClearAllExpt = () => {
        setExptBrand('');
    }
    
    const handleSearchExptBrand = (e) => {
        const foundBrand =  exptBrandData.find(brand => brand.brandName.toLowerCase() === e.toLowerCase());
        if (foundBrand) {
        setExptBrandNum(foundBrand.key.toString())
        }
    }

    const onShowExptModal = () => {
        setShowExptModal(true);
    };

    // ==================== New Handle functions for brands ======================== //
    const handleBrandChange = (e) => {
        setAllReport([]);
        let searchBrandList = [];
        let filteredList = [];
    
        if(e.length > 0){
          for(let i = 0; i < e.length; i++){
            const foundBrand = brandData.find(brand => brand.brandName === e[i]);
            filteredList.push(foundBrand);
            searchBrandList.push(foundBrand.brandName)
          }
        }
        if(filteredList && filteredList.length > 1){
          filteredList.sort((a,b) => a.key - b.key)
        }
        setFilteredBrandData(filteredList);
        setBrand(searchBrandList);
        setExptBrandAll(filteredList);

        let lowerCaseBrand = [];
        for(let i =0; i < searchBrandList.length;i++){
            let current = searchBrandList[i];
            lowerCaseBrand = lowerCaseBrand.concat(current.toLowerCase())
        }
        setBrandV2(lowerCaseBrand);
      }

    const handleBrandSearch = (e) => {
    const foundBrand =  brandData.find(brand => brand.brandName.toLowerCase() === e.toLowerCase());
    if (foundBrand) {
        setBrandNum(foundBrand.key.toString())
    }
    }

    const handleAllBrand = () => {
        setFilteredBrandData(brandData);
        const brandNames = brandData.map(brand => brand.brandName);
        handleBrandChange(brandNames)
    }


    const handleClearAll = () => {
        setFilteredBrandData([]);
        setBrand([])
    }

    const handleTabClick = (e) => {
        const foundBrand = brandData.find(brand => brand.key == e);
        if (foundBrand){
            setBrandNum(foundBrand.key.toString());
        }

        let lowCaseFoundBrand = foundBrand.brandName.toLowerCase();
        const findDepositReport = allReport.find(brand => brand.key == lowCaseFoundBrand);
        if(findDepositReport){
            setDepositList(findDepositReport.depositData)
            setExptBrandData(findDepositReport.depositData)
            const totalAmount = findDepositReport.depositData.reduce((sum, record) => sum + record.amount, 0)
            setTotalDepositAmount(totalAmount);
        }

        const findDepositAcc = findDepositReport.depositData.reduce((list, record) => {
            if(!list.includes(record.toAccount)){
                list.push(record.toAccount);
            }
            return list;
        }, []);
        if(findDepositAcc){
            let filterAccOptions = [];
            for(let i = 0; i < findDepositAcc.length; i++){
                filterAccOptions.push({text: findDepositAcc[i], value: findDepositAcc[i] })
            }
            setAccountList(filterAccOptions)
        }

    }

    const handleSummaryClick = () => {
        window.location.href = '/bankVerifyStats';
    }

// ==================== API CALLS ======================== //

    const getTodayDate = () => {
        let today = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
        setTodayDate(today);
    }

    const retrieveBrand = async() => {
        let requestData = {
            columnRequire : [
                'brandNum AS brandNum',
                'brandName AS brandName'
            ],
            userType : role,
            userId : userId
        }

        let response = await getBrandListV2(requestData);
        let result = await response.json();
        if (result.status === 'ok'){
        if(Object.keys(result.data).length > 0){
            let brandListData = result.data.map((obj) => {
                return {
                    key                 :   obj.brandNum,
                    brandName           :   obj.brandName,
                }
            })
            setBrandData(brandListData);
        } else {
            setBrandData([])
        }
        }   else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }
    }

    const retrieveBankStats = async() => {
        setBankStatLdg(true);
        let requestData = {
            status      :  '0',
        }

        let response = await getBankVeriStatus(requestData);
        let result = await response.json();
        if (result.status === 'ok'){
        if(Object.keys(result.data).length > 0){
            let successListData = result.data.filter((obj) => obj.status == 1);
            let failedListData = result.data.filter((obj) => obj.status == 9);

            setBankStatSuccessCount(successListData.length)
            setBankStatFailCount(failedListData.length)
        } else {
            console.log('getBankVeriStatus result only', result);
        }
        setBankStatLdg(false);
        }   else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
            setBankStatLdg(false);
        }
    }

    const getDepositLedger = async () => {
        setTableLoading(true);
        let requestData = {
            startDate : moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
            brand : brandV2,
        };
        const respDepoLedger = await reportDepositLedgerV2(requestData);
        let response = await respDepoLedger.json();

        if(response.status === 'ok'){
            let depositDataList = response.data.map((data) =>{
                return {
                    key              :  data.brand,
                    depositData      :  data.data,
                }
            })
            setAllReport(depositDataList);
            setBrandNum(filteredBrandData[0].key.toString())
            setDepositList(depositDataList[0].depositData);
            setTotalDepositAmount(depositDataList[0].depositData.reduce((sum, record) => sum + record.amount, 0));
        } else {
            setAllReport([])
        }
        setTableLoading(false);
    }

    return (
        <div>
            <div className="eachPageContent">
                <div>
                    <PageHeader
                        ghost={false}
                        >
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{width: '60%'}}>
                                <div>
                                    <Select 
                                        mode="multiple" 
                                        style={{
                                        marginBottom: 10, 
                                        marginLeft:10, 
                                        width:"56.8%"
                                        }} 
                                        showSearch 
                                        placeholder="Select a Brand"
                                        onChange={handleBrandChange} 
                                        onSearch={handleBrandSearch}
                                        allowClear
                                        suffixIcon={<SearchOutlined/>}
                                        value={brand}
                                        onClear={handleClearAll}
                                        >
                                        {brandData.map((e) => <Option key={e.brandName} value={e.brandName}>{e.brandName}</Option>)}
                                    </Select>
                                    <Button 
                                        type="primary"
                                        onClick={() => handleAllBrand()}
                                        style={{marginLeft: 10}}
                                        >
                                        Select All Brands
                                    </Button>
                                </div>
                                <div style={{...style.pageHeaderContainer, display: "inline"}}>
                                    <DatePicker 
                                        key="1"
                                        style={{...style.datePickerStyle, ...style.refreshBtn}}
                                        format="YYYY-MM-DD"
                                        onChange={handlestartDate}
                                        value={startDate}
                                        placeholder="Select Start Date"
                                        disabledDate={(current) => {
                                            return current > moment();
                                        }}
                                    />
                                    <DatePicker 
                                        key="2"
                                        style={{...style.datePickerStyle, ...style.refreshBtn}}
                                        format="YYYY-MM-DD"
                                        onChange={handleendDate}
                                        value={endDate}
                                        placeholder="Select End Date"
                                        disabledDate={(current)=> {
                                            if (startDate) {
                                            return current < startDate || current > moment();//.subtract(1, 'day');
                                            }else {
                                            return  current > moment();//.subtract(1, 'day');
                                            }
                                        }}
                                    />
                                    <div style={{display: "inline-block"}}>
                                        <Select
                                            style={style.datePickerSelectionStyle}
                                            value={type}
                                            onChange={setType}
                                        >
                                            <Option value="custom">Custom</Option>
                                            <Option value="today">Today</Option>
                                            <Option value="yesterday">Yesterday</Option>
                                            <Option value="thisWeek">This Week</Option>
                                            <Option value="thisMonth">This Month</Option>
                                            <Option value="lastMonth">Last Month</Option>
                                        </Select> 
                                        <Button
                                            style={{transform: "translateY(0px)"}} 
                                            type={"primary"}
                                            icon={<SearchOutlined />}
                                            loading={tableLoading}
                                            onClick={handleSearch}
                                        />
                                        {allReport.length > 0 
                                        ?
                                        <Button 
                                            type={"primary"} 
                                            style={style.refreshBtn} 
                                            icon={<FileExcelOutlined />}
                                            onClick={() => {
                                                onShowExptModal()
                                            }}
                                        >
                                            Export to Excel
                                        </Button>
                                        :
                                            <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            {brandOwnerVw(role, username) ?
                            <Card
                                style={{
                                width: 500,
                                backgroundColor: "#e2f2f0",
                                borderRadius: 10,
                                maxHeight: 200,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: 10,
                                }}
                            >
                                {bankStatLdg ? (
                                <Spin 
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyItems: "center",
                                    alignItems: "center",
                                }}
                                size="large"
                                ></Spin>

                                ) : (
                                <div>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <p style={{height: "25%", fontSize: "20px", marginBottom: 0 }}>
                                    Bank Verification Stats (Last Hour): 
                                    </p>
                                    <span >
                                        <Button
                                        style={{
                                            backgroundColor: "initial",
                                            color: "black",
                                            borderColor: "black",
                                        }}
                                        type="default"
                                        shape="round"
                                        icon={<ReloadOutlined/>}
                                        loading={bankStatReloadLdg}
                                        onClick={() => 
                                            retrieveBankStats()
                                        }
                                        >
                                        Refresh
                                        </Button>
                                    </span>
                                    </div>
                                    <Row style={{display: 'flex', justifyContent:'space-between', width:'80%',  alignItems:'center'}} >
                                        <Col  style={{ height: "25%", marginTop: "2%" }}>
                                                <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                                Success:
                                                </span> 
                                                <br/>
                                                <span style={{ fontSize: "24px", color:'green' }}>
                                                {bankStatSuccessCount}
                                                </span>
                                        </Col>
                                        <Col  style={{ height: "25%", marginTop: "2%",}}>
                                                <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                                Failed:
                                                </span> 
                                                <br/>
                                                <span style={{ fontSize: "24px", color:'red' }}>
                                                {bankStatFailCount}
                                                </span>
                                        </Col>
                                    </Row>
                                        <Row style={{ marginTop: "2%", marginBottom: 0 }}>
                                            <Button 
                                            type="default"
                                            shape="round"
                                            icon={<SearchOutlined/>}
                                            loading={bankStatReloadLdg}
                                            onClick={handleSummaryClick}
                                            >
                                            View Summary
                                            </Button>
                                        </Row>
                                </div>
                                )}
                            </Card>
                            : <></>}
                        </div>
                            
                    </PageHeader>
                    <Modal 
                        title="Export to Excel" 
                        visible={showExptModal} 
                        onCancel={onCloseExportModal}
                        footer={null}
                        mask
                        maskClosable={false}
                        >
                        <Form
                        layout="vertical"
                        style={{display: 'flex', flexDirection: 'column'}}
                        >
                        <FormItem label="Selected Brand " style={{fontWeight:'bold'}}>
                            <Select 
                            style={{width:"60%",fontWeight:'normal'}} 
                            showSearch 
                            placeholder="Select brand to export.."
                            onChange={handleChangeExptBrand} 
                            onSearch={handleSearchExptBrand}
                            allowClear
                            suffixIcon={<SearchOutlined/>}
                            onClear={handleClearAllExpt}
                            value={exptBrand}
                            >
                            {filteredBrandData.map((e) => <Option key={e.brandName} value={e.brandName}>{e.brandName}</Option>)}
                            </Select>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{marginLeft: '10px'}}
                                onClick={() => onClickExportBtn()}
                                ref={exportButtonRef}
                                >
                                Export to Excel
                            </Button>
                        </FormItem>
                        </Form>
                    </Modal>
                    <div style={{
                        backgroundColor: "#FFFFFF",
                        padding: 24,
                        marginTop: '-10px'
                        }}
                        >
                        <Tabs defaultActiveKey={brandNum} onTabClick={handleTabClick} activeKey={brandNum}>
                            {filteredBrandData.map((e) => {
                                return(
                                    <TabPane tab={e.brandName} key={e.key} disabled={allReport.length == 0} >
                                    <Card
                                        style={{
                                        width: "300px",
                                        backgroundColor: "#e2f2f0",
                                        borderRadius: 20,
                                        marginTop: "18px",
                                        marginBottom: "18px",
                                        }}
                                    >
                                        <Statistic title="Total Deposits" value={totalDepositAmount.toFixed(2)} />
                                    </Card>
                                    
                                    <Table
                                        options={{
                                        columnsButton: true,
                                        }}
                                        loading={tableLoading} 
                                        columns={columns}
                                        dataSource={depositList}
                                        className="depositLedgerReport"
                                        scroll={{ x: 100, y: null }}
                                        exportable={true}
                                        pagination={{
                                            defaultPageSize: isMobile ? 10 : 100,
                                            showSizeChanger: true,
                                            pageSizeOptions: isMobile ? ["5", "10", "20"] : settings.pageSizeOptions,
                                            style: style.paginationStyle,
                                        }}
                                        exportableProps={{
                                            fileName: "DepositLedgerReport_"+exptBrand,
                                            btnProps: {
                                              type: "primary",
                                              icon: <FileExcelOutlined />,
                                              id:"export-button-1",
                                            //   ref: exportButtonRef,
                                              children: <span>Export to Excel</span>,
                                              style: {display:"none"}
                                            },
                                          }}
                                    />
                                    </TabPane >
                                )
                            })}
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}