import React, { useState, useEffect, useRef } from "react";
import {
  Table, PageHeader, Button, Modal, Input,Badge, Select, Form, DatePicker, Row, Col, Tooltip, Popconfirm, Tabs, Dropdown, Menu, Upload, 
  InputNumber, Spin, Typography, Checkbox, Radio,
} from "antd";
import {
  userMemberLockV2,
  userMemberActiveV2,
  userMemberInactiveV2,
  csCreateMemberV2,
  csAvailBankList,
  csUpdateMember,
  userProfileBank,
  csVerifyMobile,
  csMemberWalletTrxn,
  csMemberGameSummary,
  csMemberGameTrxn,
  csMemberListV2,
  csMemberListV3,
  csMemberListAcctnum,
  csMemberListAcctnumV2,
  csMemberListGameId,
  userBankList,
  userBankAll,
  bankManageUpdate,
  userBankDelete,
  userBankNew,
  userBankUpdate,
  uploadReceiptV2,
  csBankList,
  csDepositAdjustNew,
  getReqToken,
  recoverWallet,
  userWalletHistory,
  requestToken,
  csRejectBankAccount,
  csUpdateWalletBalance,
  csUpdateUplineReferral,
  getBankList,
  getCompanyProfileData,
  getCurrentWalletBalance,
  adjustCredit,
  reportTopTransactionSummary,
  reportGameBettingSummary,
  reportDepWthList,
  getGameGroupPrefix,
  csRetrieveTwalletAcc,
  csAddTwalletAcc,
  csUpdateTwalletAcc,
} from "../../config/networkConfig";
import { useSelector } from "react-redux";
import { 
  SearchOutlined, InfoCircleFilled, DollarOutlined, EditOutlined, DeleteOutlined, DownOutlined, PayCircleOutlined, UploadOutlined,
  RotateLeftOutlined, WalletOutlined, ProfileOutlined, CopyOutlined, SaveOutlined, CloseCircleOutlined, QuestionCircleOutlined, 
} from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { settings } from "../../config/appConfig";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { FormatPt, FormatMask, FormatNumComm } from "../../library/numbers";
import { CMemberSummary } from "../customerService/components/CMemberSummary";
import { DEP_TYPE } from "../../common/constants/bankType";
import md5 from "md5";
import { 
  recoverMemberWallet,
  unlockMemberLockedBySystem,
  memberMgmtCrDelayDep, 
  unlockMember, 
  bankAccEdit, 
  memberMgmtVwMemberWallet, 
  memberMgmtCrMember,
  memberMgmtVwPlayerTrxn,
  memberMgmtRemoveCredit,
  memberMgmtUpdateUplineRef,
} from "../../library/permission";
import { bankList } from "../../common/constants/bankTransaction";
import { adjTypeList, translateAdjRemark } from "../../common/constants/adjustmentTypeList";
import style from "../../resource/style/style";
import { CS_USER_TYPE } from "../../common/constants/csuserType";
// import { genGameUser } from "../../common/constants/gameUser";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, sharedErrorAlertV2 } from "../../common/constants/sharedMsgBox";
import { BANK_INFO_TYPE } from "../../common/constants/bankType";

export default function MemberManagementV2() {
  const { TextArea } = Input;

  const adjustmentOption = [
    {
      label: 'Topup',
      value: 'Topup'
    },
    { 
      label: 'Adjustment',
      value: 'Adjustment',
    }
  ];
  const { Paragraph } = Typography;
  const { confirm } = Modal;
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [formDelayDep] = Form.useForm();
  const [formAdjustment] = Form.useForm();
  const [formRemoveCredit] = Form.useForm();
  const { Option } = Select;
  const { companyId , username, role, userId, issuper, compCode} = useSelector((state) => state.login);
  const [originData, setOriginData] = useState("");
  const [updatedData,setUpdatedData] = useState("");
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(true);
  const [reason, setReason] = useState("");
  const [memberUserId, setMemberUserId] = useState("");
  const [activeKey, setActiveKey] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loadings, setLoadings] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [editMemberModal, setEditMemberModal] = useState(false);
  const [csMemberListOptions, setCSMemberListOptions] = useState([]);
  const [csAvaBankList, setCSAvaBankListOptions] = useState([]);
  const [searchMobile,setSearchMobile] = useState("");
  const [playerTrxnURL, setPlayerTrxnURL] = useState("");
  //Permission

  //Data
  const [phoneNum, setPhoneNum] = useState("");
  const [countryCode, setCountryCode] = useState("60");
  const [pin,setPin] = useState("");
  //const [userId, setUserId] = useState("");
  const [bankcode, setBankCode] = useState("");  
  const [acctnum, setAcctNum] = useState("");  
  const [date, setDate] = useState("");
  const [acctname, setAcctname] = useState("");
  const [referCode, setReferCode] = useState("");
  const [oldReferCode, setOldReferCode] = useState("");
  const [lineId, setLineId] = useState("");
  const [gender, setGender] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [referEditDisable, setReferEditDisable] = useState(true);
  const [createMemberDisable, setCreateMemberDisable] = useState(true);
  const [editMemberDisable, setEditMemberDisable] = useState(false);
  const [prevbankcode, setPrevBankCode] = useState("");
  const [prevAcctnum,  setPrevAcctNum] = useState("");
  const [referralEditDisable, SetReferralEditDisable] = useState(true);
  const [referralVisible, SetReferralVisible] = useState(true);
  const [saveBtnLoading, SetSaveBtnLoading] = useState(false);

  //Wallet Transaction 
  const [viewTrx, setViewTrx] = useState("");
  const [puppetVisible, setPuppetVisible]= useState(false);
  const [csMemberWalletDt, setCSMemberWalletDt] = useState("");
  const [csMemberWalletBal, setCSMemberWalletBal] = useState(0);
  const [csMemberWalletComm, setCSMemberWalletComm] = useState(0);
  const [filteredPptTrxn, setFilterPptTrxn]= useState([]);

  //Turnover Transaction
  const [gameVisible, setGameVisible]= useState(false);
  const [gameTrxn, setGameTrxn]= useState([]);
  const [csGameBetAmt, setCSGameBetAmt] = useState(0);
  const [csGameUser, setCSGameUser] = useState("");
  const [csGameValidAmt, setCSGameValidAmt] = useState(0);
  const [csGameWinloss, setCSGameWinloss] = useState(0);
  const [csGameTurnover, setCSGameTurnover] = useState(0);
  const [csGameMbrTrxnAudit, setCSGameMbTrnxAudit]= useState(0);
  const [viewWallet, setViewWallet] = useState(false);

  //Wallet / Game Transaction Shared variables
  const [csSearchMemberId, setCSSearchMemberId] = useState("");
  const [csSearchPhone, setCSSearchPhone] = useState("");
  const [trxDateFrom, setTrxDateFrom] = useState("");
  const [trxDateTo, setTrxDateTo] = useState("");
  const [leapYearResult, setLeapYearResult] = useState(moment(moment().format("YYYY")).isLeapYear());
  const [maxDay, setMaxDay] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [hour, setHour] = useState("");

  const [min, setMin] = useState("");
  const [defaultHour, setDefaultHour] = useState();
  const [defaultMin, setDefaultMin] = useState();

  const reportDateFrom = useRef(null);
  const reportDateTo = useRef(null);
  const [trxLoading, setTrxLoading] = useState(false);
  
  //Customer Bank Details Dialog
  const [trxBankLoading,              setTrxBankLoading] = useState(false);
  const [editingKey,                  setEditingKey] = useState("");

  //Customer Details Dialog
  const [viewMemberTxt,               setViewMemberText] = useState("View");
  const [viewMemberDlgTitle,          setViewMemberDlgTitle] = useState("View Member");
  const [viewMemberDlgOK,             setViewMemberDlgOK] = useState("Save");
  const [editMemberEnable,            setEditMemberEnable] = useState(false);
  const [rejectMemberEnable,          setRejectMemberEnable] = useState(false);

  // User Bank 
  const [userBankData,                setUserBankData] = useState([]);
  const [editMemberId,                setEditMemberId] = useState("");
  const [editBankLoading,             setEditBankLoading] = useState(false);
  const [addBankLoading,              setAddBankLoading] = useState(false);
  const [editBankName,                setEditBankName] = useState("");
  const [editBankCode,                setEditBankCode] = useState("");
  const [editBankAccNum,              setEditBankAccNum] = useState("");
  const [editBankAccName,             setEditBankAccName] = useState("");
  const [oldBankAccName,              setOldBankAccName] = useState("");
  const [addBankModal,                setAddBankModal] = useState(false);
  const [editBankModal,               setEditBankModal] = useState(false);

  // User Twallet 
  const [twalletId,                      setTwalletId] = useState(""); 
  const [twalletUserId,                  setTwalletUserId] = useState(""); 
  const [twalletAccNum,                  setTwalletAccNum] = useState(""); 
  const [twalletAccName,                 setTwalletAccName] = useState(""); 
  const [userTwalletData,                setUserTwalletData] = useState([]);
  const [addTwalletLoading,              setAddTwalletLoading] = useState(false);
  const [editTwalletLoading,             setEditTwalletLoading] = useState(false);
  const [editTwalletAccNum,              setEditTwalletAccNum] = useState("");
  const [editTwalletAccName,             setEditTwalletAccName] = useState("");
  const [editTwalletAccStatus,           setEditTwalletAccStatus] = useState(); 
  const [oldTwalletAccName,              setOldTwalletAccName] = useState("");
  const [oldTwalletAccNum,               setOldTwalletAccNum] = useState("");
  const [addTwalletModal,                setAddTwalletModal] = useState(false);
  const [editTwalletModal,               setEditTwalletModal] = useState(false);

  //User Wallet History 
  const [userWalletData,              setUserWalletData] = useState("");
  const [userWalletPageSize,          setUserWalletPageSize] = useState(100);
  const [userWalletPageNum,           setUserWalletPageNum] = useState(1);
  const [userWalletTotal,             setUserWalletTotal] = useState(10);
  const [userWltTableLoading,         setUserWltTableLoading] = useState(false);

  //Create Deposit Dialog
  const [manualDepositVisible,        setManualDepositVisible] = useState(false);
  const [createDepositDisable,        setCreateDepositDisable] = useState(true);
  const [csBankListOptions,           setCSBankListOptions] = useState([]);
  const [delayDepPhoneNum,            setDelayDepPhoneNum] = useState("");
  const [delayDepCountryCode,         setDelayDepCountryCode] = useState("60");
  const [delayDepBankLisOptions,      setDelayDepBankLisOptions] = useState([]);
  const [delayDepDt,                  setDelayDepDt] = useState("");

  const [delayDepFromBankData,        setDelayDepFromBankData] = useState("");
  const [delayDepFromBankcode,        setDelayDepFromBankcode] = useState("");
  const [delayDepFromAcctnum,         setDelayDepFromAcctnum] = useState("");
  const [delayDepFromAcctname,        setDelayDepFromAcctname] = useState("");

  const [delayDepToBankData,          setDelayDepToBankData] = useState("");
  const [delayDepToBankCode,          setDelayDepToBankcode] = useState("");
  const [delayDepToAcctnum,           setDelayDepToAcctnum] = useState("");
  const [delayDepToAcctname,          setDelayDepToAcctname] = useState("");

  const [delayDepAmt,                 setDelayDepAmt] = useState(0);
  const refDelayDepDt = useRef(null);
  const [delayDepSubmit,              setDelayDepSubmit] = useState(true);

  // Credit Adjustment
  const [formCreditAdjustment] = Form.useForm();
  const [creditAdjustmentVisible,     setCreditAdjustmentVisible] = useState(false);
  const [submitCreditAdjustmentDisable,   setSubmitCreditAdjustmentDisable] = useState(true);
  const [creditAdjustmentButtonLoading,   setCreditAdjustmentButtonLoading] = useState(false);

  const [selectedUserPhoneNum,        setSelectedUserPhoneNum] = useState("");
  const [selectedUserCountryCode,     setSelectedUserCountryCode] = useState("60");
  const [creditAdjustmentAmt,         setCreditAdjustmentAmt] = useState(1);
  const [creditAdjustmentType,        setCreditAdjustmentType] = useState("Topup");
  const [manualBankTime,              setManualBankTime] = useState("");
  const [creditAdjustmentBankTime,    setCreditAdjustmentBankTime] = useState("");
  const [creditAdjustmentRemarkListOption,  setCreditAdjustmentRemarkListOption] = useState([]);
  const [creditAdjustmentRemark,      setCreditAdjustmentRemark] = useState("");
  const [oneMinuteBugChecked,         setOneMinuteBugChecked] = useState(false);

  //Create Adjustment
  const [createAdjustDisable,         setCreateAdjustDisable] = useState(true);
  const [adjustAmt,                   setAdjustAmt] = useState(0);
  const [adjustRemarkListOptions,     setAdjustRemarkListOptions] = useState([]);
  const [adjustRemark,                setAdjustRemark] = useState("");
  const [adjustSubmit,                setAdjustSubmit] = useState(true);
  const [recoverSubmit,               setRecoverSubmit] = useState(true);

  // Create Update Wallet Balance
  const [createRemoveCreditDisable,   setCreateRemoveCreditDisable] = useState(true);
  const [removeCreditAmt,             setRemoveCreditAmt] = useState(0);
  const [removeCreditSubmit,          setRemoveCreditSubmit] = useState(true);

  //Game User Id
  const [gameUserVisible,              setGameUserVisible]= useState(false);
  const [gameUserSecret,               setGameUserSecret] = useState("");

  // Set Secret
  const [secret,                       setSecret] = useState("");
  const [oldSecret,                    setOldSecret] = useState("");
  const [newSecret,                    setNewSecret] = useState("");
  const [secretEditDisable,            setSecretEditDisable] = useState(true);
  const [secretVisible,                setSecretVisible] = useState(true);

  // Game Provider Prefix
  const [gameProviderPrefix,           setGameProviderPrefix] = useState([]);

  //User Summary
  const [topTrxnSummary,         setTopTrxnSummary] = useState([]);
  const [userIdResult,             setUserIdResult] = useState([]);
  const [gameBetSummary,         setGameBetSummary] = useState([]);
  const [depList,                       setDepList] = useState([]);
  const [wthList,                       setWthList] = useState([]);

  const [mobile,                         setMobile] = useState([]);
  const [modalVisible,             setModalVisible] = useState(false);
  const [trxnSummaryTitle,     setTrxnSummaryTitle] = useState([]);
  const [gameBettingTitle,     setGameBettingTitle] = useState([]);
  const [depListTitle,             setDepListTitle] = useState([]);
  const [wthListTitle,             setWthListTitle] = useState([]);

  const [modal1Loading ,          setModal1Loading] = useState(false);
  const [modal2Loading ,          setModal2Loading] = useState(false);
  const [modal3Loading ,          setModal3Loading] = useState(false);
  const [modal4Loading ,          setModal4Loading] = useState(false);

  const [amt, setAmt] = useState(0);

  //Form
  const [fileList, setFileList] = useState([]);

  let maskedString ='';

  useEffect(() => {
    // eslint-disable-next-line
    getCompanyProfile();
    availBankList();
    bankcodeList();
    adjRemarkList();
    setSelectedYear(String(moment().format('YYYY')))
    setMonth(String(moment().format('MM')))
    setDay(String(moment().format('DD')))
    checkMaxDay(leapYearResult, month);
  }, []);

  useEffect(()=> {
    setUserWalletPageNum(userWalletPageNum);
    setUserWalletPageSize(userWalletPageSize);
    setUserWalletTotal(userWalletTotal);
  },[userWalletPageNum, userWalletPageSize, userWalletTotal])


  
  useEffect(()=> {
    setViewMemberText(bankAccEdit(role) ? 'Edit':'View');
    setViewMemberDlgTitle(bankAccEdit(role) ?'Edit Member':'View Member');
    setViewMemberDlgOK(bankAccEdit(role) ?'Save':'Close');
    setEditMemberEnable(bankAccEdit(role) ? true : false);
  },[])

  useEffect(()=> {
    if (delayDepFromBankData!=null) {
      let depBankData = delayDepFromBankData.split("||");
      setDelayDepFromBankcode((depBankData[0]||'').trim());
      setDelayDepFromAcctnum((depBankData[1]||'').trim());
      setDelayDepFromAcctname((depBankData[2]||'').trim());
    }
  },[delayDepFromBankData])

  useEffect(()=> {
    if (delayDepToBankData!=null) {
      let depToBankData = delayDepToBankData.split("||");
      setDelayDepToBankcode((depToBankData[0]||'').trim());
      setDelayDepToAcctnum((depToBankData[1]||'').trim());
      setDelayDepToAcctname((depToBankData[2]||'').trim());
    }
  },[delayDepToBankData])

  //#region Components   

  //#region ------- Form Submit -------

  const manualDate = async (manualYear, manualMonth, manualDay, manualHour, manualMin) => {
    // if(parseInt(manualMonth,10)<10)manualMonth='0'+manualMonth;
    // if(parseInt(manualDay,10)<10)manualDay='0'+manualDay;
    // if(parseInt(manualHour,10)<10)manualHour='0'+manualHour;
    // if(parseInt(manualMin,10)<10)manualMin='0'+manualMin;
    setSelectedYear(manualYear)
    setLeapYearResult(moment([manualYear]).isLeapYear());  
    let ManualFullDate = moment(manualYear+'-'+manualMonth+'-'+manualDay+'T'+String(manualHour)+':'+String(manualMin))
    let initialDate = new Date(ManualFullDate)
    let convertedDate = Math.floor(initialDate.getTime()/1000)
    setManualBankTime(moment.unix(convertedDate).format("YYYY-MM-DD HH:mm"))
    setCreditAdjustmentBankTime(convertedDate)
    checkMaxDay(leapYearResult, manualMonth);
  }

  const onSubmitCreditAdjustmentForm = async () => {
    setCreditAdjustmentButtonLoading(true);
    let checkManualTopupReceipt = '';

    let requestData = {
      companyID: companyId,
      columnRequire: ['SkipManualTopupReceipt']
    }

    const resCompany = await getCompanyProfileData(requestData);
    let resp = await resCompany.json();
    if (resp.status == "ok") {

      checkManualTopupReceipt = resp.data[0].SkipManualTopupReceipt == 'Y' ? true
                                   : resp.data[0].SkipManualTopupReceipt == 'N' ? false
                                   : false;

      if (creditAdjustmentType == "Topup") {
        if (checkManualTopupReceipt != true && JSON.stringify(fileList) == '[]') {
          alertData.title = "Field Required";
          alertData.msg = `Please Upload Bank Receipt. `;
          alertData.redirectMsg = `If you would like make this field optional, click `;
          alertData.redirectUrl = `/systemManagement`;
          sharedErrorAlertV2(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }

        if (selectedYear == "") {
          alertData.title = "Field Required";
          alertData.msg = "Year should not allow input less than 4 digit !";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }

        if (selectedYear < 1000) {
          setSelectedYear("")
          alertData.title = "Validation Error";
          alertData.msg = "Year should not allow input less than 4 digit !";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }

        if (month == "") {
          alertData.title = "Field Required";
          alertData.msg = "Please Fill in Month !";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }

        if (month.length != 2) {
          alertData.title = "Field Required";
          alertData.msg = "Invalid month! e.g. min 01 - 12 max!";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }

        if (day == "") {
          alertData.title = "Field Required";
          alertData.msg = "Please Fill in Day !";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }

        if (day.length != 2) {
          alertData.title = "Validation Error";
          alertData.msg = "Invalid Day! e.g. min 01 - "+maxDay+" max !";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }

        if (day > maxDay) {
          alertData.title = "Validation Error";
          alertData.msg = "Invalid Day! e.g. min 01 - "+maxDay+" max !";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          setDay("");
          return;
        }

        if (hour == "") {
          alertData.title = "Validation Error";
          alertData.msg = "Please Fill in Hour !";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }

        if (hour.length != 2) {
          alertData.title = "Validation Error";
          alertData.msg = "Invalid hour! e.g. min 00 - 23 max!";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }

        if (min == "") {
          alertData.title = "Field Required";
          alertData.msg = "Please Fill in Minute !";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }

        if (min.length != 2) {
          alertData.title = "Validation Error";
          alertData.msg = "Invalid minutes! e.g. min 00 - 59 max!";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }

        if (!creditAdjustmentBankTime) {
          alertData.title = "Validation Error";
          alertData.msg = "Please Fill in Correct Bank Time !";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }

        let curTime = new Date(moment().format())
        let curTimestamp = Math.floor(curTime.getTime()/1000)

        if (creditAdjustmentBankTime >= curTimestamp) {
          alertData.title = "Validation Error";
          alertData.msg = "Bank time cannot more than today !";
          sharedErrorAlert(alertData);
          setCreditAdjustmentButtonLoading(false);
          return;
        }
      }
    }
    else{
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);  
    }

    if (creditAdjustmentType == "Adjustment") {
      if (!creditAdjustmentRemark) {
        alertData.title = "Field Required";
        alertData.msg = "Please choose remark for Adjustment !";
        sharedErrorAlert(alertData);
        setCreditAdjustmentButtonLoading(false);
        return;
      }
    }

    let initialAdjustmentRemark = (creditAdjustmentType == 'Adjustment') ? creditAdjustmentRemark.split('||') : '', finalAdjustmentRemark = (initialAdjustmentRemark != '') ? initialAdjustmentRemark[0].trim() : '';
    let converted_creditAdjustmentAmt = 0;
    converted_creditAdjustmentAmt = finalAdjustmentRemark == 'csAction' ? (creditAdjustmentAmt * -1) : creditAdjustmentAmt;

    let requestData2 = {
      userId: csSearchMemberId, 
    }
    
    const currentWalletBalanceData = await getCurrentWalletBalance(requestData2);
    let result = await currentWalletBalanceData.json();

    if (result.status == "ok") {
      let currentBalance = result.data[0] != undefined ? result.data[0].curBal : 0;
      let afterBalance = (converted_creditAdjustmentAmt > 0) ? currentBalance + converted_creditAdjustmentAmt :
                         (converted_creditAdjustmentAmt < 0) ? currentBalance - (converted_creditAdjustmentAmt * -1) :
                         "";

      if (afterBalance < 0) {
        alertData.title = "Validation Error";
        alertData.msg = "Insufficient Balance !";
        sharedErrorAlert(alertData);
        setCreditAdjustmentButtonLoading(false);
        return;
      }

      let msgStart = `Are you sure you want to `;
      let msgAction = (creditAdjustmentType == "Topup") ? `topup ${converted_creditAdjustmentAmt}B ` : 
                      (creditAdjustmentType == "Adjustment" && converted_creditAdjustmentAmt > 0) ? `add ${converted_creditAdjustmentAmt}B ` :
                      (creditAdjustmentType == "Adjustment" && converted_creditAdjustmentAmt < 0) ? `remove ${-(converted_creditAdjustmentAmt)}B ` :
                      "";
      let msgEnd = (converted_creditAdjustmentAmt > 0) ? `credit into user wallet?` : 
                   (converted_creditAdjustmentAmt < 0) ? `credit from user wallet?` :
                   "";

      let msgAdditional = afterBalance < 0 ? ` (User balance will be negative if you do so.)` : "" ;

      let requestData2 = {
        adjustmentMode: finalAdjustmentRemark,
        adjustmentCreditAmt : finalAdjustmentRemark == 'csAction' ? (creditAdjustmentAmt * -1) : creditAdjustmentAmt,
      }

      confirm({
        className: "confirmationModalStyle",
        title: 'Confirmation',
        centered: true,
        icon: <QuestionCircleOutlined />,
        width: "500px",
        content: 
          <Paragraph>{msgStart}<b>{msgAction}</b>{msgEnd}<i><b>{msgAdditional}</b></i>
            <ul>
              <p> 
                {creditAdjustmentType == "Topup" ?
                <li>
                  Bank Time : { manualBankTime }
                </li>
                : "" 
                }
                <li>
                  Current Balance : { Number(currentBalance).toFixed(2) }
                </li>
                <li>
                  After Balance : { Number(afterBalance).toFixed(2) }
                </li>
              </p>  
            </ul>
          </Paragraph>,
        onOk() {
          onSubmitOKCreditAdjustmentForm(requestData2);
        },
        onCancel() {
          setCreditAdjustmentButtonLoading(false);
        },
      });
    }
    else{
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      setCreditAdjustmentButtonLoading(false);
    }

  }

  const onSubmitOKCreditAdjustmentForm = async (record) => {
    setTableLoading(true);

    let requestData = {
      mobile         : selectedUserCountryCode + selectedUserPhoneNum,
      adjustmentType : creditAdjustmentType,
      adjustmentMode : record.adjustmentMode.trim(),
      amt            : record.adjustmentCreditAmt,
      csUserId       : userId,
      csUsername     : username,
    }
    
    let requestTokenData = {
      mobile      : selectedUserCountryCode + selectedUserPhoneNum,
      action_type : creditAdjustmentType == 'Topup' ? 'Topup' : record.adjustmentMode.trim(),
    };

    const getToken = await requestToken(requestTokenData);
    let getTokenResponse = await getToken.json();

    if (getTokenResponse.status == 'ok') {
      requestData.reqToken = getTokenResponse.data['reqToken'];
    }
    else {
      setCreditAdjustmentButtonLoading(false);
      setTableLoading(false);
      alertData.title = "Failed";
      alertData.msg = "Request too frequent !";
      sharedFailAlert(alertData);
      return;
    }
    
    if (creditAdjustmentType == 'Topup') {
      if (creditAdjustmentBankTime != '') {
        requestData.bankTime = oneMinuteBugChecked == true ? Number(creditAdjustmentBankTime) + 1 : creditAdjustmentBankTime;
      }
      else{
        const timestamp = Math.floor(Date.now() / 1000);
        requestData.bankTime = timestamp + 1;
      }

      const uploadReceipt = await uploadFileV2();

      if (uploadReceipt.status == "ok") {
        requestData.uploadRef = uploadReceipt.data != undefined ? uploadReceipt.data.filename : '';
      }
      else {
        setCreditAdjustmentButtonLoading(false);
        setTableLoading(false);
        alertData.title = "Failed";
        alertData.msg = uploadReceipt.msg;
        sharedFailAlert(alertData);
      }
    }

    const resCreateCreditAdjustment = await adjustCredit(requestData);
    let result = await resCreateCreditAdjustment.json();

    if (result.status == "ok") {
      onCancelCreditAdjustment();
      setCreditAdjustmentButtonLoading(false);
      alertData.title = "Success";
      alertData.msg = result.msg;
      sharedSuccessAlert(alertData);
    }
    else {
      if (result.errcode == "field_required") {
        setTableLoading(false);
        setCreditAdjustmentButtonLoading(false);
        alertData.title = "Field Required";
        alertData.msg = result.msg;
        alertData.redirectMsg = `If you would like make this field optional, click `;
        alertData.redirectUrl = `/systemManagement`;
        sharedErrorAlertV2(alertData);
      }
      else {
        setTableLoading(false);
        setCreditAdjustmentButtonLoading(false);
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
      }
    }
  }

  const onSubmitRemoveCreditForm = async () => {
    let requestData = {
      userId: csSearchMemberId, 
    }
    
    const currentWalletBalanceData = await getCurrentWalletBalance(requestData);
    let result = await currentWalletBalanceData.json();

    if (result.status === "ok") {
      let currentBalance = result.data[0].curBal;
      let afterBalance = currentBalance - removeCreditAmt;
      let msg  = `Are you sure you want to proceed to remove `+removeCreditAmt+`B from user?`;
          msg += afterBalance < 0 ? ` (User balance will be negative if you do so.)` : "" ;

          confirm({
            className: "confirmationModalStyle",
            title: 'Confirmation',
            centered: true,
            icon: <QuestionCircleOutlined />,
            width: "500px",
            content: 
            <Paragraph>{msg}
              <ul>
                <p> 
                  <li>
                    Current Balance : {Number(currentBalance).toFixed(2)}
                  </li>
                  <li>
                    After Balance : {Number(afterBalance).toFixed(2)}
                  </li>
                </p>  
              </ul>
            </Paragraph>,
            onOk() {
              onSubmitOKRemoveCreditForm();
            },
            onCancel() {},
          });
    }
    else{
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }
  }
  
  const onSubmitOKRemoveCreditForm = async () => {
    const row = await formRemoveCredit.validateFields()
    setRemoveCreditSubmit(false);

    let requestData = {
      mobile   : delayDepCountryCode+ delayDepPhoneNum,
      csUserid : userId,
      amt      : -Math.abs(row.amt),
      remark   : "csAction || csAction",
    };

    const response = await csUpdateWalletBalance(requestData);
    let result = await response.json()
    if (result.status == 'ok')
    {
      alertData.title = "Success";
      alertData.msg = result.msg;
      sharedSuccessAlert(alertData);
      onCancelDelayDep();
      setManualDepositVisible(false);
      // findMobileNumber();
    }
    else
    {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }

    setRemoveCreditSubmit(true);
  }
  
  const onSubmitAdjustForm = async () => {
    const row = await formAdjustment.validateFields();
    let ADJ;
    if(row.remark == "ApproveNoCredit || Approve No Credit"){
      ADJ = DEP_TYPE.ADJUST;
    }
    else if(row.remark == "Promo || Promo"){
      ADJ = DEP_TYPE.PROMO;
    }
    else if(row.remark == "Recovery || Recovery"){
      ADJ = DEP_TYPE.RECOVER;
    }
    let requestTokenData = {
      mobile : delayDepCountryCode+ delayDepPhoneNum,
      action_type : ADJ       
    };

    const getToken = await requestToken(requestTokenData)
    let getTokenResponse = await getToken.json()
    setAdjustSubmit(false);
    
    if (getTokenResponse.status == "ok"){
      let requestData = {
        mobile   : delayDepCountryCode+ delayDepPhoneNum,
        csUserid : userId,
        deptype  : DEP_TYPE.ADJUST, 
        // reqToken : md5(getReqToken(DEP_TYPE.ADJUST)),    //old
        reqToken : getTokenResponse.data['reqToken'],       //new
        amt      : adjustAmt,
        remark   : adjustRemark,
      };
      
      const response = await csDepositAdjustNew(requestData);
      let result = await response.json();
      if (result.status == 'ok'){
        setManualDepositVisible(false);
        onCancelDelayDep();
        alertData.title = "Success";
        alertData.msg = result.msg;
        sharedSuccessAlert(alertData);
      }
      else{
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
      }

    }
    else{
        alertData.title = "Failed";
        alertData.msg = getTokenResponse.msg;
        sharedFailAlert(alertData);

    };
    setAdjustSubmit(true);
  }

  const onSubmitForm = async () => {
    formDelayDep.setFieldsValue({
      bankReceipt: fileList[0],
    });

    // token checker
    let requestTokenData = {
      mobile : delayDepCountryCode+ delayDepPhoneNum,
      action_type : DEP_TYPE.DDEP
    };

    const getToken = await requestToken(requestTokenData)
    let getTokenResponse = await getToken.json()

    if (getTokenResponse.status == "ok"){
      const row = await formDelayDep.validateFields();
      //const row = await form.validateFields();    
      const uploadResult = await uploadFileV2();

      if (uploadResult.status === "ok"){
        let filename = uploadResult.data !== undefined ? uploadResult.data.filename : '';
        const insertResult = await dataInsert2(row, filename);
          
        if (insertResult.status === "ok") {
          setManualDepositVisible(false);
          setFileList([]);
          onCancelDelayDep();
          // depositList();
          //setPhoneNum("");
          alertData.title = "Success";
          alertData.msg = insertResult.msg;
          sharedSuccessAlert(alertData);
        }
        else {
          alertData.title = "Failed";
          alertData.msg = insertResult.msg;
          sharedFailAlert(alertData);
        }             
      }
      else {
        alertData.title = "Failed";
        alertData.msg = uploadResult.msg;
        sharedFailAlert(alertData);
      }
    }
    else{
      alertData.title = "Failed";
      alertData.msg = getTokenResponse.msg;
      sharedFailAlert(alertData);
    }
      // setManualDepositVisible(false);
  };
  //#endregion

  //#region ------- Receipt file Uploads --------
  const fileProps = {
    listType: "picture",
    onRemove: (file) => {
      setFileList([]);
    },
    fileList: fileList,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        let newFileList = [];
        newFileList.push(file);
        setFileList(newFileList);
      });

      reader.readAsDataURL(file);
      return false;
    },
    onChange: (info) => {
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      let newFileList = [...info.fileList];
      newFileList = newFileList.slice(-1);

      // 2. Read from response and show file link
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }

        return file;
      });

      setFileList(newFileList);
      return false;
    },
  };

  const receiptUploads = () => {
    return (
      <>
        <Upload {...fileProps} fileList={fileList}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </>
    );
  };

  const uploadFileV2 = async () => {
    const formData = new FormData();
    formData.append("fileReceipt", fileList[0]);
    if (fileList[0]!== undefined){
        const response = await uploadReceiptV2(formData);
        let result = await response.json();
        return result;
    }

    return { status:'ok'};
  };

  //#endregion

  const menu = (
    <Menu>
      <Menu.Item key="1"
        className="ant-btn-primary"
        style={{color:"white"}}
        onClick={()=> {
          findMobileNumber();
        }}
      >
        Mobile Number
      </Menu.Item>
      <Menu.Item key="2"
        className="ant-btn-primary"
        style={{color:"white"}}
        onClick={()=>{
          findAcctnum();
        }}
      >
        Bank Account
      </Menu.Item>
      <Menu.Item key="3"
        className="ant-btn-primary"
        style={{color:"white"}}
        onClick={()=> {
          findUserGameId();
        }}
      >
        User Game ID
      </Menu.Item>
    </Menu>
  );

  ////#endregion

  //#region Lists
  const memberList = () => {
    setOriginData([]);
  
  };

  const btnEnabled = false;

  const getCompanyProfile = async () => {
    let requestData = {
      companyID: companyId,
      columnRequire: ['phpReportURL']
    }

    let manualTopupReceiptRequired = '';

    const result = await getCompanyProfileData(requestData);
    let resp = await result.json();
    if (resp.status == "ok") {
      setPlayerTrxnURL(resp.data[0].phpReportURL != '' ? resp.data[0].phpReportURL : '');
    }
    else{
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);  
    }
  };

  const availBankList = async () => {

    console.log(`companyId >> ${companyId}`);

    csAvailBankList({companyId: companyId})
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setCSAvaBankListOptions(json.data);
        }
    })
    .catch((error) => {
      console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
    });
  };

  //#endregion Lists

  //#region Functions
  const adjRemarkList = async () => {
    let filterData = adjTypeList.filter(p=>p.type != "DelayDeposit");
    setAdjustRemarkListOptions(filterData);

    let filterData2 = memberMgmtRemoveCredit(role, compCode) ? adjTypeList.filter(p => p.type != "DelayDeposit") : adjTypeList.filter(p => p.type != "DelayDeposit" && p.type != "csAction");
    setCreditAdjustmentRemarkListOption(filterData2);
  };

  const handleTableChange = async (pagination, filters, sorter)=> {

      await getUserWallet(trxDateFrom , trxDateTo, pagination.current, pagination.pageSize, filters);

      await setUserWalletPageNum(pagination.current);

      await setUserWalletPageSize(pagination.pageSize);

  }

  const getUserWallet = async(datefrom, dateto, pagenum, pagesize, filters) => {

    let startDate = new moment(moment(),"YYYY-MM-DD HH:mm").subtract(3,"days");
    let endDate = new moment(moment(),"YYYY-MM-DD HH:mm").add(1, 'days');
    startDate = startDate.format("YYYY-MM-DD 00:00:00");
    endDate = endDate.format("YYYY-MM-DD 00:00:00");

    if (datefrom !='') {
      startDate = new moment(datefrom,"YYYY-MM-DD HH:mm").format("YYYY-MM-DD 00:00:00");   
    }

    if (dateto !='') {
      endDate = new moment(dateto,"YYYY-MM-DD HH:mm").format("YYYY-MM-DD 23:59:59");
    }

    let countDL = 0;

     let requestData = {
        datefrom  : startDate,
        dateto    : endDate,
        userid    : csSearchMemberId,
        page      : pagenum,
        pagesize  : pagesize,
        filters   : filters, 
     }

    let newStartDate = new moment(startDate, "YYYY-MM-DD");
    let newEndData = new moment(endDate, "YYYY-MM-DD");

    setTrxDateFrom(newStartDate);
    setTrxDateTo(newEndData);

    setUserWltTableLoading(true);

    const responseDL = await userWalletHistory(requestData);
      let resultDL = await responseDL.json();
      if (resultDL.status === "ok") {
        const dlData = Object.keys(resultDL.data.detail).length
        ? resultDL.data.detail.map((obj) => {
            setUserWltTableLoading(false);
            return {
              key              : ++countDL,
              walletId         : obj.walletId,
              sysCreateTime    : obj.sysCreateTime,
              balBefore        : obj.balBefore,
              balance          : obj.bal,
              comm             : obj.comm,
              bonus            : obj.bonus,
              activeGame       : obj.activeGame,
              remark           : obj.remark,
            };
          })
        : [];

        setUserWalletData(dlData);

        const dlDataSummary = Object.keys(resultDL.data.summary).length
                      ? resultDL.data.summary.map((x) => {
                        return {
                            current : x.current,
                            pagesize: x.pagesize,
                            totalTrxn : x.totalTrxn
                        };
                      })
                      : [];

          setUserWalletTotal(dlDataSummary?.[0]?.totalTrxn ?? 10 );

          setUserWltTableLoading(false);
        }
        else {
          alertData.title = "Failed";
          alertData.msg = resultDL.msg;
          sharedFailAlert(alertData);  
      }

      setUserWltTableLoading(false);
  }


  const onCancelDelayDep = ()=>{
    setFileList([]);
    setManualDepositVisible(false);
    setDelayDepFromBankData(null);
    setDelayDepFromAcctname("");
    setDelayDepFromAcctnum("");
    setDelayDepFromBankcode("")
    
    setAdjustAmt(0);
    setAdjustRemark("");

    setRemoveCreditAmt(0);

    setCreateDepositDisable(true);
    setCreateAdjustDisable(true);
    setCreateRemoveCreditDisable(true);
    formDelayDep.resetFields();
    formAdjustment.resetFields();
    formRemoveCredit.resetFields();
  }

  const onCancelCreditAdjustment = ()=>{
    setCreditAdjustmentType("Topup");
    setCreditAdjustmentAmt(1);
    setCreditAdjustmentBankTime("");
    setCreditAdjustmentRemark("");
    setFileList([]);
    setOneMinuteBugChecked(false);
    setSubmitCreditAdjustmentDisable(true);
    setTableLoading(false);
    setCreditAdjustmentVisible(false);
    setHour("");
    setMin("");
    formCreditAdjustment.resetFields();
  }

  const handleRecoverWallet = async (value) => {
      //setRecoverSubmit(false);
      setTableLoading(true);
      let requestData = {
        userid   : value,
        csUserId : userId,
        reqToken : md5(getReqToken("recoverWallet")),
      };

     console.log(`requestData >> ${JSON.stringify(requestData)}`);

      const response = await recoverWallet(requestData);
      let result = await response.json();
  
      if (result.status === "ok") {
          setTableLoading(false);
          alertData.title = "Success";
          alertData.msg = result.msg;
          sharedSuccessAlert(alertData);
      }
      else {
          setTableLoading(false);
          alertData.title = "Failed";
          alertData.msg = result.msg;
          sharedFailAlert(alertData);
      }
      // setRecoverSubmit(true);
  }

  const handleChangeDelayDepAmt = (value) => {
    setDelayDepAmt(value);
    setCreateDepositDisable(false);

    if (!delayDepFromBankcode || !delayDepFromAcctnum || !delayDepFromAcctname) {
      setCreateDepositDisable(true);     
    }
  }

  const handleChangeAdjustAmt = (value) => {
    if (String(value).match(/^\d+(\.\d+)?$/)) {
      setAdjustAmt(value);
      setCreateAdjustDisable(false);
    }
    else{
      setCreateAdjustDisable(true);
    }
  }

  const handleChangeRemoveCreditAmt = (value) => {
    if (String(value).match(/^\d+(\.\d+)?$/)) {
      setRemoveCreditAmt(value);
      setCreateRemoveCreditDisable(false)
    }
    else{
      setCreateRemoveCreditDisable(true);
    }
  }

  const depDtPicker = (date, dateString) => {
    formDelayDep.setFieldsValue({
      delayDepTime: moment(date,"YYYY-MM-DD hh:mm")
    });
    setDelayDepDt(date);
  };

  const bankcodeList = async () => {
    let requestData = {
      bankType: BANK_INFO_TYPE.DEP,
      columnRequire : ['AccountNum', 'BankCode'],
    }

    const response = await getBankList(requestData);
    let result = await response.json();

    if (result.status ==  'ok'){
      setCSBankListOptions(result.data)
    }
    else{
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }
  };


  //Data Insert
  const dataInsert2 = async (data, path) => {

      let requestData = {
        mobile       : delayDepCountryCode+ delayDepPhoneNum,
        depfrom      : {
           frombankcode  : delayDepFromBankcode,
           fromacctnum   : delayDepFromAcctnum,
           fromacctname  : delayDepFromAcctname,
        },
        depto        : {
          tobankcode : delayDepToBankCode,
          toacctnum  : delayDepToAcctnum,
          toacctname : delayDepToAcctname,
        },
        uploadref    : path,
        amt          : delayDepAmt,
        deptype      : DEP_TYPE.DDEP,
        csUserid     : userId,
        createby     : userId,
        reqToken     : md5(getReqToken(DEP_TYPE.DDEP)),
        remark       : "DelayDeposit || Delay Deposit"
      }

      console.log(`delayDep requestData >> ${JSON.stringify(requestData)}`);
      return csDepositAdjustNew(
        requestData
      )
      .then((response) => response.json())
      .then((json) => {
        return { status:json.status , errcode:json.errcode, msg:json.msg};
      });
  };
  
  const sortBankData = (bankData) => {

    let fil = [...bankData].sort((x, y) =>
    x.actionStatus.toLowerCase().includes('active') && y.actionStatus.toLowerCase().includes('active')
      ? 0
      : x.actionStatus.toLowerCase().includes('active')
      ? -1
      : x.actionStatus.toLowerCase().includes('deleted') && y.actionStatus.toLowerCase().includes('deleted')
      ? -2
      : 1,
    );

    return fil;
  } 



  const getCustBankDetailsDelayTopup = async (memberUserId)=>{

    let respUserBank = await userBankAll({userid:memberUserId});
    let respBankCon = await respUserBank.json();

    if (respBankCon.status ==="ok" ) {
      if (Object.keys(respBankCon.data).length > 0 ) {      
        
        let count = 0;

        let newBankData = respBankCon.data.map((obj) => {
              return {
                key: count++,
                userid  : memberUserId,
                bankcode: obj.bankcode,
                bank : obj.bank,
                acctnum : obj.acctnum,
                acctname : obj.acctname,
                status : obj.status, 
                actionStatus : 
                  obj.status == 5 || obj.status == 7
                  ? "Pending"
                  : obj.status == 1
                  ? "Active"
                  : obj.status == 9
                  ? "Deleted"
                  : "-",
              };
            });

        newBankData = sortBankData(newBankData);

        setDelayDepBankLisOptions(newBankData);
      } 

    }
  }

  const getCustBankDetails = async (memberUserId) => {

    let respUserBank = await userBankAll({userid:memberUserId});
    let respBankCon = await respUserBank.json();

    setTrxBankLoading(true);

    if (respBankCon.status ==="ok" ) {
      if (Object.keys(respBankCon.data).length > 0 ) {      
        
        let count = 0;

        let newBankData = respBankCon.data.map((obj) => {
              return {
                key: count++,
                userid  : memberUserId,
                bankcode: obj.bankcode,
                bank : obj.bank,
                acctnum : obj.acctnum,
                acctname : obj.acctname,
                status : obj.status,
                sysCreateTime : obj.sysCreateTime,
                sysCreateBy: obj.sysCreateBy,
                actionStatus : 
                  obj.status == 5 || obj.status == 7
                  ? "Pending"
                  : obj.status == 1
                  ? "Active"
                  : obj.status == 9
                  ? "Deleted"
                  : "-",
              };
            });

        newBankData = sortBankData(newBankData);
        setUserBankData(newBankData)
      } 

      setTrxBankLoading(false);
    }

  }

  const onChangeBankTab = (key)=> {
    if (key == 1) {
      setEditBankCode("");
      setEditBankName("");
      setEditBankAccNum("");
      setEditBankAccName("");
      setOldBankAccName("");
    }

    if (key == 2) {
      setTwalletAccNum("");
      setTwalletAccName("");
    }
  }

  const onEditUserBank = async () => {
    setEditBankLoading(true);
    let requestData = {
        userid : editMemberId,
        bankcode: editBankCode,
        acctname: editBankAccName,
        acctnum : editBankAccNum,
        updateby: username
    }

    if (!editBankAccName){
      alertData.title = "Error";
      alertData.msg = "Please fill in Account Name !";
      sharedErrorAlert(alertData);
      setEditBankLoading(false);
      return;
    }

    const result = await userBankUpdate(requestData);
    let resp = await result.json();
    if (resp.status === "ok") {
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        getCustBankDetails(requestData.userid);
        clearBankFields();
        setEditBankModal(false);
        let record = {props : originData[0]};
        BtnEditMember(handleMember(record));
        setEditBankLoading(false);
      }
    else{
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);  
        clearBankFields();
        setEditBankModal(false);
        setEditBankLoading(false);
    }
  }

  const onRejectUserBank = async () => {
    let requestData = {
        userid : editMemberId,
        bankcode: editBankCode,
        acctname: oldBankAccName,
        acctnum : editBankAccNum,
        updateby: username
    }

    const result = await csRejectBankAccount(requestData);
    let resp = await result.json();
    if (resp.status === "ok") {
      alertData.title = "Success";
      alertData.msg = resp.msg;
      sharedSuccessAlert(alertData);
        getCustBankDetails(requestData.userid);
        clearBankFields();
        setEditBankModal(false);
        let record = {props : originData[0]};
        BtnEditMember(handleMember(record));
      }
    else{
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);  
        clearBankFields();
        setEditBankModal(false);
    }
  }

  const onAddUserBank = async () => {
    setAddBankLoading(true);

    if (bankcode == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please select Bank Code !";
      sharedErrorAlert(alertData);
      setAddBankLoading(false);
      return;
    }

    if (acctnum == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Bank Account Number !";
      sharedErrorAlert(alertData);
      setAddBankLoading(false);
      return;
    }

    if (acctnum.length < 9){
      alertData.title = "Field Required";
      alertData.msg = "Bank Account Number must be minimum 9 digits !";
      sharedErrorAlert(alertData);
      setAddBankLoading(false);
      return;
    }

    if (acctname == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Bank Account Name !";
      sharedErrorAlert(alertData);
      setAddBankLoading(false);
      return;
    }

    let requestData = {
       userid  : memberUserId,
       bankcode: bankcode,
       acctname: acctname,
       acctnum : acctnum,
       createby: username,
       role    : role,
       compCode: compCode,
    }

    const result = await userBankNew(requestData);
    let resp = await result.json();
    if (resp.status === "ok") {
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData); 
        getCustBankDetails(requestData.userid);
        clearBankFields();
        setAddBankModal(false);
        let record = {props : originData[0]};
        BtnEditMember(handleMember(record));
        setAddBankLoading(false);
        }
      else{
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);  
        clearBankFields();
        setAddBankModal(false);
        setAddBankLoading(false);
      }
  }

  const getUserTwalletList = async (twalletUserId) => {
    let requestData = {
      userid: twalletUserId,
      type: true
    }
    let response = await csRetrieveTwalletAcc(requestData);
    let result = await response.json();
    
    setTrxBankLoading(true);
    
    if (result.status === "ok" ) {
      if (Object.keys(result.data).length > 0 ) {      
        let count = 0;
          let newTwalletData = result.data.map((obj) => {
              return {
                key           : count++,
                id            : obj.ID,
                acctnum       : obj.BankAcc,
                acctname      : obj.BankAccName,
                status        : obj.Status,
                sysCreateTime : obj.SysCreateTime,
                sysUpdateTime : obj.SysUpdateTime,
                sysCreateBy   : obj.SysUpdateBy,
                actionStatus : 
                  obj.Status == 5 || obj.Status == 7
                  ? "Pending"
                  : obj.Status == 1
                  ? "Active"
                  : obj.Status == 9
                  ? "Deleted"
                  : "-",
              };
            });
        
        newTwalletData = sortBankData(newTwalletData);
        setUserTwalletData(newTwalletData);
      } 
      setTrxBankLoading(false);
    }
  }

  const onAddUserTwallet = async () => {
    setAddTwalletLoading(true);

    if (twalletAccNum == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please fill in True Wallet Account Number !";
      sharedErrorAlert(alertData);
      setAddTwalletLoading(false);
      return;
    }

    // if (twalletAccNum.length < 9 ){
    //   alertData.title = "Error";
    //   alertData.msg = "True Wallet Account Number must be minimum 9 digits !";
    //   sharedErrorAlert(alertData);
    //   setAddTwalletLoading(false);
    //   return;
    // }

    if (twalletAccName == ""){
      alertData.title = "Field Required";
      alertData.msg = "Please fill in True Wallet Account Name !";
      sharedErrorAlert(alertData);
      setAddTwalletLoading(false);
      return;
    }

    let requestData = {
       userid   : twalletUserId,
       csuserid : userId,
       acctname : twalletAccNum,
       acctnum  : twalletAccName
    }

    const result = await csAddTwalletAcc(requestData);
    let resp = await result.json();
    if (resp.status === "ok") {
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData); 
        clearTwalletFields();
        setAddTwalletModal(false);
        getUserTwalletList(twalletUserId);
        setAddTwalletLoading(false);
      }
      else{
          alertData.title = "Failed";
          alertData.msg = resp.msg;
          sharedFailAlert(alertData);  
          setAddTwalletLoading(false);
        }
  }

  const onEditUserTwallet = async (status) => {
    setEditTwalletLoading(true);

    let requestData = {
      id        :   twalletId,
      userid    :   userId,
      acctname  :   editTwalletAccName,
      acctnum   :   editTwalletAccNum,
      status    :   editTwalletAccStatus,
    };

    if(status == 9) {
      requestData.acctname = oldTwalletAccName;
      requestData.acctnum = oldTwalletAccNum;
      requestData.status = 9;
    };
    
    if (!editTwalletAccNum && status != 9){
      alertData.title = "Error";
      alertData.msg = "Please fill in True Wallet Account Number !";
      sharedErrorAlert(alertData);
      setEditTwalletLoading(false);
      return;
    }

    if (!editTwalletAccName && status != 9){
      alertData.title = "Error";
      alertData.msg = "Please fill in True Wallet Account Name !";
      sharedErrorAlert(alertData);
      setEditTwalletLoading(false);
      return;
    }

    // if (editTwalletAccNum.length < 9 && status != 9 ){
    //   alertData.title = "Error";
    //   alertData.msg = "True Wallet Account Number must be minimum 9 digits !";
    //   sharedErrorAlert(alertData);
    //   setEditTwalletLoading(false);
    //   return;
    // }
    
    const result = await csUpdateTwalletAcc(requestData);
    let resp = await result.json();
    if (resp.status === "ok") {
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        clearTwalletFields();
        getUserTwalletList(twalletUserId);
        setEditTwalletLoading(false);
      }
    else{
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);  
        setEditTwalletLoading(false);
    }
  }

  const onDeleteBankAcc = async (key) => {

    const dataSource = [...userBankData];
    const record = dataSource.filter((item)=> item.key === key)[0];

    let requestData = {
      userid: record.userid,
      bankcode : record. bankcode, 
      acctname : record.acctname,
      acctnum  : record.acctnum,
      updatedby  : username,
    }

    const result = await userBankDelete(requestData);
    let resp = await result.json();
    if (resp.status ==="ok") {
        //loadingFalse(index);
        //setNewVisible(false);
        // message.success(resp.msg);
        alertData.title = "Success";
        alertData.msg = resp.msg;
        sharedSuccessAlert(alertData);
        getCustBankDetails(requestData.userid);
    }else{
        //loadingFalse(index);
        // message.error(resp.msg);
        alertData.title = "Failed";
        alertData.msg = resp.msg;
        sharedFailAlert(alertData);
    }
  }

  const handleMemberPhone = (e) => {
    e.preventDefault();
    alertData.title = "Error";
    alertData.msg = "Copy Not Allowed";
    sharedErrorAlert(alertData);
  }

  const convertGender = (gender)=> {
     let gen = gender.toLowerCase();
     
     switch (gen) {
        case "m":
            return "Male";
        case "f":
            return "Female";
        case "o":
            return "Other";
        default:
            return "N/A";
     }
  }

  const clearWalletSummary = async ()=> {
    setPuppetVisible(false);
    setCSMemberWalletDt("");
    setCSMemberWalletBal("");
    setCSMemberWalletComm("");
    setTrxDateFrom("");
    setTrxDateTo("");

    setCSGameUser("");
    setCSGameBetAmt("");
    setCSGameWinloss("");
    setCSGameTurnover("");

  }

  const clearMobileNo = async ()=> {
    setSearchMobile("");
  }

  const trxDatePicker = (date, dateString) => {
    setTrxDateFrom(date);
  };

  const trxDatePicker2 = (date, dateString) => {
    setTrxDateTo(date);
  };

  const checkMaxDay = async (checkLeapYear, handleMonth)=> {
    if (handleMonth == "01" || handleMonth == "1" || 
        handleMonth == "03" || handleMonth == "3" ||
        handleMonth == "05" || handleMonth == "5" ||
        handleMonth == "07" || handleMonth == "7" ||
        handleMonth == "08" || handleMonth == "8" ||
        handleMonth == "10" || handleMonth == "10" ||
        handleMonth == "12" || handleMonth == "12"){
      setMaxDay("31")
    }
    else if (handleMonth == "04" || handleMonth == "4" ||
             handleMonth == "06" || handleMonth == "6" ||
             handleMonth == "09" || handleMonth == "9" ||
             handleMonth == "11"){
      setMaxDay("30")
    }
    else if (handleMonth == "02" && leapYearResult == true ||
             handleMonth == "2" && leapYearResult == true){
      setMaxDay("29")
    }
    else if (handleMonth == "02" && leapYearResult == false ||
             handleMonth == "2" && leapYearResult == false){
      setMaxDay("28")
    }
  };

  const searchMemberTrxn = () => {
  
    if (!trxDateFrom) {
      reportDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!trxDateTo) {
      reportDateTo.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date To";
      sharedErrorAlert(alertData);
      return;
    }

    if (viewTrx == "wallet") {
      ViewMemberWallet();
    }
    else if (viewTrx == "trxn") {         
      ViewPPTDetails();
    }
    else{
      ViewGameDetails();
    }
  };

  const clearTrxnSearch = () => {
      setTrxDateFrom(""); 
      setTrxDateTo("");

      if (viewTrx == "trxn") {
        ViewPPTDetails();
      }
      else {
        ViewGameDetails();
      }
  };


  const validateUserPhone = async(phoneNum)=> {
      const response = await csVerifyMobile({mobile: countryCode+phoneNum});
      let result = await response.json();

      if (result.status === "ok") {
        setCreateMemberDisable(true);
        setEditMemberDisable(true);
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
      }else{
        setCreateMemberDisable(false);
        setEditMemberDisable(false);
      }
  }

  const onChangeAcctNum  = (e) => {
    setAcctNum(e.target.value.replace(/\D|^/g, ""));
  }

  const findAcctnum = async () => {
    if (searchMobile.length < 6) {
      alertData.title = "Format Error";
      alertData.msg = "Please enter a valid Bank Account Number !";
      sharedErrorAlert(alertData);
      return;
    }

    let requestData = {
      userid : companyId,
      acctnum : searchMobile,
    }

    setOriginData([]);
     setFilterPptTrxn([]);
     setPuppetVisible(false);
     var count = 0;
     setTableLoading(true);

    const response = await csMemberListAcctnumV2(requestData)
    let result = await response.json();
    if (result.status === "ok") {
      const newData = Object.keys(result.data).length
        ? result.data.map((x) => {
            setTableLoading(false);
            return {
              key: count++,
              userId: x.userId,
              nickname: x.nickname,
              registered: x.registerdate,
              lastLogin: x.lastLogin,
              mobile: x.mobile,
              gameuser: x.gameuser,
              agent: x.agentUsername,
              agentId: x.agent, 
              walletBalance   : x.walletBalance,
              commBalance     : x.commBalance,
              turnover        : 
                x.turnover === -1
                  ? "N/A"
                  : x.turnover,
              reqTurnover     : x.requiredTurnover,
              status:
                x.status === "0"
                  ? "Lock"
                  : x.status === "1"
                  ? "Active"
                  : x.status === "2"
                  ? "Withdraw Locked"
                  : x.status === "3"
                  ? "Account Locked"
                  : x.status === "11"?
                  "VerifyPin"
                  : x.status === "13"
                  ? "ResetPin"
                  : "Inactive",
              rescueMission2: x.missionTime,
              action:
                x.status === "0"
                  ? "Unlock"
                  : x.status === "1"
                  ? "Inactive"
                  : x.status === "2"
                  ? "Withdraw Locked"
                  : x.status === "3"
                  ? "Account Locked"
                  : x.status === "11"?
                  "VerifyPin"
                  : x.status === "13"
                  ? "ResetPin"
                  : "Lock",
              };
            })
          : [];

          await setOriginData(newData);

          setTableLoading(false);    
     }
     else{
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      setTableLoading(false);
     }
  }

  const findMobileNumber  = async () => {
 
     if (searchMobile.length < 6) {
        alertData.title = "Format Error";
        alertData.msg = "Please enter a valid Phone Number !";
        sharedErrorAlert(alertData);
        return;
     }
     setUserTwalletData([]);
     setOriginData([]);
     setFilterPptTrxn([]);
     setPuppetVisible(false);
     var count = 0;
     setTableLoading(true);
     const response = await csMemberListV3({ userid: companyId , mobile : searchMobile})
     let result = await response.json();
     if (result.status === "ok") {
          const newData = Object.keys(result.data).length
           ? result.data.map((x) => {
               setTableLoading(false);
               return {
                 key: count++,
                 userId: x.userId,
                 nickname: x.nickname,
                 registered: x.registerDate,
                 lastLogin: x.lastLogin,
                 mobile: x.mobile,
                 gameuser: x.gameuser,
                 agent: x.agentUsername,
                 agentId: x.agent, 
                 secret: x.secret,
                 walletBalance   : x.walletBalance,
                 commBalance     : x.commBalance,
                 turnover        : 
                    x.turnover=== -1
                      ? "N/A"
                      : x.turnover,
                 reqTurnover     : x.requiredTurnover,
                 status:
                   x.status === "0"
                     ? "Lock"
                     : x.status === "1" 
                     ? "Active"
                     : x.status === "2"
                     ? "Withdraw Locked"
                     : x.status === "3"
                     ? "Account Locked"
                     : x.status === "11"
                     ? "VerifyPin"
                     : x.status === "13"
                     ? "ResetPin"
                     : "Inactive",
                 rescueMission2: x.missionTime,
                 action:
                   x.status === "0"
                     ? "Unlock"
                     : x.status === "1"
                     ? "Inactive"
                     : x.status === "2"
                     ? "Withdraw Locked"
                     : x.status === "3"
                     ? "Account Locked"
                     : x.status === "11"
                     ? "VerifyPin"
                     : x.status === "13"
                     ? "ResetPin"
                     : "Lock",
                };
             })
           : [];

           setSecret(newData[0].secret);
           setOldSecret(newData[0].secret);
           setTwalletUserId(newData[0].userId);
           await setOriginData(newData);      
     }
     else{
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
        setTableLoading(false);
     }
  }

  const findUserGameId  = async () => {
 
    if (searchMobile.length < 6) {
       alertData.title = "Format Error";
       alertData.msg = "Please enter a valid User Game ID !";
       sharedErrorAlert(alertData);
       return;
    }

    setOriginData([]);
    setFilterPptTrxn([]);
    setPuppetVisible(false);
    var count = 0;
    setTableLoading(true);
    const response = await csMemberListGameId({ userid: companyId , gameid : searchMobile})
    let result = await response.json();
    if (result.status === "ok") {
        const newData = Object.keys(result.data).length
          ? result.data.map((x) => {
              setTableLoading(false);
              return {
                key: count++,
                userId: x.userId,
                nickname: x.nickname,
                registered: x.registerDate,
                lastLogin: x.lastLogin,
                mobile: x.mobile,
                gameuser: x.gameuser,
                agent: x.agentUsername,
                agentId: x.agent, 
                walletBalance   : x.walletBalance,
                commBalance     : x.commBalance,
                turnover        : 
                   x.turnover=== -1
                     ? "N/A"
                     : x.turnover,
                reqTurnover     : x.requiredTurnover,
                status:
                  x.status === "0"
                    ? "Lock"
                    : x.status === "1" 
                    ? "Active"
                    : x.status === "2"
                    ? "Withdraw Locked"
                    : x.status === "3"
                    ? "Account Locked"
                    : x.status === "11"
                    ? "VerifyPin"
                    : x.status === "13"
                    ? "ResetPin"
                    : "Inactive",
                rescueMission2: x.missionTime,
                action:
                  x.status === "0"
                    ? "Unlock"
                    : x.status === "1"
                    ? "Inactive"
                    : x.status === "2"
                    ? "Withdraw Locked"
                    : x.status === "3"
                    ? "Account Locked"
                    : x.status === "11"
                    ? "VerifyPin"
                    : x.status === "13"
                    ? "ResetPin"
                    : "Lock",
               };
            })
          : [];

          await setOriginData(newData);      
    }
    else{
       alertData.title = "Failed";
       alertData.msg = result.msg;
       sharedFailAlert(alertData);
       setTableLoading(false);
    }
  }
  //#region Buttons
  const BtnViewTrxn = (props)=> {
    setCSSearchMemberId(props.memberUserId);
    setCSSearchPhone(props.memberPhone);

    return (
        <Tooltip title="View Transaction">
          <Button type="primary" shape="circle" icon={<InfoCircleFilled />} 
            onClick={()=>{
              clearWalletSummary();
              ViewPPTDetails();
              }
            }
            loading={loadings[3]}
            style={style.refreshBtn}
          />
       </Tooltip> 
    )
  }


  const BtnViewTurnover = (props)=> {
    setCSSearchMemberId(props.memberUserId);

    return (
      <Tooltip title="View Turnover">
        <Button type="primary" shape="circle" icon={<DollarOutlined />} 
            onClick={()=>{
              clearWalletSummary();
              ViewGameDetails();
            }
          }
          style={style.refreshBtn}
        />
      </Tooltip>  
    )
  }

  const BtnEditMember = (props)=> {
    return (
      <Button
          style={style.refreshBtn}
          type={"primary"}
          onClick={() => {
            handleMember(props);
            getUserTwalletList(twalletUserId);
          }}
        >
        {viewMemberTxt}
      </Button> 
    )
    //Previously Button text "Edit"
  }

  const BtnInActiveMember = (props) => {
    let showButton = false;
    if (showButton){
      return (
        <Button
          style={{
            border: "none",
            backgroundColor: "#FF0000",
            color: "#FFFFFF",
          }}
          onClick={() => {
            handleInactive(props);
          }}
        >
          Inactive 
        </Button>   
      )
    }else{
      return(<></>)
    }

  }

  const BtnMemberWallet = (props) =>{

    if (memberMgmtVwMemberWallet(role)){
      return (
        <Tooltip title="View Member Wallet Log" placement="bottomLeft">
          <Button type="primary" shape="circle" icon={<WalletOutlined />} 
             onClick={()=>{
                 setCSSearchMemberId(props.memberUserId);
                 ViewMemberWallet();
               }
             }
            style={style.refreshBtn}
          />
        </Tooltip>   
      )
    }else{
      return(<></>)
    }
  }

  const BtnRecoverWallet = (props) => {
    if (recoverMemberWallet(role)){
      return (
        <Tooltip title="Recover Wallet">
        { recoverSubmit?
          <Button type="primary" shape="circle" icon={<RotateLeftOutlined />} 
             onClick={()=>{
                 handleRecoverWallet(props.memberUserId);
               }
             }
            style={{display:"block"},style.refreshBtn}
          />: <Spin style={{margin:7}}></Spin>
        }
      </Tooltip>   
      )
    }else{
      return(<></>)
    }
  }

  const BtnCreateDelayTopup = (props) => {
    if (memberMgmtCrDelayDep(role)){
      return (
        <Tooltip title="Create Delayed Deposit">
        <Button type="primary" shape="circle" icon={<PayCircleOutlined/>} 
            onClick={()=>{
              setDelayDepFormDetails(props.customer);
              setManualDepositVisible(true);
            }
          }
          style={{display:"block"},style.refreshBtn}
        />
      </Tooltip>   
      )
    }
    else{
      return(<></>)
    }
  }

  // Added on 2023-04-21, consolidate manual top up / delay deposit / adjustment into one
  const BtnCreditAdjustment = (props) => {
    if (memberMgmtCrDelayDep(role, compCode)){
      return (
        <Tooltip title="Add / Remove Credit">
          <Button type="primary" shape="circle" icon={<PayCircleOutlined/>} 
              onClick={()=>{
                setSelectedUserInfo(props.customer);
                setCreditAdjustmentVisible(true);
                setSelectedYear(moment().format("YYYY"))
                setLeapYearResult(moment(moment().format("YYYY")).isLeapYear())
                setMonth(moment().format("MM"))
                setDay(moment().format("DD"))
                checkMaxDay(leapYearResult, month);
                setDefaultHour(String(moment().format('HH')))
                setDefaultMin(String(moment().format('mm')))
              }
            }
            style={{
              marginLeft: 10,
            }}
          />
        </Tooltip>
      )
    }
    else{
      return(<></>)
    }
  }

  const setSelectedUserInfo = (data) => {    
    setSelectedUserCountryCode(data.mobile.substring(0,2));
    setSelectedUserPhoneNum(data.mobile.substring(2, data.mobile.length));
  }

  const BtnViewPlayerTrxn = () => {

    if (memberMgmtVwPlayerTrxn(role)) {
      return (<Button type="primary" style={style.refreshBtn}
          onClick={() => viewPlayerTrxn()
      }>View Player Trxn</Button>)
    }else {
      return (<></>);
    }
  }

  const setDelayDepFormDetails = (data) => {    
    setDelayDepCountryCode(data.mobile.substring(0,2));
    setDelayDepPhoneNum(data.mobile.substring(2, data.mobile.length));
    setDelayDepDt(moment(moment(), 'YYYY-MM-DD 00:00'));
    getCustBankDetailsDelayTopup(data.userId);
  }


  ////#region
  const viewPlayerTrxn = () => {
    if (!playerTrxnURL){
      alertData.title = "Error";
      alertData.msg = "Report Url not found ! Please configure on Company Profile.";
      sharedErrorAlert(alertData);
    }
    else{
      window.open(playerTrxnURL+csSearchPhone,'_blank')
    }
  };

  const handleGameExit = (userid) => {
    recoverWallet(userid);
  }

  const datePicker = (date, dateString) => {
    setDate(dateString);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  const ViewMemberWallet = async () => {
    setViewTrx("wallet");

    setPuppetVisible(true);  
    setTrxLoading(true);

    getUserWallet(trxDateFrom, trxDateTo, userWalletPageNum, userWalletPageSize,{});

  }

  const ViewPPTDetailsV2 = async (csMemberUserId) => {
    setViewTrx("trxn");

    setCSSearchMemberId(csMemberUserId);

    setPuppetVisible(true);  
    setTrxLoading(true);

    let startDate = await trxDateFrom == "" || trxDateFrom == null ? "": trxDateFrom.format("YYYY-MM-DD");
    let endDate = await trxDateTo == "" || trxDateTo == null ? "": trxDateTo.format("YYYY-MM-DD");

    let requestData = {
      userid : csMemberUserId,
      datefrom : startDate, 
      dateto : endDate,
    }

    let respTrxn = await csMemberWalletTrxn(requestData);
    let resTrxn = await respTrxn.json();

    if (resTrxn.status === "ok") {

      if (resTrxn.data.detail && resTrxn.data.detail.length) {
        setFilterPptTrxn(resTrxn.data.detail); 

        let dates = resTrxn.data.detail.map(item => moment(item.date)), maxDate = moment.max(dates) , minDate = moment.min(dates);

        setTrxDateFrom(minDate);
        setTrxDateTo(maxDate);
      }

      if (resTrxn.data.summary && resTrxn.data.summary.length) {
          let walletSumm = resTrxn.data.summary[0];
          setCSMemberWalletDt(walletSumm.Dt);
          setCSMemberWalletBal(`${FormatPt(walletSumm.Balance)}`);
          setCSMemberWalletComm(`${FormatPt(walletSumm.Comm)}`);
      }else{
        setCSMemberWalletDt("N/A");
        setCSMemberWalletBal("N/A");
        setCSMemberWalletComm("N/A");
      }
    }else {
      setFilterPptTrxn([]);
    }

    setTrxLoading(false);
  }

  const ViewPPTDetails = async () => {
    setViewTrx("trxn");

    setPuppetVisible(true);  
    setTrxLoading(true);

    let startDate = await trxDateFrom == "" || trxDateFrom == null ? "": trxDateFrom.format("YYYY-MM-DD");
    let endDate = await trxDateTo == "" || trxDateTo == null ? "": trxDateTo.format("YYYY-MM-DD");

    let respTrxn = await csMemberWalletTrxn({userid:csSearchMemberId, datefrom: startDate, dateto: endDate});
    let resTrxn = await respTrxn.json();


    if (resTrxn.status === "ok") {

      if (resTrxn.data.detail && resTrxn.data.detail.length) {
        setFilterPptTrxn(resTrxn.data.detail); 

        let dates = resTrxn.data.detail.map(item => moment(item.date)), maxDate = moment.max(dates) , minDate = moment.min(dates);

        setTrxDateFrom(minDate);
        setTrxDateTo(maxDate);
      }

      if (resTrxn.data.summary && resTrxn.data.summary.length) {
          let walletSumm = resTrxn.data.summary[0];
          setCSMemberWalletDt(walletSumm.Dt);
          setCSMemberWalletBal(`${FormatPt(walletSumm.Balance)}`);
          setCSMemberWalletComm(`${FormatPt(walletSumm.Comm)}`);
      }else{
        setCSMemberWalletDt("N/A");
        setCSMemberWalletBal("N/A");
        setCSMemberWalletComm("N/A");
      }
    }

    setTrxLoading(false);

  }

  const ViewGameDetails = async () => {

    setViewTrx("turnover");

    setPuppetVisible(true);  
    setTrxLoading(true);

    let startDate = await trxDateFrom == "" || trxDateFrom == null ? "" : trxDateFrom.format("YYYY-MM-DD");
    let endDate = await trxDateTo == "" || trxDateTo == null ? "" : trxDateTo.format("YYYY-MM-DD");

    const response = await csMemberGameSummary({userid:csSearchMemberId});
    const respTrxn = await csMemberGameTrxn({userid:csSearchMemberId, datefrom: startDate, dateto: endDate});
  
    let result = await response.json();
    let resTrxn = await respTrxn.json();

    if (result.status === "ok") {
       if (result.data && result.data.length) {
         let walletSumm = result.data[0];

         setCSGameUser(walletSumm.gameuser);
         setCSGameBetAmt(walletSumm.betAmt);
         setCSGameWinloss(walletSumm.winLoss_AUDIT);
         setCSGameTurnover(walletSumm.sumTurnover);

         if (resTrxn.status === "ok") {
           if (resTrxn.data && resTrxn.data.length) {
              setFilterPptTrxn(resTrxn.data);  
           }
         }
         else{
            setFilterPptTrxn([]);
         }
       }
       else{
        setCSGameUser("N/A");
        setCSGameBetAmt("N/A");
        setCSGameWinloss("N/A");
        setCSGameTurnover("N/A");
        setFilterPptTrxn([]);
       }

    } 
    else {
      alertData.title = "Failed";
      alertData.msg = "Transaction not found";
      sharedFailAlert(alertData);
      setFilterPptTrxn([]);  
    }

    setTrxLoading(false);
  }

  const onCancelModal = ()=> {
    setModalVisible(false);  
  }

  const topTransactionSummary = async (record) => {

    let requestData = {
      mobile : record,
    }

    let response = await reportTopTransactionSummary(requestData);
    let result = await response.json();

    if (result.status == 'ok'){
      // setAgentOptions(resTrxn.msg != 'No record.' ? resTrxn.data : []);
    }

    setTopTrxnSummary(result.data)
  }

  const gameBettingSummary = (record) => {
  
    let requestData = {
      mobile : record,
    }

    reportGameBettingSummary(requestData)
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
        const userIDResult = Object.keys(json.data.userData).length
        ? json.data.userData.map((data) => {
            // setTableLoading(false);
            return {
              Mobile                : data.Mobile,
              AWC_UserID            : data.AWC_UserID,
              BankAccName           : data.BankAccName,
              GameUser              : data.GameUser,
              Joker_UserID          : data.Joker_UserID,
              RegisterDate          : data.RegisterDate,
              SA_UserID             : data.SA_UserID,
              UFA_UserID            : data.UFA_UserID,
            };
            })
            : [];            

            setUserIdResult(userIDResult);
        } 
        // else {
        //   alertData.title = "Error";
        //   alertData.msg = "Connection not stable, please try again !";
        //   sharedErrorAlert(alertData);
        // }        
      });

      reportGameBettingSummary(requestData)
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
        const gameBetResult = Object.keys(json.data.gameBetData).length
        ? json.data.gameBetData.map((data) => {
            // setTableLoading(false);
            return {
              TrxnDate              : data.TrxnDate,
              GameCode              : data.GameCode,
              GameID                : data.GameID,
              GameName              : data.GameName,
              TotalTicket           : data.TotalTicket,
              BetAmt                : data.BetAmt,
              WinLoss               : data.WinLoss,
              Turnover              : data.Turnover,
            };
            })
            : [];            

            setGameBetSummary(gameBetResult);
        } 
        // else {
        //   alertData.title = "Error";
        //   alertData.msg = "Connection not stable, please try again !";
        //   sharedErrorAlert(alertData);
        // }        
      });
      
  };

  const depWthList = (record) => {
  
    let requestData = {
      mobile : record,
    }

    reportDepWthList(requestData)
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
        const depListResult = Object.keys(json.data.depData).length
        ? json.data.depData.map((data) => {
            // setTableLoading(false);
            return {
              
              TrxnDate              : data.TrxnDate,
              TrxnAmt               : data.TrxnAmt,
            };
            })
            : [];            

            setDepList(depListResult);
        } 
        // else {
        //   alertData.title = "Error";
        //   alertData.msg = "Connection not stable, please try again !";
        //   sharedErrorAlert(alertData);
        // }        
      });

      reportDepWthList(requestData)
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
        const wthListResult = Object.keys(json.data.wthData).length
        ? json.data.wthData.map((data) => {
            // setTableLoading(false);
            return {
              
              TrxnDate              : data.TrxnDate,
              TrxnAmt               : data.TrxnAmt,
            };
            })
            : [];            

            setWthList(wthListResult);
        } 
        // else {
        //   alertData.title = "Error";
        //   alertData.msg = "Connection not stable, please try again !";
        //   sharedErrorAlert(alertData);
        // }        
      });
      
  };

  const topListDetails = (record, record2) => {
    setMobile(record);
    setModalVisible(true);
    setTrxnSummaryTitle(`Transaction Summary`);
    setGameBettingTitle(`Game Betting Summary`);
    setDepListTitle(`Deposit List`);
    setWthListTitle(`Withdraw List`);

    topTransactionSummary(record);
    gameBettingSummary(record);
    depWthList(record);
  }

  const onChangeTransferTab = (key)=> {
    if (key === 1) {

    }

    if (key === 2) {
    }

  }

  const onEditTransferForm =(newTransferDtt) => {
    return (
      <Form
        layout="vertical" 
        name="editTransferForm"
        // form={form}
        //onFinish={handleFinishSubmitTransfer}
      >
        <Tabs defaultActiveKey="1" onChange={onChangeTransferTab}
        >
          <TabPane tab={trxnSummaryTitle} key="1">
            <Table
              loading={modal1Loading}
              columns ={trxnSummaryColumns}
              dataSource= {topTrxnSummary}
              scroll={{ x: isMobile ? "110%" : "100%", y: null }}
              pagination={settings.pagination}
            />
          </TabPane>
          <TabPane tab={gameBettingTitle} key="2">
            {/* <Table
              loading={modal2Loading}
              columns ={userIDColumns}
              dataSource= {userIdResult}
              scroll={{ x: isMobile ? "110%" : "100%", y: null }}
              pagination={settings.pagination}
            /> */}
            <Table
              loading={modal2Loading}
              columns ={gameBettingColumns}
              dataSource= {gameBetSummary}
              scroll={{ x: isMobile ? "110%" : "100%", y: null }}
              pagination={settings.pagination}
            />
          </TabPane>
          <TabPane tab={depListTitle} key="3">
            <Table
              loading={modal3Loading}
              columns ={depListColumns}
              dataSource= {depList}
              scroll={{ x: isMobile ? "110%" : "100%", y: null }}
              pagination={settings.pagination}
            />
          </TabPane>
          <TabPane tab={wthListTitle} key="4">
            <Table
              loading={modal4Loading}
              columns ={wthListColumns}
              dataSource= {wthList}
              scroll={{ x: isMobile ? "110%" : "100%", y: null }}
              pagination={settings.pagination}
            />
          </TabPane>
        </Tabs>
      </Form>
    )
  }
  //#endregion

  let trxnSummaryColumns = [
    {
      align: "center",
      title: "Trxn Type",
      dataIndex:"TrxnType",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "Total Trxn Amount",
      dataIndex:"TrxnAmt",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "Trxn Today",
      dataIndex:"TrxnToday",
      width: "15%",
      render:function(record, record2) {
        return  <p style={{marginBottom: "-2px"}}>{record} <span style={{color:"blue", marginTop: "14px"}}>&#40;<b>{record2.TrxnTodayCnt}</b>&#41;</span></p>
      }
    },
    {
      align: "center",
      title: "Trxn Yesterday",
      dataIndex:"TrxnYester",
      width: "15%",
      render: (record, record2)=>{
        return  <p style={{marginBottom: "-2px"}}>{record} <span style={{color:"blue", marginTop: "14px"}}>&#40;<b>{record2.TrxnYesterCnt}</b>&#41;</span></p>
      }
    },
    {
      align: "center",
      title: "Trxn 2 Day Ago",
      dataIndex:"Trxn2dayAgo",
      width: "15%",
      render: (record, record2)=>{
        return  <p style={{marginBottom: "-2px"}}>{record} <span style={{color:"blue", marginTop: "14px"}}>&#40;<b>{record2.Trxn2dayAgoCnt}</b>&#41;</span></p>

      }
    },
  ]

  let userIDColumns = [
    {
      align: "center",
      title: "Mobile",
      dataIndex:"Mobile",
      width: "13.5%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "Register Date",
      dataIndex:"RegisterDate",
      width: "13.5%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "BankAccName",
      dataIndex:"BankAccName",
      width: "13.5%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "AWC UserID",
      dataIndex:"AWC_UserID",
      width: "13.5%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "Joker UserID",
      dataIndex:"Joker_UserID",
      width: "13.5%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "SA UserID",
      dataIndex:"SA_UserID",
      width: "13.5%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "UFA UserID",
      dataIndex:"UFA_UserID",
      width: "13.5%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "HABA UserID",
      dataIndex:"GameUser",
      width: "9.5%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "WSSPORT UserID",
      dataIndex:"GameUser",
      width: "9.5%",
      render: (record)=>{
        return record
      }
    },
  ]
  
  let gameBettingColumns = [
    {
      align: "center",
      title: "Trxn Date",
      dataIndex:"TrxnDate",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "Provider",
      dataIndex:"GameCode",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "Game Name",
      dataIndex:"GameName",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "Total Ticket",
      dataIndex:"TotalTicket",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "Bet Amount",
      dataIndex:"BetAmt",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "Win Loss",
      dataIndex:"WinLoss",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "Turnover",
      dataIndex:"Turnover",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
  ]
  
  let depListColumns = [
    {
      align: "center",
      title: "Trxn Date",
      dataIndex:"TrxnDate",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "Deposit Amount",
      dataIndex:"TrxnAmt",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
  ]

  let wthListColumns = [
    {
      align: "center",
      title: "Trxn Date",
      dataIndex:"TrxnDate",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
    {
      align: "center",
      title: "Withdraw Amount",
      dataIndex:"TrxnAmt",
      width: "15%",
      render: (record)=>{
        return record
      }
    },
  ]

  const walletColumns = [
    {
      ellipsis: true,
      align: "center",
      title: "Date Time",
      dataIndex: "sysCreateTime",
      width: 120,
      fixed:"left",
      render: function(text, row) {
          return text;
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Balance (Before)",
      dataIndex: "balBefore",
      width: 80,
      render: function(text,record) {
          return FormatNumComm(text);
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Balance (After)",
      dataIndex: "balance",
      width: 80,
      render: function(text,record) {
          return FormatNumComm(text);
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Commission",
      dataIndex: "comm",
      width: 80,
      render: function(text,record) {
        return FormatNumComm(text);
      }
    },
    // {
    //   ellipsis: true,
    //   align: "center",
    //   title: "Bonus",
    //   dataIndex: "bonus",
    //   width: 80,
    //   render:function(text) {
    //     return FormatPt(text);
    //   }
    // }
    {
      ellipsis: true,
      align: "center",
      title: <div>Active<br/>Game</div>,
      dataIndex: "activeGame",
      width: 80,
      render: function(text,record) {
        return (text);
      }
    },
    {
      align: "center",
      title: "Remark",
      dataIndex: "remark",
      width: 200,
      render: function(text,record) {
        return <div style={{textAlign:"left"}}>{text}</div>;
      }
    },
  ]

  const memberColumns = [
    {
      ellipsis: true,
      align: "center",
      title: "Mobile",
      dataIndex: "mobile",
      width: 120,
      fixed:"left",
      render:function(record) {
        return  <p style={style.noHighlight}
                  // onClick={()=>{
                  //     // setGameUserVisible(true);
                  //     // setGameUserSecret(text);
                  //   }
                  // }
                  onClick={() => topListDetails(record)}
                >{record}</p>        
      }
      // render: function(text, row) {
      //   return (
      //     <p onCopy={handleMemberPhone} style={style.noHighlight}>
      //       {text}
      //     </p>
      //   )
      // }
    },
    {
      //ellipsis: true,
      align: "center",
      title: "Game User",
      dataIndex: "gameuser",
      width: 120,
      render: function(text,record) {
          if (text==undefined) {
            return <>{"N/A"}</>;
          }
          else {
            return (
              <>
                {text}&nbsp;&nbsp;
                 <Tooltip title="View Game User Id By Provider" placement="bottomLeft">
                    <Button type="primary" shape="circle" icon={<ProfileOutlined />} 
                      onClick={()=>{
                        getProviderPrefix();
                        }
                      }
                    />
                  </Tooltip>
              </>

            )

          }
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Agent",
      dataIndex: "agent",
      width: 90,
    },
    {
      ellipsis: true,
      align: "center",
      title: "Wallet",
      dataIndex: "walletBalance",
      width: 70,
      render:function(text) {
        return FormatPt(text);
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Comm.",
      dataIndex: "commBalance",
      width: 70,
      render:function(text) {
        return FormatPt(text);
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Turnover",
      dataIndex: "turnover",
      width: 80,
      render:function(text) {
        if (text!='N/A') {
          return FormatPt(text);
        }else{
          return text;
        }
      }

    },
    {
      ellipsis: true,
      align: "center",
      title: <div>Req. <br/>Turnover</div>,
      dataIndex: "reqTurnover",
      width: 90,
      render:function(text) {
        return FormatPt(text);
      }
    },
    // {
    //   ellipsis: true,
    //   align: "center",
    //   title: "Last Login",
    //   dataIndex: "lastLogin",
    //   width: 180,
    // },
    {
      //ellipsis: true,
      align: "center",
      title: <div>Date <br/>Registered</div>,
      dataIndex: "registered",
      width: 100,
      render: (text)=> {
        return <div>{moment(text).format("YYYY-MM-DD")}<br/>{moment(text).format("HH:mm:ss")}</div>
      }
    },
    {
      //ellipsis: true,
      align: "center",
      title: <div>Rescue<br/>Mission 2</div>,
      dataIndex: "rescueMission2",
      width: 100,
      render: (text)=> {
        switch (text) {
          case '':
            return <div>N/A</div>
          
          case undefined:
            return <div>N/A</div>

          case '1970-01-01 08:00:00':
            return <div>N/A</div>

          default:
            return <div>{moment(text).format("YYYY-MM-DD")}<br/>{moment(text).format("HH:mm:ss")} <br/> GMT +8</div>
        }
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Status",
      dataIndex: "STATUS",
      width : "120px",
      render: (text, record) => {
        switch (record.status) {
          case "Lock":
            return <Badge status="warning" text="Lock" />;

          case "Active":
            return <Badge status="success" text="Active" />;

          case "Withdraw Locked":
            return <Badge status="warning" text="Withdraw Locked" />;

          case "Account Locked":
            return <Badge status="warning" text="Account Locked" />;
          
          case "ResetPin":
            return <Badge status="success" text="Reset Pin"/>;
          
          case "VerifyPin":
              return <Badge color="magenta" text="Verify Pin"/>;

          default:
            return <Badge status="error" text="Inactive" />;
        }
      },
    },
    {
      //ellipsis: true,
      width: 300,
      align: "center",
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {

        switch (record.action) {

          case "Inactive":
          case "ResetPin":
            return (
              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  display: "flex",
                }}
              >
                  {   
                    unlockMember(role) ?
                    (
                    <>
                      <Button
                        style={{
                          width: "70px",
                          marginRight: 20,
                          backgroundColor: "#F7B105",
                          border: "none",
                          color: "#FFFFFF",
                        }}
                        onClick={() => {
                          handleLock(record);
                        }}
                      >
                      Lock
                    </Button>
                    <BtnInActiveMember props={record} />
                    </>
                    )
                    :
                      (<></>)
                  }           
                  <BtnEditMember props={record}/>
                  <BtnRecoverWallet issuper={issuper} memberUserId={record.userId}/>
                  {/* <BtnCreateDelayTopup issuper={issuper} customer={record}/> */}
                  <BtnCreditAdjustment customer={record}/>
                  <BtnViewTrxn memberUserId={record.userId} memberPhone={record.mobile}/>
                  <BtnViewTurnover memberUserId={record.userId}/>    
                  <BtnMemberWallet issuper={issuper} memberUserId={record.userId}/>      
              </div>
            );

          case "Unlock":

            return (
              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  display: "flex",
                }}
              >
                { unlockMember(role)?
                (<Button
                  style={{
                    marginRight: 20,
                    backgroundColor: "#0000FF",
                    border: "none",
                    color: "#FFFFFF",
                  }}
                  loading={loadings[2]}
                  onClick={() => {
                    handleUnlock(record, 2);
                  }}
                >
                  Unlock
                </Button>) :<></>
                }
                <BtnInActiveMember props={record} />
                <BtnEditMember props={record}/>
                <BtnViewTrxn memberUserId={record.userId} memberPhone={record.mobile}/>
                <BtnViewTurnover memberUserId={record.userId}/>   
                <BtnMemberWallet issuper={issuper} memberUserId={record.userId}/>       
              </div>
            );

            case "Account Locked":

              return (
                <div
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                  }}
                >
                  { 
                    unlockMemberLockedBySystem(role, compCode) ?
                      (
                        <Button
                          style={{
                            marginRight: 20,
                            backgroundColor: "#0000FF",
                            border: "none",
                            color: "#FFFFFF",
                        }}
                          loading={loadings[2]}
                          onClick={() => {
                            handleUnlock(record, 2);
                          }}
                        >
                          Unlock
                        </Button>
                      ) 
                      :
                    <></>
                  }
                  <BtnInActiveMember props={record} />
                  <BtnEditMember props={record}/>
                  <BtnViewTrxn memberUserId={record.userId} memberPhone={record.mobile}/>
                  <BtnViewTurnover memberUserId={record.userId}/>   
                  <BtnMemberWallet issuper={issuper} memberUserId={record.userId}/>       
                </div>
              );

          case "Lock":
            return (
              <>
              { unlockMember(role)?
              (<Button
                loading={loadings[1]}
                type={"primary"}
                style={{ border: "none" }}
                onClick={() => {
                  handleActive(record, 1);
                }}
              >
                Active
              </Button>) :<></>
              }
              <BtnEditMember props={record}/>
              <BtnViewTrxn memberUserId={record.userId} memberPhone={record.mobile}/>
              <BtnViewTurnover memberUserId={record.userId}/> 
              <BtnMemberWallet issuper={issuper} memberUserId={record.userId}/>    
              </>
            );

          default:
            return <div>-</div>;
        }
      },
    },
  ]

  
  let bankColumns = [
    {
      title: "Bank Name",
      dataIndex: "bank",
      width: "15%",
    },
    {
      title: "Account Name",
      dataIndex: "acctname",
      width: "20%",
    },
    {
      title: "Account Number",
      dataIndex: "acctnum",
      width: "20%",
      render : (text)=> {
        return editMemberEnable ? 
            (
              <span>{text}</span>         
            ):
            (
              <span>{FormatMask(text)}</span>
            )
      }
      
    },
    {
      title: "Date Created",
      dataIndex: "sysCreateTime",
      width: "15%",
    },
    {
      title: "Created By",
      dataIndex: 'sysCreateBy',
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "actionStatus",
      width: "15%",
      sorter: (a, b) => a.status - b.status,
      render: (text, record) => {
        switch (record.status) {
            case 1: 
              return <Badge status="success" text="Active" />;       
            case 5:
            case 7: 
              return <Badge status="warning" text="Pending" />;
            case 9: 
              return <Badge status="error" text="Not Approved" />;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "userid",
      width : "10%",
      render: (text , record) => {
        return  (
          <div             
            style={{
              flexDirection: "row",
              display: "flex",
              float:"left"
            }}>
            {
              !editMemberEnable?
              (
                <Popconfirm title="Sure to delete?" onConfirm={() => {                   
                    onDeleteBankAcc(record.key)                    
                }}>
                  <Button
                    type="danger"
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              ):
              <></>
            }
            {
              editMemberEnable?
              (
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  props = {record}
                  onClick={()=> {
                    setEditMemberId(record.userid);
                    setEditBankCode(record.bankcode);
                    setEditBankName(record.bank);
                    setEditBankAccNum(record.acctnum);
                    setOldBankAccName(record.acctname);
                    setEditBankAccName(record.acctname);
                    if(record.status == 9 || record.status == 0 ) { setRejectMemberEnable(true) }
                    else { setRejectMemberEnable(false) };
                    setEditBankModal(true);
                  }}
                >
                </Button>
              ):
              <></>
            }
          </div>
        );
      },
    }
  ];

  bankColumns = editMemberEnable ? bankColumns.slice(0,7) : bankColumns.slice(0,6);

  let pptColumns = [
    {
      align: "center",
      title: "Date Time",
      dataIndex:"date",
      width: "200px",
      ellipsis: true,
      //sorter: (a, b) => new Date(a.date) - new Date(b.date)
    },
    // {
    //   align: "center",
    //   title: "Trxn ID",
    //   dataIndex:"trxnID",
    //   width: "200px",
    //   // ellipsis: true,
    // },
    {
      align: "center",
      title: <div>Transaction<br/>Type</div>,
      dataIndex: "trxnType",
      ellipsis: true,
      filters: [
        {
          text: 'Commision',
          value: 'Comm',
        },

        {
          text: 'Game-in',
          value: 'Game-In',
        },
        {
          text: 'Game-out',
          value: 'Game-Out',
        },
        {
          text: 'Promotion',
          value: 'Promo',
        },
        {
          text: 'Recovery',
          value: 'Recovery',
        },
        {
          text: 'Mission',
          value: 'Mission',
        },
        {
          text: 'Topup',
          value: 'Topup',
        },
        {
          text:'Adjustment',
          value : 'Adjustment',
        },
        {
          text: 'Withdraw',
          value: 'Withdraw',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.trxnType?.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
    },
    {
      align: "center",
      title: "Adj. Type",
      dataIndex: "trxnmode",
      render: (text,record)=> {  
        if (record.trxnType == "Adjustment"){
          if (text.includes("DelayDeposit")) {
            return (<div>Delay Deposit</div>)
          }
          if (text.includes("ApproveNoCredit")) {
            return (<div>Approve No Credit</div>)
          }
          if (text.includes("Promo")) {
            return (<div>Promo</div>)
          }
          if (text.includes("csAction")) {
            return (<div>Remove Credit</div>)
          }
          if (text.includes("Recovery")) {
            return (<div>{text}</div>)
          }
        }
      },
    },
    {
      align: "center",
      title: "Game Provider",
      dataIndex: "gamecode",
      ...getColumnSearchProps("gamecode"),
    },
    {
      ellipsis: true,
      align: "center",
      title: "Amount",
      dataIndex: "amount",
      width: "150px",
      ...getColumnSearchProps("amount"),
      render : (text) => {
        return <>{FormatPt(text)}</>
      },
    }, 
    {
      ellipsis: true,
      align: "center",
      title: "Actual Amount",
      dataIndex: "actualAmount",
      width: "150px",
      render :(text)=> {
        if (text===0) {
            return <>-</>;
        }else {
            return <>{FormatPt(text)}</>
        }
      },
      ...getColumnSearchProps("actualAmount"),
    },   
    {
      ellipsis: true,
      align: "center",
      title: "Commission",
      dataIndex: "comm",
      width: "150px",
    },
    {
      ellipsis: true,
      align: "left",
      title: "Status",
      dataIndex: "status",
      width: "200px",
      render: (text, record) => {
        switch (text) {
            case 1:
                if (record.trxnType =="Withdraw") {
                    return <Badge text="Bank Process Complete" color={"blue"} />;
                }
                return <Badge text="Approved" color={"blue"} />;       
            case 3:
                return <Badge text="Approved" color={"blue"} />;
            case 9:
              return <Badge status="error" text="Rejected" />;
            default:
               return <span>{text}</span>;

        }
      }
    },
    {
      align: "center",
      title: "Processed Time",
      dataIndex:"processtime",
      width: "200px",
      ellipsis: true,
      render: (text) => {
        return (text == "1970-01-01 08:00:00" ? "" : text);
      }
    }
  ];

  let gameColumns = [
    {
      align: "center",
      title: "Ticket Time",
      dataIndex:"ticketTime",
      width: "200px",
      ellipsis: true,
      fixed:"left",
    },
    {
      align: "center",
      title: "Game Code",
      dataIndex: "gamecode",
      width: "200px",
      ellipsis: true,
    },
    {
      ellipsis: true,
      align: "center",
      title: "Bet Amount",
      dataIndex: "betAmount",
      width: "150px",
      render:function(record) {
        return FormatPt(record);
      }
    },    
    {
      ellipsis: true,
      align: "center",
      title: "Win / Lose Amount",
      dataIndex: "winloseAmount",
      width: "150px",
      render:function(record) {
        return FormatPt(record);
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Win Loss",
      dataIndex: "winloss",
      width: "150px",
      render:function(record) {
        return FormatPt(record);
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Turnover",
      dataIndex: "turnover",
      width: "150px",
      render:function(record) {
        return FormatPt(record);
      }
    },
    {
      ellipsis: true,
      align: "center",
      title: "Status",
      dataIndex: "status",
      width: "200px",
      render: (text, record) => {
        switch (text) {
            case "L":
                return "Lose"
            case "D":
              return "Draw"
            case "W":
              return "Win"
            default:
               return "";

        }
      }
    },
  ];

  let twalletColumns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "8%",
    },
    {
      title: "Account Name",
      dataIndex: "acctname",
      width: "20%",
    },
    {
      title: "Account Number",
      dataIndex: "acctnum",
      width: "15%"
    },
    {
      title: "Date Created",
      dataIndex: "sysCreateTime",
      width: "13%",
    },
    {
      title: "Last Updated",
      dataIndex: "sysUpdateTime",
      width: "13%",
    },
    {
      title: "Updated By",
      dataIndex: 'sysCreateBy',
      width: "13%",
    },
    {
      title: "Status",
      dataIndex: "actionStatus",
      width: "10%",
      sorter: (a, b) => a.status - b.status,
      render: (text, record) => {
        switch (record.status) {
            case 1: 
              return <Badge status="success" text="Active" />;       
            case 5:
            case 7: 
              return <Badge status="warning" text="Pending" />;
            case 9: 
              return <Badge status="error" text="Not Approved" />;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width : "8%",
      render: (text , record) => {
        return  (
          <div             
            style={{
              flexDirection: "row",
              display: "flex",
              float:"left"
            }}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={()=> {
                    setTwalletId(record.id);
                    setEditMemberId(record.userid);
                    setEditTwalletAccNum(record.acctnum);
                    setEditTwalletAccName(record.acctname);
                    setEditTwalletAccStatus(record.status);
                    setOldTwalletAccName(record.acctname);
                    setOldTwalletAccNum(record.acctnum);
                    setEditTwalletModal(true);
                    if(record.status == 9 || record.status == 0 ) { setRejectMemberEnable(true) }
                    else { setRejectMemberEnable(false) };
                  }}
                >
                </Button>
          </div>
        );
      },
    }
  ];
  //#endregion 


  const handleUnlock = (record, index) => {
    loadingTrue(index);
    userMemberActiveV2({ userid: record.userId, csUserid : userId })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          var newData = [...originData];
          newData[record.key].status = "Active";
          newData[record.key].action = "Inactive";
          setOriginData(newData);
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
          loadingFalse(index);
        } else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
          loadingTrue(index);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const handleActive = (record, index) => {
    loadingTrue(index);
    userMemberActiveV2({ userid: record.userId, csUserid : userId })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          var newData = [...originData];
          newData[record.key].status = "Active";
          newData[record.key].action = "Inactive";
          
          //findMobileNumber();
          //setUpdatedData(filterMemberList(originData,searchMobile));
          setOriginData(newData);
          loadingFalse(index);
          // message.success(json.msg);
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
        } else {
          // message.error(json.msg);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const handleLock = (record) => {
    setSelected(true);
    setVisible(true);
    setMemberUserId(record.userId);
    setActiveKey(record.key);
    setReason("");
  };

  const handleInactive = (record) => {
    setVisible(true);
    setSelected(false);
    setMemberUserId(record.userId);
    setActiveKey(record.key);
    setReason("");
  };

  const handleReasonOk = (index) => {
    if (reason === "") {
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Lock Reason !";
      sharedErrorAlert(alertData);
      return;
    }
    if (selected) {
      loadingTrue(index);
      userMemberLockV2({
        userid: memberUserId,
        reason: reason,
        csUserid: userId,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            var newData = [...originData];
            newData[activeKey].status = "Lock";
            newData[activeKey].action = "Unlock";
          
            setOriginData(newData);
            setReason("");
            setVisible(false);

            alertData.title = "Success";
            alertData.msg = json.msg;
            sharedSuccessAlert(alertData);
            loadingFalse(index);
          } else {
            loadingTrue(index);
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          alertData.title = "Error";
          alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
          sharedErrorAlert(alertData);
        });
    }
    else {
      loadingTrue(index);
      userMemberInactiveV2({ userid: memberUserId, reason: reason, csUserid: userId })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            var newData = [...originData];
            newData[activeKey].status = "Inactive";
            newData[activeKey].action = "Active";

            setOriginData(newData);
            setVisible(false);
            // message.success(json.msg);
            alertData.title = "Success";
            alertData.msg = json.msg;
            sharedSuccessAlert(alertData);
            loadingFalse(index);
          } else {
            // message.error(json.msg);
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
            loadingTrue(index);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          alertData.title = "Error";
          alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
          sharedErrorAlert(alertData);
        });
    }
  };

  const handleReasonCancel = () => {
    setVisible(false);
  };

  const handleMember = async (record) => {
    let newRecord = JSON.parse(JSON.stringify(record.props));

    const response = await userProfileBank({userid:newRecord.userId});

    let respUserBank = await userBankAll({userid:newRecord.userId});

    let respBankCon = await respUserBank.json();

    setTrxBankLoading(true);

    if (respBankCon.status === "ok" ) {
         
        let count = 0;

        let newBankData = Object.keys(respBankCon.data).length ? 
            respBankCon.data.map((obj) => {
              return {
                key: count++,
                userid  : newRecord.userId,
                bankcode: obj.bankcode,
                bank : obj.bank,
                acctnum : obj.acctnum,
                acctname : obj.acctname,
                status : obj.status, 
                sysCreateTime: obj.sysCreateTime,
                sysUpdateTime: obj.sysUpdateTime,
                sysCreateBy: obj.sysCreateBy,
                actionStatus : 
                  obj.status == 5 || obj.status == 7
                  ? "Pending"
                  : obj.status == 1
                  ? "Active"
                  : obj.status == 9
                  ? "Deleted"
                  : "-",
              };
            }): [];

            newBankData = sortBankData(newBankData);
            setUserBankData(newBankData)

      
      setTrxBankLoading(false);
    }

    let result = await response.json();
    if (result.status === "ok") {

      if (Object.keys(result.data).length > 0) {
        let userProfile = result.data;
        setMemberUserId(userProfile.userid);
        setCountryCode(userProfile.mobile.substring(0, 2));
        setPhoneNum(userProfile.mobile.substring(2, newRecord.mobile.length));
        //setPhoneNum(userProfile.mobile.substring(2, record.mobile.length));
        setPin(userProfile.pin);
        setBankCode(userProfile.bankcode);
        setAcctNum(userProfile.acctnum);
        setAcctname(userProfile.acctname);
        setReferCode(userProfile.uplineReferCode);
        setLineId(userProfile.lineid);
        setGender(userProfile.gender);
        setDate(userProfile.dob)
        setReferEditDisable(userProfile.referCodeDisable);
        setPrevBankCode(userProfile.bankcode);
        setPrevAcctNum(userProfile.acctnum);
        setEditMemberModal(true);
      }
      else {
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
      }
    }
  };

  const selectDelayDepCountryCode = (value) => {
    setDelayDepCountryCode(value);
  };

  const selectCountryCode = (value) => {
    setCountryCode(value);
  };

  const addPhoneNumber = () => {

     return csCreateMemberV2({
       mobile: countryCode + phoneNum,
       compid: companyId,
       pin:pin,
       referCode: referCode,
       bankcode : bankcode,
       acctname : acctname,
       acctnum: acctnum,
       lineid : lineId,
       dob : date,
       gender : gender,
       createby : username,
       csUserId   : userId,
       reqToken : md5(getReqToken("memberSignUp")),
     })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          //memberList();

          setAddMemberModal(false);
          setPhoneNum("");
          setPin("");
          setReferCode("")
          setBankCode("")
          setAcctname("")
          setAcctNum("")
          setLineId("")
          setGender("");
          setDate("");
          setPrevBankCode("");
          setPrevAcctNum("");
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
        } 
        else {
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        };
      });
  };

const getProviderPrefix = async () => {
  let requestData = {
    columnRequire : ['GameGroup AS gameProvider', 'GameGroupPrefix AS prefix'],
    currency : 'THB'
  }

  const response = await getGameGroupPrefix(requestData);
  let result = await response.json();

  if(result.status === 'ok'){
    setGameProviderPrefix(result.data);
    setGameUserVisible(true);
  }
  else {
    alertData.title = "Failed";
    alertData.msg = result.msg;
    sharedFailAlert(alertData);
    setGameUserVisible(false);
  }
}

const genGameUser = (secret,gameProviderPrefix) =>{
  let userSecret = secret.toLowerCase();
  let gameUserList = [];

  for( let i = 0; i < gameProviderPrefix.length; i++){
      let gameProvider = { 
          id : gameProviderPrefix[i].gameProvider.toUpperCase(),
          username : (gameProviderPrefix[i].prefix != null ? gameProviderPrefix[i].prefix + userSecret : userSecret),
      }
      gameUserList.push(gameProvider)
  }
  return gameUserList;
}

  const updateMemberInfo = () => {
    let requestData = {
      mobile: countryCode + phoneNum,
      compid: companyId,
      pin: pin,
      referCode: referCode,
      bankcode : bankcode,
      acctname : acctname,
      acctnum: acctnum,
      lineid : lineId,
      dob : date,
      gender : gender,
      updatedby : username,
      userid : memberUserId, 
      prevbankcode : prevbankcode, 
      prevacctnum  : prevAcctnum,
    }

    if (newSecret){
      if (newSecret.length < 9 ) {
        alertData.title = "Validation Error";
        alertData.msg = "Game ID must be minimum 9 characters long!";
        sharedErrorAlert(alertData);
        return;
      }
      
      if (newSecret != oldSecret) {
        requestData.secret = newSecret;
      }
    }

    return csUpdateMember(requestData)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
            //memberList();
            findMobileNumber();
            setOldSecret(newSecret);
            clearAllFields(); 
            setEditMemberModal(false);
            alertData.title = "Success";
            alertData.msg = json.msg;
            sharedSuccessAlert(alertData);
        }
        else { 
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
        };
      });
  };

  const clearAllFields = () => {
    setAddMemberModal(false);
    setPhoneNum("");
    setPin("");
    setReferCode("")
    setBankCode("")
    setAcctname("")
    setAcctNum("")
    setLineId("")
    setGender("");
    setDate("")
    setPrevBankCode("");
    setPrevAcctNum("");
    setSecretEditDisable(true);
    setNewSecret("");
    setSecretVisible(true);
    setTwalletAccNum("")
    setTwalletAccName("")
  }

  const clearTwalletFields = () => {
    setTwalletAccNum("");
    setTwalletAccName("");
    setEditTwalletModal(false);
  }

  const clearBankFields = () => {
    setBankCode("");
    setAcctname("");
    setAcctNum("");
    setOldBankAccName("")
  }

  const clearBankCode = () => {
     setBankCode("");
  }

  const updateUplineRef = async () => {
    if (referCode == '') {
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Upline Referral Code !";
      sharedErrorAlert(alertData);
    }
    else { 
      let requestData = {
        userid : memberUserId,
        updateby : userId,
        newreferral : referCode,
      }
      const updateUplineReferral = await csUpdateUplineReferral(requestData);
      let result = await updateUplineReferral.json();

      if (result.status == "ok" ) {
        alertData.title = "Success";
        alertData.msg = result.msg;
        sharedSuccessAlert(alertData);
        SetReferralEditDisable(true);
        SetReferralVisible(true);
      }
      else{
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
      }
    }
  }

  const onCreateMemberForm = () => {
    return (
      <Form form={form} 
            layout="vertical" 
            name="createMemberform"
            initialValues={{ remember:false }}
      >
        <Form.Item
          label="Phone Number"
          required={true}
        >
          <Input.Group compact>
            <Select value={countryCode}
              onChange={selectCountryCode}>
              <Option value="66">+66</Option>
              <Option value="60">+60</Option>
            </Select>
            <Input style={{width:"66%"}} defaultValue="" placeholder="phone number" value={phoneNum}
              onChange={(e) => {
                setPhoneNum(e.target.value.replace(/\D|^0/g, ""));
              }}
              onBlur={(e)=>{
                validateUserPhone(e.target.value.replace(/\D|^0/g, ""))
              }}
            />
          </Input.Group>
        </Form.Item>
        <Form.Item
          id="pin"
          label="PIN"
          required={true}
        >
          <Input
           value={pin}
           style={style.formItem}
           placeholder="PIN"
           rules={[{required:true, message:'PLease input your PIN'}]}
           maxLength={40}
           onChange={(e) => {
              setPin(e.target.value.replace(/\s+/g, ""));
           }}
          />
        </Form.Item>
        <Form.Item
          id="bankCode"
          label="Bank"
        >
          <Select
                style={style.formItem}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.includes(input);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                value={bankcode}
                onChange={(value, event) => {
                  setBankCode(value);
                  //Search Bank Acc Name
                  for (var i = 0; i < csAvaBankList.length; i++) {
                    if (csAvaBankList[i].bankAcc === value) {
                      setBankCode(csAvaBankList[i].bankCode);
                      form.setFieldsValue({
                        bankcode: csAvaBankList[i].bankCode,
                      });
                    }
                  }
                }}
              >
                {csAvaBankList.map((option) => {
                  return (
                    <Option key={option.bankCode} value={option.bankCode}>
                      {option.bankName} 
                    </Option>
                  );
                })}
              </Select>
                <Button onClick={clearBankCode} >clear</Button>
        </Form.Item>
        <Form.Item
          id="bankAccountNo"
          label="Bank Account Number"
        >
          <Input
            value={acctnum}
            style={style.formItem}
            placeholder="Bank Account Number"
            onChange={onChangeAcctNum}
          />
        </Form.Item>
        <Form.Item
          id="bankAccountName"
          label="Bank Account Name"
        >
          <Input
            value={acctname}
            style={style.formItem}
            placeholder="Bank Account Name"
            onChange={(e) => {
                setAcctname(e.target.value);
            }}
            />
        </Form.Item>
        <Form.Item
          id="referralCode"
          label="Upline Referral Codes"
        >
          <Input
            value={referCode}
            style={style.formItem}
            placeholder="Upline Referral Code"
            onChange={(e) => {
                setReferCode(e.target.value);
            }}
            />
        </Form.Item>
        
        <Form.Item
          id="lineId"
          label="Line Id"
        >
          <Input
            value={lineId}
            style={style.formItem}
            placeholder="Line Id"
            onChange={(e) => {
                setLineId(e.target.value.replace(/\D|^0/g, ""));
            }}
            />
        </Form.Item>
        <Row>
            <Col span={6}>
                <Form.Item
                  id="gender"
                  label="Gender"
                >
                  <Select
                    dropdownStyle={{ position: "fixed" }}
                    value={gender}
                    onChange = {(gender)=> {
                      setGender(gender)
                  }}
                  >
                    <Option value="M">MALE</Option>
                    <Option value="F">FEMALE</Option>
                    <Option value="O">OTHER</Option>
                  </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                id="dob"
                label="Date of Birth"
                  >
                <DatePicker
                  key="1"
                  id="datePicker"
                  style={style.datePickerStyle}
                  onChange={datePicker}
                  value={ date !== "" ? moment(date) : undefined}
                  disabledDate={(current)=> {
                    return current > moment().endOf('day');
                  }}
                />
              </Form.Item>
            </Col>
        </Row>
       
      </Form>
    );
  };

  const onEditMemberForm = () => {

    return (
      <Form form={form} 
            layout="vertical" 
            name="editMemberform"
      >
        <Row>
          <Col span={12} >
            <Form.Item
              label="Phone Number"
              required={true}
            >
              <Input.Group compact>
                {
                  editMemberEnable ?
                      (
                        <>
                          <Select value={countryCode}
                              onChange={selectCountryCode}>
                                <Option value="66">+66</Option>
                                <Option value="60">+60</Option>
                              </Select>
                              <Input 
                                style={{width: "27.5%"}} 
                                defaultValue="" 
                                placeholder="phone number" 
                                value={phoneNum}
                                onChange={(e) => {
                                  setPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                                }}
                              />
                        </>
                      ):
                      (
                        <span>
                        {countryCode}{phoneNum}
                        </span>
                      )

                }
              </Input.Group>
            </Form.Item>
            <Form.Item
              id="pin"
              label="PIN"
              required ={true}
            >
              {
                editMemberEnable ?
                  (
                    <>
                      <Input
                        value={pin}
                                style={{width: "40%"}} 
                        placeholder="PIN"
                        maxLength={40}
                        rules={[{required:true, message:'Please input your PIN'}]}
                        onChange={(e) => {
                          setPin(e.target.value.replace(/\s+/g, ""));
                        }}
                      />
                    </>
                  ):
                  (
                    <span>
                      {pin}
                    </span>
                  )
              }
            </Form.Item>
            <Form.Item
              id="referralCode"
              label="Upline Referral Code"
            >
              <Input
              value={referCode}
              required={true}
              style={{width: "40%"}} 
              disabled={referralEditDisable}
              onChange={(e) => {
                setReferCode(e.target.value);
              }}
              />
              <span> </span>
              {
                memberMgmtUpdateUplineRef(role) && referralVisible == true ?
                <Button
                    style={{ marginLeft : '1%'}}
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={()=> {
                      SetReferralEditDisable(false);
                      SetReferralVisible(false);
                      SetSaveBtnLoading(true);
                      setOldReferCode(referCode)
                    }}
                  />
                :
                <></>
              }
              {
                memberMgmtUpdateUplineRef(role) && referralVisible == false ?
                <span>
                <Button
                    style={{ marginLeft : '1%'}}
                    type="dashed"
                    shape="circle"
                    icon={<SaveOutlined />}
                    onClick={()=> {
                      updateUplineRef()
                    }}
                />
                <Popconfirm
                  title="Confirm cancel?"
                  onConfirm={() => {
                    SetReferralVisible(true);
                    SetReferralEditDisable(true);
                    setReferCode(oldReferCode)
                  }}
                >
                <Button
                    style={{ marginLeft : '2%'}}
                    type="danger"
                    shape="circle"
                    icon={<CloseCircleOutlined />}
                    onClick={()=> {}}
                />
                </Popconfirm>
                </span>
                :
                <></>
              }

            </Form.Item>
            <Form.Item
              id="gameID"
              label="Game ID"
            >
              <Input
                value={secret}
                required={true}
                disabled={secretEditDisable}
                maxLength={12}
                style={{width: "40%"}} 
                onChange={(e) => {
                  setNewSecret(e.target.value);
                  setSecret(e.target.value);
                }}
              />
              <span> </span>
              {
                memberMgmtUpdateUplineRef(role) && secretVisible == true ?
                <Button
                    style={{ marginLeft : '1%'}}
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={()=> {
                      setSecretEditDisable(false);
                      setSecretVisible(false);
                      SetSaveBtnLoading(true);
                      setOldSecret(secret)
                    }}
                  />
                :
                <></>
              }
              {
                memberMgmtUpdateUplineRef(role) && secretVisible == false ?
                <span>
                <Popconfirm
                  title="Confirm cancel?"
                  onConfirm={() => {
                    setSecretVisible(true);
                    setSecretEditDisable(true);
                    setSecret(oldSecret);
                    setNewSecret("");
                  }}
                >
                <Button
                    style={{ marginLeft : '2%'}}
                    type="danger"
                    shape="circle"
                    icon={<CloseCircleOutlined />}
                    onClick={()=> {}}
                />
                </Popconfirm>
                </span>
                :
                <></>
              }

            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              id="lineId"
              label="Line Id"
              style={{width: '48%'}}
            >
              {
                editMemberEnable?
                (
                  <Input
                  value={lineId}
                  style={style.formItem2}
                  placeholder="Line Id"
                  onChange={(e) => {
                      setLineId(e.target.value.replace(/\D|^0/g, ""));
                  }}
                  />
                ):
                (
                  <span>{lineId}</span>
                )
              }
            </Form.Item>
            <Form.Item
              id="gender"
              label="Gender"  
              style={{width: '40%'}}   
              >
              {
                editMemberEnable ?
                (
                  <Select
                    dropdownStyle={{ position: "fixed" }}
                    value={gender}
                    onChange = {(gender)=> {
                        setGender(gender)
                    }}
                    style={style.fullWidth}
                  >
                    <Option value="M">MALE</Option>
                    <Option value="F">FEMALE</Option>
                    <Option value="O">OTHER</Option>
                  </Select>
                ):
                (
                  <span>{convertGender(gender)}</span>
                )
              }
            </Form.Item>
            <Form.Item
                id="dob"
                label="Date of Birth"
                style={{width: "40%"}}
                pre
                  >
                    {
                      editMemberEnable ?
                      (
                        <DatePicker
                          key="1"
                          id="datePicker"
                          style={style.fullWidth}
                          onChange={datePicker}
                          value={ date !== "" ? moment(date) : undefined}
                          disabledDate={(current)=> {
                            return current > moment().endOf('day');
                          }}
                        /> 
                      )
                      :
                      (
                        <span>{date}</span>
                      )
                    }
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          id="bankCode"
          label=""
        > 
          <Tabs defaultActiveKey="1" onChange={onChangeBankTab} id="member-bank-list">
            <TabPane tab="Bank Account(s)" key="1">
              <Table dataSource={userBankData} 
                    loading={trxBankLoading} 
                    columns={bankColumns} 
                    pagination={false}
                    scroll={{y : 300}}
                    />
              <Modal
                title={"Add Bank"}
                visible={addBankModal}
                onCancel={() => {
                  setAddBankModal(false);
                }}
                style={{
                  marginTop: 200
                }}
                loading={addBankModal}
                footer={
                  [
                    <Button 
                      align="right"
                      type="primary"
                      onClick = {()=> {
                        setAddBankModal(false);
                      }}
                      loading={editBankLoading}
                      >
                          Close
                    </Button>
                    ,
                    <Button 
                      align="right"
                      type="primary"
                      danger
                      onClick = {()=> {
                        onAddUserBank();
                      }}
                      loading={editBankLoading}
                      >
                          Save
                    </Button>
                  ]
                }
              >
                  <Form.Item style={style.addBankFormStyle}>
                      <Form.Item 
                      label="Select Bank"
                      style={{...style.show}}
                      >
                          <Select
                          style={{...style.formItem}}
                          showSearch
                          value={bankcode}
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option.children.includes(input);
                          }}
                          placeholder="Please select a bank"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          onChange={(value, event) => {
                            setBankCode(value);
                            //Search Bank Acc Name
                            for (var i = 0; i < csAvaBankList.length; i++) {
                              if (csAvaBankList[i].bankAcc === value) {
                                setBankCode(csAvaBankList[i].bankCode);
                                form.setFieldsValue({
                                  bankcode: csAvaBankList[i].bankCode,
                                });
                              }
                            }
                          }}
                        >
                          {csAvaBankList.map((option) => {
                            return (
                              <Option key={option.bankCode} value={option.bankCode}>
                                {option.bankName} 
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                      label="Account Number"
                      style={{...style.show}}
                      >
                      <Input
                        value={acctnum}
                        style={style.formItem}
                        placeholder="Enter Bank Account Number"
                        onChange={onChangeAcctNum}
                      />
                      </Form.Item>
                      <Form.Item
                        id="bankAccountName"
                        label="Account Name"
                        // style={role == CS_USER_TYPE.SuperCS ? {...style.show}: {...style.visHide}}
                        style={{...style.show}}

                      >
                        <Input
                          value={acctname}
                          // style={role == CS_USER_TYPE.SuperCS ? {...style.formItem}: {...style.visHide}}
                          style={ {...style.formItem}}
                          placeholder="Enter Bank Account Name"
                          onChange={(e) => {
                              setAcctname(e.target.value.replace(/[^a-zA-Z\u0E00-\u0E7F\s]+/g,""));
                          }}
                          />
                      </Form.Item> 
                  </Form.Item>
              </Modal>
              <Modal
                title={"Edit Bank"}
                visible={editBankModal}
                onCancel={() => {
                  setEditBankModal(false);
                }}
                style={{
                  marginTop: 200
                }}
                loading={editBankModal}
                footer={
                  [
                    <Button 
                    align="right"
                    type="secondary"
                    onClick = {()=> {
                      setEditBankModal(false);
                    }}
                    loading={editBankLoading}
                    >
                        Close
                  </Button>
                  ,
                    <Button 
                      align="right"
                      type="primary"
                      onClick = {()=> {
                        onEditUserBank()
                      }}
                      loading={editBankLoading}
                      >
                          Update
                  </Button>,
                  <Button 
                  align="right"
                  type="danger"
                  onClick = {()=> {
                    onRejectUserBank();
                  }}
                  loading={editBankLoading}
                  hidden={rejectMemberEnable}
                  >
                      Reject
                  </Button> 
                  ]
                  }
              >
              <Form.Item 
                style={style.editBankFormStyle}
              >
                          <Form.Item 
                            id="editBankName"
                            label="Bank Name"
                            style={{...style.show}}
                          >
                            {editBankName}
                          </Form.Item>
                          <Form.Item 
                            id="editBankAccountNum"
                            label="Bank Account Number"
                            style={{...style.show}}
                          >
                          {editBankAccNum}
                          </Form.Item>
                          <Form.Item
                            id="editBankAccountName"
                            label="Account Name"
                            style={{...style.show}}
                            >
                            <Input
                              value={editBankAccName}
                              style={style.formItem}
                              placeholder="Enter Bank Account Name"
                              onChange={(e) => {
                                  setEditBankAccName(e.target.value.replace(/[^a-zA-Z\u0E00-\u0E7F\s]+/g,""));
                              }}
                            />
                          </Form.Item>
                  </Form.Item>
              </Modal>
              <Button
                type="primary"
                style={{
                  marginTop: 15,

                }}
                block
                onClick={()=> {setAddBankModal(true); clearBankFields()}}
                >
                Add Bank
              </Button>
            </TabPane>
            <TabPane tab="True Wallet(s)" key="2">
              <Table 
                dataSource={userTwalletData} 
                columns={twalletColumns} 
                pagination={false}
                scroll={{y : 300}}
                />
              <Modal
                title={"Add True Wallet"}
                visible={addTwalletModal}
                onCancel={() => {
                  setAddTwalletModal(false);
                }}
                style={{
                  marginTop: 200
                }}
                loading={addTwalletModal}
                footer={
                  [
                    <Button 
                      align="right"
                      type="primary"
                      onClick = {()=> {
                        setAddTwalletModal(false);
                        clearTwalletFields();
                      }}
                      loading={addTwalletLoading}
                      >
                        Close
                    </Button>
                    ,
                    <Button 
                      align="right"
                      type="primary"
                      danger
                      onClick = {()=> {
                        onAddUserTwallet()
                      }}
                      loading={addTwalletLoading}
                      >
                        Save
                    </Button>
                  ]
                }
              >
                <Form.Item style={style.addBankFormStyle}>
                    <Form.Item
                    label="Account Number"
                    style={{...style.show}}
                    >
                      <Input
                        value={twalletAccNum}
                        style={style.formItem}
                        placeholder="Enter True Wallet Account Number"
                        onChange={(e) => {
                          setTwalletAccNum(e.target.value.replace(/\D|^0/g, ""));
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      id="bankAccountName"
                      label="Account Name"
                      style={{...style.show}}

                    >
                      <Input
                        value={twalletAccName}
                        style={{...style.formItem}}
                        placeholder="Enter True Wallet Account Name"
                        onChange={(e) => {
                          setTwalletAccName(e.target.value);
                        }}
                        />
                    </Form.Item> 
                </Form.Item>
              </Modal>
              <Modal
                title={"Edit True Wallet"}
                visible={editTwalletModal}
                onCancel={() => {
                  clearTwalletFields();
                }}
                style={{
                  marginTop: 200
                }}
                footer={
                  [
                    <Button 
                      align="right"
                      type="secondary"
                      onClick = {()=> {
                        clearTwalletFields();
                      }}
                      loading={editTwalletLoading}
                      >
                          Close
                    </Button>
                    ,
                    <Button 
                      align="right"
                      type="primary"
                      onClick = {()=> {
                        onEditUserTwallet();
                      }}
                      loading={editTwalletLoading}
                      >
                          Update
                    </Button>
                    ,
                    <Button 
                      align="right"
                      type="primary"
                      danger
                      onClick = {()=> {
                        onEditUserTwallet(9)
                      }}
                      loading={editTwalletLoading}
                      hidden = {rejectMemberEnable}
                    >
                        Reject
                    </Button> 
                  ]
                  }
              >
                <Form.Item 
                  style={style.editBankFormStyle}
                >
                  <Form.Item 
                    id="editTwalletAccountNum"
                    label="Account Number"
                    style={{...style.show}}
                    block
                  >
                  <Input
                      value={editTwalletAccNum}
                      style={{...style.formItem}}
                      placeholder="Enter True Wallet Account Number"
                      onChange={(e) => {
                        setEditTwalletAccNum(e.target.value.replace(/\D|^0/g, ""));
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    id="editTwalletAccountName"
                    label="Account Name"
                    style={{...style.show}}
                    >
                    <Input
                      value={editTwalletAccName}
                      style={{...style.formItem}}
                      placeholder="Enter True Wallet Account Name"
                      onChange={(e) => {
                        setEditTwalletAccName(e.target.value);
                      }}
                    />
                  </Form.Item>
                  </Form.Item>
              </Modal>
              <Button
                type="primary"
                style={{marginTop: 15}}
                block
                onClick={()=> {setAddTwalletModal(true)}}
                >
                Add True Wallet
              </Button>
            </TabPane>
         </Tabs>
         
        </Form.Item>
        <Form.Item
          id="bankCode"
          label="Bank"
          style={style.hide}
        >
          <Select
                style={style.formItem}
                disabled={!btnEnabled}
                showSearch
                value={bankcode}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.includes(input);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value, event) => {
                  setBankCode(value);
                  //Search Bank Acc Name
                  for (var i = 0; i < csAvaBankList.length; i++) {
                    if (csAvaBankList[i].bankAcc === value) {
                      setBankCode(csAvaBankList[i].bankCode);
                      form.setFieldsValue({
                        bankcode: csAvaBankList[i].bankCode,
                      });
                    }
                  }
                }}
              >
                {csAvaBankList.map((option) => {
                  return (
                    <Option key={option.bankCode} value={option.bankCode}>
                      {option.bankName} 
                    </Option>
                  );
                })}
              </Select>
              {
                 btnEnabled?
                 (
                  <Button onClick={clearBankCode}>clear</Button>
                 ):
                 (<></>)
              }
              
        </Form.Item>
        <Form.Item
          id="bankAccountNo"
          label="Bank Account Number"
          style={style.hide}
        >
          <span>{FormatMask(acctnum)}</span>
        </Form.Item>
        <Form.Item
          id="bankAccountName"
          label="Bank Account Name"
          style={style.hide}
        >
          <span>{acctname}</span>
        </Form.Item>
      </Form>
    );
  };

  const onCreditAdjustmentForm = () => {
    return (
      <div>
        <div>
            <Form 
              form={formCreditAdjustment} 
              layout="vertical" 
              // name="creditAdjustmentform"
            >
              <Form.Item
                label="Phone Number"
                required={true}
              >
                <Input.Group compact>
                  <Select 
                    value={selectedUserCountryCode} 
                    disabled={true}
                  >
                    <Option value="66">+66</Option>
                    <Option value="60">+60</Option>
                  </Select>
                  <Input 
                    style={{...style.phoneNumStyle2}}
                    placeholder="phone number" 
                    value={selectedUserPhoneNum} 
                    disabled={true}
                  />
                </Input.Group>
              </Form.Item>

              <Form.Item
                style={{
                  margintBottom: 10
                }}
              >
                  <Radio.Group 
                    defaultValue={"Topup"}
                    options={adjustmentOption} 
                    onChange={(e) => {
                      setCreditAdjustmentType(e.target.value);
                      setCreditAdjustmentAmt(1);
                    }}
                  />
                </Form.Item>
              
              {
                (creditAdjustmentType == 'Topup') ?
                  <Form.Item
                    label="Bank Receipt"
                    required={true}
                  >
                    <Upload 
                      {...fileProps} 
                      fileList={fileList}
                    >
                      <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                  </Form.Item>
                :
                  <></>
              }

              <Form.Item
                label="Amount"
                required={true}
              >
                <InputNumber
                  style={{
                    width: "180px"
                  }}
                  onChange={(value) => {
                    setCreditAdjustmentAmt(value);
                  }}
                  type="number"
                  min={1}
                  value={creditAdjustmentAmt}
                />
              </Form.Item>

              {
                (creditAdjustmentType == 'Topup') ?
                  <Form.Item
                    label="Bank Time"
                    required={true}
                  >
                    <Tooltip placement="bottom" title="Year">
                    <Input
                      type="text" 
                      size="4"
                      min="0"
                      maxLength="4"
                      placeholder="Year"
                      style={{
                        width: "80px"
                      }}
                      format="YYYY"
                      showTime
                      onOpenChange={false}
                      onChange={(e) => {
                        let onChangeYear = e.target.value.replace(/\D|^0/g, "")                        
                        setSelectedYear(onChangeYear);  
                        let checkLeapYear = moment([onChangeYear]).isLeapYear()
                        setLeapYearResult(checkLeapYear);                        
                        manualDate(onChangeYear, month, "", hour, min)       
                        setMonth("")                
                        setDay("")
                        checkMaxDay(checkLeapYear, month);

                      }}
                      allowClear={true}
                      value={selectedYear} 
                    />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Month">
                    <Input
                      type="text"
                      size="2"
                      min="0"
                      maxLength="2"
                      placeholder="Month"
                      style={{
                        width: "90px"
                      }}
                      onChange={(e) => {
                        let onChangeMonth = e.target.value.replace(/\D|^0/g, "0")
                        if (onChangeMonth > 12) {
                          onChangeMonth = ''
                          alertData.title = "Validation Error";
                          alertData.msg = "Invalid Month! e.g. min 01 - 12 max !";
                          sharedErrorAlert(alertData);
                          setCreditAdjustmentButtonLoading(false);
                        }
                        setMonth(onChangeMonth); 
                        setLeapYearResult(moment(selectedYear).isLeapYear())
                        manualDate(selectedYear, onChangeMonth, "", hour, min)
                        
                        setDay("");
                        checkMaxDay(leapYearResult, onChangeMonth); 
                      }}
                      allowClear={true}
                      value={month} 
                    />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Day">
                    <Input
                      min={0}
                      type="text"
                      max={31} 
                      maxLength={2}
                      placeholder="Day"
                      style={{
                        width: "80px"
                      }}
                      onChange={(e) => {
                        let onChangeDay = e.target.value.replace(/\D|^0/g, "0")
                        checkMaxDay(leapYearResult, month)
                        if (onChangeDay > maxDay) {
                          onChangeDay = ""
                          alertData.title = "Validation Error";
                          alertData.msg = "Invalid Day! e.g. min 01 - "+maxDay+" max !";
                          sharedErrorAlert(alertData);
                          setCreditAdjustmentButtonLoading(false);
                        }
                        setDay(onChangeDay);  
                        manualDate(selectedYear, month, onChangeDay, hour, min)
                        
                      }}
                      allowClear={true}
                      value={day} 
                    />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Hour">
                    <Input 
                      min={0} 
                      maxLength={2} 
                      value={hour} 
                      type="text"
                      placeholder={defaultHour}
                      style={{
                        width: "70px"
                      }}
                      onChange={(e) => {
                        let onChangeHour = e.target.value.replace(/\D|^0/g, "0")
                        if (onChangeHour > 23) {
                          onChangeHour = ''
                          alertData.title = "Validation Error";
                          alertData.msg = "Invalid Hour! e.g. min 00 - 23 max !";
                          sharedErrorAlert(alertData);
                          setCreditAdjustmentButtonLoading(false);
                        }
                        manualDate(selectedYear, month, day, onChangeHour, min)
                        setHour(onChangeHour);  
                      }}
                      allowClear={true}
                    />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Minute">
                    <Input
                      value={min} 
                      maxlength={2} 
                      type="text"
                      inputmode="numeric"
                      placeholder={defaultMin}
                      style={{
                        width: "70px"
                      }}
                      onChange={(e) => {
                        let onChangeMin = e.target.value.replace(/\D|^0/g, "0")
                        if (onChangeMin > 59) {
                          onChangeMin = ''
                          alertData.title = "Validation Error";
                          alertData.msg = "Invalid Minute! e.g. min 00 - 59 max !";
                          sharedErrorAlert(alertData);
                          setCreditAdjustmentButtonLoading(false);
                        }
                        manualDate(selectedYear, month, day, hour, onChangeMin)
                        setMin(onChangeMin);  
                      }}
                      allowClear={true}
                    />
                    </Tooltip>
                  </Form.Item>
                  :
                  <Form.Item
                  // name="remark"
                  label="Remark"
                  required={true}
                >
                  <Select
                    placement="bottomLeft"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.children.includes(input);
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(value, event) => {
                      setCreditAdjustmentRemark(value);
                      // console.log(`value`,value)
                      // for (var i = 0; i < creditAdjustmentRemarkListOption.length; i++) {
                      //   if (creditAdjustmentRemarkListOption[i].type === value) {
                      //   }
                      // }
                    }}
                    >          
                      {
                        creditAdjustmentRemarkListOption.map((option) => {
                          return (
                            <Option key={option.type} value={`${option.type} || ${option.remark}`}>
                              {option.remark}  
                            </Option>
                          );
                        })
                      }
                  </Select>
                </Form.Item>
              }

              {
                (creditAdjustmentType == 'Topup') ?
                    <Checkbox
                      onChange={(value) => {
                        setOneMinuteBugChecked(value.target.checked);
                      }}
                    >
                      1 Minute Bug Topup?
                    </Checkbox>
                :
                  <></>
              }
              <br/>
              <br/>

            </Form>

            

            <Button 
              type="primary" 
              onClick={onSubmitCreditAdjustmentForm} 
              // disabled={submitCreditAdjustmentDisable}
              disabled={false}
              loading={creditAdjustmentButtonLoading}
              >
                Submit
            </Button> 
        </div>
      </div>
    )
  }

  //#region -------- Manual Deposit Form --------
  const onManualCreateForm = () => {
    return (
      <div>
        <div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Delay Deposit" key="1">
              <Form form={formDelayDep} layout="vertical" name="depositform">
              {/* Mobile Id*/}
                <Form.Item
                  label="Phone Number"
                  required={true}
                  >
                    <Input.Group compact>
                      <Select value={delayDepCountryCode} disabled={true}
                        onChange={selectDelayDepCountryCode}>
                        <Option value="66">+66</Option>
                        <Option value="60">+60</Option>
                      </Select>
                      <Input style={{...style.phoneNumStyle2}} defaultValue="" placeholder="phone number" value={delayDepPhoneNum} disabled={true}
                        onChange={(e) => {
                          setDelayDepPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                        }}
                        onBlur={(e) => {
                            console.log(`validate phone number`);
                            validateUserPhone(e.target.value.replace(/\D|^0/g, ""));
                        }}
                      />
                    </Input.Group>
                  </Form.Item>  
              {/* Upload Receipt */}
              <Form.Item
                name="bankReceipt"
                label="Bank Receipt"
              >
                {receiptUploads()}
              </Form.Item>
              {/* Bank Acc Name */}
              <Form.Item
                name="depToBank"
                label="Deposit To Bank"
                rules={[
                  {
                    required: true,
                    message: "Please Select Deposit To bank!",
                  },
                ]}
              >
                <Select
                    style={style.datePickerStyle2}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.children.includes(input);
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(value, event) => {
                      setDelayDepToBankData(value);
                      //Search Bank Acc Name
                      for (var i = 0; i < csBankListOptions.length; i++) {
                        if (csBankListOptions[i].bankAcc === value) {
                        }
                      }
                    }}
                  >
                    {csBankListOptions.map((option) => {
                      return (
                        <Option key={option.AccountNum} value={`${option.BankCode} || ${option.AccountNum} || ${option.TableName}`}>
                          {option.BankCode} ({option.AccountNum})
                        </Option>
                      );
                    })}
                  </Select>
              </Form.Item>
              <Form.Item
                name="bankaccnum"
                label="Customer Bank Account"
                rules={[
                  {
                    required: true,
                    message: "Please input your bank!",
                  },
                ]}
              >
                <Select
                  style={style.datePickerStyle2}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.children.includes(input);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(value, event) => {
                    //setAcctNum(value);
                    //Search Bank Acc Name  
                    setDelayDepFromBankData(value);
                    //setDelayDepBankData(value);
                    for (var i = 0; i < delayDepBankLisOptions.length; i++) {
                      if (delayDepBankLisOptions[i].bankAcc === value) {
                        formDelayDep.setFieldsValue({
                          bankcode: delayDepBankLisOptions[i].bankcode,
                          bankaccname: delayDepBankLisOptions[i].acctname,
                        });
                      }
                    }
                  }}
                >
                  {delayDepBankLisOptions.map((option) => {
                    return (
                      <Option key={option.acctnum} value={`${option.bankcode} || ${option.acctnum} || ${option.acctname}`}>
                        {option.bankcode} ({option.acctnum})
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item> 

              {/* Bank Acc Num */}
              {/* <Form.Item name="bankacctnum" label="Bank Acc Number">
                {delayDepFromAcctnum}
              </Form.Item> */}

              {/* Bank Acc Name */}
              <Form.Item name="bankaccname" label="Bank Acc Name">
                {delayDepFromAcctname}
              </Form.Item>

              {/* Amount */}
              <Form.Item
                name="amt"
                label="Amount"
                rules={[
                  {
                    required: true,
                    message: "Please input the amount!",
                  },
                ]}
              >
                <InputNumber
                  onChange={handleChangeDelayDepAmt}
                  min={1}
                  type="number"
                  value={delayDepAmt}
                />
              </Form.Item>

              <Button type="primary" onClick={onSubmitForm} disabled={createDepositDisable}>
                Submit
              </Button> 

            </Form>
            </TabPane>

            <TabPane 
              tab="Adjustment" 
              key="2"
            >
              <Form 
                form={formAdjustment} 
                layout="vertical" 
                name="adjustform"
              >
                <Form.Item
                  label="Phone Number"
                  required={true}
                >
                  <Input.Group compact>
                    <Select 
                      value={delayDepCountryCode}
                      disabled={true}
                      onChange={selectDelayDepCountryCode}
                    >
                      <Option value="66">+66</Option>
                      <Option value="60">+60</Option>
                    </Select>
                    <Input 
                      style={{...style.phoneNumStyle2}} 
                      defaultValue="" 
                      placeholder="phone number" 
                      value={delayDepPhoneNum} disabled={true}
                      onChange={(e) => {
                        setDelayDepPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                      }}
                      onBlur={(e) => {
                          console.log(`validate phone number`);
                          validateUserPhone(e.target.value.replace(/\D|^0/g, ""));
                      }}
                    />
                  </Input.Group>
                </Form.Item>

                <Form.Item
                  name="amt"
                  label="Amount"
                  rules={[
                    {
                      required: true,
                      message: "Please input the amount!",
                    },
                  ]}
                >
                  <InputNumber
                    onChange={handleChangeAdjustAmt}
                    min={1}
                    type="number"
                    value={adjustAmt}
                  />
                </Form.Item>

                <Form.Item
                  name="remark"
                  label="Remark"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Remark!",
                    },
                  ]}
                  >
                    <Select
                      style={style.datePickerStyle2}
                      placement="bottomLeft"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.children.includes(input);
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(value, event) => {
                        setAdjustRemark(value);

                        for (var i = 0; i < adjustRemarkListOptions.length; i++) {
                          if (adjustRemarkListOptions[i].type === value) {
                          }
                        }
                      }}
                    >            
                      {
                        adjustRemarkListOptions.map((option) => {
                          return (
                            <Option key={option.type} value={`${option.type} || ${option.remark}`}>
                              {option.remark}  
                            </Option>
                          );
                        })
                      }
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="empty"
                    lable=""
                  >
                    <TextArea
                        rows={2}
                        style={style.hide}
                      />
                  </Form.Item>

                  {/* <Form.Item
                    name="remark"
                    label="Remark"
                  >
                    <TextArea
                      rows={4}
                      onChange={(e) => setAdjustRemark(e.target.value)}
                      value={adjustRemark}
                    />
                  </Form.Item> */}
                </Form>
                {
                  adjustSubmit ?
                    <Button type="primary" onClick={onSubmitAdjustForm} disabled={createAdjustDisable}>
                        Submit
                    </Button> 
                  :
                    <Spin style={{margin:7}}></Spin>
                }
            </TabPane>

            {
              memberMgmtRemoveCredit(role, compCode)?
              <TabPane tab="Remove Credit" key="3">
                <Form 
                  form={formRemoveCredit} 
                  layout="vertical" 
                  name="removeCreditform"
                >
                  <Form.Item
                    label="Phone Number"
                    required={true}
                  >
                    <Input.Group compact>
                      <Select 
                        value={delayDepCountryCode} 
                        disabled={true}
                        onChange={selectDelayDepCountryCode}
                      >
                        <Option value="66">+66</Option>
                        <Option value="60">+60</Option>
                      </Select>
                      <Input 
                        style={{...style.phoneNumStyle2}} 
                        defaultValue="" placeholder="phone number" 
                        value={delayDepPhoneNum} 
                        disabled={true}
                        onChange={(e) => {
                          setDelayDepPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                        }}
                        onBlur={(e) => {
                            console.log(`validate phone number`);
                            validateUserPhone(e.target.value.replace(/\D|^0/g, ""));
                        }}
                      />
                    </Input.Group>
                  </Form.Item>

                  <Form.Item
                    name="amt"
                    label="Amount (-)"
                    rules={[
                      {
                        required: true,
                        message: "Please input the amount!",
                      },
                    ]}
                  >
                    <InputNumber
                      onChange={handleChangeRemoveCreditAmt}
                      min={1}
                      type="number"
                      value={removeCreditAmt}
                    />
                  </Form.Item>
                </Form>

                {
                  removeCreditSubmit ?
                    <Button type="primary" onClick={onSubmitRemoveCreditForm} disabled={createRemoveCreditDisable}>
                        Submit
                    </Button> 
                  :
                    <Spin style={{margin:7}}></Spin>
                }

              </TabPane>
            :
              <></>
            }

          </Tabs>
        </div>
      </div>
    );
  };
  //#endregion

  return (
    <div>
      <PageHeader ghost={false}>
        <div style={style.pageHeaderContainer}>
          <div style={{float:"left"}}>
            <Input
              type="text"
              id="searchMobileNo"
              placeholder="Enter Mobile Number"
              style={{...style.refreshBtn,...style.memberSearchStyle}}
              onChange={(e) => {
                // setSearchMobile(e.target.value.replace(/\D/g, ""));           
                setSearchMobile(e.target.value);
              }}
              value={searchMobile}
            >
            </Input>
            <Button 
              type="primary"
              onClick={()=>{
                findMobileNumber();
              }}
              style ={style.hide}
            >
                Search Mobile Number
            </Button>
            <Dropdown overlay={menu}>
              <Button type="primary">
                Search By <DownOutlined />
              </Button>
            </Dropdown>

            <Button
              style={style.refreshBtn}
              type="primary"
              onClick={() => {
                  setFilterPptTrxn([]);
                  setOriginData([]);
                  clearWalletSummary();
                  clearMobileNo();
              }}
            >
              Reset
            </Button>
            <div style={{float:"right"}}>
                {
                memberMgmtCrMember(role)?
                  <Button
                  type="primary"
                  onClick={() => {
                    setAddMemberModal(true);
                    setEditMode(false);
                  }}
                >
                  Add Member
                </Button>:<></>
  
              }
            </div>



          </div>


        </div>
      </PageHeader>
      <div style={style.tabelContainer}>
        <Table
          columns={memberColumns}
          dataSource={originData}
          loading={tableLoading}
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={false}
          className="viewMember"
        />
        <Row style={{height:"50"}}>
            &nbsp;
        </Row>
        <Row style={style.refreshBtn} visible={puppetVisible}>
          <>
          {
            puppetVisible?
            (
              viewTrx == "wallet"?
              (<b>Member Wallet Log </b>)
              :viewTrx == "trxn" ? 
              (<><b>Member Wallet As of :</b> {csMemberWalletDt} &nbsp;&nbsp;<b>Balance :</b> <span type="primary">{csMemberWalletBal}</span>&nbsp;&nbsp;<b>Comm:</b>{csMemberWalletComm}</>):
              (<CMemberSummary version={"1"} type="gameuser" gameuser={csGameUser} betAmount={csGameBetAmt} winloss={csGameWinloss} turnover={csGameTurnover} />)
            ):""
          }
          </>            
       </Row>
       <div style={style.tabelContainer}>
        <>
          {
            puppetVisible?
            (
              <>
              <Row style={{marginBottom:30  }}>
              <DatePicker
                  key="2"
                  style={{...style.datePickerStyle, ...style.refreshBtn}}
                  onChange={trxDatePicker}
                  value={trxDateFrom}
                  ref={reportDateFrom}
                  refs
                  placeholder={"Select Date From"}
                  disabledDate={(current)=> {
                    return current > moment().endOf('day');
                  }}/>
                <DatePicker
                  key="3"
                  style={{...style.datePickerStyle, ...style.refreshBtn}}
                  onChange={trxDatePicker2}
                  value={trxDateTo}
                  ref={reportDateTo}
                  placeholder={"Select Date To"}
                  disabledDate={(current)=> {
                    let dateFr = trxDateFrom;
                    if (dateFr) {
                      return current < dateFr || current > moment().add(1, 'day');
                    }else {
                      return  current > moment().subtract(1, 'day');
                    }
                  }}
                  defaultPickerValue={()=>{
                      let dateFr = trxDateFrom;
                      if (dateFr) {
                          return dateFr;
                      }
                  }}
                  />
                  <Button
                    type={"primary"}
                    style={style.searchResetStyle}
                    icon={<SearchOutlined />}
                    onClick={searchMemberTrxn}
                  />
                  <Button
                    type={"primary"}
                    style={{...style.searchResetStyle,...style.refreshBtn, ...style.hide}}
                    onClick={clearTrxnSearch}
                     >
                    Reset   
                  </Button>
                  <BtnViewPlayerTrxn memberId={acctname}/>
              </Row>
                {
                  viewTrx == "wallet"? 
                  <Table
                    loading={userWltTableLoading}
                    columns={walletColumns}
                    dataSource= {userWalletData}
                    scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: settings.pageSizeOptions,
                      style: style.paginationStyle,
                      alignmentBottom: 'right',
                      total: userWalletTotal,
                    }}
                    onChange={handleTableChange}
                  />     
                  :
                  <Table
                    loading={trxLoading}
                    columns={viewTrx=="trxn" ? pptColumns :  gameColumns}
                    dataSource= {filteredPptTrxn }
                    scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: settings.pageSizeOptions,
                      style: style.paginationStyle,
                      alignmentBottom: 'right',
                    }}
                  />
                }
              </>
            ):""
          }
        </>
        </div>
      </div>{/*Table container ending*/}
      <Modal
        title="Game User Id"
        visible={gameUserVisible}
        getContainer={false}
        onCancel={()=>{
           setGameUserVisible(false);
        }}
        cancelButtonProps={{ type: "primary" }}   
        okButtonProps={({style:style.hide})} 
        cancelText="Close"
      >
          <div className="gameUserInfo">
            {   
              genGameUser(secret, gameProviderPrefix).map(function(obj){
                return(
                    <Row key={obj.id}>
                      <div className="col1"><b>{obj.id}</b>:</div>
                      <div className="col2">{obj.username}</div>
                      <div className="scol3">
                      <Tooltip title='Copy this Game Id'>
                        <Button type="primary" shape="circle"
                            onClick={() =>  navigator.clipboard.writeText(obj.username)}
                            icon={<CopyOutlined />} 
                            style={style.refreshBtn}
                          >
                          </Button>
                      </Tooltip>
          
                      </div>
                    </Row>
                  )
              })
            }
          </div>
      </Modal>
      <Modal
        title="Delayed Deposit / Adjustment Form"
        visible={manualDepositVisible}
        getContainer={false}
        //
        onCancel= {() => {
          onCancelDelayDep();
        }}
        cancelButtonProps={{ type: "primary" }}   
        okButtonProps={({style:style.hide})} 
        okText={viewMemberDlgOK}
        cancelText="Close"
        //
      >

        {onManualCreateForm()}
      </Modal>

      <Modal
        title="Credit Adjustment Form"
        visible={creditAdjustmentVisible}
        getContainer={false}
        closable={false}
        maskClosable={false}
        okButtonProps={{
          style: style.hide
        }} 
        onCancel= {() => {
          onCancelCreditAdjustment();
        }}
        cancelButtonProps={{ 
          type: "primary", 
          loading: creditAdjustmentButtonLoading
        }}
        cancelText="Close"
      >

        {onCreditAdjustmentForm()}
      </Modal>

      <Modal
        title={selected ? "Lock Reason" : "Inactive Reason"}
        visible={visible}
        onOk={() => {
          handleReasonOk(0);
        }}
        onCancel={handleReasonCancel}
        okText={selected ? "Lock" : "Inactive"}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0] }}
      >
        <TextArea
          rows={4}
          onChange={(e) => setReason(e.target.value)}
          value={reason}
        />
      </Modal>

      <Modal
        title={"Add Member"}
        visible={addMemberModal}
        onOk={addPhoneNumber}
        onCancel={() => {
          setAddMemberModal(false);
          clearAllFields();
        }}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0]  ,disabled: createMemberDisable }}
      >
        {onCreateMemberForm()}
      </Modal>
      
      <Modal 
        title ={viewMemberDlgTitle}
        visible={editMemberModal}
        onOk={updateMemberInfo}
        onCancel= {() => {
          setEditMemberModal(false);
          setSecret(oldSecret);
          clearAllFields();
        }}
        cancelButtonProps={{ type: "primary" }}   
        okButtonProps={
            editMemberEnable? 
            (
              { type: "danger", loading: loadings[0]  , disabled: editMemberDisable }
            ):
            (
              {style:style.hide}
            )
        } 
        okText={viewMemberDlgOK}
        cancelText="Close"
        center   
        width={1100}
      >
        {onEditMemberForm()}
      </Modal>
      <Modal visible={modalVisible}
                  title={ <Table
                    loading={modal2Loading}
                    columns ={userIDColumns}
                    dataSource= {userIdResult}
                    scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                    pagination={settings.pagination}
                  />}
                  okText={"Save"}
                  maskClosable={false}
                  cancelButtonProps={{ type: "primary" }}
                  okButtonProps={{ style:{display: "none"} }}
                  cancelText={"Close"}
                  onCancel={onCancelModal}
                  style={style.modalWide}
                  width={1300}
                >
                    {onEditTransferForm()}
                </Modal>
    </div>
  );
}
