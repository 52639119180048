import React, { useState, useEffect , useRef} from "react";
import {
  Table,
  message,
  Upload,
  Button,
  Modal,
  Form,
  Input,
  PageHeader,
  Tooltip,
  Badge,
  Radio,
  Row,
  Select,
  InputNumber,
  Dropdown,
  Menu,
  Spin,
  DatePicker,
} from "antd";
import { 
  UploadOutlined, 
  SearchOutlined, 
  InfoCircleFilled, 
  FileImageOutlined, 
  DownOutlined, 
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { isMobile } from "react-device-detect";
import {
  csDepositListV1,
  csDepositManualApprove,
  csDepositReject,
  puppetTrxnDetails,
  puppetTransactionList,
  puppetTrxnList,
  userProfileBank,
  getReqToken,
  csVerifyMobile,
  csBankList,
  uploadReceiptV2,
  csCreateManualDeposit,
  userBankAll,
  getBankList,
  csAvailBankList,
  
} from "../../config/networkConfig";
import { settings } from "../../config/appConfig";
import { fileDomain } from "../../config/config";
import { useSelector } from "react-redux";
import { CS_USER_TYPE } from "../../common/constants/csuserType";
import { PROCESS_TYPE } from "../../common/constants/processType";
import { FormatPt , FormatMask , ShowMobileNumber} from "../../library/numbers";
import { PPT_RM, BO_OFFICE } from "../../common/constants/constant";
import moment from "moment";
import md5 from "md5";
import { depMgmtManCrDep, depMgmtManAppRejDep } from "../../library/permission";
import style from "../../resource/style/style";
import { sortBankData } from "../../library/utils";
import { bankList } from "../../common/constants/bankTransaction";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import { BANK_INFO_TYPE } from "../../common/constants/bankType";

export default function DepositManagementV2() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(true);
  const [depositVisible, setDepositVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [amount, setAmount] = useState("");
  const [confirmAmount, setConfirmAmount] = useState("");
  const [reason, setReason] = useState("");
  const [searchText, setSearchText] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [status, setStatus] = useState("Pending");
  const {Column } = Table;
  const { role, userId ,companyId, issuper} = useSelector((state) => state.login);

  
  //Puppet Modal
  const [puppetVisible, setPuppetVisible]= useState(false);
  const [csPptTrxnList, setCSPptTrxnList] = useState([]);
  const [filteredPptTrxn, setFilterPptTrxn] = useState([]);
  const [memberBankAcctNum, setMemberAcctNum] = useState("");
  const [memberBankCode, setMemberBankCode] = useState("");
  const [trxnLoading, setTrxnLoading] = useState(false);

  //const [pptTrxnId, setPPTTrxnId] = useState("");
  const [mtTrxnId, setMTTrnxId] = useState(""); //Member Transaction Id;
  const [memberNickname, setMemberNickname] = useState("");
  const [topupAmt, setTopupAmt] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [processing, setProcessing] = useState(false);

  // Create Deposit
  const [bankcode, setBankCode] = useState("");
  const [acctnum, setAcctNum] = useState("");
  const [amt, setAmt] = useState(0);
  const [countryCode, setCountryCode] = useState("60");
  const [phoneNum, setPhoneNum] = useState("");
  //const [csBankListOptions, setCSBankListOptions] = useState([]);

  //Modal
  //const [manualDepositVisible, setManualDepositVisible] = useState(false);
  //const [createDepositDisable, setCreateDepositDisable] = useState(true);

  //Manual Deposit
  const [formDelayDep]             = Form.useForm();
  const [manualDepositVisible,     setManualDepositVisible] = useState(false);
  const [createDepositDisable,     setCreateDepositDisable] = useState(true);
  const [csBankListOptions,        setCSBankListOptions] = useState([]);
  const [csDelayDepFromBankList,   setDelayDepFromBankList] = useState([]);
  const [delayDepPhoneNum,         setDelayDepPhoneNum] = useState("");
  const [delayDepCountryCode,      setDelayDepCountryCode] = useState("66");
  const [delayDepBankLisOptions,   setDelayDepBankLisOptions] = useState([]);
  const [delayDepBankTime,         setDelayDepBankTime] = useState("");

  const [delayDepFromBankData,     setDelayDepFromBankData] = useState("");
  const [delayDepFromBankCode,     setDelayDepFromBankCode] = useState("");
  const [delayDepFromBankAcc,      setDelayDepFromBankAcc] = useState("");
  const [delayDepFromBankAccName,  setDelayDepFromBankAccName] = useState("");

  const [delayDepToBankData,       setDelayDepToBankData] = useState("");
  const [delayDepToBankCode,       setDelayDepToBankcode] = useState("");
  const [delayDepToBankAcc,        setDelayDepToBankAcc] = useState("");
  const [delayDepToBankAccName,    setDelayDepToBankAccName] = useState("");

  const [delayDepAmt,              setDelayDepAmt] = useState("");
  const refDelayDepDt              = useRef(null);
  const [delayDepSubmit,           setDelayDepSubmit] = useState(true);
  const [viewMemberDlgOK,          setViewMemberDlgOK] = useState("Save");
  const [formManualDepositLoading, setFormManualDepositLoading] = useState(false);

  //Form
  const [fileList, setFileList] = useState([]);
  const { Option } = Select;
  
  //Approved Button 
  const [btnApprovedLable,         setBtnApprovedLable] = useState("Approved");
 
  //To Prevent Multiple Create
  const [createSubmit,             setCreateSubmit] = useState(true);

  useEffect(() => {
    depositList();
    bankcodeList();
    availableFromBankList();
  }, [status]);
  
  useEffect(() => {
    setDelayDepCountryCode(delayDepCountryCode);
  }, [delayDepCountryCode]);

  useEffect(()=> {
    if (delayDepFromBankData != null) {
      let depBankData = delayDepFromBankData.split("||");
      setDelayDepFromBankCode((depBankData[0]||'').trim());
      setDelayDepFromBankAcc((depBankData[1]||'').trim());
      setDelayDepFromBankAccName((depBankData[2]||'').trim());
    }
  },[delayDepFromBankData])

  useEffect(()=> {
    if (delayDepToBankData != null) {
      let depToBankData = delayDepToBankData.split("||");
      setDelayDepToBankcode((depToBankData[0]||'').trim());
      setDelayDepToBankAcc((depToBankData[1]||'').trim());
      setDelayDepToBankAccName((depToBankData[2]||'').trim());
    }
  },[delayDepToBankData])

  useEffect(() => {
    submitWithdrawCheckList();
  }, [delayDepPhoneNum,delayDepFromBankData,delayDepToBankData,delayDepAmt]);

  // Onchange for checking all delay deposit field before unlock submit button
  const submitWithdrawCheckList = async () => {
    let phoneCheck = true, depositToBankCheck = true, depositFromBankCheck = true, depositAmtCheck = true;

    if (!delayDepPhoneNum) { 
        phoneCheck = false; 
    }

    if (!delayDepFromBankAcc) { 
      depositToBankCheck = false; 
    }

    if (!delayDepToBankAcc) { 
      depositFromBankCheck = false; 
    }

    if (delayDepAmt < 1) {
      depositAmtCheck = false; 
    }
    
    if (phoneCheck && depositToBankCheck && depositFromBankCheck && depositAmtCheck) {
      setCreateDepositDisable(false);
    }
    else { 
      setCreateDepositDisable(true);
    }
  }

  const depositList = async () => {
    var count = 0;
    setTableLoading(true);

    const resDepositList = await csDepositListV1({ processtype: PROCESS_TYPE.MANUAL });
    let result = await resDepositList.json();

    if (result.status == "ok") {
      const newData = Object.keys(result.data).length
      ? result.data.map((x) => {
        return {
          key: count++,
          date: x.dateTime,
          nickname: x.nickname,
          mobile  : x.mobile, 
          newNickname: ShowMobileNumber(role, x.nickname, x.mobile),
          bankcode: x.bank,
          bankNameIn: x.bankAccName,
          bankAcctIn: x.bankAcc,
          amount: x.amount,
          actualAmount: x.actualamount,
          trxnId: x.trxnId,
          userId: x.userId,
          pptTrxnId: x.pptTrxnid,
          receipt: x.receipt,
          remark: x.remark,
          completeByCS: x.CompleteByCS,
          actionStatus:
            x.status === "0" || x.status === "8"
              ? "Pending"
              : x.status === "1"
              ? "Approved"
              : x.status === "9"
              ? "Rejected"
              : "-",
          };
        })
      : [];

      setOriginData(newData);

      const pending = newData.filter((val) => {
        return val.actionStatus === "Pending";
      });
      const approve = newData.filter((val) => {
        return val.actionStatus === "Approved";
      });
      const reject = newData.filter((val) => {
        return val.actionStatus === "Rejected";
      });
      const approveDelayDep = newData.filter((val) => {
        return val.actionStatus === "Approved" && val.remark.includes(PPT_RM.DELAY_DEP);
      });

      const sortData =
        status === "Pending"
          ? pending
          : status === "Approved"
          ? approve
          : status === "Rejected"
          ? reject
          : status === "ApprovedDelayDep"
          ? approveDelayDep
          : [...newData];

      setData(sortData);
      setTableLoading(false);
    }
    else {
      setTableLoading(false);
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }
  };

  const availableFromBankList = async () => {

    const resDepositList = await csAvailBankList({ companyId: companyId });
    let result = await resDepositList.json();

    if (result.status == "ok") {
      setDelayDepFromBankList(result.data);
    }
    else {
      alertData.title = "Error";
      alertData.msg = result.msg;
      sharedErrorAlert(alertData);
    }
  };

const onManualCreateForm = () => {
  return (
    <div>
      <div>
          <Form 
            form={formDelayDep} 
            layout="vertical" 
            name="depositform">
            {/* Mobile Id*/}
              <Form.Item
                label="Phone Number"
                required={true}
              >
                <Input.Group compact>
                  <Select value={delayDepCountryCode} 
                    onChange={selectDelayDepCountryCode}>
                    <Option value="66">+66</Option>
                    <Option value="60">+60</Option>
                  </Select>
                  <Input style={{width:"66%"}} defaultValue="" placeholder="phone number" value={delayDepPhoneNum}
                    onChange={(e) => {
                      setDelayDepPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                    }}
                    onBlur={(e) => {
                      validateUserPhone(e.target.value.replace(/\D|^0/g, ""));
                    }}
                  />
                </Input.Group>
              </Form.Item>  
            {/* Upload Receipt */}
            <Form.Item
              // name="bankReceipt"
              label="Bank Receipt"
              required={true}
            >
              {receiptUploads()}
            </Form.Item>
            {/* Bank Acc Name */}
            <Form.Item
              name="depToBank"
              label="Deposit To Bank"
              rules={[
                {
                  required: true,
                  message: "Please Select Deposit To bank!",
                },
              ]}
            >
              <Select
                  style={styles.datePickerStyle2}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.children.includes(input);
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(value, event) => {
                    setDelayDepToBankData(value);
                    //Search Bank Acc Name
                    for (var i = 0; i < csBankListOptions.length; i++) {
                      if (csBankListOptions[i].bankAcc === value) {
                      }
                    }
                  }}
                >
                  {
                    csBankListOptions.map((option) => {
                      return (
                        <Option key={option.AccountNum} value={`${option.BankCode} || ${option.AccountNum} || ${option.AccountName}`}>
                          {option.BankCode} ({option.AccountNum}) - {option.AccountName}
                        </Option>
                      );
                    })
                  }
                </Select>
            </Form.Item>

            {/* <Form.Item
              name="bankTime"
              label="Transaction Date Time"
              rules={[
                {
                  required: true,
                  message: "Please Select Transaction Date Time!",
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime
                onChange={(value, dateString) => {
                  let initialDate = new Date(dateString)
                  let convertedDate = Math.floor(initialDate.getTime()/1000)
                  setDelayDepBankTime(convertedDate)
                }}
              >
              </DatePicker>
            </Form.Item> */}

            <Form.Item
              name="depFromBank"
              label="Customer Bank Account"
              rules={[
                {
                  required: true,
                  message: "Please input your bank!",
                },
              ]}
            >
              <Select
                style={styles.datePickerStyle2}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.includes(input);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value, event) => {
                  setDelayDepFromBankData(value);
                  for (var i = 0; i < delayDepBankLisOptions.length; i++) {
                    if (delayDepBankLisOptions[i].bankAcc === value) {
                      formDelayDep.setFieldsValue({
                        bankcode: delayDepBankLisOptions[i].bankcode,
                        bankaccname: delayDepBankLisOptions[i].acctname,
                      });
                    }
                  }
                }}
              >
                {
                  delayDepBankLisOptions.map((option) => {
                    return (
                      <Option key={option.acctnum} value={`${option.bankcode} || ${option.acctnum} || ${option.acctname}`}>
                        {option.bankcode} ({option.acctnum}) - {option.acctname}
                      </Option>
                    );
                  })
                }
              </Select>
            </Form.Item> 
            
            {/* Deposit From Bank Code */}
            {/* <Form.Item
              name="depositFromBankCode"
              label="Deposit From Bank Code"
              rules={[
                {
                  required: true,
                  message: "Please select Deposit From Bank Code !",
                },
              ]}
            >
              <Select
                style={styles.datePickerStyle2}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.includes(input);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                value={bankcode}
                onChange={(value, event) => {
                  setDelayDepFromBankCode(value);
                  for (var i = 0; i < csDelayDepFromBankList.length; i++) {
                    if (csDelayDepFromBankList[i].bankAcc === value) {
                      setDelayDepFromBankCode(csDelayDepFromBankList[i].bankCode);
                      formDelayDep.setFieldsValue({
                        bankcode: csDelayDepFromBankList[i].bankCode,
                      });
                    }
                  }
                }}
                >
                  {csDelayDepFromBankList.map((option) => {
                    return (
                      <Option key={option.bankCode} value={option.bankCode}>
                        {option.bankName} 
                      </Option>
                    );
                  })}
                  </Select>
              </Form.Item> */}

            {/* Deposit From Bank Acc Reference */}
            {/* <Form.Item
              name="depFromBankAcc"
              label="Deposit From Bank Account"
              rules={[
                {
                  required: true,
                  message: "Please fill in Deposit Bank Account !",
                },
              ]}
            >
              <Input
                type="text"
                onChange={handleChangeDelayFromBankAcc}
                allowClear
                placeholder={'Eg: xxx-x-x5033-x, Enter 5033 for KBank'}
                // maxLength={6}
                value={delayDepFromBankAcc}
              />
            </Form.Item> */}

            {/* Amount */}
            <Form.Item
              // name="amt"
              label="Amount"
              required={true}
            >
              <InputNumber
                min={1}
                type="number"
                value={delayDepAmt}
                onChange={(value) => {
                  setDelayDepAmt(value);
                }}
              />
            </Form.Item>

            <Button 
              type="primary" 
              onClick={handleCreateDelayDep} 
              disabled={createDepositDisable} 
              loading={formManualDepositLoading}>
              Submit
            </Button>

          </Form>
      </div>
    </div>
  );
};

const selectDelayDepCountryCode = (value) => {
  setDelayDepCountryCode(value);
};

const getCustBankDetailsDelayTopup = async (memberUserId)=>{

  let respUserBank = await userBankAll({userid:memberUserId});
  let respBankCon = await respUserBank.json();

  if (respBankCon.status == "ok" ) {
    if (Object.keys(respBankCon.data).length > 0 ) {      
      
      let count = 0;
      let newBankData = respBankCon.data.map((obj) => {
        return {
          key: count++,
          userid : memberUserId,
          bankcode : obj.bankcode,
          bank : obj.bank,
          acctnum : obj.acctnum,
          acctname : obj.acctname,
          status : obj.status, 
          actionStatus : 
            obj.status == 5 || obj.status == 7
            ? "Pending"
            : obj.status == 1
            ? "Active"
            : obj.status == 9
            ? "Deleted"
            : "-",
        };
      });

      let filter_active_bank = newBankData.filter(e => e.status == 1);
      setDelayDepBankLisOptions(filter_active_bank);
    }
    else {
        clearAllFields();
        alertData.title = "Error";
        alertData.msg = `Please create Bank Account for user +${delayDepCountryCode+delayDepPhoneNum}`;
        sharedErrorAlert(alertData);
    }
  }
}

const handleCreateDelayDep = async () => {
  if (fileList[0] == undefined) {
    alertData.title = "Field Required";
    alertData.msg = "Please upload Bank Receipt !";
    sharedErrorAlert(alertData);
    setFormManualDepositLoading(false);
    return;
  }

  formDelayDep.setFieldsValue({
    bankReceipt: fileList[0],
  });

  setCreateSubmit(false);
  setFormManualDepositLoading(true);

  const row = await formDelayDep.validateFields();
  const uploadResult = await uploadFileV2();
    
  if (uploadResult.status == "ok") {
    let filename = uploadResult.data !== undefined ? uploadResult.data.filename : '';
    let requestData = {
      mobile : delayDepCountryCode + delayDepPhoneNum,
      depFrom : 
        {
          fromBankCode     : delayDepFromBankCode,
          fromBankAcc      : delayDepFromBankAcc,
          fromBankAccName  : delayDepFromBankAccName,
        },
      depTo : 
        {
          toBankCode     : delayDepToBankCode,
          toBankAcc      : delayDepToBankAcc,
          toBankAccName  : delayDepToBankAccName,
        },

      uploadRef : filename,
      amt       : delayDepAmt,
      csId      : userId,
      reqToken  : md5(getReqToken('depoistManualCreate')),
    }

    let resCreateDelayDeposit = await csCreateManualDeposit(requestData);
    let result = await resCreateDelayDeposit.json();
    if (result.status == "ok") {
      onCancelDelayDep();
      depositList();
      setFormManualDepositLoading(false);
      alertData.title = "Success";
      alertData.msg = result.msg;
      sharedSuccessAlert(alertData);
    }
    else {
      setFormManualDepositLoading(false);
      alertData.title = "Error";
      alertData.msg = result.msg;
      sharedErrorAlert(alertData);
    }
  }
  else {
    alertData.title = "Failed";
    alertData.msg = uploadResult.msg;
    sharedFailAlert(alertData);
  }

  setCreateSubmit(true);
};

const clearAllFields = () => {
  setFileList([]);
  setDelayDepFromBankData(null);
  setDelayDepFromBankAccName("");
  setDelayDepFromBankAcc("");
  setDelayDepFromBankCode("");
  setDelayDepCountryCode("66");
  setDelayDepPhoneNum("");
  setDelayDepAmt("");
  formDelayDep.resetFields();
}

const onCancelDelayDep = () => {
  clearAllFields();
  setCreateDepositDisable(true);
  setFormManualDepositLoading(false);
  setManualDepositVisible(false);
}
  //#endregion

   //#region ------- Receipt file Uploads --------
  const fileProps = {
  listType: "picture",
  onRemove: (file) => {
    setFileList([]);
  },
  fileList: fileList,
  beforeUpload: (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      let newFileList = [];
      newFileList.push(file);
      setFileList(newFileList);
    });

    reader.readAsDataURL(file);
    return false;
  },
  onChange: (info) => {
    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);

    // 2. Read from response and show file link
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }

      return file;
    });

    setFileList(newFileList);
    return false;
  },
};

const receiptUploads = () => {
  return (
    <>
      <Upload {...fileProps} fileList={fileList}>
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
    </>
  );
};

const uploadFileV2 = async () => {
  const formData = new FormData();
  formData.append("fileReceipt", fileList[0]);
  if (fileList[0]!== undefined){
        const response = await uploadReceiptV2(formData);
      let result = await response.json();
      return result;
  }
  return { status:'ok'};
};

  //#endregion

  ////#region  Functions
const bankcodeList = async () => {
  let requestData = {
    bankType: BANK_INFO_TYPE.DEP,
    columnRequire : ['AccountNum', 'BankCode', 'AccountName'],
  }

  const response = await getBankList(requestData);
  let result = await response.json();

  if (result.status ==  'ok'){
    setCSBankListOptions(result.data)
  }
  else{
    alertData.title = "Failed";
    alertData.msg = result.msg;
    sharedFailAlert(alertData);
  }
};

const selectCountryCode = (value) => {
  setCountryCode(value);
};

const validateUserPhone = async(phoneNum)=> {
  const response = await csVerifyMobile({ mobile: delayDepCountryCode + phoneNum });
  let result = await response.json();

  if (result.status == "ok") {
    getCustBankDetailsDelayTopup(result.data.userid);
  }
  else {
    setCreateDepositDisable(true);
    clearAllFields();
    alertData.title = "Error";
    alertData.msg = "User account not found !";
    sharedErrorAlert(alertData);
  }
}

const loadingTrue = (index) => {
  const newLoadings = [...loadings];
  newLoadings[index] = true;
  setLoadings(newLoadings);
};

const loadingFalse = (index) => {
  const newLoadings = [...loadings];
  newLoadings[index] = false;
  setLoadings(newLoadings);
};

const handleSearch = (selectedKeys, confirm) => {
  confirm();
  setSearchText(selectedKeys[0]);
};

const handleReset = (clearFilters) => {
  clearFilters();
  setSearchText("");
};

  ////#endregion Functions

let searchInput = null;

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex=="newNickname"? "nickname":dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button
        onClick={() => handleReset(clearFilters)}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : false,
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) setTimeout(() => searchInput.select());
  },
  render: (text) =>
    text ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      false
    ),
});

const columns = [
  {
    align: "center",
    title: "Date / Time",
    dataIndex: "date",
    width: "200px",
    ellipsis: true,
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    fixed:"left",
  },
  {
    align: "center",
    title: "Bank Name(In)",
    dataIndex: "bankNameIn",
    width: "200px",
    ...getColumnSearchProps("bankNameIn"),
    render : (text) => {
      return <Tooltip title={text}>
          <label>{text}</label>
      </Tooltip>
    }
  },
  {
    ellipsis: true,
    align: "center",
    title: "Bank Account(In)",
    dataIndex: "bankAcctIn",
    width: "200px",
    render: (text) => { return <Tooltip placement="left" title={text}>{text}</Tooltip> } ,
    ...getColumnSearchProps("bankAcctIn"),
  },
  {
    ellipsis: true,
    align: "center",
    title: "Member Mobile",
    dataIndex: "newNickname",
    ...getColumnSearchProps("newNickname"),
    width: "200px",
  },
  {
    ellipsis: true,
    align: "center",
    title: "Amount",
    dataIndex: "amount",
    width: "150px",
    render: (text) => {
      return FormatPt(text);
    },
    ...getColumnSearchProps("amount"),
  },
  // {
  //   ellipsis: true,
  //   align: "center",
  //   title: "Actual Amount",
  //   dataIndex: "actualAmount",
  //   width: "150px",
  //   render :(text)=> {
  //       if (text === 0) {
  //           return <>-</>;
  //       }else {
  //           return <>{FormatPt(text)}</>
  //       }
  //   },
  //   ...getColumnSearchProps("actualAmount"),
  // },
  {
    ellipsis: true,
    align: "center",
    title: "Status",
    dataIndex: "action",
    width: "150px",
    render: (text, record) => {
      switch (record.actionStatus) {
        case "Pending":
          return (
            <Tooltip title="Press Me!">
              <Button
                style={{ backgroundColor: "#F7B105", border: "none" }}
                name={"Check"}
                type="primary"
                onClick={(event) => {
                  pendingBtnV2(record, event.target);
                }}
              >
                Pending
              </Button>
            </Tooltip>
          );

        case "Rejected":
          return <Badge status="error" text="Rejected" />;

        case "Approved":
          return <>
                <Badge status="success" text="Approved" />&nbsp;&nbsp;
                  {
                    record.pptTrxnId!='00000000-0000-0000-0000-000000000000' ?
                    (                   
                      <Tooltip title="View Details">
                      <Button type="primary" shape="circle" icon={<InfoCircleFilled />} 
                        onClick={()=>{
                          setEditMode(false);
                          ViewPPTDetails(record);
                        }
                        }
                      />
                    </Tooltip>
                    ):
                    ""
                  }

                </>
        default:
          return <></>;
      }
    },
  },
  {
      title :"Remark",
      dataIndex:"remark",
      width:"205px",
    //DELAY_DEPOSIT
      filters: [
      {
        text: 'Delayed Deposit',
        value: PPT_RM.DELAY_DEP,
      },
    ],
    filterMultiple: false,
    onFilter: (value, record) => record.remark.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
      //DELAY_DEPOSIT
    //...getColumnSearchProps("remark"),
      render:(text,record) => {
        let fulltext     = `{0} (${record.completeByCS})`;
        let actionStatus = record.actionStatus;
        switch (actionStatus) {
          case "Approved" :
            fulltext = fulltext.replace('{0}', 'Approved by');
            break;
          case "Rejected":
            fulltext = fulltext.replace('{0}', 'Rejected by');       
            break;
        }
        if (actionStatus == "Approved" ){
          if (record.remark.includes(PPT_RM.DELAY_DEP)) {
              let remark = record.remark;
              let newRemark = remark.indexOf('||') > 0 ? remark.split('||')[0]: remark;
              return <span><b>{fulltext}<br/>{newRemark}</b></span>;
          }
          return <span><b>{fulltext}</b></span>;
          
        } else if (actionStatus == "Rejected") {
          if (text =="") {
              return <span><b>{fulltext}</b></span>;
          }else{
            return <span>{text}<br/><b>{fulltext}</b></span>;
          }
        }
      }
  },
];

//columns = editMode? pptColumns: pptColumns.slice(0,6);

//#region Puppet Transactions
const onCancelDepReject = () => {
  if (selected)  { 
    setDepositVisible(false); 
  } 
  else {
    setRejectVisible(false);
  }
}


let pptColumns = [
  {
    align: "center",
    title: "",
    dataIndex:"PptBankTransactionID",
    width: "10px",
    ellipsis: true,
    render: (text)=> {
      return "";
    }
  },
  {
    align: "center",
    title: "Date / Time",
    dataIndex: "BankTrxTime",
    width: "200px",
    ellipsis: true,
    // render:(text) => {
    //   return moment(text).format('YYYY-MM-DD hh:mm:ss');
    // }
  },
  {
    align: "center",
    title: "Bank Name(In)",
    dataIndex: "BankCode",
    width: "150px",
    ellipsis: true,
  },    
  {
    align: "center",
    title: "Account Number",
    dataIndex: "BankAccNo",
    width: "150px",
    ellipsis: true,
  },
  {

    align: "center",
    title: "Bank Details",
    dataIndex: "BankDetails",
    width: "200px",
    ellipsis: true,
    ...getColumnSearchProps("BankDetails"),
  },
  {
    align: "center",
    title: "Amount",
    dataIndex: "BankAmount",
    width: "150px",
    ellipsis: true,
    render: (text) => {
      return FormatPt(text);
    }
  },
  {
    align: "center",
    title: "Action",
    dataIndex: "PptBankTransactionID",
    width: "200px",
    ellipsis: true,
    visible:false,
    render: (text, record) => {
      return (
        <div
          style={{
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
          }}
        >
            <Button
              type="primary"
              loading={loadings[1]}
              onClick={() => {
                //handleLock(record);
                approvePPTTrxn(record, 1);
              }}
            >
              {processing? "Processing" : "Approve"}
            </Button>
        </div>
      );
    }
  }
  ];

const pendingBtnV2 = (record) => {
  form.resetFields();
  setDepositVisible(true);
  setSelected(true);
  setActiveKey(record.key);
  setConfirmAmount(record.amount);
};

const handleApproveV2 = async (index)=>{
  setTrxnLoading(true);

  var newData = [...originData];
  let matchBank = newData[activeKey].bankcode, matchAcctno = newData[activeKey].bankAcctIn, matchAmt = newData[activeKey].amount , matchTrxnId = newData[activeKey].trxnId, memberNick = newData[activeKey].nickname;
  let matchUserId = newData[activeKey].userId;

  setPuppetVisible(true);
  setMemberNickname(memberNick);
  setTopupAmt(matchAmt);

  //Get user Bank Details
  const respUser = await userProfileBank({ userid: matchUserId });
  let resUser = await respUser.json();
  if (resUser.status == "ok") {
      if (Object.keys(resUser.data).length > 0) {
          let userData = resUser.data;

          setMemberBankCode(userData.bankcode);
          setMemberAcctNum(FormatMask(userData.acctnum));
      }
  }

  let requestData = {
    bankCode : matchBank,
    bankAccNum : matchAcctno,
    amt : matchAmt
  }

  const response = await puppetTrxnList(requestData);
  let result = await response.json();

  if (result.status == "ok") {
    if (result.data && result.data.length) {
      const pending = result.data.filter((item) => item.Status === 0 || item.Status === 8 );
      const filterPptTrxn = Object.keys(pending).length
      ? pending.map((x) => {
        return {
          key: x.PptBankTransactionID,
          BankTrxTime: x.BankTrxTime,
          BankCode: x.BankCode,
          BankAccNo: x.BankAccNo,
          BankDetails: x.BankDetails,
          BankAmount: x.BankAmount,
        }
      })
      : []

      setFilterPptTrxn(filterPptTrxn);
      setMTTrnxId(matchTrxnId);
      setEditMode(true);
    }
    else {
      setFilterPptTrxn([]);
    }
  }
  else {
    alertData.title = "Failed";
    alertData.msg = result.msg;
    sharedFailAlert(alertData);
    setFilterPptTrxn([]);
  }

  setTrxnLoading(false);
}

const approvePPTTrxn = async (record, index) => {

  var newData = [...originData];

  let approveData = {
    trxnId       : mtTrxnId, 
    pptTrxnId    : record.key, 
    processType  : PROCESS_TYPE.MANUAL, 
    csUserId     : userId,
    reqToken     : md5(getReqToken('depositManualApprove')),
    acctNum      : record.BankAccNo
  }

  if (index > -1) {
    const response = await csDepositManualApprove(approveData);
    let result = await response.json();
    loadingTrue(index);
    setProcessing(true);
    if (result.status == 'ok') {
      setData(newData);
      newData[activeKey].actionStatus = "Approved";
      setSelected(false);
      setDepositVisible(false);
      form.resetFields();
      setPuppetVisible(false);
      loadingFalse(index);
      setProcessing(false);
      alertData.title = "Success";
      alertData.msg = result.msg;
      sharedSuccessAlert(alertData);
      depositList();
    }
    else { 
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      loadingTrue(false);
      setProcessing(false);

      if (result.errcode == "2072") {
        newData[activeKey].actionStatus = result.data.status;
        setSelected(false);
        setDepositVisible(false);
        form.resetFields();
      }
    }
  }
  else {
    loadingTrue(index);
    setProcessing(false);
    alertData.title = "Failed";
    alertData.msg = "Connection not stable, please try again later.";
    sharedFailAlert(alertData);
  }
}

const onCancelApproveTrxn =() => {
  setPuppetVisible(false);
  setMemberNickname("")
  setTopupAmt("");
  setMemberAcctNum("");
  setMemberBankCode("");
}  

const ViewPPTDetails = async (record) => {
  //Get user Bank Details
  const respUser = await userProfileBank({ userid: record.userId });
  let resUser = await respUser.json();
  if (resUser.status == "ok") {
      if (Object.keys(resUser.data).length > 0) {
          let userData = resUser.data;

          setMemberBankCode(userData.bankcode);
          setMemberAcctNum(FormatMask(userData.acctnum));
      }
  }

  let requestData = {
    pptTrxnId: record.pptTrxnId,
    bankAcc: record.bankAcctIn
  }

  const response = await puppetTrxnDetails(requestData);
  let result = await response.json();
  if (result.status == "ok") {
    if (result.data && result.data.length) {
        const resultData = Object.keys(result.data).length
        ? result.data.map((x) => {
          return {
            key: x.PptBankTransactionID,
            BankTrxTime: x.BankTrxTime,
            BankCode: x.BankCode,
            BankAccNo: x.BankAccNo,
            BankDetails: x.BankDetails,
            BankAmount: x.BankAmount,
          }
        })
        : []

      setFilterPptTrxn(resultData);  
      setMemberNickname(record.nickname);
      setTopupAmt(record.amount);
      setPuppetVisible(true);     
    }
  }
  else {
    alertData.title = "Failed";
    alertData.msg = result.msg;
    sharedFailAlert(alertData);
  }
}
  //#endregion

const deposit = () => {
  return (
    <Form form={form}>
      <Form.Item name={["member", "amount"]} label="Deposit Amount">
        <div>{FormatPt(confirmAmount)}</div>
      </Form.Item>
      <Form.Item
        id="amount"
        name={["member", "amount"]}
        label="Confirm Amount"
        style={{display:'none'}}
      >
        <Input
          type={"number"}
          onChange={(e) => {
            setAmount(Number(e.target.value));
          }}
        />
      </Form.Item>
      <Form.Item style={{ textAlign: "center" }}>
        <Button type="primary" htmlType="submit" onClick={depositCancel}>
          Cancel
        </Button>
        {
          depMgmtManAppRejDep(role) ?
          (
            <>
              <Button
                loading={loadings[0]}
                style={{
                  marginRight: 20,
                  marginLeft: 20,
                  backgroundColor: "#4190f7",
                  borderColor: "#4190f7",
                }}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  handleApproveV2(0);
                }}
              >
                View Trxn & Approve
              </Button>
              <Button
                danger
                type="primary"
                htmlType="submit"
                onClick={(event) => {
                  handleReject(event.target);
                }}
              >
                Reject
              </Button>
            </>
          ):
          (<></>)
        }

      </Form.Item>
    </Form>
  );
};

const depositCancel = () => {
  setDepositVisible(false);
  // setSelected(false);
};

const rejectCancel = () => {
  setRejectVisible(false);
  setSelected(true);
};

const handleReject = () => {
  setRejectVisible(true);
  setSelected(false);
  return;
};

const rejectView = () => {
  return (
    <div>
      <Form form={form}>
        <Form.Item>
          <TextArea rows={4} onChange={(e) => setReason(e.target.value)} />
        </Form.Item>

        <Form.Item>
          <div style={{ textAlign: "center", marginTop: 30 }}>
          { //role === CS_USER_TYPE.Senior || role === CS_USER_TYPE.CS ?
              (<>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 10 }}
                  onClick={rejectCancel}
                >
                  Cancel
                </Button>
                <Button
                  loading={loadings[1]}
                  danger
                  id="rejectbtn"
                  name="rejectbtn"
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    rejectBtn(1);
                  }}
                >
                  Reject
                </Button>
              </>
              )
              //:(<></>)             
          }
          </div>
        </Form.Item>
      </Form>
    </div>
  );
  };

const rejectBtn = (index) => {
  loadingTrue(index);
  if (reason !== "") {
    var newData = [...originData];

    let rejectData =  {
      trxnId: newData[activeKey].trxnId,
      reason: reason,
      csUserid : userId,
      process : PROCESS_TYPE.MANUAL,
      reqToken   : md5(getReqToken('depositManualReject')),
    }

    csDepositReject(rejectData)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          loadingFalse(index);
          newData[activeKey].actionStatus = "Reject";
          setRejectVisible(false);
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
          depositList();
        } else {
          alertData.title = "Error";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
          loadingFalse(index);
          if (json.errcode === "2072") {
            newData[activeKey].actionStatus = json.data.status;
            setRejectVisible(false);
            form.resetFields();
          }
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  } 
  else {
    loadingFalse(index);
    alertData.title = "Field Required";
    alertData.msg = "Please fill in Reject Reason !";
    sharedErrorAlert(alertData);
  }
};

const styles = {
  pageHeaderContainer: {
    justifyContent: isMobile ? "" : "space-between",
    display: "flex",
    flexDirection: isMobile ? "column" : "",
    alignItems: isMobile ? "center" : "",
  },
  refreshBtn: {
    marginLeft: 10,
  },
  tabelContainer: {
    backgroundColor: "#FFFFFF",
    padding: 24,
  },
  paginationStyle: {
    marginTop: 50,
  },
  // dropdownButtonStyle:{
  //   marginLeft:"-16px",
  //   marginRight:"-16px",
  // },
  hide :{
    display:"none",
  }
};

pptColumns = editMode? pptColumns: pptColumns.slice(0,6);
let pptPagination = editMode ? 
                    {
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20"],
                      style: styles.paginationStyle,
                    }: false ;
return (
  <div className="manualDeposit">
    <PageHeader ghost={false}>
      <div style={styles.pageHeaderContainer}>
        <div>
          <Button
            style={styles.refreshBtn}
            type="primary"
            onClick={() => {
              depositList();
            }}
          >
            Refresh Page
          </Button>
          {/* {
            depMgmtManCrDep(role) ?
              <Button
                type="primary"
                style={styles.refreshBtn}
                onClick={() => {
                  setManualDepositVisible(true);
                }}
            >
              Create Delay Deposit
            </Button>
            :
              <></>
          } */}
        </div>
        <Radio.Group
          buttonStyle={"solid"}
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        >
          <Radio.Button value="All">All</Radio.Button>
          <Radio.Button value="Pending">Pending</Radio.Button>
          <Radio.Button value="Approved">Approved</Radio.Button>
          <Radio.Button value="Rejected">Reject</Radio.Button>
        </Radio.Group>
      </div>
    </PageHeader>
    <Modal
      title="Delay Deposit Form"
      visible={manualDepositVisible}
      getContainer={false}
      cancelButtonProps={{ type: "primary", loading:formManualDepositLoading }}  
      okButtonProps={({style:style.hide})} 
      okText={viewMemberDlgOK}
      cancelText="Close"
      onCancel= {() => {
        onCancelDelayDep();
      }}
    >
      {onManualCreateForm()}
    </Modal>
    <Modal
      title={selected ? "Deposit Confirmation" : "Reject Reason"}
      visible={selected ? depositVisible : rejectVisible}
      footer={null}
      getContainer={false}
      onCancel={onCancelDepReject}
    >
      {selected ? deposit() : rejectView()}
    </Modal>
    <div style={styles.tabelContainer}>
      <Table
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => (
            record.receipt!=''?    
            <FileImageOutlined
            onClick={(e) => onExpand(record, e)}
            style={{ fontSize: 30 }}
            /> : null
          ),
          expandIconColumnIndex: 10,
          expandedRowRender: (record) => (
              <div style={{ textAlign: "center" }}>
              <img
                src={`${fileDomain}${record.receipt}`}
                style={{ height: 500 }}
                alt="receipt"
              /> 
            </div>
            
          ),
          rowExpandable: (record) => record.receipt !== "",
        }}
        loading={tableLoading}
        columns={columns}
        dataSource={data}
        scroll={{ x: isMobile ? "110%" : "100%", y: null }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: settings.pageSizeOptions,
          style: styles.paginationStyle,
          alignmentBottom: 'right',
        }}
      >
      </Table>
    </div>
    <Modal 
      title={editMode? "Transaction Lists" : "Transaction Details"}
      visible={puppetVisible}
      style={{ top : "35%" }}
      onCancel={()=>{
          setPuppetVisible(false);
          onCancelApproveTrxn();
      }}
      width={1200}
      footer={[
        <Button key="back"
        align="left"
        onClick={onCancelApproveTrxn}
        >
          {editMode? "Cancel": "Close"}
        </Button>
      ]}
    >
      <Row style={styles.refreshBtn}>
          Member Nickname : {memberNickname} &nbsp;&nbsp;&nbsp; Deposit Amount :  {FormatPt(topupAmt)}&nbsp;&nbsp;&nbsp;   Bank Code : {memberBankCode}   &nbsp;&nbsp;&nbsp; Acct No. : {memberBankAcctNum}  
      </Row>
      <div style={styles.tabelContainer}>
        <Table
          loading={trxnLoading}
          columns={pptColumns}
          dataSource={filteredPptTrxn}
          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
          pagination={{pptPagination}}
        />
      </div>
    </Modal>
  </div>
  );
}
