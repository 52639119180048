import React, { useEffect, useState , useRef  } from "react";
import {  Button,  Table,  PageHeader,  Badge,  Spin, Row, Col, Card, DatePicker, Space, Select} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import {  getGroupIp, getGroupUniqueIp } from "../../config/networkConfig";
import { alertData, sharedFailAlert } from "../../common/constants/sharedMsgBox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function BankVerifySum() {
    const [bankVerifyStatData, setBankVerifyStatData]       = useState([]);
    const [bankStatSuccessCount, setBankStatSuccessCount]   = useState(0);
    const [bankStatFailCount, setBankStatFailCount]         = useState(0);
    
    const [dateRange, setDateRange] = useState([moment(), moment()]);
    const reportDateFrom = useRef(moment().format('YYYY-MM-DD'));
    const reportDateTo = useRef(moment().format('YYYY-MM-DD'));
  
    // Loading
    const [tableLoading, setTableLoading]                   = useState(false);

    // useEffect
    useEffect(() => {
        retrieveBankStats();
    }, []);

    const columns = [
        {
            title: 'Create Time',
            dataIndex: 'sysCreateTime',
            width: '25%',
            render : (text,record) => {
                const date = new Date(Number(text) * 1000);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                const readableDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                return readableDate;
            },
            sorter: (a, b) => a.sysCreateTime - b.sysCreateTime,
        },
        {
            title: 'Request Ip',
            dataIndex: 'reqIp',
        },
        {
            title: 'Request Count',
            dataIndex: 'ipCount',
            sorter: (a, b) => a.ipCount - b.ipCount,
        },
        {
            title: 'Success',
            dataIndex: 'success',
        },
        {
            title: 'Fail',
            dataIndex: 'fail',
        },
    ]   

    //////////// functions /////////////////
    const handleDateChange = (date, dateString) => {
        setDateRange(date);
        reportDateFrom.current = dateString[0];
        reportDateTo.current = dateString[1];
        console.log("reportDateFrom.current: ",reportDateFrom.current);
        console.log("reportDateTo.current: ",reportDateTo.current);
    };

    const handleDateType = (value) => {
        let startDate, endDate;

        switch (value) {
          case "yesterday":
            startDate = moment().subtract(1, "days");
            endDate = moment().subtract(1, "days");
            break;
          case "thisweek":
            startDate = moment().startOf("week");
            endDate = moment().endOf("week");
            break;
          case "thismonth":
            startDate = moment().startOf("month");
            endDate = moment().endOf("month");
            break;
          case "lastmonth":
            startDate = moment().subtract(1, "month").startOf("month");
            endDate = moment().subtract(1, "month").endOf("month");
            break;
          default:
            startDate = moment();
            endDate = moment();
        }
    
        // Update the RangePicker with the selected date range
        setDateRange([startDate, endDate]);
    
        // Update the refs with the selected date range
        reportDateFrom.current = startDate.format('YYYY-MM-DD');
        reportDateTo.current = endDate.format('YYYY-MM-DD');
        console.log("reportDateFrom.current: ", reportDateFrom.current);
        console.log("reportDateTo.current: ", reportDateTo.current);
    };

    const searchDate = async () => {
        retrieveBankStats();
    }

    // ==================== API CALLS ======================== //
    const retrieveBankStats = async() => {
        console.log("retrieveDateFrom.current: ",reportDateFrom.current);
        console.log("retrieveDateTo.current: ",reportDateTo.current);
        setTableLoading(true)
        let requestData = {
            dateFrom      : reportDateFrom.current,
            dateTo        : reportDateTo.current,
        }
        
        let response = await getGroupIp(requestData);
        let result = await response.json();
        console.log("result :", result);

        let UniResponse = await getGroupUniqueIp(requestData);
        let UniRes = await UniResponse.json();
        console.log("UniRes :", UniRes);
        if (result.status == 'ok' && UniRes.status == 'ok'){
          if(Object.keys(result.data).length > 0){

            setBankStatSuccessCount(UniRes.data[0].success);
            setBankStatFailCount(UniRes.data[0].fail);

            let bankVeriStat = result.data.map((obj) => {
                return {
                    // count                  :   count++,
                    status                 :   obj.status,
                    sysCreateTime          :   obj.SysCreateTime,
                    success                :   obj.success,
                    fail                   :   obj.fail,
                    ipCount                :   obj.ipCount,
                    reqIp                  :   obj.ReqIp,
                }
            })
            let sortedData = bankVeriStat.sort((a,b) => b.sysCreateTime - a.sysCreateTime )
            setBankVerifyStatData(sortedData);
          } else {
            console.log('getGroupIp result only', result);
        }
        setTableLoading(false);
        }   else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
            setTableLoading(false);
        }
    }

    return (
        <div style={{backgroundColor: "#FFFFFF",padding: 24,paddingTop: 0}}>
            <PageHeader>
                {/* Example 1a: left card top refresh btn */}
                <div>
                    <div style={{display: 'flex', marginBottom: 15, alignContent: 'end'}}>
                        <Space direction="horizontal" size={12}>
                            <RangePicker 
                              value={dateRange}
                              onChange={handleDateChange}
                            />
                            <Select style={{marginLeft: 10, width: 120,}} placeholder="Select Preset Date" onChange={handleDateType}>
                               <Option value="yesterday">Yesterday</Option>
                               <Option value="thisweek">This Week</Option>
                               <Option value="thismonth">This Month</Option>
                               <Option value="lastmonth">Last Month</Option>
                            </Select>
                            <Button
                              type={"primary"}
                              icon={<SearchOutlined
                              onClick={searchDate}
                              />}
                            />
                        </Space>
                    </div>
                    <Card
                        style={{
                        width: 500,
                        backgroundColor: "#e2f2f0",
                        borderRadius: 10,
                        minHeight: 150,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        padding: 10
                        }}
                    >
                        {tableLoading ? (
                        <Spin 
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: "center",
                            alignItems: "center",
                        }}
                        size="large"
                        ></Spin>
                        ) : (
                        <div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                            <p style={{height: "25%", fontSize: "20px" }}>
                            Bank Verification IP Stats: 
                            </p>
                            </div>
                            <Row style={{display: 'flex', justifyContent:'space-between', width:'80%',  alignItems:'center'}} >
                                <Col  style={{ height: "25%", marginTop: "2%" }}>
                                        <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                        Success:
                                        </span> 
                                        <br/>
                                        <span style={{ fontSize: "24px", color:'green' }}>
                                        {bankStatSuccessCount}
                                        </span>
                                </Col>
                                <Col  style={{ height: "25%", marginTop: "2%",}}>
                                        <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                        Failed:
                                        </span> 
                                        <br/>
                                        <span style={{ fontSize: "24px", color:'red' }}>
                                        {bankStatFailCount}
                                        </span>
                                </Col>
                            </Row>
                        </div>
                        )}
                    </Card>
                </div>
            </PageHeader>
            <Table
            style={{ overflow: "auto", marginLeft: 20 }}
            loading={tableLoading}
            columns={columns}
            dataSource={bankVerifyStatData}
            // pagination={{
            //     // defaultPageSize: isMobile ? 10 : 100,
            //     defaultPageSize: 10,
            //     showSizeChanger: true,
            //     pageSizeOptions: isMobile ? ["5", "10", "20"] : settings.pageSizeOptions,
            //     style: {marginTop: 50},
            //   }}
            pagination={true}
            />
        </div>
    )    
  }