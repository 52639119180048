const InitialState = {
  signIn: false,
  role: "",
  userId: "",
  username: "",
  companyId: "", //only cs will be use
  compCode: "",
  reqToken:10,
  loginToken: "",
  issuper:false,
  bearerToken:"",
};

const login = (state = InitialState, { type, payload }) => {
  let newState = {};
  switch (type) {
    case "sign_in":
      newState = {
        ...state,
        signIn: true,
        role: payload.type,
        userId: payload.userId,
        username: payload.username,
        companyId: payload.companyId,
        compCode: payload.compCode,
        qrCode: payload.qrCode,
        refCode: payload.refCode,
        reqToken:payload.reqToken,
        loginToken: payload.loginToken,
        bearerToken: payload.bearerToken,
        issuper:JSON.parse(payload.issuper?? "false"),
      };
      break;

    case "sign_out":
      newState = {
        ...state,
        signIn: false,
        role: "",
        userId: "",
        username: "",
        companyId: "",
        qrCode: "",
        refCode: "",
        reqToken:10,
        loginToken: "",
        issuper:false,
      };
      break;

    default:
      newState = state;
  }
  return newState;
};

export default login;
