import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Form,
  Button,
  Modal,
  PageHeader,
  Select,
  Badge,
  Spin,
  Switch,
  Divider,
  InputNumber,
} from "antd";
import {
  SettingOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  getAllBrandsMaintList,
  getSingleBrandMaintList,
  setBrandMaint,
} from "../../config/networkConfig";
import md5 from "md5";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { CS_USER_TYPE, MARKETING_USER_TYPE, REPORT_USER_TYPE } from "../../common/constants/csuserType";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import { boUserVw, mtnMgmtSwt } from "../../library/permission";
import { CNotAuthorized } from "../../common/components/CNotAuthorized";


export default function BoMaintenanceSetting() {
  const {issuper, userId, username , role, companyId, compCode } = useSelector((state) => state.login);
  const [form] = Form.useForm();
  const [tableKey, setTableKey] = useState(0);
  const { Option } = Select;
  const { Search } = Input;
  
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [tableData, setTableData] = useState("");
  const [filteredTableData, setFilteredTableData] = useState("");
  const [tableLoading, setTableLoading] = useState(false);

  // Modal State
  const [visible, setVisible] = useState(false);
  const [mtnSystem,       setMtnSystem]       = useState(false);    //mtn = Maintenance
  const [mtnPlayGame,     setMtnPlayGame]     = useState(false);
  const [mtnDeposit,      setMtnDeposit]      = useState(false);
  const [mtnWithdraw,     setMtnWithdraw]     = useState(false);
  const [mtnMovie,        setMtnMovie]        = useState(false);
  const [mtnSport,        setMtnSport]        = useState(false);
  const [mtnPorn,         setMtnPorn]         = useState(false);
  const [mtnAutoWithdrawAmt, setMtnAutoWithdrawAmt] = useState(1);

  const [settLoading, setSettLoading] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [maintPageLoading, setMaintPageLoading] = useState(false);

  const styles = {
    pageHeaderContainer: {
      // justifyContent: isMobile ? "" : "space-between",
      display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
    },
    refreshBtn: {
      marginLeft: 10,
    },
    actionBtn: {
        height:"30px",
        display : issuper? "block":"none",
    },
    submitBtn: {
      marginBottom:"20px",
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    paginationStyle: {
      marginTop: 50,
    },
    formItem: {
      width:"80%"
    },
    datePickerStyle: {
      width: 260,
    },
    hide : {
      display:"none",
    },
    memberSearchStyle:{
      width:180,
    },
    on : {
      color:"green",
    },
    off : {
      color :"red"
    }
  };

  //Components
  const CLabel = (props) => {
    return <b>{props.loading? <Spin/> : 
               props.setting ? <span style={styles.on} loading={settLoading}>ON</span>:<span style={styles.off} loading={settLoading}>OFF</span> }</b>
}

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };

  const selectBrands = () => {
    const selected = [];

    selectedRowKeys.forEach(key => {
        const brand = tableData.find(item => item.key === key);

        if (brand) {
            // Create a new object with only the desired keys
            const { key, brandName, boApiUrl } = brand;
            selected.push({ key, brandName, apiUrl: boApiUrl });
        }
    });

    setSelectedBrands(selected);
  }

  useEffect(() => {
    const updatedSelectedBrands = selectedBrands;
  },[selectedBrands]);

  const onFinish = async (values) => {
    setSubmitLoading(true);

    let updatingBrands = selectedBrands;

    for (let i = 0 ; i < updatingBrands.length ; i ++) {
  
      if (updatingBrands[i]) {
        updatingBrands[i].mtnSystem = boolToString(mtnSystem);
        updatingBrands[i].mtnPlayGame = boolToString(mtnPlayGame);
        updatingBrands[i].mtnTrxnDeposit = boolToString(mtnDeposit);
        updatingBrands[i].mtnTrxnWithdraw = boolToString(mtnWithdraw);
        updatingBrands[i].mtnMovie = boolToString(mtnMovie);
        updatingBrands[i].mtnPorn = boolToString(mtnPorn);
        updatingBrands[i].mtnAutoWithdrawAmt = mtnAutoWithdrawAmt;
      }
    }

    console.log("updatingBrands >>>", updatingBrands);

    const result = await setBrandMaint(updatingBrands);
      let resp = await result.json();
      if (resp.status == "ok") {
          alertData.title = "Success";
          alertData.msg = resp.msg;
          sharedSuccessAlert(alertData);
      }
      else {
          alertData.title = "Failed";
          alertData.msg = resp.msg;
          sharedFailAlert(alertData);
      }
      onCancel();
      retrieveBrandMaintList();
      setSelectedRowKeys([]);
};

const onCancel = () => {
  setMtnSystem(false);
  setMtnPlayGame(false);
  setMtnDeposit(false);
  setMtnWithdraw(false);
  setMtnMovie(false);
  setMtnPorn(false);
  setMtnAutoWithdrawAmt(1);
  setVisible(false);
  setSubmitLoading(false);
}

const handleBulkAction = async () => {
  setSettLoading(true);
  setVisible(true)
  setSettLoading(false);
};


  //#region Functions
let boolToString = (value) => {
  if (value == null || value == '') {
    return 'N';
  }
  else {
     if (JSON.parse(value) == true) {
        return 'Y';
     }
     else {
       return 'N';
     }
  }
}

let boolToInt = (value) => {
  if (value == null || value == '') {
    return 0;
  }
  else {
     if (JSON.parse(value) == true) {
        return 1;
     }
     else {
       return 0;
     }
  }
}

const onChangeMtnSys = (e)=> {

  let status = e;
    setMtnSystem(status);
    setMtnSport(status)
    setMtnPlayGame(status);
    setMtnDeposit(status);
    setMtnWithdraw(status);
    setMtnMovie(status);
    setMtnPorn(status);
}

  useEffect(() => {
    // getParentPageList();
    // retrieveUserType();
    retrieveBrandMaintList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterBrandMaintList();
  },[searchField])

  const retrieveBrandMaintList = async () => {
    setTableLoading(true);
    const requestData = {
      columnRequire : [
        'brandName',
        'boApiUrl',
        'brandNum'
      ]
    }
    const getResponse = await getAllBrandsMaintList(requestData);
    const getResult = await getResponse.json();
    console.log("getResult >>>", getResult)

    if (getResult.status === 'ok' && getResult.data.length > 0) {
      // const data = getBrandResult.data.map((data) => (
      //   {
      //     key: data.brandName,
      //     brandName: data.brandName,
      //     boApiUrl: data.boApiUrl,
      //   }
      // ))
      setTableData(getResult.data)
      setFilteredTableData(getResult.data)
    } else {
      alertData.title = "Failed";
      alertData.msg = getResult.msg;
      sharedFailAlert(alertData);
    }
    setTableLoading(false);
  }

  const filterBrandMaintList = () => {
    let filteredData = []

    if (!searchField && tableData) {
      filteredData = tableData
    } else if (searchField && tableData) {
      filteredData =  tableData.filter(data => 
        data.brandName.startsWith(searchField)
      )
    }

    setFilteredTableData(filteredData);
  }

  const columns = [
    {
      ellipsis: true,
      title: "Brand",
      dataIndex: "brandName",
      width: "150px",
    },
    {
      ellipsis: true,
      title: "System Maintenance",
      dataIndex: "mtnSystem",
      align: "center",
      width: "200px",
      render: (text, record) => {
        switch (record.mtnSystem) {
          case "N":
            return <Badge status="error" text="OFF" />;
          case "Y":
            return <Badge status="success" text="ON" />;
          default:
            return record;
        }
      },
    },
    {
      ellipsis: true,
      title: "Play Game Maintenance",
      dataIndex: "mtnPlayGame",
      align: "center",
      width: "200px",
      render: (text, record) => {
        switch (record.mtnPlayGame) {
          case "N":
            return <Badge status="error" text="OFF" />;
          case "Y":
            return <Badge status="success" text="ON" />;
          default:
            return record;
        }
      },
    },
    {
      ellipsis: true,
      title: "Deposit Maintenance",
      dataIndex: "mtnDeposit",
      align: "center",
      width: "200px",
      // width: "100px",
      render: (text, record) => {
        switch (record.mtnDeposit) {
          case "N":
            return <Badge status="error" text="OFF" />;
          case "Y":
            return <Badge status="success" text="ON" />;
          default:
            // return <Badge status="error" text="Inactive" />;
            return record;
        }
      },
    },
    {
      ellipsis: true,
      title: "Withdraw Maintenance",
      dataIndex: "mtnWithdraw",
      align: "center",
      width: "200px",
      render: (text, record) => {
        switch (record.mtnWithdraw) {
          case "N":
            return <Badge status="error" text="OFF" />;
          case "Y":
            return <Badge status="success" text="ON" />;
          default:
            // return <Badge status="error" text="Inactive" />;
            return record;
        }
      },
    },
    {
      ellipsis: true,
      title: "Auto Withdraw Amount",
      dataIndex: "autoWitAmt",
      align: "center",
      width: "180px",
    },
    {
      ellipsis: true,
      title: "Action",
      // dataIndex: "action",
      align: "center",
      width: "150px",
      render: (text, record) => {
        return(
            <div>
                <Button 
                  type="primary"
                  onClick={() => handleMaintPage(record.boApiUrl, record.brandName)}
                  shape="circle"
                  loading={maintPageLoading}
                >
                        <SettingOutlined />
                </Button>
            </div>
        )
      }
    },
  ]

  const handleMaintPage = async (boApiUrl, brandName) => {
    setMaintPageLoading(true);

    const urlParams = new URLSearchParams({
        boApiUrl: boApiUrl,
        brandName: brandName
    }).toString();
    
    window.location.href = '/maintenance?' + urlParams;
}

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    selectBrands();
  },[selectedRowKeys])


  return (
    <div >
      {
        boUserVw(role) ?
        <div style={{ backgroundColor: "#FFFFFF" }}>
          <Modal
            title="Bulk maintenance"
            visible={visible}
            // footer={null}
            onCancel={onCancel}
            onOk={onFinish}
            okText="Submit"
            confirmLoading={submitLoading}
            cancelButtonProps={{loading: submitLoading}}
            width={700}
            // confirmLoading={modalLoading}
          >
            <Form
              {...formItemLayout}
              // onFinish={onFinish}
              form={form}
              // initialValues={{
              //   subUrls: subUrl,
              // }}
              style={{backgroundColor:"white",paddingBottom:"5px"}}
            >
              {/*Maintnenance Settings start */}
              <Form.Item label="System Maintenance">
                <Form.Item name="sysMtn" noStyle>
                  {
                    !mtnMgmtSwt(role, compCode)?
                    <>
                      <CLabel setting={mtnSystem} loading={settLoading}/>
                    </>
                    :
                      <Switch
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      onChange={(e) => {
                        onChangeMtnSys(e);
                      }}
                      checked={mtnSystem}
                      loading={settLoading}
                    />
                  }

                </Form.Item>
              </Form.Item>
              
              <Form.Item label="Play Game Maintenance">
                <Form.Item name="playGameMtn" noStyle>
                  {
                    !mtnMgmtSwt(role, compCode)?
                    <>
                      <CLabel setting={mtnPlayGame} loading={settLoading}/>
                    </>
                    :
                    <Switch
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      onChange={(e) => {
                        setMtnPlayGame(e);
                      }}
                      checked={mtnPlayGame}
                      loading={settLoading}
                    />
                  }
                </Form.Item>
              </Form.Item>

              <Form.Item label="Deposit Maintenance">
                <Form.Item name="depositMtn" noStyle>
                  {
                    !mtnMgmtSwt(role, compCode)?
                    <>
                      <CLabel setting={mtnDeposit} loading={settLoading}/>
                    </>
                    :
                    <Switch
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      onChange={(e) => {
                        setMtnDeposit(e);
                      }}
                      checked={mtnDeposit}
                      loading={settLoading}
                    />
                  }
                </Form.Item>
              </Form.Item>

              <Form.Item label="Withdrawal Maintenance">
                <Form.Item name="withdrawMtn" noStyle>
                  {
                    !mtnMgmtSwt(role, compCode)?
                    <>
                      <CLabel setting={mtnWithdraw} loading={settLoading}/>
                    </>
                    :
                    <Switch
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      onChange={(e) => {
                        setMtnWithdraw(e);
                      }}
                      checked={mtnWithdraw}
                      loading={settLoading}
                    />
                  }
                </Form.Item>
              </Form.Item>

              <Form.Item label="Movie Maintenance">
                <Form.Item name="movieMtn" noStyle>
                  {
                    !mtnMgmtSwt(role, compCode)?
                    <>
                      <CLabel setting={mtnMovie} loading={settLoading}/>
                    </>
                    :
                    <Switch
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      onChange={(e) => {
                        setMtnMovie(e);
                      }}
                      checked={mtnMovie}
                      loading={settLoading}
                    />
                  }
                </Form.Item>
              </Form.Item>

              {
                (compCode != 'OGT' && compCode != 'TKC') ?
                <></>
              :
                <Form.Item label="Live Sports TV Maintenance">
                    <Form.Item name="sportMtn" noStyle>
                      {
                        !mtnMgmtSwt(role, compCode)?
                        <>
                          <CLabel setting={mtnSport} loading={settLoading}/>
                        </>
                        :
                        <Switch
                          checkedChildren="ON"
                          unCheckedChildren="OFF"
                          onChange={(e) => {
                            setMtnSport(e);
                          }}
                          checked={mtnSport}
                          loading={settLoading}
                        />
                      }
                    </Form.Item>
                </Form.Item>
              }


              <Form.Item label="Adult Movie Maintenance">
                <Form.Item name="pornMtn" noStyle>
                  {
                    !mtnMgmtSwt(role, compCode)?
                    <>
                      <CLabel setting={mtnPorn} loading={settLoading}/>
                    </>
                    :
                    <Switch
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      onChange={(e) => {
                        setMtnPorn(e);
                      }}
                      checked={mtnPorn}
                      loading={settLoading}
                    />
                  }
                </Form.Item>
              </Form.Item>

              <Divider />

              {/* <Form.Item label="Withdraw by API Switch (SCB)">
                <Form.Item name="autoWithdrawMtn" noStyle>
                  {
                    !mtnMgmtSwt(role, compCode)?
                    <>
                      <CLabel setting={mtnWithdrawByAPI} loading={settLoading}/>
                    </>
                    :
                    <Switch
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      onChange={(e) => {
                        setMtnWithdrawByAPI(e);
                      }}
                      checked={mtnWithdrawByAPI}
                      loading={settLoading}
                    />
                  }
                </Form.Item>
              </Form.Item> */}

              <Form.Item label="Auto Withdraw Amount (Set to 1 to Disable)">
                <Form.Item name="autoWithdrawAmtMtn" noStyle>
                  {
                    !mtnMgmtSwt(role, compCode)?
                    <>
                      <InputNumber
                        style={{width: "30%"}}
                        value={mtnAutoWithdrawAmt}
                        disabled={true}
                      />
                    </>
                    :
                    <>
                      <InputNumber
                        style={{width: "30%"}}
                        value={mtnAutoWithdrawAmt}
                        min={0}
                        onChange={(e) => {
                          setMtnAutoWithdrawAmt(e);
                        }}
                      />
                      <InputNumber
                        style={{width: "30%", display: "none"}}
                        value={mtnAutoWithdrawAmt}
                        min={0}
                        onChange={(e) => {
                          setMtnAutoWithdrawAmt(e);
                        }}
                        disabled={true}
                      />
                    </>
                    
                  }
                </Form.Item>
              </Form.Item>
            </Form>
          </Modal>

          <PageHeader ghost={false}>
            <div>
              <Search
                placeholder="Search brand"
                onSearch={""}
                value={searchField}
                disabled={tableLoading}
                onChange={(e) => setSearchField(e.target.value.toUpperCase())}
                style={{
                  width: 200,
                }}
              />
              <span style={{ marginLeft: 16 }}>
                  {selectedRowKeys.length > 0 
                      ? <Button 
                          type="primary"
                          onClick={handleBulkAction}
                          // shape="circle"
                          loading={settLoading}
                        >
                                <SettingOutlined />
                                {selectedRowKeys.length > 0 
                                  ? selectedRowKeys.length === 1 
                                      ? `Selected ${selectedRowKeys.length} brand` 
                                      : `Selected ${selectedRowKeys.length} brands` 
                                  : ''}
                        </Button>
                      : ''}
              </span>
            </div>
          </PageHeader>
          <Form form={form} component={false}>
            <Table
              loading={tableLoading}
              scroll={{ x: "100%", y: null }}
              dataSource={filteredTableData}
              columns={columns}
              pagination={{
                defaultPageSize: 100,
              }}
              rowSelection={rowSelection}
              style={{ overflow: "auto", marginLeft: 20 }}
            />
          </Form>
      </div> : <CNotAuthorized />
      }
    </div>
  );
}