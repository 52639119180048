import React, { useEffect, useState ,  } from "react";
import { Form,  Button, Input,  Table,  Select, PageHeader, Modal, Badge, Typography} from "antd";
import {  getBrandList, addBrandConfig, updateBrandConfig, getCompanyUser, addBrandOwnership, deleteBrandOwnership } from "../../config/networkConfig";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert } from "../../common/constants/sharedMsgBox";
import { SearchOutlined,QuestionCircleOutlined, EditOutlined} from "@ant-design/icons";
import FormItem from "antd/lib/form/FormItem";
import { settings } from "../../config/appConfig";
import { isMobile } from "react-device-detect";
import { Option } from "antd/lib/mentions";

export default function BrandManagement() {
    const { Paragraph }                          =  Typography;
    const { confirm }                            =  Modal;
    const [form]                                 =  Form.useForm();

    const [addBtnLdg, setAddBtnLdg]              =  useState(false);
    const [editBtnLdg, setEditBtnLdg]            =  useState(false);
    const [formLdg, setFormLdg]                  =  useState(false);
    const [tableLdg, setTableLdg]                =  useState(false);
    const [editLdg, setEditLdg]                  =  useState(false);
    const [showAddModal, setShowAddModal]        =  useState(false);
    const [showEditModal, setShowEditModal]      =  useState(false);
    const [showTokenModal, setShowTokenModal]    =  useState(false);

    const [brandData, setBrandData]              =  useState([]);
    
    const [brandName, setBrandName]              =  useState('');
    const [brandUrl, setBrandUrl]                =  useState('');
    const [boApiUrl, setBoApiUrl]                =  useState('');
    const [bsUrl, setBsUrl]                      =  useState('');
    const [token, setToken]                      =  useState('');
    const [status, setStatus]                    =  useState('');
    const [brandNum, setBrandNum]                =  useState(0);
    
    const [ownerList, setOwnerList]              =  useState([]);
    const [owner, setOwner]                      =  useState([]);

    useEffect (() => {
        retrieveBrand();
        retrieveOwner();
    }, [])

    useEffect(() => {
        console.log("boApiUrl >>>", boApiUrl);
    },[boApiUrl])

    const layout = {
        labelCol: { span: 8},
        wrapperCol: { span: 16 },
    };
    
    const tailLayout = {
        wrapperCol: {
          offset: 8,
          span: 16,
        },
    };

    const columns = [
        {
            title: 'Brand No.',
            dataIndex: 'brandNum',
            width: '10%',
            // sorter: (a,b) => a.brandNum - b.brandNum,
            // defaultSortOrder: 'ascend'
        },
        {
            title: 'Brand Name',
            dataIndex: 'brandName',
            width: '15%'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '10%',
            render: (text, record) => {
                switch (record.status) {
                    case 0: 
                    return <Badge status="warning" text="Not Live" />;
                    case 1: 
                    return <Badge status="success" text="Live" />;  
                    case 2: 
                    return <Badge status="error" text="Demo" />;       
                }
            }
        },
        {
            title: 'BackOffice API Url',
            dataIndex: 'boApiUrl',
        },
        {
            title: 'Appbox API Url',
            dataIndex: 'brandUrl',
        },
        {
            title: 'Bank Service Url',
            dataIndex: 'bankServiceUrl'
        },
        // {
        //     title: 'Token',
        //     dataIndex: 'token',
        //     align: "center",
        //     ellipsis: true,
        //     render: (text,record) => {
        //         return (
        //             <Button
        //                 type="primary"
        //                 onClick={()=> viewToken(record)}
        //             >
        //                 View Token
        //             </Button>
        //         )
        //     }
        // },
        {
            // align: "center",
            title: 'Owner',
            dataIndex: 'owner',
            render: (text, record) => {
                // let testOwner = {text}.split(',');
                return (
                    <div style={{marginTop: '10px' }}>
                        {record.owner.split(',').map((obj) => (
                            <p key={obj}>{obj}</p>
                        ))}
                    </div>
                )
            },
        },
        {
            align: "center",
            ellipsis: true,
            title: "Action",
            width: "10%",
            dataIndex: "action",
            render: (text, record) => {
                return(
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                    />
                )
            },
        },
    ]   

    // ==================== On click functions ======================== //
    
    const onReset = () => {
        setAddBtnLdg(false);
        setEditBtnLdg(false);
        setBoApiUrl('');
        setBrandName('');
        setBrandNum('');
        setBrandUrl('');
        setBsUrl('');
        setToken('');
        setStatus('');
    };


    // const viewToken = (e) =>{
    //     setShowTokenModal(true);
    //     setToken(e.token)
    // }

    const onCancelSubmit = () => {
        setShowAddModal(false);
        handleClearAll();
        onReset();
    };

    const onCancelEdit = () => {
        setShowEditModal(false);
        onReset();
    };

    const onCancelToken = () => {
        setShowTokenModal(false);
        onReset();
    };

    // ==================== Handle functions ======================== //
    const handleAdd = () => {
        setAddBtnLdg(true);
        setShowAddModal(true);
    }

    const handleBrandName = (e) =>{
        const brand = e.target.value.replace(/[^a-zA-Z0-9\s]+/g,"");
        
        setBrandName(brand);
    }

    const handleEdit = (e) => {
        setShowEditModal(true);
        setBrandName(e.brandName);
        setBoApiUrl(e.boApiUrl);
        setBrandUrl(e.brandUrl);
        setBsUrl(e.bankServiceUrl);
        setToken(e.token);
        setStatus(e.status);
        setBrandNum(e.brandNum);
        setOwner(e.owner.split(','))
    }

    const handleStatusChange = (e) => {
        setStatus(e);
    }

    const handleBrandUrl = (e) =>{
        const brandUrl = e.target.value;
        setBrandUrl(brandUrl);
    }

    const handleBoApiUrl = (e) =>{
        const url = e.target.value;
        setBoApiUrl(url);
    }

    const handleBsUrl = (e) =>{
        const bsUrl = e.target.value;
        setBsUrl(bsUrl);
    }

    // const handleToken = (e) =>{
    //     const token = e.target.value;
    //     setToken(token);
    // }

    const handleSubmit = () => {
        setFormLdg(true);
        if (!brandName){
            alertData.title = "Field Required";
            alertData.msg = "Please fill in Brand Name !";
            sharedErrorAlert(alertData);
            setFormLdg(false);
            return;
        }

        if (!brandUrl){
            alertData.title = "Field Required";
            alertData.msg = "Please fill in Brand Url !";
            sharedErrorAlert(alertData);
            setFormLdg(false);
            return;
        }

        if (!bsUrl){
            alertData.title = "Field Required";
            alertData.msg = "Please fill in Bank Service Url !";
            sharedErrorAlert(alertData);
            setFormLdg(false);
            return;
        }

        // if (!token){
        //     alertData.title = "Field Required";
        //     alertData.msg = "Please fill in Brand Token !";
        //     sharedErrorAlert(alertData);
        //     setFormLdg(false);
        //     return;
        // }

        if(!owner || owner.length < 1){
            alertData.title = "Field Required";
            alertData.msg = "Please fill in Brand Owner !";
            sharedErrorAlert(alertData);
            setFormLdg(false);
            return;
        }

        if (status === ''){
            alertData.title = "Field Required";
            alertData.msg = "Please select brand status !";
            sharedErrorAlert(alertData);
            setFormLdg(false);
            return;
        }

        confirm({
            className: "confirmationModalStyle",
            title: 'Confirmation',
            centered: true,
            icon: <QuestionCircleOutlined />,
            width: "500px",
            content: 
            <Paragraph>
                Are you sure the details for this brand is correct?
            </Paragraph>,
            onOk() {
                addNewBrand()
            },
            onCancel() {
                setFormLdg(false);
            }
        })
    };

    const handleSubmitEdit = () => {
        setFormLdg(true);
        if (!brandName){
            alertData.title = "Field Required";
            alertData.msg = "Please fill in Brand Name !";
            sharedErrorAlert(alertData);
            setFormLdg(false);
            return;
        }

        if (!brandUrl){
            alertData.title = "Field Required";
            alertData.msg = "Please fill in Brand Url !";
            sharedErrorAlert(alertData);
            setFormLdg(false);
            return;
        }

        if (!bsUrl){
            alertData.title = "Field Required";
            alertData.msg = "Please fill in Bank Service Url !";
            sharedErrorAlert(alertData);
            setFormLdg(false);
            return;
        }

        // if (!token){
        //     alertData.title = "Field Required";
        //     alertData.msg = "Please fill in Brand Token !";
        //     sharedErrorAlert(alertData);
        //     setFormLdg(false);
        //     return;
        // }

        if(!owner || owner.length < 1){
            alertData.title = "Field Required";
            alertData.msg = "Please fill in Brand Owner !";
            sharedErrorAlert(alertData);
            setFormLdg(false);
            return;
        }

        if (status === ''){
            alertData.title = "Field Required";
            alertData.msg = "Please select brand status !";
            sharedErrorAlert(alertData);
            setFormLdg(false);
            return;
        }

        confirm({
            className: "confirmationModalStyle",
            title: 'Confirmation',
            centered: true,
            icon: <QuestionCircleOutlined />,
            width: "500px",
            content: 
            <Paragraph>
                Are you sure the details for this brand is correct?
            </Paragraph>,
            onOk() {
                updateBrand()
            },
            onCancel() {
                setFormLdg(false);
            }
        })
    };

    const handleOwnerChange = (e) => {
        let searchList = [];
        if(e.length > 0){
            for(let i = 0; i < e.length; i++){
                const foundOwner = ownerList.find(owner => owner.owner == e[i])
                searchList.push(foundOwner.owner);
                searchList.sort((a, b) => a.localeCompare(b));
            }
        }
        // this will pass into API
        setOwner(searchList)
    }

    const handleOwnerSearch = (e) => {
        const foundOwner = ownerList.find(owner => owner.owner == e[0])
        if (foundOwner) {
            setOwner(foundOwner.owner)
        }
    }

    const handleClearAll = () => {
        setOwner([]);
    }

    // ==================== API Calls ======================== //
    const retrieveBrand = async() => {
        setTableLdg(true);
        let requestData = {
        }
    
        let response = await getBrandList(requestData);
        let result = await response.json();
        if (result.status === 'ok'){
          if(Object.keys(result.data).length > 0){
            let brandListData = result.data.map((obj) => {
                return {
                    key                 :   obj.brandNum + obj.brandName,
                    brandNum            :   obj.brandNum,
                    brandName           :   obj.brandName,
                    boApiUrl            :   obj.boApiUrl,
                    brandUrl            :   obj.brandUrl,
                    bankServiceUrl      :   obj.bankServiceUrl,
                    // token               :   obj.token,
                    status              :   obj.status,
                    owner              :   obj.owner,
                }
            })
                let newBrandListData = brandListData.sort((a,b) => a.brandNum - b.brandNum);
                setBrandData(newBrandListData);
                setTableLdg(false);
            } else {
                setBrandData([])
                setTableLdg(false);
            }
        }   else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
            setTableLdg(false);
        }
    }
    
    const retrieveOwner = async () => {
        let requestData = {
        }

        let response = await getCompanyUser(requestData);
        let result = await response.json();
        if (result.status === 'ok'){
            if(Object.keys(result.data).length > 0){
                let ownerListData = result.data.map((obj) => {
                    return {
                        key              :   obj.username,
                        owner            :   obj.username,
                    }
                })
                    setOwnerList(ownerListData);
            } else {
                setOwnerList([])
            }
        }   else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
            setTableLdg(false);
        }
    }

    const addNewBrand = async () => {
        let requestData = {
            brandName       :   brandName,
            boApiUrl        :   boApiUrl,
            brandUrl        :   brandUrl,
            bsUrl           :   bsUrl,
            // token           :   token,
            status          :   status,
            owner           :   owner,
        }
        let response1 = await addBrandOwnership(requestData);
        let result1 = await response1.json();
        if(result1.status === 'ok'){
            let response2 = await addBrandConfig(requestData);
            let result2 = await response2.json();
    
            if(result2.status === 'ok'){
                alertData.title = "Success";
                alertData.msg = `${brandName} is added successfully`;
                sharedSuccessAlert(alertData);  
                setShowAddModal(false);
                setFormLdg(false);
                onReset();
                retrieveBrand();
            } else {
                alertData.title = "Failed";
                alertData.msg = `${brandName} can't be added. Please try again.`;
                sharedFailAlert(alertData);  
                setShowAddModal(false);
                setFormLdg(false);
            }
        } else {
            alertData.title = "Failed";
            alertData.msg = `${brandName} ownership can't be added. Please try again.`;
            sharedFailAlert(alertData);  
            setShowAddModal(false);
            setFormLdg(false);
        }

    }

    const updateBrand = async () => {
        setEditLdg(true);
        let requestData = {
            brandName   : brandName,
            boApiUrl    : boApiUrl,
            brandUrl    : brandUrl,
            bsUrl       : bsUrl,
            // token       : token,
            brandStatus : status,
            brandNum    : brandNum,
            owner       : owner,
        }
        let response1 = await deleteBrandOwnership(requestData);
        let result1 = await response1.json();
        if(result1.status === 'ok'){
            let response2 = await addBrandOwnership(requestData);
            let result2 = await response2.json();

            if(result2.status === 'ok'){
                let response3 = await updateBrandConfig(requestData);
                let result3 = await response3.json();
        
                if(result3.status === 'ok'){
                    alertData.title = "Success";
                    alertData.msg = `${brandName} is updated successfully`;
                    sharedSuccessAlert(alertData);  
                    setShowEditModal(false);
                    setFormLdg(false);
                    onReset();
                    retrieveBrand();
                } else {
                    alertData.title = "Failed";
                    alertData.msg = `${brandName} can't be updated. Please try again.`;
                    sharedFailAlert(alertData);  
                    setShowEditModal(false);
                    setFormLdg(false);
                }
            } else {
                alertData.title = "Failed";
                alertData.msg = `${brandName} can't be added. Please try again.`;
                sharedFailAlert(alertData);  
                setShowEditModal(false);
                setFormLdg(false);
            }
        } else {
            alertData.title = "Failed";
            alertData.msg = `${brandName} ownership can't be updated. Please try again.`;
            sharedFailAlert(alertData);  
            setShowEditModal(false);
            setFormLdg(false);
        }
        setEditLdg(false);
    }

    return (
        <div>
            <Modal 
                title="Add Brand Config" 
                visible={showAddModal} 
                onCancel={onCancelSubmit}
                footer={null}
                mask
                maskClosable={false}
                >
                <Form
                {...layout}
                layout="vertical"
                form={form}
                style={{display: 'flex', flexDirection: 'column'}}
                >
                    <FormItem label="Brand Name" rules={[{ required: true }]} required={true}>
                        <Input 
                        value={brandName}  
                        placeholder="Enter brand name here... Eg. TKB"
                        onChange={handleBrandName}
                        disabled={formLdg}
                        />
                    </FormItem>
                    <FormItem label="BackOffice API Url" rules={[{ required: false }]} required={false}>
                        <Input 
                        value={boApiUrl}  
                        placeholder="Enter backoffice api url here... "
                        onChange={handleBoApiUrl}
                        disabled={formLdg}
                        />
                    </FormItem>
                    <FormItem label="Brand Url" rules={[{ required: true }]} required={true}>
                        <Input 
                        value={brandUrl}  
                        placeholder="Enter brand url here... "
                        onChange={handleBrandUrl}
                        disabled={formLdg}
                        />
                    </FormItem>
                    <FormItem label="Bank Service Url" rules={[{ required: true }]} required={true}>
                        <Input 
                        value={bsUrl}  
                        placeholder="Enter bank service url here... "
                        onChange={handleBsUrl}
                        disabled={formLdg}
                        />
                    </FormItem>
                    {/* <FormItem label="Token" rules={[{ required: true }]} required={true}>
                        <Input 
                        value={token}  
                        placeholder="Enter token here... "
                        onChange={handleToken}
                        disabled={formLdg}
                        />
                    </FormItem> */}
                    <FormItem label="Brand Owner" rules={[{required : true}]} required={true}>
                        <Select
                            mode="multiple"
                            showSearch 
                            placeholder="Select the owner"
                            onChange={handleOwnerChange} 
                            onSearch={handleOwnerSearch}
                            allowClear
                            suffixIcon={<SearchOutlined/>}
                            value={owner}
                            onClear={handleClearAll}
                        >
                            {ownerList.map((e) => <Option key={e.key} value={e.owner}>{e.owner}</Option>)}

                        </Select>
                    </FormItem>

                    <Form.Item label="Status" rules={[{required : true}]} required={true}>
                        <Select
                            placeholder="Select brand status here"
                            value={status}
                            onChange={handleStatusChange}
                            style={{width:'150%', color: status === 0 ? 'orange' : status === 1 ? 'green' : 'red'}}
                        >
                            <Option value={0} style={{color: 'orange'}}>Not Live</Option>
                            <Option value={1} style={{color: 'green'}}>Live</Option>
                            <Option value={2} style={{color: 'red'}}>Demo</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item {...tailLayout} style={{ textAlign: "right" }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginRight: 8 }}
                            loading={formLdg}
                            onClick={handleSubmit}
                            >
                            Submit
                        </Button>
                        <Button 
                            htmlType="button" 
                            onClick={onCancelSubmit}
                            loading={formLdg}
                            >
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal 
                title="Edit Brand Config" 
                visible={showEditModal} 
                onCancel={onCancelEdit}
                footer={null}
                mask
                maskClosable={false}
                >
                <Form
                {...layout}
                layout="vertical"
                form={form}
                style={{display: 'flex', flexDirection: 'column'}}
                >
                    <FormItem label="Brand Name" rules={[{ required: true }]} required={true}>
                        <Input 
                        value={brandName}  
                        placeholder="Enter brand name here... Eg. TKB"
                        onChange={handleBrandName}
                        disabled={true}
                        />
                    </FormItem>
                    <FormItem label="BackOffice API Url" rules={[{ required: false }]} required={false}>
                        <Input 
                        value={boApiUrl}  
                        placeholder="Enter bo api url here... Eg. "
                        onChange={handleBoApiUrl}
                        disabled={formLdg}
                        />
                    </FormItem>
                    <FormItem label="Brand Url" rules={[{ required: true }]} required={true}>
                        <Input 
                        value={brandUrl}  
                        placeholder="Enter brand url here... Eg. "
                        onChange={handleBrandUrl}
                        disabled={formLdg}
                        />
                    </FormItem>
                    <FormItem label="Bank Service Url" rules={[{ required: true }]} required={true}>
                        <Input 
                        value={bsUrl}  
                        placeholder="Enter bank service url here... Eg. "
                        onChange={handleBsUrl}
                        disabled={formLdg}
                        />
                    </FormItem>
                    {/* <FormItem label="Token" rules={[{ required: true }]} required={true}>
                        <Input 
                        value={token}  
                        placeholder="Enter token here... Eg. "
                        onChange={handleToken}
                        disabled={formLdg}
                        />
                    </FormItem> */}
                    <FormItem label="Brand Owner" rules={[{required : true}]} required={true}>
                        <Select
                            mode="multiple"
                            showSearch 
                            placeholder="Select the owner"
                            onChange={handleOwnerChange} 
                            onSearch={handleOwnerSearch}
                            allowClear
                            suffixIcon={<SearchOutlined/>}
                            value={owner}
                            onClear={handleClearAll}
                        >
                            {ownerList.map((e) => <Option key={e.key} value={e.owner}>{e.owner}</Option>)}

                        </Select>
                    </FormItem>
                    <Form.Item label="Status" rules={[{required : true}]} required={true}>
                        <Select
                            value={status}
                            onChange={handleStatusChange}
                            style={{width:'150%', color: status === 0 ? 'orange' : status === 1 ? 'green' : 'red'}}
                        >
                            <Option value={0} style={{color: 'orange'}}>Not Live</Option>
                            <Option value={1} style={{color: 'green'}}>Live</Option>
                            <Option value={2} style={{color: 'red'}}>Demo</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item {...tailLayout} style={{ textAlign: "right" }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginRight: 8 }}
                            loading={formLdg}
                            onClick={handleSubmitEdit}
                            >
                            Submit
                        </Button>
                        <Button 
                            htmlType="button" 
                            onClick={onCancelEdit}
                            loading={formLdg}
                            >
                            Cancel
                        </Button>
                    </Form.Item>
                    
                </Form>
            </Modal>
            <Modal 
                title="View Token" 
                visible={showTokenModal} 
                onCancel={onCancelToken}
                footer={null}
                >
                <Form
                {...layout}
                layout="vertical"
                form={form}
                style={{display: 'flex', flexDirection: 'column'}}
                >
                    <FormItem label="Luckypay Token " style={{fontWeight:'bold'}}>
                        <p style={{fontWeight:'normal'}}>{token}</p>
                    </FormItem>
                </Form>
            </Modal>
            <PageHeader 
            >
                <Button
                    type="primary"
                    onClick={handleAdd}
                    loading={addBtnLdg}
                >
                    Add
                </Button>
            </PageHeader>
                <Table
                style={{ overflow: "auto", marginLeft: 20 }}
                loading={tableLdg}
                columns={columns}
                dataSource={brandData}
                pagination={{
                    defaultPageSize: isMobile ? 10 : 100,
                    showSizeChanger: true,
                    pageSizeOptions: isMobile ? ["5", "10", "20"] : settings.pageSizeOptions,
                    style: {marginTop: 50},
                  }}
                />
        </div>
    )    
  }