import React, { useEffect, useState ,  } from "react";
import {  Button,  Table,  PageHeader,  Badge,  Spin, Row, Col, Card} from "antd";
import {  getBankVeriStatus } from "../../config/networkConfig";
import { alertData, sharedFailAlert } from "../../common/constants/sharedMsgBox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function BankVerifyStats() {
    const [bankVerifyStatData, setBankVerifyStatData]          =  useState([]);
    const [bankStatSuccessCount, setBankStatSuccessCount] = useState(0);
    const [bankStatFailCount, setBankStatFailCount] = useState(0);

    // Loading
    const [tableLoading, setTableLoading]          =  useState(false);

    // Page navigation
    const history = useHistory();

    // useEffect
    useEffect(() => {
        retrieveBankStats();
    }, []);

    const columns = [
        {
            title: 'Create Time',
            dataIndex: 'sysCreateTime',
            width: '25%',
            render : (text,record) => {
                const date = new Date(Number(text) * 1000);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                const readableDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                return readableDate;
            },
            sorter: (a, b) => a.sysCreateTime - b.sysCreateTime,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '15%',
            render: (text, record) => {
                switch (record.status) {
                    case 1: 
                    return <Badge status="success" text="Success" />;  
                    case 9: 
                    return <Badge status="error" text="Failed" />;       
                }
            },
            filters: [
                {
                    text: 'Success',
                    value: 1,
                },
                {
                    text: 'Failed',
                    value: 9,
                }
            ],
            onFilter: (value, record) => record.status == value,
        },
        {
            title: 'Account Number',
            dataIndex: 'accNum',
        },
        {
            title: 'Account Code',
            dataIndex: 'accCode',
        },
    ]   

    // functions
    const handleRefresh = () => {
        window.location.reload();
        retrieveBankStats();
    }


    // ==================== API CALLS ======================== //
    const retrieveBankStats = async() => {
        setTableLoading(true)
        let requestData = {
            status      :  '0',
        }
    
        let response = await getBankVeriStatus(requestData);
        let result = await response.json();
        if (result.status === 'ok'){
          if(Object.keys(result.data).length > 0){
            let successListData = result.data.filter((obj) => obj.status == 1);
            let failedListData = result.data.filter((obj) => obj.status == 9);
    
            setBankStatSuccessCount(successListData.length);
            setBankStatFailCount(failedListData.length);

            let count = 1;
            let bankVeriStat = result.data.map((obj) => {
                return {
                    count                 :   count++,
                    status                 :   obj.status,
                    sysCreateTime          :   obj.SysCreateTime,
                    accNum                 :   obj.BankAcc,
                    accCode                :   obj.BankCode,
                }
            })
            let sortedData = bankVeriStat.slice(-30).sort((a,b) => b.sysCreateTime - a.sysCreateTime )
            setBankVerifyStatData(sortedData);
          } else {
            console.log('getBankVeriStatus result only', result);
        }
        setTableLoading(false);
        }   else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
            setTableLoading(false);
        }
      }

    return (
        <div
            style={{
                backgroundColor: "#FFFFFF",
                padding: 24,
                paddingTop: 0,
            }}
        >
            <PageHeader 
            >
                {/* Example 1a: left card top refresh btn */}
                <div>
                    <div style={{display: 'flex', marginBottom: 15, alignContent: 'end'}}>
                        <Button
                            onClick={() => history.goBack()}
                            loading={tableLoading}
                        >
                            Back
                        </Button>
                        <Button
                                type="primary"
                                onClick={() => handleRefresh()}
                                style={{marginLeft: 10}}
                                loading={tableLoading}
                            >
                                Refresh
                        </Button>
                    </div>
                    <Card
                            style={{
                            width: 500,
                            backgroundColor: "#e2f2f0",
                            borderRadius: 10,
                            minHeight: 150,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            padding: 10
                            }}
                        >
                            {tableLoading ? (
                            <Spin 
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyItems: "center",
                                alignItems: "center",
                            }}
                            size="large"
                            ></Spin>

                            ) : (
                            <div>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                <p style={{height: "25%", fontSize: "20px" }}>
                                Bank Verification Stats (Last Hour): 
                                </p>
                                </div>
                                <Row style={{display: 'flex', justifyContent:'space-between', width:'80%',  alignItems:'center'}} >
                                    <Col  style={{ height: "25%", marginTop: "2%" }}>
                                            <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                            Success:
                                            </span> 
                                            <br/>
                                            <span style={{ fontSize: "24px", color:'green' }}>
                                            {bankStatSuccessCount}
                                            </span>
                                    </Col>
                                    <Col  style={{ height: "25%", marginTop: "2%",}}>
                                            <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                            Failed:
                                            </span> 
                                            <br/>
                                            <span style={{ fontSize: "24px", color:'red' }}>
                                            {bankStatFailCount}
                                            </span>
                                    </Col>
                                </Row>
                            </div>
                            )}
                    </Card>
                </div>
                {/* Example 2: right card */}
                {/* <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div style={{display: 'flex', flexDirection:'column',  justifyContent:'flex-start'}}>
                        <div style={{display: 'flex', marginTop: 15, alignContent: 'end'}}>
                            <Button
                                    type="primary"
                                    onClick={() => handleRefresh()}
                                    loading={tableLoading}
                                >
                                    Refresh
                            </Button>
                            <Button
                                onClick={() => window.location.href = '/'}
                                style={{marginLeft: 10}}
                                loading={tableLoading}
                            >
                                Back
                            </Button>
                        </div>
                    </div>
                    <Card
                        style={{
                        width: 500,
                        backgroundColor: "#e2f2f0",
                        borderRadius: 10,
                        minHeight: 150,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        }}
                    >
                        {tableLoading ? (
                        <Spin 
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: "center",
                            alignItems: "center",
                        }}
                        size="large"
                        ></Spin>

                        ) : (
                        <div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                            <p style={{height: "25%", fontSize: "20px", marginBottom: 0 }}>
                            Bank Verification Stats: 
                            </p>
                            </div>
                            <Row style={{display: 'flex', justifyContent:'space-between', width:'80%',  alignItems:'center'}} >
                                <Col  style={{ height: "25%", marginTop: "2%" }}>
                                        <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                        Success:
                                        </span> 
                                        <br/>
                                        <span style={{ fontSize: "24px", color:'green' }}>
                                        {bankStatSuccessCount}
                                        </span>
                                </Col>
                                <Col  style={{ height: "25%", marginTop: "2%",}}>
                                        <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                        Failed:
                                        </span> 
                                        <br/>
                                        <span style={{ fontSize: "24px", color:'red' }}>
                                        {bankStatFailCount}
                                        </span>
                                </Col>
                            </Row>
                        </div>
                        )}
                    </Card>
                </div> */}
            </PageHeader>
                <Table
                style={{ overflow: "auto", marginLeft: 20 }}
                loading={tableLoading}
                columns={columns}
                dataSource={bankVerifyStatData}
                // pagination={{
                //     // defaultPageSize: isMobile ? 10 : 100,
                //     defaultPageSize: 10,
                //     showSizeChanger: true,
                //     pageSizeOptions: isMobile ? ["5", "10", "20"] : settings.pageSizeOptions,
                //     style: {marginTop: 50},
                //   }}
                pagination={false}
                />
        </div>
    )    
  }