import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import style from "../../resource/style/style";
import { Button, Card, Col, DatePicker, Form, Modal, PageHeader, Row, Select, Spin, Statistic, Tabs } from "antd";
import { Table } from "ant-table-extensions";
import {
    SearchOutlined,
    CloseOutlined,
    CheckOutlined,
    ReloadOutlined,
    FileExcelOutlined,
  } from "@ant-design/icons";
import moment from "moment";
import { Option } from "antd/lib/mentions";
import {  getBankVeriStatus, getBrandList, getBrandListV2, reportCompanyProfit } from "../../config/networkConfig";
import { alertData, sharedErrorAlert, sharedFailAlert } from "../../common/constants/sharedMsgBox";
import { settings } from "../../config/appConfig";
import { isMobile } from "react-device-detect";
import { brandOwnerVw } from "../../library/permission";

export default function ReportCompanyProfit() {
    const { role, userId, username } = useSelector((state) => state.login);
    const {TabPane} = Tabs;

    // Data from db state
    const [compProfitList, setCompProfitList] = useState([]);
    const [allReport, setAllReport] = useState([]);

    // Picker state
    const [date, setDate] = useState("");
    const [type, setType] = useState("thisMonth");

    // retrieve brand
    const [brand, setBrand] = useState([]);
    const [brandV2, setBrandV2] = useState([]);
    const [brandNum, setBrandNum] = useState('0');
    const [brandData, setBrandData] = useState([]);
    const [filteredBrandData, setFilteredBrandData] = useState([]);


    // export data
    const [exptBrand, setExptBrand] = useState([]);
    const [exptBrandAll, setExptBrandAll] = useState([]);
    const [todayDate, setTodayDate] = useState("");

    // bank status card
    const [bankStatLdg, setBankStatLdg] = useState(false);
    const [bankStatReloadLdg, setBankStatReloadLdg] = useState(false);
    const [bankStatSuccessCount, setBankStatSuccessCount] = useState(0);
    const [bankStatFailCount, setBankStatFailCount] = useState(0);

    // Loader state
    const [tableLoading, setTableLoading] = useState(false);

    // useEffects
    useEffect(() => {
        retrieveBrand();
        retrieveBankStats();
        getTodayDate();
      }, []);

    // Table Data
    const columns = [
        {
          align: "center",
          ellipsis: true,
          title: "Brand",
          dataIndex: "brand",
          render: (text,record) => {return <p style={{color: 'blue',marginTop: "14px"}}>{text}</p>}
        },
        {
          align: "center",
          ellipsis: true,
          title: "NGR",
          dataIndex: "ngr",
          render: (text,record) => {
            return <p style={{color: 'red',marginTop: "14px"}}>{Number(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          }
        },
        {
          align: "center",
          ellipsis: true,
          title: "GGR",
          dataIndex: "ggr",
          render: (text,record) => {
            return Number(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }
        }, 
        {
          align: "center",
          ellipsis: true,
          title: "Royalties",
          dataIndex: "royalties",
          render: (text,record) => {
            return Number(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }
        },        
        {
          align: "center",
          ellipsis: true,
          title: "OGCT PT 3%",
          dataIndex: "itCharge",
          render: (text,record) => {
            return Number(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }
        },        
        {
          align: "center",
          ellipsis: true,
          title: "Promotion",
          dataIndex: "promotionCost",
          render: (text,record) => {
            return Number(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }
        },        
        {
          align: "center",
          ellipsis: true,
          title: "Comm To Bank",
          dataIndex: "commToBank",
          render: (text,record) => {
            return Number(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }
        },   
        {
          align: "center",
          ellipsis: true,
          title: "Comm To Wallet",
          dataIndex: "commToWallet",
          render: (text,record) => {
            return Number(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }
        },   
        {
          align: "center",
          ellipsis: true,
          title: "Affiliate Cost",
          dataIndex: "affiliateCost",
          render: (text,record) => {
            return Number(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }
        },            
        {
          align: "center",
          ellipsis: true,
          title: "System Cost",
          dataIndex: "systemCost",
          render: (text,record) => {
            return Number(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }
        }
    ];

    // Functions
    const handleDate = (date) => {
        setType("custom")
        setDate(date)
        console.log('date >>', date);
    }

    const handleSearch = () => {
        if(!brand || brand == '') {
            alertData.title = "Field Required";
            alertData.msg = "Please select Brand !";
            sharedErrorAlert(alertData);
            return;
        }

        if (!date) {
            alertData.title = "Field Required";
            alertData.msg = "Please select a Month !";
            sharedErrorAlert(alertData);
            return;
        }
        
        getCompProfit()
    }

    // ==================== Export functions for brands ======================== //

    const exportButtonRef = useRef(null);

    const onClickExportBtn = ()=> {
        if (allReport.length === 0 ) {
        alertData.title = "Error";
        alertData.msg = "No data to download !";
        sharedErrorAlert(alertData);
        return;
        }
        if (compProfitList.length === 0 ) {
        alertData.title = "Error";
        alertData.msg = "No data to download !";
        sharedErrorAlert(alertData);
        return;
        }
        const exportButton = document.getElementById("export-button-1");
        if (exportButton) {
            console.log("Export button found:");
            exportButton.click();
        } else {
            console.error("Export button not found!");
        }
    }

    // ==================== New Handle functions for brands ======================== //
    const handleBrandChange = (e) => {
        setAllReport([]);
        let searchBrandList = [];
        let filteredList = [];
    
        if(e.length > 0){
          for(let i = 0; i < e.length; i++){
            const foundBrand = brandData.find(brand => brand.brandName === e[i]);
            filteredList.push(foundBrand);
            searchBrandList.push(foundBrand.brandName)
          }
        }
        if(filteredList && filteredList.length > 1){
          filteredList.sort((a,b) => a.key - b.key)
        }
        setFilteredBrandData(filteredList);
        setBrand(searchBrandList);
        setExptBrandAll(filteredList);

        let lowerCaseBrand = [];
        for(let i =0; i < searchBrandList.length;i++){
            let current = searchBrandList[i];
            lowerCaseBrand = lowerCaseBrand.concat(current.toLowerCase())
        }

        // let exptBrandName = lowerCaseBrand.join("_");
        // setExptBrand(exptBrandName)
        
        lowerCaseBrand = lowerCaseBrand.join("', '");
        lowerCaseBrand = "'"+lowerCaseBrand+"'";
        setBrandV2(lowerCaseBrand);
    }

    const handleBrandSearch = (e) => {
    const foundBrand =  brandData.find(brand => brand.brandName.toLowerCase() === e.toLowerCase());
    if (foundBrand) {
        setBrandNum(foundBrand.key.toString())
    }
    }

    const handleAllBrand = () => {
        setFilteredBrandData(brandData);
        const brandNames = brandData.map(brand => brand.brandName);
        handleBrandChange(brandNames)
    }


    const handleClearAll = () => {
        setFilteredBrandData([]);
        setBrand([])
    }

    const handleSummaryClick = () => {
        window.location.href = '/bankVerifyStats';
    }

// ==================== API CALLS ======================== //

    const getTodayDate = () => {
        let today = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
        setTodayDate(today);
        console.log('today >>', today);
    }

    const retrieveBrand = async() => {
        let requestData = {
            columnRequire : [
                'brandNum AS brandNum',
                'brandName AS brandName'
            ],
            userType : role,
            userId : userId
        }

        let response = await getBrandListV2(requestData);
        let result = await response.json();
        if (result.status === 'ok'){
        if(Object.keys(result.data).length > 0){
            let brandListData = result.data.map((obj) => {
                return {
                    key                 :   obj.brandNum,
                    brandName           :   obj.brandName,
                }
            })
            setBrandData(brandListData);
        } else {
            setBrandData([])
        }
        }   else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }
    }

    const retrieveBankStats = async() => {
        setBankStatLdg(true);
        let requestData = {
            status      :  '0',
        }

        let response = await getBankVeriStatus(requestData);
        let result = await response.json();
        if (result.status === 'ok'){
        if(Object.keys(result.data).length > 0){
            let successListData = result.data.filter((obj) => obj.status == 1);
            let failedListData = result.data.filter((obj) => obj.status == 9);

            setBankStatSuccessCount(successListData.length)
            setBankStatFailCount(failedListData.length)
        } else {
            console.log('getBankVeriStatus result only', result);
        }
        setBankStatLdg(false);
        }   else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
            setBankStatLdg(false);
        }
    }

    const getCompProfit = async () => {
        setTableLoading(true);
        let requestData = {
            date : moment(date).format("YYYYMM"),
            brand : brandV2,
        };

        const respCompProfit = await reportCompanyProfit(requestData);
        let response = await respCompProfit.json();

        if(response.status === 'ok'){
            let compProfitDataList = response.data.map((data) =>{
                return {
                    key              :  data.brand,
                    brand            :  data.brand,
                    ngr              :  data.ngr,
                    ggr              :  data.ggr,
                    royalties        :  data.royalties,
                    itCharge         :  data.itCharge,
                    promotionCost    :  data.promotionCost,
                    affiliateCost    :  data.affiliateCost,
                    systemCost       :  data.systemCost,
                    commToBank       :  data.commToBank,
                    commToWallet       :  data.commToWallet,
                }
            })
            // Calculate the summary using reduce
            let summary = compProfitDataList.reduce((acc, curr) => {
                return {
                    key              : 'Summary',
                    brand            : 'Summary',
                    ngr              : acc.ngr + curr.ngr,
                    ggr              : acc.ggr + curr.ggr,
                    royalties        : acc.royalties + curr.royalties,
                    itCharge         : acc.itCharge + curr.itCharge,
                    promotionCost    : acc.promotionCost + curr.promotionCost,
                    affiliateCost    : acc.affiliateCost + curr.affiliateCost,
                    systemCost       : acc.systemCost + curr.systemCost,
                    commToBank       : acc.commToBank + curr.commToBank,
                    commToWallet       : acc.commToWallet + curr.commToWallet,
                };
            }, {
                ngr : 0,
                ggr: 0,
                royalties: 0,
                itCharge: 0,
                promotionCost: 0,
                affiliateCost: 0,
                systemCost: 0,
                commToBank: 0,
                commToWallet: 0,
            });

            compProfitDataList.push(summary);
            setAllReport(compProfitDataList);
            setBrandNum(filteredBrandData[0].key.toString())
            setCompProfitList(compProfitDataList);
        } else {
            setAllReport([])
        }
        setTableLoading(false);
    }

    return (
        <div>
            <div className="eachPageContent">
                <div>
                    <PageHeader
                        ghost={false}
                        >
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{width: '60%'}}>
                                <div>
                                    <Select 
                                        mode="multiple" 
                                        style={{
                                        marginBottom: 10, 
                                        marginLeft:10, 
                                        width:"56.8%"
                                        }} 
                                        showSearch 
                                        placeholder="Select a Brand"
                                        onChange={handleBrandChange} 
                                        onSearch={handleBrandSearch}
                                        allowClear
                                        suffixIcon={<SearchOutlined/>}
                                        value={brand}
                                        onClear={handleClearAll}
                                        >
                                        {brandData.map((e) => <Option key={e.brandName} value={e.brandName}>{e.brandName}</Option>)}
                                    </Select>
                                    <Button 
                                        type="primary"
                                        onClick={() => handleAllBrand()}
                                        style={{marginLeft: 10}}
                                        >
                                        Select All Brands
                                    </Button>
                                </div>
                                <div style={{...style.pageHeaderContainer, display: "inline"}}>
                                    <DatePicker
                                        key="1"
                                        style={{...style.datePickerStyle, ...style.refreshBtn}}
                                        onChange={handleDate}
                                        value={date}
                                        picker="month"
                                        placeholder="Select a Month"
                                    />
                                    <div style={{display: "inline-block"}}>
                                        {/* <Select
                                            style={style.datePickerSelectionStyle}
                                            value={type}
                                            onChange={setType}
                                        >
                                            <Option value="custom">Custom</Option>
                                            <Option value="today">Today</Option>
                                            <Option value="yesterday">Yesterday</Option>
                                            <Option value="thisWeek">This Week</Option>
                                            <Option value="thisMonth">This Month</Option>
                                            <Option value="lastMonth">Last Month</Option>
                                        </Select>  */}
                                        <Button
                                            style={{transform: "translateY(0px)"}} 
                                            type={"primary"}
                                            icon={<SearchOutlined />}
                                            loading={tableLoading}
                                            onClick={handleSearch}
                                        />
                                        {allReport.length > 0 
                                        ?
                                        // <Button 
                                        //     type={"primary"} 
                                        //     style={style.refreshBtn} 
                                        //     icon={<FileExcelOutlined />}
                                        //     onClick={() => {
                                        //         onShowExptModal()
                                        //     }}
                                        // >
                                        //     Export to Excel
                                        // </Button>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{marginLeft: '10px'}}
                                            onClick={() => onClickExportBtn()}
                                            ref={exportButtonRef}
                                            >
                                            Export to Excel
                                        </Button>
                                        :
                                            <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        {brandOwnerVw(role, username) ?
                            <Card
                                style={{
                                width: 500,
                                backgroundColor: "#e2f2f0",
                                borderRadius: 10,
                                maxHeight: 200,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: 10,
                                }}
                            >
                                {bankStatLdg ? (
                                <Spin 
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyItems: "center",
                                    alignItems: "center",
                                }}
                                size="large"
                                ></Spin>

                                ) : (
                                <div >
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <p style={{height: "25%", fontSize: "20px", marginBottom: 0 }}>
                                    Bank Verification Stats (Last Hour): 
                                    </p>
                                    <span >
                                        <Button
                                        style={{
                                            backgroundColor: "initial",
                                            color: "black",
                                            borderColor: "black",
                                        }}
                                        type="default"
                                        shape="round"
                                        icon={<ReloadOutlined/>}
                                        loading={bankStatReloadLdg}
                                        onClick={() => 
                                            retrieveBankStats()
                                        }
                                        >
                                        Refresh
                                        </Button>
                                    </span>
                                    </div>
                                    <Row style={{display: 'flex', justifyContent:'space-between', width:'80%',  alignItems:'center'}} >
                                        <Col  style={{ height: "25%", marginTop: "2%" }}>
                                                <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                                Success:
                                                </span> 
                                                <br/>
                                                <span style={{ fontSize: "24px", color:'green' }}>
                                                {bankStatSuccessCount}
                                                </span>
                                        </Col>
                                        <Col  style={{ height: "25%", marginTop: "2%",}}>
                                                <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                                                Failed:
                                                </span> 
                                                <br/>
                                                <span style={{ fontSize: "24px", color:'red' }}>
                                                {bankStatFailCount}
                                                </span>
                                        </Col>
                                    </Row>
                                        <Row style={{ marginTop: "2%", marginBottom: 0 }}>
                                            <Button 
                                            type="default"
                                            shape="round"
                                            icon={<SearchOutlined/>}
                                            loading={bankStatReloadLdg}
                                            onClick={handleSummaryClick}
                                            >
                                            View Summary
                                            </Button>
                                        </Row>
                                </div>
                                )}
                            </Card>
                        : <></>}
                        </div>
                            
                    </PageHeader>
                    <div style={{
                        backgroundColor: "#FFFFFF",
                        padding: 24,
                        marginTop: '-10px'
                        }}
                    >
                        <Table
                                        options={{
                                        columnsButton: true,
                                        }}
                                        loading={tableLoading} 
                                        columns={columns}
                                        dataSource={compProfitList}
                                        className="compProfitReport"
                                        scroll={{ x: 100, y: null }}
                                        exportable={true}
                                        pagination={{
                                            defaultPageSize: isMobile ? 10 : 100,
                                            showSizeChanger: true,
                                            pageSizeOptions: isMobile ? ["5", "10", "20"] : settings.pageSizeOptions,
                                            style: style.paginationStyle,
                                        }}
                                        exportableProps={{
                                            fileName: "compProfitReport_"+moment(date).format("YYYY-MM"),
                                            btnProps: {
                                              type: "primary",
                                              icon: <FileExcelOutlined />,
                                              id:"export-button-1",
                                            //   ref: exportButtonRef,
                                              children: <span>Export to Excel</span>,
                                              style: {display:"none"}
                                            },
                                          }}
                                    />
                    </div>
                </div>
            </div>
        </div>
    )
}