import React, { useState, useEffect, useRef } from "react";
import { Form, Table, Button, PageHeader, Input, Badge, Tag, Modal, Typography, Dropdown, Space, Menu } from "antd";
import { SettingOutlined, CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, QuestionCircleOutlined, DownOutlined, UndoOutlined, 
    CloudUploadOutlined } from "@ant-design/icons"
import { useSelector } from "react-redux";
import { CNotAuthorized } from "../../common/components/CNotAuthorized";
import { getBrandListV2, setUserStatus, setBrandAppboxBackendDeployment, getBrandAppboxBackendDeployment, 
    resetAppboxBackendDeploymentStatus } from "../../config/networkConfig";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";


export default function BoDeploymentSetting () {
    const { read } = useSelector((state) => state.currentPageAccess);
    const [form] = Form.useForm();
    const { Search } = Input;
    const { confirm } = Modal;
    const { Paragraph } = Typography;

    // useStates
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [tableLoading, setTableLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [onDeployLoading, setOnDeployLoading] = useState(false);
    const [onResetLoading, setOnResetLoading] = useState(false);

    // useEffect
    useEffect(() => {
        retrieveBrandList();
    },[])

    useEffect(() => {
        selectBrands();
    },[selectedRowKeys])

    useEffect(() => {
        filterBrandMaintList();
      },[searchField])

    const retrieveBrandList = async () => {
        setTableLoading(true);
        // const requestData = {
        //     columnRequire: [
        //         'brandName',
        //         'brandNum',
        //         'boApiUrl',
        //         'brandUrl'
        //     ]
        // }
        const getResponse = await getBrandAppboxBackendDeployment();
        const getResult = await getResponse.json();

        const data = getResult.data.map((brand) => ({
            key: brand.brandName,
            // deploymentDate: new Date(brand.deploymentDate * 1000).toISOString().slice(0, 19).replace("T", " "),
            ...brand
        })).sort((a, b) => a.brandNum - b.brandNum);

        if (getResult.status === 'ok' && getResult.data.length > 0) {
            setTableData(data)
            setFilteredTableData(data)
        } else {
            alertData.title = "Failed";
            alertData.msg = getResult.msg;
            sharedFailAlert(alertData);
        }
        setTableLoading(false);
    }   

    const rowSelection = {
    selectedRowKeys,
    onChange: ((newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys)),
    };

    const filterBrandMaintList = () => {
        let filteredData = []

        if (!searchField && tableData) {
            filteredData = tableData
        } else if (searchField && tableData) {
            filteredData =  tableData.filter(data => 
            data.brandName.startsWith(searchField)
            )
        }

        setFilteredTableData(filteredData);
    }

    const selectBrands = () => {
        const selected = [];
    
        selectedRowKeys.forEach(key => {
            const brand = tableData.find(item => item.key === key);
    
            if (brand) {
                // Create a new object with only the desired keys
                const { key, brandName, brandUrl } = brand;
                selected.push({ key, brandName, appboxApiUrl: brandUrl });
            }
        });
    
        setSelectedBrands(selected);
    }

    // columns
    const columns = [
        {
            ellipsis: true,
            title: "Brand",
            dataIndex: "brandName",
            width: "150px",
        },
        {
            ellipsis: true,
            title: "Last Deployment Date/Time",
            dataIndex: "deploymentDate",
            width: "200px",
        },
        {
            ellipsis: true,
            title: "Last Deployment Status",
            dataIndex: "deploymentStatus",
            width: "200px",
            render: (text, record) => {
                switch (record.deploymentStatus) {
                  case 0:
                    return <Tag icon={<ClockCircleOutlined />} color="default">not started</Tag>;
                  case 9:
                    return <Tag icon={<CloseCircleOutlined />} color="error">failed</Tag>;
                  case 1:
                    return <Tag icon={<CheckCircleOutlined />} color="success">success</Tag>;
                  default:
                    return record;
                }
            },
        },
    ]

    const onConfirmDeploy = () => {
        const list = (
            <ul>
                {selectedRowKeys.map((brand, index) => (
                    <li key={index}>{brand}</li>
                ))}
            </ul>
        );
        const count = selectedRowKeys.length
        const brand = count > 1 ? "brands" : "brand"

        confirm({
            className: "confirmationModalStyle",
            title: 'Confirmation',
            centered: true,
            icon: <QuestionCircleOutlined />,
            width: "500px",
            content: 
            <Paragraph>
                Are you sure to make deployment for these brands?
                {list}
                Total : {count} {brand}
            </Paragraph>,
            onOk() {
                onDeploy()
            },
            onCancel() {}
        })
    }

    const onDeploy = async () => {
        setOnDeployLoading(true);

        const response = await setBrandAppboxBackendDeployment(selectedBrands)
        const result = await response.json()
        
        if (result.status == "ok") {
            alertData.title = "Success";
            alertData.msg = result.msg;
            sharedSuccessAlert(alertData);
        }
        else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }
        setSelectedRowKeys([]);
        retrieveBrandList();
        setOnDeployLoading(false);
    }

    const onConfirmReset = () => {
        const list = (
            <ul>
                {selectedRowKeys.map((brand, index) => (
                    <li key={index}>{brand}</li>
                ))}
            </ul>
        );
        const count = selectedRowKeys.length
        const brand = count > 1 ? "brands" : "brand"

        confirm({
            className: "confirmationModalStyle",
            title: 'Confirmation',
            centered: true,
            icon: <QuestionCircleOutlined />,
            width: "500px",
            content: 
            <Paragraph>
                Are you sure to reset status for these brands?
                {list}
                Total : {count} {brand}
            </Paragraph>,
            onOk() {
                onReset();
            },
            onCancel() {}
        })
    }

    const onReset = async () => {
        setOnResetLoading(true);

        const response = await resetAppboxBackendDeploymentStatus(selectedRowKeys)
        const result = await response.json()

        if (result.status === "ok") {
            alertData.title = "Success";
            alertData.msg = result.msg;
            sharedSuccessAlert(alertData);
        } else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }
        setSelectedRowKeys([]);
        retrieveBrandList();
        setOnResetLoading(false);
    }

    const actions = (
        <Menu
            // type="primary"
            // style={{ backgroundColor: "green"}}
            >
          <Menu.Item key="1"
            style={{color:"white", padding: 0}}
          >
            <Button 
                // type="dashed"
                onClick={onConfirmDeploy}
                style={{
                    width: '100%',                         
                    display: 'flex',                       
                    justifyContent: 'flex-start',         
                    textAlign: 'left',
                    alignItems: 'center',                    
                    // fontWeight: 500,                                   
                  }}
                >
                        <CloudUploadOutlined />
                        Deploy
                        
                        {/* {selectedRowKeys.length > 0 
                        ? selectedRowKeys.length === 1 
                            ? `Deploy ${selectedRowKeys.length} brand` 
                            : `Deploy ${selectedRowKeys.length} brands` 
                        : ''} */}
            </Button>
          </Menu.Item>
          <Menu.Item key="2"
            style={{color:"white", padding: 0}}
          >
            <Button
                // type="dashed"
                onClick={onConfirmReset}
                style={{
                    marginTop: '2px',
                    width: '100%',                         
                    display: 'flex',                     
                    justifyContent: 'flex-start',           
                    textAlign: 'left',
                    alignItems: 'center',                       
                    // fontWeight: 500,                                    
                  }}
                >
                    <UndoOutlined />
                    Reset
                    
                    {/* {selectedRowKeys.length > 0 
                        ? selectedRowKeys.length === 1 
                            ? `Reset ${selectedRowKeys.length} brand` 
                            : `Reset ${selectedRowKeys.length} brands` 
                        : ''} */}
            </Button>
          </Menu.Item>
        </Menu>
      );

    return (
        <div>
            { read ?
                <div style={{backgroundColor: "FFFFFF"}}>

                    <PageHeader ghost={false}>
                        <div>
                            <Search
                                placeholder="Search brand"
                                onSearch={""}
                                value={searchField}
                                disabled={tableLoading}
                                onChange={(e) => setSearchField(e.target.value.toUpperCase())}
                                style={{
                                width: 200,
                                }}
                            />

                            {/* Action button */}
                            <span style={{ marginLeft: 16}}>
                                {selectedRowKeys.length > 0
                                        ? <Dropdown overlay={actions}>
                                            <Button 
                                                type="primary" 
                                                loading={onDeployLoading || onResetLoading}
                                                style={{ fontWeight: 500 }}
                                            >
                                                Actions <DownOutlined style={{ marginLeft: '15px' }} />
                                            </Button>
                                        </Dropdown> : ''
                                }
                            </span>
                        </div>
                    </PageHeader>

                    <Form form={form} component={false}>
                        <Table
                        loading={tableLoading}
                        scroll={{ x: "100%", y: null }}
                        dataSource={filteredTableData}
                        columns={columns}
                        pagination={{
                            defaultPageSize: 100,
                        }}
                        rowSelection={rowSelection}
                        style={{ overflow: "auto", marginLeft: 20 }}
                        />
                    </Form>

                </div> : <CNotAuthorized />
            }
        </div>
    )
}