import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { SearchOutlined, FileExcelOutlined, ReloadOutlined } from "@ant-design/icons";
import { getBankVeriStatus, getBrandList, getBrandListV2, reportDailyTrxnStatsV2 } from "../../config/networkConfig";
import { Table } from "ant-table-extensions";
import { PageHeader, Button, Statistic, Row, Card, DatePicker, Space, Select, Checkbox, Switch, Tabs, Popover, Modal, Form, Col, Spin } from "antd";
import { isMobile } from "react-device-detect";
import "jspdf-autotable";
import { settings } from "../../config/appConfig";
import { FormatDataMobile } from "../../library/numbers";
import moment from "moment";
import { alertData, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import FormItem from "antd/lib/form/FormItem";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { brandOwnerVw } from "../../library/permission";
import { render } from "@testing-library/react";


export default function ReportDailyTrxnStatsv2() {
  const {TabPane} = Tabs;
  const { Option } = Select;
  const CheckboxGroup = Checkbox.Group;
  const plainOptions = ['Deposit', 'Withdrawal', 'Comm2Bank', 'To Resolve'];
  const defaultCheckedList = ['Deposit', 'Withdrawal', 'Comm2Bank', 'To Resolve'];
  const [checkAll, setCheckAll] = useState(true);

  const [depositCheckbox, setDepositCheckbox] = useState(true);
  const [withdrawalCheckbox, setWithdrawalCheckbox] = useState(true);
  const [comm2BankCheckbox, setComm2BankCheckbox] = useState(true);
  const [toResolveCheckbox, setToResolveCheckbox] = useState(true);

  const [depositChange, setDepositChange] = useState(0);
  const [withdrawalChange, setWithdrawalChange] = useState(0);
  const [comm2BankChange, setComm2BankChange] = useState(0);
  const [toResolveChange, setToResolveChange] = useState(0);

  const { userId , role, username, compCode } = useSelector((state) => state.login);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [report, setReport] = useState("");
  const [depositAmtTotal, setDepositAmtTotal] = useState("");
  const [withAmountTotal, setWithAmountTotal] = useState("");
  const [confirmWithdrawAmtTotal, setConfirmWithdrawAmtTotal] = useState("");
  const [toResolveAmtTotal, setToResolveAmtTotal] = useState("");
  const [commToBankAmtTotal, setCommToBankAmtTotal] = useState("");

  const [brand, setBrand] = useState([]);
  const [brandNum, setBrandNum] = useState('0');
  const [brandData, setBrandData] = useState([]);
  const [filteredBrandData, setFilteredBrandData] = useState([]);

  // card Data
  const [winLoseSummary, setWinLoseSummary] = useState("");
  const [statPctSummary, setStatPctSummary] = useState("");
  const [depSummary, setDepSummary] = useState("");
  const [wthSummary, setWthSummary] = useState("");
  const [comm2BkSummary, setComm2BkSummary] = useState("");
  const [toResolveSummary, setToResolveSummary] = useState("");

  // bank status card
  const [bankStatLdg, setBankStatLdg] = useState(false);
  const [bankStatReloadLdg, setBankStatReloadLdg] = useState(false);
  const [bankStatAllData, setBankStatAllData] = useState([]);
  const [bankStatSuccessCount, setBankStatSuccessCount] = useState(0);
  const [bankStatFailCount, setBankStatFailCount] = useState(0);


  const [searchLdg, setSearchLdg] = useState(false);

  const [allReport, setAllReport] = useState("");
  const [combinedReport, setCombinedReport] = useState("");
  const [finalReportData, setFinalReportData] = useState("");

  const [todayDate, setTodayDate] = useState("");

  const [tableLoading, setTableLoading] = useState(false);
  const [trxLoading, setTrxLoading] = useState(false);

  // export data
  const [exptBrand, setExptBrand] = useState([]);
  const [exptBrandTitle, setExptBrandTitle] = useState("");
  const [exptBrandList, setExptBrandList] = useState([]);
  const [showExptModal, setShowExptModal] = useState(false);
  const [exptBrandData, setExptBrandData] = useState([]);
  const [exptBrandNum, setExptBrandNum] = useState('0');
  const [exptBrandAll, setExptBrandAll] = useState([]);

  const [type, setType] = useState("thismonth");
  const [trxDateFrom, setTrxDateFrom] = useState("");
  const [trxDateTo, setTrxDateTo] = useState("");

  const [expand, setExpand]  = useState(false);

  const reportDateFrom = useRef(null);
  const reportDateTo = useRef(null);

  // useHistory hook, use this instead of useNavigate for react-router-dom v5
  const history = useHistory();

  useEffect(() => {
    retrieveBrand();
    retrieveBankStats();
  }, []);

  useEffect(()=> {
    setTrxDateFrom(trxDateFrom);
    setTrxDateTo(trxDateTo);
  },[trxDateFrom,trxDateTo]);

  useEffect(() => {
      if(brandNum){
      handleTabClick(brandNum)
    }else {
      handleTabClick(0);
    }
  },[allReport,combinedReport])

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
      paddingTop: 0,
    },
    expandStyle: {
      marginLeft: 20,
    },
    inlineBlock: {
      display: "inline-block",
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
      display: "inline",
    },
    resetBtn: {
      marginLeft: 10,
    },
    inline: {
      display: "inline",
    },
    hide: {
      marginLeft: 10000,
    },
    datePickerStyle: {
      width: 280,
    },
    datePickerSelectionStyle: {
      marginLeft: 10,
      width: 120,
    },
    refreshBtn: {
      marginLeft: 10,
    },
    mobilePageHearderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
    },
    mobileSearchResetBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: 50,
      alignItems: "center",
      paddingLeft: 60,
      paddingRight: 60,
    },
    btnContainer: {
      width: "100vw",
      padding: 10,
    },
    pdfStyle: {
      borderRadius: 10,
      width: 1200,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 200,
    },
    decContainer: {
      paddingLeft: 75,
    },
    checkbox: {
      marginTop: 12,
      paddingBottom: 30,
      paddingLeft: 10,
    },
    webRow: {
      backgroundColor: "#FFFFFF",
      paddingTop: 80,
      paddingLeft: 30,
      paddingBottom: 50,
      fixed: "left",
    },
    mobileRow: {
      backgroundColor: "#FFFFFF",
      paddingLeft: 30,
    },
    cardStyle: {
      borderRadius: 10,
      height: 160,
      width: 200,
      border: "none",
      marginTop: -70,
      marginLeft: -30,
      marginRight: 40,
      marginBottom: -90,
      fixed: "left",
      display: "inline-block"
    },
    cardStyle2: {
      borderRadius: 0,
      height: 160,
      width: 1050,
      border: "none",
      marginTop: -70,
      marginLeft: -30,
      marginRight: 15,
      marginBottom: -90,
      fixed: "left"
    },
    cardStyle3: {
      borderRadius: 0,
      height: 160,
      width: 150,
      border: "none",
      marginTop: -70,
      marginLeft: -5,
      marginRight: 0,
      marginBottom: -90,
      fixed: "left"
    },
    statistic1: {
      width: 130,
    },
    statisticPercentage: {
      width: 100,
    },
    statistic2: {
      width: 150,
    },
    statistic3: {
      width: 150,
    },
    statistic4: {
      width: 120,
    },
    statistic5: {
      width: 130,
    },
    statistic6: {
      width: 150,
    },
    equal: {
      width: 25,
    },
    openBracket: {
      width: 15,
    },
    closeBracket: {
      width: 15,
    },
    plus: {
      width: 28,
    },
    minus1: {
      width: 20,
    },
    minus2: {
      width: 24,
    },
    expandButton: {
      marginLeft: 30,
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 20,
      height: 20,
    },
    headStyles2: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 20,
      height: 20,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
    bodyStyle2: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
  };

// ==================== COLUMN FIELDS ======================== //

  const columns = [
    {
        align: "center",
        title: "Date",
        dataIndex: "trxnDate",
        width: 100,
        ellipsis: true,
        fixed:"left",
        render:function(record) {
          return <p style={{color:"blue", marginTop: "10px"}}>{record}</p>
        }
    },
    {
        align: "center",
        title: "Start",
        dataIndex: "startTime",
        width: 100,
        ellipsis: true,
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "10px"}}>{record}</p>
        }
    },
    {
        align: "center",
        title: "End",
        dataIndex: "endTime",
        width: 100,
        ellipsis: true,
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "10px"}}>{record}</p>
        }
    },
    {
      align: "center",
      title: "Total User*",
      dataIndex: "totalUser",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return record != 'N/A' ? Number(record).toLocaleString(undefined) : 'N/A';
      }
      
    },
    {
      align: "center",
      title: "Register User*",
      dataIndex: "registerUser",
      width: 100,
      headStyles:[{"font-size":"2px"}],
      render: (text,record) => {
        return Number(text).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      }
    },
    {
      align: "center",
      title: "Invited User*",
      dataIndex: "inviteUser",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render: (text,record) => {
        return Number(text).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      }
    },
    {
      align: "center",
      title: "Active User*",
      dataIndex: "activeUser",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render: (text,record) => {
        return Number(text).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      }
    },
    {
      align: "center",
      title: "Deposit#",
      dataIndex: "depCount",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render: (text,record) => {
        return Number(text).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      }
    },
    {
      align: "center",
      title: "Unique Deposit*",
      dataIndex: "uniqueDep",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render: (text,record) => {
        return Number(text).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      }
    },
    {
      align: "center",
      title: "Deposit Amount",
      dataIndex: "depositAmt",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatDataMobile(record) >= 100000?
              <p style={{color:"red", marginTop: "10px"}}>{FormatDataMobile(record)}</p>
              : FormatDataMobile(record);
      }
    },
    {
      align: "center",
      title: "Max Deposit",
      dataIndex: "maxDepositAmt",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return record != 'N/A' ? FormatDataMobile(record) : 'N/A';
      }
    },
    {
        align: "center",
        title: "New Unique Deposit#",
        dataIndex: "depositFirstCount",
        width: 100,
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "10px"}}>{Number(record).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
        }
    },
    {
      align: "center",
      title: "New Unique Deposit Amount",
      dataIndex: "depositFirstAmount",
      width: 120,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return <p style={{color:"blue", marginTop: "10px"}}>{FormatDataMobile(record)}</p>
      }
    },
    {
      align: "center",
      title: "First New Unique Deposit Amount",
      dataIndex: "depositFirstTimeAmt",
      width: 120,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return record2.trxnDate == todayDate ?
              <p style={{color:"blue", marginTop: "10px"}}>N/A</p>
              : <p style={{color:"blue", marginTop: "10px"}}>{FormatDataMobile(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Regular Deposit#",
      dataIndex: "depositRegularCount",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return <p style={{color:"blue", marginTop: "10px"}}>{Number(record).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
      }
    },
    {
      align: "center",
      title: "Regular Deposit Amount",
      dataIndex: "depositRegularAmount",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return <p style={{color:"blue", marginTop: "10px"}}>{FormatDataMobile(record)}</p>
      }
    },
    {
      align: "center",
      title: "Total Adjustment",
      dataIndex: "adjustAmt",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatDataMobile(record);
      }
    },
    {
      align: "center",
      title: "Withdrawals#",
      dataIndex: "withdrawCount",
      width: 110,
      style: [{"word-wrap":"break-word"}],
      render: (text,record) => {
        return Number(text).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      }
    },
    {
      align: "center",
      title: "Unique Withdrawals#",
      dataIndex: "uniqueWth",
      width: 110,
      style: [{"word-wrap":"break-word"}],
      render: (text,record) => {
        return Number(text).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      }
    },
    {
      align: "center",
      title: "Withdraw Amount",
      dataIndex: "withdrawAmt",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatDataMobile(record) <= -100000?
              <p style={{color:"red", marginTop: "10px"}}>{FormatDataMobile(record)}</p>
              : FormatDataMobile(record);
      }
    },
    {
      align: "center",
      title: "Max Withdraw",
      dataIndex: "maxWthAmt",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return record != 'N/A' ? FormatDataMobile(record) : 'N/A';
      }
    },
    {
      align: "center",
      title: "To Resolve",
      dataIndex: "toResolve",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatDataMobile(record);
      }
    },
    {
      align: "center",
      title: "Turnover",
      dataIndex: "playerTurnover",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return record2.trxnDate == todayDate && record == 'N/A'?
              <p style={{fontWeight: "bold", marginTop: "10px"}}>N/A</p>
              : FormatDataMobile(record);
      }
    },
    {
      align: "center",
      title: "Comm2Wallet",
      dataIndex: "commToWallet",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatDataMobile(record);
      }
    },
    {
      align: "center",
      title: "Comm2Bank",
      dataIndex: "commToBank",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return FormatDataMobile(record);
      } 
    },
    {
      align: "center",
      title: "Players With Bank",
      dataIndex: "playerWithBank",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return record2.playerWithBank.toFixed(0);
      } 
    },
    {
      align: "center",
      title: "Players Without Bank",
      dataIndex: "playerWithoutBank",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render: (text,record) => {
        return Number(record.playerWithoutBank).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      }
    },
    {
      align: "center",
      title: "Provider Win / Loss",
      dataIndex: "playerWinLoss",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return FormatDataMobile(record);
      } 
    },
    {
      align: "center",
      title: "Promotion Cost",
      dataIndex: "promotionCost",
      width: 120,
      hidden: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#ab3da9"}}><b>{FormatDataMobile(record)}</b></p>
      }
    },
    {
      align: "center",
      title: "Invite Angpao Amount",
      dataIndex: "inviteAngpaoAmt",
      width: expand == true ? 160 : 0,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Fortune Wheel Amount",
      dataIndex: "fortuneWheelAmt", 
      width: expand == true ? 160 : 0,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Permanent Angpao Amount",
      dataIndex: "permanentAngpaoAmt",
      width: expand == true ? 180 : 0,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Mission Amount",
      dataIndex: "missionAmt",
      width: expand == true ? 130 : 0,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Daily Free Credit",
      dataIndex: "dailyFreeCredit",
      width: expand == true ? 130 : 0,
      hidden: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Push Notification",
      dataIndex: "pushNotification",
      width: expand == true ? 130 : 0,
      hidden: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Learn More",
      dataIndex: "learnMore",
      width: expand == true ? 120 : 0,
      hidden: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Bind Bank Account",
      dataIndex: "bindBankAcc",
      width: expand == true ? 140 : 0,
      hidden: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Player Rebate Amount",
      dataIndex: "playerRebate",
      width: expand == true ? 160 : 0,
      enableHiding: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Downline Commission",
      dataIndex: "downlineComm",
      width: expand == true ? 160 : 0,
      enableHiding: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
  ];


// ==================== HANDLE FUNCTIONS ======================== //

  const handleSearch = (e) => {
    const foundBrand =  brandData.find(brand => brand.brandName.toLowerCase() === e.toLowerCase());
    if (foundBrand) {
      setBrandNum(foundBrand.key.toString())
    }
  }

  const handleAllBrand = () => {
    setFilteredBrandData(brandData);
    const brandNames = brandData.map(brand => brand.brandName);
    handleChange(brandNames)
  }


  const handleClearAll = () => {
    setFilteredBrandData([]);
    setBrand([])
  }

  const handleSearchExptBrand = (e) => {
    const foundBrand =  exptBrandData.find(brand => brand.brandName.toLowerCase() === e.toLowerCase());
    if (foundBrand) {
      setExptBrandNum(foundBrand.key.toString())
    }
  }

  const handleChangeExptBrand = (e) => {
    const foundBrand = exptBrandData.find(brand => brand.brandName === e);

    setExptBrandList(foundBrand);
    setExptBrand(e)
  }

  const handleClearAllExpt = () => {
    setExptBrandList([]);
  }

  const onClickExportBtnV2 = (e) => {
    handleTabClick(e.key);
    onClickExportBtn();
  }
  
  const handleChange = (e) => {
    let searchBrandList = [];
    let filteredList = [];

    if(e.length > 0){
      for(let i = 0; i < e.length; i++){
        const foundBrand = brandData.find(brand => brand.brandName === e[i]);
        filteredList.push(foundBrand);
        searchBrandList.push(foundBrand.brandName)
      }
    }
    if(filteredList && filteredList.length > 1){
      filteredList.sort((a,b) => a.key - b.key)
      setBrandNum(0)
    }
    setFilteredBrandData(filteredList);
    setBrand(searchBrandList);
    setExptBrandAll(searchBrandList);
  }

  const handleTabClick = (e) => {
    
    if(e == 0) {
      let newReportList = combinedReport;
      const foundBrand = filteredBrandData.find(brand => brand.brandName == 'All');
      if (foundBrand){
        setBrandNum(foundBrand.key.toString());
        let activeBrandData = newReportList.filter((data) => data.brand == foundBrand.brandName);
        if(activeBrandData){
          let finalReportData = activeBrandData;
          addSummaryRecord(finalReportData);
        }
      }
    } else {
      let newReportList = allReport;
      const foundBrand = brandData.find(brand => brand.key == e);
      
      if (foundBrand){
        setBrandNum(foundBrand.key.toString());
        let activeBrandData = newReportList.filter((data) => data.brand == foundBrand.brandName);
        if(activeBrandData){
          let finalReportData = activeBrandData;
          addSummaryRecord(finalReportData);
        }
      }
    }
  }

  const handleSummaryClick = () => {
    window.location.href = '/bankVerifyStats';
  }

  const addSummaryRecord = (data) => {
    // Summary Row
    let highestTotalUser = 0, totalRegUser = 0, totalInvUser = 0, totalActiveUser = 0, totalDepCnt = 0, totalUniqDep = 0 , totalDepAmt = 0, highestDepAmt = 0 , totalDepFirstCnt = 0 , totalDepFirstAmt = 0, totalDepFirstTimeAmt = 0, totalDepRegCnt = 0, totalDepRegCntSummary = 0;
    let totalDepRegAmt = 0, totalAdjustAmt = 0 , totalWithdrawCount = 0, totalUniqueWth = 0, totalWithdrawAmt = 0, highestWthAmt = 0, totalToResolve = 0, totalPlayerTurnover = 0, totalCommToWallet = 0, totalplayerWithBank = 0, totalPlayerWithoutBank = 0;
    let totalCommToBank = 0, totalPromotionCost = 0, totalFortuneWheelAmt = 0, totalInviteAngpaoAmt = 0, totalPermanentAngpaoAmt = 0, totalMissionAmt = 0, totalPlayerRebate = 0, totalDownlineComm = 0;
    let totalDailyFreeCredit = 0, totalPushNotification = 0, totalLearnMore = 0, totalBindBankAcc = 0, totalPlayerWinLoss = 0;
    let dailyTrxnSummary = [...data];

    dailyTrxnSummary.forEach(({ totalUser, registerUser, inviteUser, activeUser, depCount, uniqueDep, depositAmt, maxDepositAmt, depositFirstCount, depositFirstAmount, depositFirstTimeAmt, depositRegularCount, depositRegularAmount, adjustAmt, withdrawCount, uniqueWth, playerWinLoss,
      withdrawAmt, maxWthAmt, toResolve, playerTurnover, commToWallet, commToBank, playerWithBank, playerWithoutBank, promotionCost, fortuneWheelAmt, inviteAngpaoAmt, permanentAngpaoAmt, missionAmt, playerRebate, downlineComm, dailyFreeCredit, pushNotification, learnMore, bindBankAcc }) => {
        
    if(highestTotalUser == 0) {
    highestTotalUser = totalUser;
    }
    else {
    if (totalUser > highestTotalUser) {
    highestTotalUser = totalUser;
    }
    }
    totalRegUser        += registerUser;
    totalInvUser        += inviteUser;
    totalActiveUser     += activeUser
    totalDepCnt         += depCount;
    totalUniqDep        += uniqueDep;
    totalDepAmt         += depositAmt;

    if(highestDepAmt == 0) {
      highestDepAmt = maxDepositAmt;
    }
    else {
      if (maxDepositAmt > highestDepAmt) {
        highestDepAmt = maxDepositAmt;
      }
    }

    totalDepFirstCnt    += depositFirstCount;
    totalDepFirstAmt    += depositFirstAmount;
    totalDepFirstTimeAmt+= depositFirstTimeAmt;
    totalDepRegCnt      += depositRegularCount;
    totalDepRegAmt      += depositRegularAmount;
    totalAdjustAmt      += adjustAmt;
    totalWithdrawCount  += withdrawCount;
    totalUniqueWth      += uniqueWth;
    totalWithdrawAmt    += withdrawAmt;
    totalplayerWithBank += Number(playerWithBank);
    totalPlayerWithoutBank += Number(playerWithoutBank);
    totalPlayerWinLoss += playerWinLoss;

    if(highestWthAmt == 0) {
    highestWthAmt = maxWthAmt;
    }
    else {
    if (maxWthAmt < highestWthAmt) {
    highestWthAmt = maxWthAmt;
    }
    }

    totalToResolve      += toResolve;

    if (String(playerTurnover) != 'N/A') {
    totalPlayerTurnover += playerTurnover;
    }

    totalCommToWallet   += commToWallet;
    totalCommToBank     += commToBank;
    totalPromotionCost  += promotionCost;
    totalFortuneWheelAmt+= fortuneWheelAmt;
    totalInviteAngpaoAmt+= inviteAngpaoAmt;
    totalPermanentAngpaoAmt += permanentAngpaoAmt;
    totalMissionAmt     += missionAmt;
    totalPlayerRebate   += playerRebate;
    totalDownlineComm   += downlineComm;
    totalDailyFreeCredit+= dailyFreeCredit;
    totalPushNotification   += pushNotification;
    totalLearnMore      += learnMore;
    totalBindBankAcc    += bindBankAcc;
    });

    let maxKey = parseInt(Math.max.apply(Math, dailyTrxnSummary.map(function(o) { return o.key; })))+1;

    dailyTrxnSummary= [
    ...dailyTrxnSummary,
    {
    key                  : Number(maxKey),
    trxnDate             : "Summary",
    totalUser            : highestTotalUser,
    registerUser         : totalRegUser,
    inviteUser           : totalInvUser,
    activeUser           : totalActiveUser,
    depCount             : totalDepCnt,
    uniqueDep            : totalUniqDep,
    depositAmt           : totalDepAmt,
    maxDepositAmt        : highestDepAmt,
    depositFirstCount    : totalDepFirstCnt,
    depositFirstAmount   : totalDepFirstAmt,
    depositFirstTimeAmt  : totalDepFirstTimeAmt,
    depositRegularCount  : totalDepRegCnt,
    depositRegularAmount : totalDepRegAmt,
    adjustAmt            : totalAdjustAmt,
    withdrawCount        : totalWithdrawCount,
    uniqueWth            : totalUniqueWth,
    withdrawAmt          : totalWithdrawAmt,
    maxWthAmt            : highestWthAmt,
    toResolve            : totalToResolve,
    playerTurnover       : totalPlayerTurnover, 
    commToWallet         : totalCommToWallet,
    commToBank           : totalCommToBank,
    promotionCost        : totalPromotionCost,
    fortuneWheelAmt      : totalFortuneWheelAmt,
    inviteAngpaoAmt      : totalInviteAngpaoAmt,
    permanentAngpaoAmt   : totalPermanentAngpaoAmt,
    missionAmt           : totalMissionAmt,
    playerRebate         : totalPlayerRebate,
    downlineComm         : totalDownlineComm,
    dailyFreeCredit      : totalDailyFreeCredit,
    pushNotification     : totalPushNotification,
    learnMore            : totalLearnMore,
    bindBankAcc          : totalBindBankAcc,
    playerWithBank       : totalplayerWithBank,
    playerWithoutBank    : totalPlayerWithoutBank,
    playerWinLoss        : totalPlayerWinLoss,
  },
    ];


    let todayDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
    setTodayDate(todayDate);
    const filterDetail = data.filter(
      (data) => data.trxnDate //!== todayDate
    );

    const getdepositAmt = filterDetail.map((data) => data.depositAmt);
    var depositAmtTotal = getdepositAmt.reduce(function (previous, current) {
    return previous + current;
    }, 0);

    const getwithdrawAmt = filterDetail.map((data) => data.withdrawAmt);
    var confirmWithdrawAmtTotal = getwithdrawAmt.reduce(function (previous, current) {
    return previous + current;
    }, 0);

    const getToResolveAmt = filterDetail.map((data) => data.toResolve);
    var toResolveAmtTotal = getToResolveAmt.reduce(function (previous, current) {
    return previous + current;
    }, 0);

    const getCommToBankAmt = filterDetail.map((data) => data.commToBank);
    var commToBankAmtTotal = getCommToBankAmt.reduce(function (previous, current) {
    return previous + current;
    }, 0);

    setStatPctSummary(
      Number((1 - ((-confirmWithdrawAmtTotal + toResolveAmtTotal)/(depositAmtTotal))) * 100).toFixed(2) > 0 || 
      Number((1 - ((-confirmWithdrawAmtTotal + toResolveAmtTotal)/(depositAmtTotal))) * 100).toFixed(2) < 0 ? 
      Number((1 - ((-confirmWithdrawAmtTotal + toResolveAmtTotal)/(depositAmtTotal != 0 ? depositAmtTotal : 0))) * 100).toFixed(2)+'%' : 
      Number(0).toFixed(2)+'%'
    )
    setDepSummary(Number(depositAmtTotal).toFixed(2))
    setWthSummary(Number(confirmWithdrawAmtTotal).toFixed(2))
    setComm2BkSummary(Number(-commToBankAmtTotal).toFixed(2))
    setToResolveSummary(Number(toResolveAmtTotal).toFixed(2))
    
    if(depositCheckbox == true){
      setDepSummary(Number(depositAmtTotal).toFixed(2));
    }else{
      setDepSummary(0);
    }
    if(withdrawalCheckbox == true){
      setWthSummary(-Number(confirmWithdrawAmtTotal).toFixed(2));
    }else{
      setWthSummary(0);
    }
    
    if(comm2BankCheckbox == true){
      setComm2BkSummary(Number(-commToBankAmtTotal).toFixed(2));
    }else{
      setComm2BkSummary(0);
    }
    
    if(toResolveCheckbox == true){
      setToResolveSummary(toResolveAmtTotal);
    }else{
      setToResolveSummary(0);
    }
    setWinLoseSummary(((depositAmtTotal) - (-confirmWithdrawAmtTotal + toResolveAmtTotal)).toFixed(2))

    // dailyTrxnSummary[dailyTrxnSummary.length - 1].playerWinLoss = ((depositAmtTotal) - (-confirmWithdrawAmtTotal + toResolveAmtTotal)).toFixed(2);
    
    setFinalReportData(dailyTrxnSummary);
}


const onClickExportBtn = ()=> {

  if (allReport.length === 0 ) {
    alertData.title = "Error";
    alertData.msg = "No data to download !";
    sharedErrorAlert(alertData);
    return;
  }
  if (combinedReport.length === 0 ) {
    alertData.title = "Error";
    alertData.msg = "No data to download !";
    sharedErrorAlert(alertData);
    return;
  }
  document.getElementById("export-button-1").click();
}

const onShowExptModal = () => {
  setShowExptModal(true);
};

const onCloseExportModal = () => {
  setShowExptModal(false);
};

const resetBtn = () => {
  setAllReport([]);
  setDepositAmtTotal(0);
  setWithAmountTotal(0);
  setConfirmWithdrawAmtTotal(0);
  setToResolveAmtTotal(0);
  setCommToBankAmtTotal(0);
  setType("thismonth");
  setDepositCheckbox(true);
  setWithdrawalCheckbox(true);
  setComm2BankCheckbox(true);
  setToResolveCheckbox(true);
  setCheckedList(defaultCheckedList);
  setDepositChange(0);
  setWithdrawalChange(0);
  setComm2BankChange(0);
  setToResolveChange(0);
  setFilteredBrandData([]);
  setBrand([]);
  window.location.reload();
};

const trxDatePicker = (date, dateString) => {
  setType("custom");
  setTrxDateFrom(date);
};

const trxDatePicker2 = (date, dateString) => {
  setType("custom");
  setTrxDateTo(date);
};

const PickerWithType = ({ type, onChange }) => {
  if (type === 'custom'){
      setTrxDateFrom(trxDateFrom);
      setTrxDateTo(trxDateTo);
  }
  if (type === 'today'){
    let todayStart = moment().startOf('day')
    let todayEnd = moment().endOf('day')
    setTrxDateFrom(todayStart);
    setTrxDateTo(todayEnd);
  } 
  if (type === 'yesterday'){
    let ytdStart = moment().subtract(1, 'days').startOf('day')
    let ytdEnd = moment().subtract(1, 'days').endOf('day')
    setTrxDateFrom(ytdStart);
    setTrxDateTo(ytdEnd);
  }
  if (type === 'thisweek'){
    let weekStart = moment().startOf('week')
    let weekEnd = moment().endOf('week').subtract(1, 'days').endOf('day');
    setTrxDateFrom(weekStart);
    setTrxDateTo(weekEnd);
  }
  if (type === 'thismonth'){
    let monthStart = moment().startOf('month')
    let monthEnd = moment().endOf('month');
    setTrxDateFrom(monthStart);
    setTrxDateTo(monthEnd);
  }
  if (type === 'lastmonth'){
    let lastMonthStart = moment().subtract(1, 'months').startOf('month');
    let lastMonthEnd = moment().subtract(1, 'months').endOf('month');
    setTrxDateFrom(lastMonthStart);
    setTrxDateTo(lastMonthEnd);
  }
  return <DatePicker picker={type} onChange={onChange} />;
};

const searchDate = async () => {
  if (!trxDateFrom) {
    reportDateFrom.current.focus();
    alertData.title = "Field Required";
    alertData.msg = "Please select Date From !";
    sharedErrorAlert(alertData);
    return;
  }

  if (!trxDateTo) {
    reportDateTo.current.focus();
    alertData.title = "Field Required";
    alertData.msg = "Please select Date To !";
    sharedErrorAlert(alertData);
    return;
  }

  if (trxDateTo < trxDateFrom || trxDateFrom > trxDateTo) {
    alertData.title = "Error";
    alertData.msg = "Please select a valid Date Range !";
    sharedErrorAlert(alertData);
    return;
  }

  if(!brand || brand == ''){
    alertData.title = "Field Required";
    alertData.msg = "Please select Brand !";
    sharedErrorAlert(alertData);
    return;
  }

  setTrxLoading(true);
  reportallReportListV2();
}

const expandButton = (record) => {
  if(expand == false){
    setExpand(true);

  }else if (expand == true){
    setExpand(false);
  }
}

const onChange = (list) => {
  setCheckedList(list);
  setCheckAll(list.length === plainOptions.length);
  if(list.includes('Deposit')){
    setDepositCheckbox(true);
    setDepositChange(depositAmtTotal)

  }else if(!list.includes('Deposit')){
    setDepositCheckbox(false);
    setDepositChange(0)
  }

  if(list.includes('Withdrawal')){
    setWithdrawalCheckbox(true);
    setWithdrawalChange(withAmountTotal);
  }else if(!list.includes('Withdrawal')){
    setWithdrawalCheckbox(false);
    setWithdrawalChange(0);
  }

  if(list.includes('Comm2Bank')){
    setComm2BankCheckbox(true);
    setComm2BankChange(commToBankAmtTotal);
  }else if(!list.includes('Comm2Bank')){
    setComm2BankCheckbox(false);
    setComm2BankChange(0);
  }

  if(list.includes('To Resolve')){
    setToResolveCheckbox(true);
    setToResolveChange(toResolveAmtTotal);
  }else if(!list.includes('To Resolve')){
    setToResolveCheckbox(false);
    setToResolveChange(0);
  }
};

// ==================== API CALLS ======================== //

  const retrieveBrand = async() => {
    let requestData = {
      columnRequire : [
        'brandNum AS brandNum',
        'brandName AS brandName'
      ],
      userType : role,
      userId : userId
    }

    let response = await getBrandListV2(requestData);
    let result = await response.json();
    if (result.status === 'ok'){
      if(Object.keys(result.data).length > 0){
        let brandListData = result.data.map((obj) => {
            return {
                key                 :   obj.brandNum,
                brandName           :   obj.brandName,
            }
        })
        setBrandData(brandListData);
      } else {
        setBrandData([])
      }
    }   else {
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
    }
  }


  const reportallReportListV2 = async () => {
    setTableLoading(true);
    setAllReport([]);
    setSearchLdg(true);
    let requestData= {
      datefrom   : moment(trxDateFrom).format("YYYY-MM-DD"),
      dateto     : moment(trxDateTo).format("YYYY-MM-DD"),
      brand      : brand,
    }

    let response = await reportDailyTrxnStatsV2(requestData);
    let result = await response.json();
    if(result.status === 'ok'){
      if(Object.keys(result.data).length > 0 && result.data.singleBrandData.length > 0){
        let allReportData = result.data.singleBrandData.map((data) => {
          return {
            key                   : data.TrxnDate + data.Brand,
            trxnDate              : data.TrxnDate,
            startTime             : data.StartTime,
            endTime               : data.EndTime,
            totalUser             : data.TotalUser,
            registerUser          : data.RegisterUser,
            inviteUser            : data.InviteUser,
            activeUser            : data.ActiveUser,
            depCount              : data.DepositCount,
            uniqueDep             : data.DepositCountUnique,
            depositAmt            : data.DepositAmount,
            maxDepositAmt         : data.DepositAmountMax,
            depositFirstCount     : data.DepositFirstCount,
            depositFirstAmount    : data.DepositFirstAmount,
            depositFirstTimeAmt   : data.DepositFirstTimeAmt,
            depositRegularCount   : data.DepositRegularCount,
            depositRegularAmount  : data.DepositRegularAmount,
            adjustAmt             : data.AdjustAmount,
            withdrawCount         : data.WithdrawCount,
            uniqueWth             : data.WithdrawCountUnique,
            withdrawAmt           : data.WithdrawAmountBot,
            maxWthAmt             : data.WithdrawAmountMax,
            depositUser           : data.DepositUser,
            commToWallet          : data.CommToWallet,
            commToBank            : data.CommToBank,
            remark                : data.Remark,
            toResolve             : data.BotToResolve,
            playerTurnover        : data.PlayerTurnover,
            playerWinLoss         : data.PlayerWinLoss,
            promotionCost         : data.PromotionCost,
            fortuneWheelAmt       : data.FortuneWheelAmt,
            permanentAngpaoAmt    : data.PermanentAngpaoAmt,
            inviteAngpaoAmt       : data.InviteAngpaoAmt,
            missionAmt            : data.MissionAmt,
            playerRebate          : data.PlayerRebate,
            downlineComm          : data.AffiliateComm,
            dailyFreeCredit       : data.DailyFreeCredit,
            pushNotification      : data.PushNotification,
            learnMore             : data.LearnMore,
            bindBankAcc           : data.BindBankAccount,
            brand                 : data.Brand,
            playerWithBank        : data.PlayerWithBank,
            playerWithoutBank     : data.PlayerWithoutBank,
          }
        });
        setAllReport(allReportData);
        setSearchLdg(false);
      } else {
        setAllReport([])
        setSearchLdg(false);
      }

      if(Object.keys(result.data).length > 0 && result.data.allBrandData.length > 0){
        let combinedReportData = result.data.allBrandData.map((data) => {
          return {
            key                   : data.TrxnDate + data.Brand,
            trxnDate              : data.TrxnDate,
            startTime             : data.StartTime,
            endTime               : data.EndTime,
            totalUser             : data.TotalUser,
            registerUser          : data.RegisterUser,
            inviteUser            : data.InviteUser,
            activeUser            : data.ActiveUser,
            depCount              : data.DepositCount,
            uniqueDep             : data.DepositCountUnique,
            depositAmt            : data.DepositAmount,
            maxDepositAmt         : data.DepositAmountMax,
            depositFirstCount     : data.DepositFirstCount,
            depositFirstAmount    : data.DepositFirstAmount,
            depositFirstTimeAmt   : data.DepositFirstTimeAmt,
            depositRegularCount   : data.DepositRegularCount,
            depositRegularAmount  : data.DepositRegularAmount,
            adjustAmt             : data.AdjustAmount,
            withdrawCount         : data.WithdrawCount,
            uniqueWth             : data.WithdrawCountUnique,
            withdrawAmt           : data.WithdrawAmountBot,
            maxWthAmt             : data.WithdrawAmountMax,
            depositUser           : data.DepositUser,
            commToWallet          : data.CommToWallet,
            commToBank            : data.CommToBank,
            remark                : data.Remark,
            toResolve             : data.BotToResolve,
            playerTurnover        : data.PlayerTurnover,
            playerWinLoss         : data.PlayerWinLoss,
            promotionCost         : data.PromotionCost,
            fortuneWheelAmt       : data.FortuneWheelAmt,
            permanentAngpaoAmt    : data.PermanentAngpaoAmt,
            inviteAngpaoAmt       : data.InviteAngpaoAmt,
            missionAmt            : data.MissionAmt,
            playerRebate          : data.PlayerRebate,
            downlineComm          : data.AffiliateComm,
            dailyFreeCredit       : data.DailyFreeCredit,
            pushNotification      : data.PushNotification,
            learnMore             : data.LearnMore,
            bindBankAcc           : data.BindBankAccount,
            brand                 : data.Brand,
            playerWithBank        : data.PlayerWithBank,
            playerWithoutBank     : data.PlayerWithoutBank,
          }
        });

        if(!filteredBrandData.find((obj) => obj.brandName === 'All')){
          filteredBrandData.unshift({key : 0, brandName : 'All'})
        }
        if(filteredBrandData.length > 1 && !filteredBrandData.find((obj) => obj.brandName === 'All')){
          filteredBrandData.unshift({key : 0, brandName : 'All'})
        }
        setExptBrandData(filteredBrandData)
        setCombinedReport(combinedReportData)
        setSearchLdg(false);
      } else {
        setExptBrandData([])
        setCombinedReport([])
        setSearchLdg(false);
      }
    } else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }
    setTableLoading(false);
  }

  const retrieveBankStats = async() => {
    setBankStatLdg(true);
    let requestData = {
        status      :  '0',
    }

    let response = await getBankVeriStatus(requestData);
    let result = await response.json();
    if (result.status === 'ok'){
      if(Object.keys(result.data).length > 0){
        let successListData = result.data.filter((obj) => obj.status == 1);
        let failedListData = result.data.filter((obj) => obj.status == 9);

        setBankStatSuccessCount(successListData.length)
        setBankStatFailCount(failedListData.length)
      } else {
        // console.log('getBankVeriStatus result only', result);
      }
      setBankStatLdg(false);
    }   else {
        alertData.title = "Failed";
        alertData.msg = result.msg;
        sharedFailAlert(alertData);
        setBankStatLdg(false);
      }
  }

  return (
    <div>
        
        <div className="eachPageContent">
          <div>
            <PageHeader
              ghost={false}
            >
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div  style={{maxWidth: '60%'}}>
                <div>
                  <Select 
                    mode="multiple" 
                    style={{
                      marginBottom: 10, 
                      marginLeft:10, 
                      width:"75%"
                      // width:"45%"
                    }} 
                    showSearch 
                    placeholder="Select a Brand"
                    onChange={handleChange} 
                    onSearch={handleSearch}
                    allowClear
                    suffixIcon={<SearchOutlined/>}
                    value={brand}
                    onClear={handleClearAll}
                    >
                    {brandData.map((e) => <Option key={e.brandName} value={e.brandName}>{e.brandName}</Option>)}
                  </Select>
                  <Button 
                    type="primary"
                    onClick={() => handleAllBrand()}
                    style={{marginLeft: 10}}
                    >
                    Select All Brands
                  </Button>
                </div>
                <div style={styles.pageHeaderContainer}>
                          <DatePicker
                              key="date-1"
                              style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                              format="YYYY-MM-DD"
                              onChange={trxDatePicker}
                              value={trxDateFrom}
                              ref={reportDateFrom}
                              refs
                              placeholder={"Select Date From"}
                              disabledDate={(current)=> {
                                return current > moment();//.subtract(1, 'day');
                              }}
                          />
                          <DatePicker
                            key="date-2"
                            style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                            format="YYYY-MM-DD"
                            onChange={trxDatePicker2}
                            value={trxDateTo}
                            ref={reportDateTo}
                            placeholder={"Select Date To"}
                            disabledDate={(current)=> {
                              let dateFrom = trxDateFrom;
                              if (dateFrom) {
                                return current < dateFrom || current > moment();//.subtract(1, 'day');
                              }else {
                                return  current > moment();//.subtract(1, 'day');
                              }
                            }}
                          />
                          <div style={styles.inlineBlock}>
                            <Space>
                              <Select style={{...styles.datePickerSelectionStyle}} value={type} type={type} onChange={setType}>
                                <Option value="custom">Custom</Option>
                                {/* <Option value="today">Today</Option> */}
                                <Option value="yesterday">Yesterday</Option>
                                <Option value="thisweek">This Week</Option>
                                <Option value="thismonth">This Month</Option>
                                <Option value="lastmonth">Last Month</Option>
                              </Select>
                            </Space>
                            <Button
                              type={"primary"}
                              icon={<SearchOutlined
                              onClick={searchDate}
                              loading={searchLdg}
                              />}
                            />
                            <Button style={{...styles.resetBtn,...styles.refreshBtn}} onClick={resetBtn}>
                              Reset
                            </Button>
                          </div>
                          {finalReportData ? 
                          <Button 
                                type={"primary"} 
                                style={styles.refreshBtn} 
                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                  onShowExptModal()
                                }}
                            >
                              Export to Excel
                          </Button>
                          :
                          <></>
                          }
                          
                </div>
                <div style={styles.checkbox}>
                  <p>
                  <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                  <span style={styles.expandStyle}>Expandable:  <Switch  checkedChildren="ON" unCheckedChildren="OFF" onClick={(event) => { expandButton() }}/></span>
                  </p>
                </div>
              </div>
              {brandOwnerVw(role, username) ?
              <Card
              style={{
                width: 500,
                backgroundColor: "#e2f2f0",
                borderRadius: 10,
                maxHeight: 200,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: 10
              }}
            >
              {bankStatLdg ? (
                <Spin 
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "center",
                  alignItems: "center",
                }}
                size="large"
              ></Spin>

              ) : (
                <div>
                  <div style={{display:'flex', justifyContent:'space-between'}}>
                    <p style={{height: "25%", fontSize: "20px", marginBottom: 0 }}>
                    Bank Verification Stats (Last Hour): 
                    </p>
                    <span >
                      <Button
                        style={{
                          backgroundColor: "initial",
                          color: "black",
                          borderColor: "black",
                        }}
                        type="default"
                        shape="round"
                        icon={<ReloadOutlined/>}
                        loading={bankStatReloadLdg}
                        onClick={() => 
                            retrieveBankStats()
                        }
                      >
                        Refresh
                      </Button>
                    </span>
                  </div>
                  <Row style={{display: 'flex', justifyContent:'space-between', width:'80%',  alignItems:'center'}} >
                        <Col  style={{ height: "25%", marginTop: "2%" }}>
                              <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                              Success:
                              </span> 
                              <br/>
                              <span style={{ fontSize: "24px", color:'green' }}>
                              {bankStatSuccessCount}
                              </span>
                        </Col>
                        <Col  style={{ height: "25%", marginTop: "2%",}}>
                              <span style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "14px" }}>
                              Failed:
                              </span> 
                              <br/>
                              <span style={{ fontSize: "24px", color:'red' }}>
                              {bankStatFailCount}
                              </span>
                        </Col>
                  </Row>
                        <Row style={{ marginTop: "2%", marginBottom: 0 }}>
                          <Button 
                            type="default"
                            shape="round"
                            icon={<SearchOutlined/>}
                            loading={bankStatReloadLdg}
                            onClick={handleSummaryClick}
                            >
                            View Summary
                          </Button>
                        </Row>
                </div>
              )}
            </Card>
              : <></>}
              </div>
              
            </PageHeader>
            <Modal 
                title="Export to Excel" 
                visible={showExptModal} 
                onCancel={onCloseExportModal}
                footer={null}
                mask
                maskClosable={false}
                >
                <Form
                layout="vertical"
                style={{display: 'flex', flexDirection: 'column'}}
                >
                  <FormItem label="Selected Brand " style={{fontWeight:'bold'}}>
                    <Select 
                      style={{width:"60%",fontWeight:'normal'}} 
                      showSearch 
                      placeholder="Select brand to export.."
                      onChange={handleChangeExptBrand} 
                      onSearch={handleSearchExptBrand}
                      allowClear
                      suffixIcon={<SearchOutlined/>}
                      value={exptBrand}
                      onClear={handleClearAllExpt}
                      >
                      {filteredBrandData.map((e) => <Option key={e.brandName} value={e.brandName}>{e.brandName}</Option>)}
                    </Select>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{marginLeft: '10px'}}
                        onClick={() => onClickExportBtnV2(exptBrandList)}
                        >
                        Export to Excel
                    </Button>
                  </FormItem>
                </Form>

            </Modal>
            <div style={styles.tableContainer}>
              <Tabs defaultActiveKey={brandNum ? brandNum : '0'} onTabClick={handleTabClick} activeKey={brandNum} >
                {filteredBrandData.map((e) => {
                  return(
                    <TabPane tab={e.brandName} key={e.key} disabled={!allReport && !combinedReport} >
                      <Row style={styles.webRow}>
                        <Card
                          title="Win / Lose &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (%) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; = &nbsp; Deposit &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;[ Withdrawal &nbsp;&nbsp;&nbsp; (&nbsp;Comm2Bank &nbsp;&nbsp; ) + &nbsp; To Resolve&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;] " 
                          style={styles.cardStyle2}
                          headStyle={styles.headStyles2}
                          bodyStyle={styles.bodyStyle2}
                        >
                          <Statistic  
                            style={styles.statistic1}
                            value={
                              winLoseSummary
                          }
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.openBracket}
                            value={"("}
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.statisticPercentage}
                            value={
                              statPctSummary
                          }
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.closeBracket}
                            value={")"}
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.equal}
                            value={"="}
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.statistic2}
                            value={depSummary}
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.minus1}
                            value={"-"}
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.openBracket}
                            value={"["}
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.statistic4}
                            value={wthSummary}
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.openBracket}
                            value={"("}
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.statistic5}
                            value={comm2BkSummary}
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.closeBracket}
                            value={")"}
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.plus}
                            value={"+"}
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.statistic6}
                            value={toResolveSummary}
                            valueStyle={{ fontSize: 17 }}
                          />
                          <Statistic
                            style={styles.closeBracket}
                            value={"]"}
                            valueStyle={{ fontSize: 17 }}
                          />
                        </Card>
                      </Row>
                      <Table
                        options={{
                          columnsButton: true,
                        }}
                        loading={tableLoading} 
                        columns={columns}
                        dataSource={finalReportData}
                        className="cs_dailyTrxnStat"
                        scroll={{ x: 100, y: null }}
                        exportable={true}
                        pagination={{
                          defaultPageSize: isMobile ? 10 : 100,
                          showSizeChanger: true,
                          pageSizeOptions: isMobile ? ["5", "10", "20"] : settings.pageSizeOptions,
                          style: styles.paginationStyle,
                        }}
                        exportableProps={{
                          fileName: "DailyTransactionStatsReport_"+exptBrand+"_"+todayDate,
                          btnProps: {
                            type: "primary",
                            icon: <FileExcelOutlined />,
                            id:"export-button-1",
                            children: <span>Export to Excel</span>,
                            style: {display:"none"}
                          },
                        }}
                      />
                    </TabPane>
                  )
                })}
              </Tabs>
              <br></br>
              <div>
                <p><b>REMARK :</b></p>
                <p>(1) Win / Lose = (1 - (Total Withdrawal / Total Deposit)) x 100</p>
                <p>(2) Percentage(%) may be infinity if Deposit are not selected</p>
                <p>(3) Promotion Cost is expandable</p>
                <p>(4) <span style={{color:"red"}}>Turnover</span> columns on today <b>{todayDate}</b> was disabled due to heavy loading.</p>
                <p>(5) Withdrawal already included Comm2Bank</p>
                <p>(6) (Amount in <span style={{color:"red"}}>RED</span> color is &#8250;= <span style={{color:"red"}}>100000</span> OR &#8249;= <span style={{color:"red"}}>-100000</span> (THB) )</p>
                <p>(7) Transaction listed in maximum 31 days.</p>
                <p>(8) '#' mean 'Count of Times'</p>
                <p>(9) REGISTER USER is the Daily New Registered User, INVITE USER is also part of REGISTER USER, they are introduced by Existing Players (Not Agent). --&#8250; so REGISTER USER deduct INVITE USER = Agent Direct Invite User.</p>
                <p>(10) ACTIVE USER is the Daily Login User (within the day), regardless of playing or not playing games.</p>
                <p>(11) DEPOSIT # is the Total Daily Deposit, including 1 player with multiple deposit.</p>
                <p>(12) UNIQUE DEPOSIT = number of unique user who deposited. Eg: If player with multiple deposit, UNIQUE DEP consider ONE.</p>
                <p>(13) MAX DEPOSIT and MAX WITHDRAW = the largest deposit and withdrawal amount in 1 Day.</p>
                <p>(14) UNIQUE DEPOSIT = NEW UNIQUE DEPOSIT # + REGULAR DEPOSIT #.</p>
                <p>(15) DEPOSIT # = NEW UNIQUE DEPOSIT AMOUNT + REGULAR DEPOSIT AMOUNT.</p>   
                <p style={{color:"blue"}}>Eg-1: 2021-06-27: there is total 3463 Active Users (Login User), 310 is New Register User, which 246 is invited by Players, 64 (= 310 - 246) is invited by Agent</p>
                <p style={{color:"blue"}}>Eg-2: 2021-06-27: Total Deposit = 163509.54 (THB), done by 520 Unique players from 1383 deposit, Max Deposit is 2000.21. From 520 Unique Deposit, New Unique Deposit is 7733.41 by 52 players, Regular Deposit is 155776.13 by 468 players</p>
                <div style={{...styles.inline, ...styles.hide}}>
                <PickerWithType type={type} onChange={(value) => console.log(value)} />
              </div>
              </div>
            </div>
          </div>
        </div>
    </div>
      
  );
}