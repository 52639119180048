import React, { useEffect, useState , useRef } from "react";
import { Form, Flex, Button, Switch, Input, Row, Col,Progress, Table,  Select, PageHeader, Modal, Badge, Typography, Spin} from "antd";
import { useSelector } from "react-redux";
import { createScbConnectInstance, delScbConnectInstance, delScbConnectConfigRecord, getBankListV2, getScbConnectConfigList, getScbConnectOtp } from "../../config/networkConfig";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, sharedErrorAlertV2 } from "../../common/constants/sharedMsgBox";
import { bindScbConnectVw, mtnMgmtSwt } from "../../library/permission";
import { QuestionCircleOutlined, DeleteOutlined} from "@ant-design/icons";
import FormItem from "antd/lib/form/FormItem";
import { BANK_INFO_TYPE } from "../../common/constants/bankType";

export default function BindScbConnect() {
    const { Paragraph } = Typography;
    const { confirm } = Modal;
    const { Option } = Select;
    const { role,  } = useSelector((state) => state.login);
    
    const [form]                                                  =       Form.useForm();
    const countdownRef                                            =       useRef(null);
    // modal
    const [showSetupModal, setShowSetupModal]                     =       useState(false);
    const [showOtpModal, setShowOtpModal]                         =       useState(false);
    // input
    const [bankCode, setBankCode]                                 =       useState("");
    const [accName, setAccName]                                   =       useState("");
    const [accNum, setAccNum]                                     =       useState("");
    const [linePw, setLinePw]                                     =       useState("");
    const [lineEmail, setLineEmail]                               =       useState("");
    const [otp, setOtp]                                           =       useState("");
    const [otpCreateTime, setOtpCreateTime]                       =       useState(0);
    const [otpOld, setOtpOld]                                     =       useState("");

    // data list
    const [depBankList, setDepBankList]                           =       useState([]);
    const [scbConnectConfigList, setScbConnectConfigList]         =       useState([]);
    const [filteredBankList, setFilteredBankList]                 =       useState([]);

    // loading 
    const [formLoading, setFormLoading]                           =       useState(false);
    const [setupBtnLdg, setSetupBtnLdg]                           =       useState(false);
    const [otpLdg, setOtpLdg]                                     =       useState(false);
    const [submitBtnLdg, setSubmitBtnLdg]                         =       useState(false);
    const [tableLoading, setTableLoading]                         =       useState(false);
    const [refreshBtnLdg, setRefreshBtnLdg]                       =       useState(false);

    // others
    const [isValidEmail, setIsValidEmail]                         =       useState(true);
    const [timer, setTimer]                                       =       useState(0);
    const [counter, setCounter]                                   =       useState(0);

    let count = 1;

    useEffect (() => {
        retrieveBankList();
        retrieveScbConnectConfigList();
    }, [])

    const layout = {
        labelCol: { span: 8},
        wrapperCol: { span: 16 },
    };
    
    const tailLayout = {
        wrapperCol: {
          offset: 8,
          span: 16,
        },
      };

    const columns = [
        {
            title: 'Account No.',
            dataIndex: 'accNumV2'
        },
        {
            title: 'Account Name',
            dataIndex: 'accName'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                switch (record.status) {
                    case 0: 
                    return <Badge status="warning" text="Pending" />;
                    case 1: 
                    return <Badge status="success" text="Active" />;  
                    case 2: 
                    return <Badge status="error" text="Failed" />;       
                }
              },
        },
        {
            title: 'Date Created',
            dataIndex: 'sysCreateTime'
        },
        {
            title: 'SCB Connect Server',
            dataIndex: 'scbConnectServer'
        },
        {
            title: 'Action',
            align: "center",
            dataIndex: 'action',
            render: (text, record) => {
                return(
                    <div style={{display:'flex', flexDirection:'row', gap:'5px', alignItems:'center', justifyContent:'center'}}>
                        <Button 
                            type="danger" 
                            onClick={() => handleDelete(record)}
                            >
                                { bindScbConnectVw(role) ?  <DeleteOutlined /> : <></> }
                        </Button>
                    </div>
                )
            }
        }
    ]    

    const onComplete = () => {
        setShowOtpModal(false); 
        confirm({
            className: "confirmationModalStyle",
            title: 'Confirmation',
            centered: true,
            icon: <QuestionCircleOutlined />,
            width: "500px",
            content: 
            <Paragraph>
                Are you sure you have completed the OTP process for this bank?
            </Paragraph>,
            onOk() {
                setShowOtpModal(false); 
                clearInterval(countdownRef.current)
                onReset();
                retrieveScbConnectConfigList();
            },
            onCancel() {
                setShowOtpModal(true); 
            }
        })
  }
  
    const onReset = () => {
        setBankCode('');
        setAccName('');
        setAccNum('');
        setLineEmail('');
        setLinePw('');
        setTimer(0)
        setIsValidEmail(true);
        clearInterval(countdownRef.current)
    };

    const onCancelSubmit = () => {
        setShowSetupModal(false);
        onReset();
    };

    const onCancelOtp = () => {
        setShowOtpModal(false);
        confirm({
            className: "confirmationModalStyle",
            title: 'Confirmation',
            centered: true,
            icon: <QuestionCircleOutlined />,
            width: "500px",
            content: 
            <Paragraph>
                Are you sure you want to close this?
            </Paragraph>,
            onOk() {
                onReset();
                clearInterval(countdownRef.current)
            },
            onCancel() {
                setShowOtpModal(true); 
            }
        })
        
    };
  
    const onSubmit = () => {
        setSubmitBtnLdg(true);
        setFormLoading(true);

        if(!accName || !accNum || !bankCode){
            alertData.title = 'Field Required !';
            alertData.msg = 'Please select Bank.';
            sharedErrorAlert(alertData);
            setFormLoading(false);
            setSubmitBtnLdg(false);
            return
        }
        if(!lineEmail){
            alertData.title = 'Field Required !';
            alertData.msg = 'Please fill in Line Email';
            sharedErrorAlert(alertData);
            setFormLoading(false);
            setSubmitBtnLdg(false);
            return
        }
        if(isValidEmail === false){
            alertData.title = 'Validation Error !';
            alertData.msg = 'Please use a valid email address';
            sharedErrorAlert(alertData);
            setSubmitBtnLdg(false);
            setFormLoading(false);
            return
        }

        if(!linePw){
            alertData.title = 'Field Required !';
            alertData.msg = 'Please fill in Line Password';
            sharedErrorAlert(alertData);
            setSubmitBtnLdg(false);
            setFormLoading(false);
            return
        }

        confirm({
            className: "confirmationModalStyle",
            title: 'Confirmation',
            centered: true,
            icon: <QuestionCircleOutlined />,
            width: "500px",
            content: 
            <Paragraph>
                Are you sure you want to setup SCB Connect for this bank?
                <br/>
                <ul>
                    <li>
                        Bank Account Name: {accName} 
                    </li>
                    <li>
                        Bank Account Number: {accNum} 
                    </li>
                    <li>
                        Line Email: {lineEmail} 
                    </li>
                    <li>
                        Line Password: {linePw} 
                    </li>
                </ul>
            </Paragraph>,
            onOk() {
            setupScbConnect();
            },
            onCancel() {
            setSubmitBtnLdg(false);
            setFormLoading(false);
            }
        })
    };

    const handleShowSetupModal = () => {
        setShowSetupModal(true);
        sortBankList();
    }

    const handleBankSelect = (e) => {
        const bank = e;
        for(let i = 0; i < depBankList.length; i++){
            if(bank == depBankList[i].accNum){
                setAccName(depBankList[i].accName);
                setAccNum(depBankList[i].accNum);
                setBankCode(depBankList[i].bankCode);
            }
        }
    }
  
    const handleInputEmail = (e) =>{
        const email = e.target.value;
        setLineEmail(email);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(email));
    }
    const handleInputPw = (e) =>{
        const pw = e.target.value;
        setLinePw(pw);
    }

    const handleOtp = (otpCreateTime) => {
        let newTime = otpCreateTime;

        countdownRef.current = setInterval(()=>{
            let time = new Date().getTime();
            let current = Math.floor(time / 1000) ;
            let diff = current - newTime;
            let seconds = Math.floor(100 - diff);

            if (isNaN(seconds)) {
                return;
            }
            if(seconds < 0){
                return;
            }
            setTimer(seconds);
            if(seconds > 0 ){
                setOtpLdg(false);
            }

            if(seconds === 0){
                clearInterval(countdownRef.current)
                setRefreshBtnLdg(false);
                setOtpLdg(true);
                setTimeout(() => grabOtp(),22000)
            }
        },1000)
    }

    const handleDelete = (record) => {
        confirm({
            className: "confirmationModalStyle",
            title: 'Confirmation',
            centered: true,
            icon: <QuestionCircleOutlined />,
            width: "500px",
            content: 
            <Paragraph>
                Are you sure you want to delete this SCB Connect bank?
                <br/>
                <ul>
                    <li>Account No. : {record.accNum}</li>
                    <li>Account Name : {record.accName}</li>
                    <li>Bank : {record.bankCode}</li>
                </ul>
            </Paragraph>,
            onOk() {
                delScbConnectConfig(record);
            },
            onCancel() {
                onReset();
            }
        })
    }

    // get list of deposit banks that don't have SCB setup before [getBankListV2]
    const retrieveBankList = async () => {
        let requestData = {
            bankType : BANK_INFO_TYPE.DEP,
            columnRequire : [
                'AccountNum AS accNum',
                'AccountName AS accName',
                'BankCode AS bankCode',
                'Status AS status',
            ],
            // condition : ["Remark NOT LIKE '%scb%'"]
        }

        let response = await getBankListV2(requestData);
        let result = await response.json();
        if(result.status === 'ok'){
            if(Object.keys(result.data).length > 0){
                let count = 0;
                let depBankListData = result.data.map((obj) => {
                    return {
                        key           :   count++,
                        accNum        :   obj.accNum,
                        accName       :   obj.accName,
                        bankCode      :   obj.bankCode,
                        status        :   obj.status, 
                    }
                })
                if (depBankListData.length > 0){
                    setDepBankList(depBankListData)
                }
            }
        } else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
          }
    }

    const sortBankList = () => {
        const bankAccNum = scbConnectConfigList.map((item) => item.accNum);
        const sortedList = depBankList.filter((item) => !bankAccNum.includes(item.accNum));
        setFilteredBankList(sortedList);
    }

    // create scb connect instance by consuming ZY's API (it will create instance and insert record into db) [createScbConnectInstance]
    const setupScbConnect = async () => {
        setSubmitBtnLdg(false);
        setFormLoading(false);
        setShowOtpModal(true);
        setShowSetupModal(false);
        setOtpLdg(true);

        let requestData = {
            username            :   lineEmail,
            password            :   linePw,
            accountNo           :   accNum,
            accountName         :   accName,
            bankCode            :   bankCode,
        }
        
        let response = await createScbConnectInstance(requestData);
        let result = await response.json();

        if(result.status === 'ok'){
            setTimeout(() => {
                grabOtp();
            }, 5000);
        } else{
            alertData.title = "Failed";
            alertData.msg = response.msg;
            sharedFailAlert(alertData);  
            setShowSetupModal(true);
            setShowOtpModal(false);
        }
    }

    // grab otp from scb_connect_config table (when click refresh) [getScbConnectOtp]
    const grabOtp = async () => {
        if(countdownRef.current){
            clearInterval(countdownRef.current)
        }
        setRefreshBtnLdg(false);
        setOtpLdg(true);
        setTimer(100);

        let requestData = {
            accountNo : accNum,
        }
        let response = await getScbConnectOtp(requestData);
        let result = await response.json();
        let newCount = count++
        setCounter(newCount)
        if(newCount > 5){
            alertData.title = "Failed";
            alertData.msg = 'Failed to setup SCB Connect, please remove account and try again.';
            sharedFailAlert(alertData);  
            setShowOtpModal(false);
            setCounter(0);
            onReset();
            clearInterval(countdownRef.current)
            return;
        }
        
        if(result.status === 'ok') {
            if(Object.keys(result.data).length > 0){
                let getOtpData = result.data.map((obj) => {
                    return {
                        otp  : obj.otp,
                        otpCreateTime : obj.otpCreateTime
                    }
                })
                if(getOtpData.length > 0){
                    if(getOtpData[0].otp === null){
                        setOtpLdg(true);
                        setTimeout(() => {
                            grabOtp();
                        }, 5000);
                    }

                    else {
                        setOtpOld(getOtpData[0].otp);
                        if(getOtpData[0].otp === otpOld){
                            setOtpLdg(true);
                            setTimeout(() => {
                                grabOtp();
                            }, 5000);
                        }
                        
                        if (getOtpData[0].otp !== otpOld){
                            count = 1;
                            setCounter(0);
                            clearInterval(countdownRef.current)
                            setOtp(getOtpData[0].otp)
                            setOtpCreateTime(getOtpData[0].otpCreateTime)
                            setOtpLdg(false);
                            handleOtp(parseInt(getOtpData[0].otpCreateTime));
                        }
                    }
                } else {
                        setOtpLdg(true);
                        setTimeout(() => {
                            grabOtp();
                        }, 5000);
                }
            }
        } else{
            alertData.title = "Failed";
            alertData.msg = response.msg;
            sharedFailAlert(alertData);  
            setShowSetupModal(true);
            setShowOtpModal(false);
            onReset();
            setCounter(0);
            return;
        }
        retrieveScbConnectConfigList()
        setOtpLdg(false);
    }

    // get list of banks from scb_connect_config table [getScbConnectConfigList]
    const retrieveScbConnectConfigList = async () => {
        setTableLoading(true)
        let requestData = {
            columnRequire : [
                'accountNo AS accNum',
                'accountName AS accName',
                'status AS status',
                `FROM_UNIXTIME(SysCreateTime,'%Y-%m-%d %H:%i:%s') as SysCreateTime`,
                'bankCode AS bankCode',
                'scbConnectServer AS scbConnectServer',
            ]
        }

        let response = await getScbConnectConfigList(requestData);
        let result = await response.json();
            if(result.status === 'ok'){
                if(Object.keys(result.data).length > 0){
                    let count = 0;
                    let scbConnectConfigListData = result.data.map((obj) => {
                        return {
                            key                 :   count++ ,
                            accNum              :   obj.accNum,
                            accName             :   obj.accName,
                            bankCode            :   obj.bankCode,
                            status              :   obj.status, 
                            sysCreateTime       :   obj.SysCreateTime,
                            scbConnectServer    :   obj.scbConnectServer,
                            accNumV2            :   obj.accNum + ` (${obj.bankCode})`,
                        }
                    })
                        setScbConnectConfigList(scbConnectConfigListData)
                } else {
                    setScbConnectConfigList([])
                }
            }   else {
                alertData.title = "Failed";
                alertData.msg = result.msg;
                sharedFailAlert(alertData);
            }
          setTableLoading(false);
    }

    // delete scb connect record from scb_connect_config table [delete ]
    // Updated 10/05/2024 : Add delScbConnect to delete pm2, json & userData + edit .env file
    const delScbConnectConfig = async (obj) => {
        setTableLoading(true)
        let requestData = {
            accountNo       :   obj.accNum,
        }

        let response = await delScbConnectInstance(requestData);
        let result = await response.json();
        if(result.status === 'ok'){
            let response2 = await delScbConnectConfigRecord(requestData)
            let result2 = await response2.json();
            if(result2.status === 'ok'){
                alertData.title = "Success";
                alertData.msg = `SCB Connect account for ${obj.accNum} (${obj.bankCode}) is removed successfully.`;
                sharedSuccessAlert(alertData);
                setTableLoading(true);
                retrieveScbConnectConfigList();
            } else {
                alertData.title = "Failed";
                alertData.msg = result.msg;
                sharedFailAlert(alertData);
                retrieveScbConnectConfigList();
            }
        } else {
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
            retrieveScbConnectConfigList();
        }
    }

    return (
        <div>
            <Modal 
                title="Setup SCB Connect" 
                visible={showSetupModal} 
                onCancel={onCancelSubmit}
                footer={null}
                mask
                maskClosable={false}
                >
                <Form
                {...layout}
                layout="vertical"
                form={form}
                style={{display: 'flex', flexDirection: 'column'}}
                >
                    <FormItem label="Deposit Bank" rules={[{ required: true }]} required={true}>
                        <Select value={accNum} placeholder="Select your bank" onChange={handleBankSelect} disabled={submitBtnLdg}>
                            {filteredBankList.map((data) => (
                                <Option key={data.key} value={data.accNum} >
                                {data.bankCode} ( {data.accNum} ) - {data.accName} 
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem label="Line Email" rules={[{ required: true }]} required={true}>
                        <Input 
                        value={lineEmail}  
                        placeholder="Enter Line Email here..."
                        onChange={handleInputEmail}
                        style={{ borderColor: isValidEmail ? '' : 'red' }}
                        disabled={submitBtnLdg}
                        />
                        {!isValidEmail && <p style={{ color: 'red' }}>Please enter a valid email address.</p>}
                    </FormItem>
                    <FormItem label="Line Password" rules={[{ required: true }]} required={true}>
                        <Input 
                        value={linePw}  
                        placeholder="Enter Line Password here..."
                        onChange={handleInputPw}
                        disabled={submitBtnLdg}
                        />
                    </FormItem>
                    <Form.Item {...tailLayout} style={{ textAlign: "right" }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginRight: 8 }}
                            loading={formLoading}
                            onClick={onSubmit}
                            >
                            Submit
                        </Button>
                        <Button 
                            htmlType="button" 
                            onClick={onCancelSubmit}
                            loading={formLoading}
                            >
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal 
                title="Line OTP" 
                visible={showOtpModal} 
                footer={null} 
                onCancel={onCancelOtp}
                mask
                maskClosable={false}
                >
                { otpLdg  ?
                    <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:'center', gap: '14px'}}>
                        <Spin tip="Loading"></Spin>
                        <p>Please wait for a few seconds while SCB Connect is being setup.</p>
                    </div>
                    :
                    (<div >
                        { !otp ?
                            <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:'center', gap: '14px'}}>
                                <Spin tip="Loading"></Spin>
                                <p>Please wait for a few seconds while SCB Connect is being setup.</p>
                            </div>
                            :
                            <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:'center'}}>
                                <Progress type="circle" percent={timer} format={() => `${otp ? otp : 'N/A'}`} />
                                {
                                    timer > 0 ?
                                    (
                                        <p style={{marginTop: '10px', width: '100%'}}>
                                        Line OTP will expire in <span style={{color:"red"}} id="otpCd" >{timer}</span> seconds.
                                        <br/>
                                        Kindly key in the OTP in the registered Line device.
                                        </p>
                                    ) : (
                                        <p style={{marginTop: '10px', width: '100%', color:'red'}}>
                                        Line OTP is expired, please refresh for new Line OTP.
                                        </p>
                                    )
                                }
                                <div style={
                                    {
                                        width: '100%',
                                        display:'flex',
                                        gap:'2rem',
                                        alignItems:'center',
                                        justifyContent:'end'
                                    }
                                }>
                                <Button type="secondary" onClick={onComplete}>Complete</Button>
                                </div>
                            </div>
                        }
                    </div>)
                }
            </Modal>
            <div style={{backgroundColor: "#FFFFFF", paddingLeft: 10, }}>
                <PageHeader>
                    {bindScbConnectVw(role) ? 
                    <Button
                        type="primary"
                        onClick={handleShowSetupModal}
                        loading={setupBtnLdg}
                    >
                        Add
                    </Button>
                    :
                    <></>
                }
                </PageHeader>
                <Table
                style={{ overflow: "auto", marginLeft: 20 }}
                loading={tableLoading}
                columns={columns}
                dataSource={scbConnectConfigList}
                pagination={false}
                />
                <div style={{padding: 24}}>
                    {/* <br></br> */}
                    <p>
                        <b>REMARK :</b>
                    </p>
                    <p>
                        <span style={{fontWeight: 'bold', color:'blue'}}>[1]</span> SCB Connect <span style={{fontWeight: 'bold',}}>MUST BE IN ENGLISH</span> before proceeding to <span style={{fontWeight:'bold'}}>"Add"</span>.
                    </p>
                </div>
            </div>
        </div>
    )    
  }