import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Button,
  Input,
  Select,
  Table,
  PageHeader,
  DatePicker,
  Spin,
} from "antd";
import { Modal, Typography } from "antd";
import {
  QuestionCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import {
  alertData,
  sharedSuccessAlert,
  sharedErrorAlert,
  sharedFailAlert,
} from "../../common/constants/sharedMsgBox";
import {
  getBlacklistBankAcc,
  createBlacklistBankAcc,
  deleteBlacklistBankAcc,
  csAvailBankList,
} from "../../config/networkConfig";

export default function Testing() {
  const dateFormat = "YYYY-MM-DD HH:mm";
  const modalForm = useRef();
  const { Option } = Select;
  const { Paragraph } = Typography;
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [tempTableData, setTempTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [bankCode, setBankCode] = useState("");
  const [bankCodeList, setBankCodeList] = useState([]);
  const [bankAccount, setBankAccount] = useState("");
  const { companyId } = useSelector((state) => state.login);

  const layout = {
    labelCol: { span: 8 },
  };

  const columns = [
    {
      ellipsis: true,
      title: "Bank Code",
      dataIndex: "BankCode",
      width: "100px",
    },
    {
      ellipsis: true,
      title: "Bank Account",
      dataIndex: "BankAcc",
      width: "100px",
    },
    {
      ellipsis: true,
      title: "Creation Time",
      dataIndex: "formatDate",
      width: "100px",
    },
    {
      ellipsis: true,
      title: "Action",
      width: "100px",
      render: (_, record) => (
        <>
          <Button
            style={{ marginRight: "10px" }}
            type="danger"
            key="Delete"
            icon={<DeleteOutlined />}
            onClick={() => handleConfirmDelete(record)}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    getBankAcc();
    getAllBankCode();

    const spinnerTimeout = setTimeout(() => {
      setTableLoading(false);
    }, 2000);

    return () => clearTimeout(spinnerTimeout);
  }, []);

  const styles = {
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      // display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
      display: "inline",
    },
    inlineBlock: {
      display: "inline-block",
    },
    datePickerStyle: {
      width: 280,
    },
    filterStyle: {
      marginLeft: "10px",
    },
  };

  const getBankAcc = async () => {
    const res = await getBlacklistBankAcc();
    const result = await res.json();

    const data = result.data.map((item, index) => {
      let date = new Date(item.SysCreateTime * 1000);
      const formatDate = formatedDate(date);

      return { ...item, formatDate: formatDate, key: index };
    });

    setTableData(data);
    setTempTableData(data);
  };

  const getAllBankCode = async () => {
    const res = await csAvailBankList({ companyId: companyId });
    const result = await res.json();

    const bankCode = result.data.map((item) => {
      return { bankCode: item.bankName, key: item.bankCode };
    });

    setBankCodeList(bankCode);

    // console.log(bankCodeList);
  };

  const formatedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleInputBankAccount = (e) => {
    const bankAcc = e.target.value;
    setBankAccount(bankAcc);
  };

  const handleConfirmDelete = (record) => {
    let msg = `Are you sure to delete?`;

    confirm({
      className: "confirmationModalStyle",
      title: "Confirmation",
      width: "500px",
      centered: true,
      icon: <QuestionCircleOutlined />,
      content: (
        <Paragraph>
          {msg}
          <ul>
            <li>Bank Code : {record.BankCode}</li>
            <li>Bank Account : {record.BankAcc}</li>
          </ul>
        </Paragraph>
      ),
      onOk() {
        handleDelete(record);
      },
      onCancel() {},
    });
  };

  const handleDelete = async (record) => {
    let data = {
      bankcode: record.BankCode,
      acctnum: record.BankAcc,
    };
    const res = await deleteBlacklistBankAcc(data);
    const result = await res.json();

    if (result.status === "ok") {
      setTableLoading(true);
      alertData.title = "Success";
      alertData.msg = result.msg;
      sharedSuccessAlert(alertData);
      getBankAcc();
      const spinnerTimeout = setTimeout(() => {
        setTableLoading(false);
      }, 1000);
      return () => clearTimeout(spinnerTimeout);
    } else {
      getBankAcc();
      setModalLoading(false);
      alertData.title = "User Deleted Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setBankCode("");
    setBankAccount("");
    modalForm.current.resetFields();
  };

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    // Allow only numeric keys and control keys (e.g., backspace, delete)
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const handleConfirm = () => {
    if (!bankCode) {
      setModalLoading(false);
      alertData.title = "Warning";
      alertData.msg = "Please select bank code!";
      sharedFailAlert(alertData);
      return;
    }
    if (!bankAccount) {
      setModalLoading(false);
      alertData.title = "Warning";
      alertData.msg = "Please enter bank account!";
      sharedFailAlert(alertData);
      return;
    }

    if (bankAccount && bankCode) {
      setModalLoading(true);
      let msg = "Are you sure to submit detail?";

      confirm({
        className: "confirmationModalStyle",
        title: "Confirmation",
        width: "500px",
        centered: true,
        icon: <QuestionCircleOutlined />,
        content: (
          <Paragraph>
            {msg}
            <ul>
              <li>Bank Code : {bankCode}</li>
              <li>Bank Account : {bankAccount}</li>
            </ul>
          </Paragraph>
        ),
        onOk() {
          handleSubmit();
        },
        onCancel() {
          setModalLoading(false);
          handleCancel();
        },
      });
    }
  };

  const handleSubmit = async () => {
    let data = {
      bankcode: bankCode,
      acctnum: bankAccount,
    };

    console.log(data);

    const res = await createBlacklistBankAcc(data);
    const result = await res.json();

    console.log(result);

    if (result.status === "ok") {
      setTableLoading(true);
      getBankAcc();
      setModalLoading(false);
      alertData.title = "Success";
      alertData.msg = result.msg;
      sharedSuccessAlert(alertData);
      handleCancel();
      const spinnerTimeout = setTimeout(() => {
        setTableLoading(false);
      }, 1000);
      return () => clearTimeout(spinnerTimeout);
    } else {
      getBankAcc();
      setModalLoading(false);
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      handleCancel();
    }
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF", paddingLeft: 10 }}>
      <Modal
        title={"Add New Blacklist"}
        visible={visible}
        footer={null}
        confirmLoading={modalLoading}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <Form {...layout} ref={modalForm} form={form} layout="vertical">
          <Form.Item name={["bankcode", "selectBankCode"]} label="BankCode">
            <Select
              placeholder="Please Select Bank Code"
              getPopupContainer={(trigger) => trigger.parentNode}
              onSelect={(value, option) => {
                console.log(value);
                setBankCode(option.key);
              }}
            >
              {bankCodeList.length > 0 ? (
                bankCodeList.map((bankCode) => (
                  <Option key={bankCode.key} value={bankCode.bankCode}>
                    {`${bankCode.bankCode}`}
                  </Option>
                ))
              ) : (
                <Option value="None" disabled>
                  None
                </Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item name="bankAccount" label="Bank Account">
            <Input
              value={bankCode}
              placeholder="Enter Bank Account here..."
              onChange={handleInputBankAccount}
              disabled={modalLoading}
              onKeyPress={handleKeyPress}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: "right" }}>
            <Button
              loading={modalLoading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: 10 }}
              onClick={() => handleConfirm()}
            >
              Submit
            </Button>
            <Button
              htmlType="button"
              onClick={() => {
                handleCancel();
              }}
              disabled={modalLoading}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <PageHeader ghost={true}>
        <div style={{ paddingBottom: "10px" }}>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
            }}
            disabled={modalLoading}
          >
            Add
          </Button>
        </div>
      </PageHeader>
      <Form form={form} component={false}>
        <Spin
          spinning={tableLoading}
          size="large"
          style={{ display: "block", textAlign: "center", marginTop: 20 }}
        >
          <Table
            style={{ overflow: "auto", marginLeft: 20 }}
            scroll={{ x: "100%", y: null }}
            columns={columns}
            dataSource={tableLoading ? [] : tempTableData}
          />
        </Spin>
      </Form>
    </div>
  );
}
