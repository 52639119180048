export const CS_USER_TYPE = {
  Senior: 'CS_Senior',
  Junior: 'CS_Junior',
  CS: 'CS',
  SuperCS: 'CS_Super'
};

export const REPORT_USER_TYPE = {
  Reporter: 'Reporter',
  Ext_Finance: 'Ext_Finance',
};

export const MARKETING_USER_TYPE = {
  Marketing: 'MKTG',
};

export default { CS_USER_TYPE, REPORT_USER_TYPE, MARKETING_USER_TYPE };