import React, { useEffect, useRef, useState } from "react";
import { ReportDepositLedgerVw } from "../../../library/permission";
import { CNotAuthorized } from "../../../common/components/CNotAuthorized";
import { useSelector } from "react-redux";
import style from "../../../resource/style/style";
import { Button, Card, DatePicker, PageHeader, Select, Statistic, Table } from "antd";
import {
    SearchOutlined,
    CloseOutlined,
    CheckOutlined,
  } from "@ant-design/icons";
import moment from "moment";
import { Option } from "antd/lib/mentions";
import { getBankMachList, reportDepositLedger } from "../../../config/networkConfig";
import { alertData, sharedErrorAlert } from "../../../common/constants/sharedMsgBox";
import { settings } from "../../../config/appConfig";
import { isMobile } from "react-device-detect";

export default function DepositLedger() {
    const { role } = useSelector((state) => state.login);


    // Data from db state
    const [accountList, setAccountList] = useState([]);
    const [depositList, setDepositList] = useState([]);
    const [totalDepositAmount, setTotalDepositAmount] = useState(0);


    // Picker state
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [type, setType] = useState("thisMonth");
    const [account, setAccount] = useState("");


    // Loader state
    const [tableLoading, setTableLoading] = useState(false);


    // Table Data
    const columns = [
        {
          ellipsis: true,
          title: "Deposit Time",
          dataIndex: "depositTime",
          width: 100,
        },
        {
          ellipsis: true,
          title: "To Account",
          dataIndex: "toAccount",
          width: 80,
        },
        {
          ellipsis: true,
          title: "Bank Details",
          dataIndex: "bankDetails",
          width: 80,
        },
        {
          ellipsis: true,
          title: "Amount",
          dataIndex: "amount",
        //   align: "center",
          width: 50,
        },
        {
          ellipsis: true,
          title: "Receive By API",
          dataIndex: "receivedByAPI",
          editable: true,
          width: 80,
          align: "center",
          render: (record) => {
            switch (record) {
                case '0':
                  return <CloseOutlined style={{ color: "red"}} />;
                case '1':
                  return <CheckOutlined style={{ color: "green"}} />; // Unicode for check mark: &#x2714;
                // default:
                //   return <ExclamationCircleOutlined style={{ color: "grey"}} />
            }
          },
        },
        {
          ellipsis: true,
          title: "Received By SMS",
          dataIndex: "receivedBySMS",
          editable: true,
          width: 80,
          align: "center",
          render: (record) => {
            switch (record) {
                case '0':
                  return <CloseOutlined style={{ color: "red"}} />;
                case '1':
                  return <CheckOutlined style={{ color: "green"}} />; // Unicode for check mark: &#x2714;
                // default:
                //   return <ExclamationCircleOutlined style={{ color: "grey"}} />
            }
          },
        },
        {
          ellipsis: false,
          title: "Received By SCB Connect",
          dataIndex: "receivedBySCB",
          editable: true,
          width: 80,
          align: "center",
          render: (record) => {
            switch (record) {
                case '0':
                  return <CloseOutlined style={{ color: "red"}} />;
                case '1':
                  return <CheckOutlined style={{ color: "green"}} />; // Unicode for check mark: &#x2714;
                // default:
                //   return <ExclamationCircleOutlined style={{ color: "grey"}} />
            }
          },
        },
        
    ];

    // Functions
    const handlestartDate = (date) => {
        setType("custom")
        setStartDate(date)
    }

    const handleendDate = (date) => {
        setType("custom")
        setEndDate(date)
    }

    const pickerWithType = () => {
        if (type === 'custom'){
            setStartDate(startDate);
            setEndDate(endDate);
        }
        if (type === 'today'){
          let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
          setStartDate(moment().set('date', today))
          setEndDate(moment().set('date', today))
        } 
        if (type === 'yesterday'){
          let yesterday = new Date(new Date().getTime());
          yesterday.setDate(yesterday.getDate()-1);
          let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
          let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
          let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
          yestMonth = yestMonth - 1
    
          setStartDate(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
          setEndDate(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
        }
        if (type === 'thisWeek'){
          let today = new Date();
          let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
          let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
          let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
          let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
              firstMonthOfWeek = firstMonthOfWeek - 1; 
          let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
          let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
          let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
          let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
              lastMonthOfWeek = lastMonthOfWeek - 1;
          setStartDate(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
          setEndDate(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
        }
        if (type === 'thisMonth'){
          let date = new Date(), y = date.getFullYear(), m = date.getMonth();
          let firstDay = new Date(y, m, 1);
          let lastDay = new Date(y, m + 1, 0);
    
          let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
    
          let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
          lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
          month = month - 1
    
          setStartDate(moment().set({'year': year, 'month': month, 'date': 1}))
          setEndDate(moment().set({'year': year, 'month': month, 'date': lastDay}))
        }
        if (type === 'lastMonth'){
          let date = new Date(), y = date.getFullYear(), m = date.getMonth();
          let firstDay = new Date(y, m-1, 1);
          let lastDay = new Date(y, m, 0);
    
          let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
          let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})
    
          let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
          lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
          month = month - 1
    
          setStartDate(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
          setEndDate(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
        }
    };

    const getAccountList = async () => {
        const requestData = {
            BankType: "Deposit"
        }

        const bankMachResp = await getBankMachList(requestData);
        const response = await bankMachResp.json();

        if (response.status === "ok" && response.data.length > 0) {
            let count = 0;
            const data = response.data.map((resp) => ({
                key: count++,
                accountNo: resp.acctnum,
                accountName: resp.acctname,
                bankCode: resp.bankCode
            }));
            setAccountList(data);
        }
        else {
            setAccountList([]);
        }
    }

    const handleSearch = () => {
        if (!startDate) {
            // reportStartDate.current.focus();
            alertData.title = "Field Required";
            alertData.msg = "Please select Start Date !";
            sharedErrorAlert(alertData);
            return;
        }

        if (!endDate) {
            // reportEndDate.current.focus();
            alertData.title = "Field Required";
            alertData.msg = "Please select End Date !";
            sharedErrorAlert(alertData);
            return;
        }

        if (endDate < startDate || startDate > endDate) {
            alertData.title = "Error";
            alertData.msg = "Please select a valid Date Range !";
            sharedErrorAlert(alertData);
            return;
        }
        getDepositLedger()
    }

    const getDepositLedger = async () => {
        setTableLoading(true);
        let requestData = {
            startDate : moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
            account: account,
        };

        let count = 0;

        const respDepoLedger = await reportDepositLedger(requestData);
        let response = await respDepoLedger.json();

        if (response.status === "ok" && response.data.length > 0) {
            const totalAmount = response.data.reduce((sum, record) => sum + record.amount, 0);
            setTotalDepositAmount(totalAmount.toFixed(2));
            const newData = response.data.map((resp) => {
                return {
                    key: count++,
                    amount: resp.amount,
                    ...resp,
                } 
            })
            setDepositList(newData);
        } else {
            setDepositList([]);
            setTotalDepositAmount(0);
        }

        setTableLoading(false);
    }

    useEffect(() => {
        getAccountList()
    },[])

    useEffect(() => {
        pickerWithType()
    },[type])

    return (
        <div>
            {
                ReportDepositLedgerVw(role) ?
                <div className="eachPageContent">
                    <div>
                        <PageHeader
                            ghost={false}
                            >
                                <div style={{...style.pageHeaderContainer, display: "inline"}}>
                                    <DatePicker 
                                        key="1"
                                        style={{...style.datePickerStyle, ...style.refreshBtn}}
                                        format="YYYY-MM-DD"
                                        onChange={handlestartDate}
                                        value={startDate}
                                        // ref={reportStartDate}
                                        placeholder="Select Start Date"
                                        disabledDate={(current) => {
                                            return current > moment();
                                        }}
                                    />
                                    <DatePicker 
                                        key="2"
                                        style={{...style.datePickerStyle, ...style.refreshBtn}}
                                        format="YYYY-MM-DD"
                                        onChange={handleendDate}
                                        value={endDate}
                                        // ref={reportEndDate}
                                        placeholder="Select End Date"
                                        disabledDate={(current)=> {
                                            if (startDate) {
                                            return current < startDate || current > moment();//.subtract(1, 'day');
                                            }else {
                                            return  current > moment();//.subtract(1, 'day');
                                            }
                                        }}
                                    />
                                    <div style={{display: "inline-block"}}>
                                        <Select
                                            style={style.datePickerSelectionStyle}
                                            value={type}
                                            onChange={setType}
                                        >
                                            <Option value="custom">Custom</Option>
                                            <Option value="today">Today</Option>
                                            <Option value="yesterday">Yesterday</Option>
                                            <Option value="thisWeek">This Week</Option>
                                            <Option value="thisMonth">This Month</Option>
                                            <Option value="lastMonth">Last Month</Option>
                                        </Select> 

                                        <Select
                                            style={{...style.refreshBtn, width: "180px"}}
                                            value={account || "to Account"}
                                            onChange={setAccount}
                                        >
                                            <Option key="none" value="">All</Option>
                                            { accountList ? (
                                                accountList.map((account) => (
                                                    <Option key={account.accountNo} value={account.accountNo}>
                                                        {account.accountNo}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option disabled>-</Option>
                                            )}
                                        </Select>
                                            
                                        <Button
                                            style={{transform: "translateY(0px)"}} 
                                            type={"primary"}
                                            icon={<SearchOutlined />}
                                            onClick={handleSearch}
                                        />
                                    </div>
                                </div>
                        </PageHeader>
                        <div style={style.tableContainer}>
                            <Card
                                style={{
                                width: "300px",
                                backgroundColor: "#e2f2f0",
                                borderRadius: 20,
                                marginTop: "-18px",
                                marginBottom: "18px",
                                }}
                            >
                               <Statistic title="Total Deposits" value={totalDepositAmount} />
                            </Card>
                            
                            <Table
                                options={{
                                // Allow user to hide/show
                                // columns from Columns Button
                                columnsButton: true,
                                }}
                                loading={tableLoading} 
                                columns={columns}
                                dataSource={depositList}
                                scroll={{ x: 100, y: null }}
                                pagination={{
                                defaultPageSize: isMobile ? 10 : 100,
                                showSizeChanger: true,
                                pageSizeOptions: isMobile ? ["5", "10", "20"] : settings.pageSizeOptions,
                                style: style.paginationStyle,
                                }}
                            />
                        </div>
                    </div>
                </div>
                :
                <CNotAuthorized />
            }
        </div>
    )
}