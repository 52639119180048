import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  DatePicker,
  Spin,
  Select,
  Button,
  Modal,
  message,
  Tabs,
  PageHeader,
  Input
} from "antd";
import { Table } from "ant-table-extensions";
import {reportRegisterVsDeposit} from "../../../config/networkConfig";
import moment from "moment";
import { useSelector } from "react-redux";
import { repRegVsDepVw } from "../../../library/permission";
import style from "../../../resource/style/style";
import momentTz from "moment-timezone";
import { CNotAuthorized } from "../../../common/components/CNotAuthorized";
import { SearchOutlined, FileExcelOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { isMobile } from "react-device-detect";
import { settings } from "../../../config/appConfig";
import { FormatDate } from "../../../library/dates";
import { FormatNumComm } from "../../../library/numbers";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";
import { Excel } from 'antd-table-saveas-excel';

export default function RegVsDepReport() {
  const [ formPreview] = Form.useForm();
  const { role, username, compCode  } = useSelector((state) => state.login);
  const { Option } = Select;
  const { TabPane } = Tabs;

  //useRef to prevent not insert start date or end date
  const [reportLoading,         setReportLoading] = useState(false);
  const [reportRegisterFrom,    setRegisterFrom] = useState("");
  const [reportRegisterTo,      setRegisterTo] = useState("");

  const refRegFrom = useRef(null);
  const refRegTo   = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [reportData, setReportData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  
//   useEffect(() => {
//     getGameProvider();
//   }, []);

  useEffect(() => {
    // eslint-disable-next-line
    if (repRegVsDepVw(role, compCode)){
      // initPage();
      // csStaffList();
    }
  }, []);

  const onChangeTab  = (key) => {
    if (key == 1) {
      //  operatioPrevList();
    }
  }

  const onFinish = async (values) => {
  }

  const repRegisterFromPicker = (date,dateString) => {
    setRegisterFrom(date);
  }

  const repRegisterToPicker = (date,dateString) => {
    setRegisterTo(date);
  }

  const onResetFilters = () => {
    setRegisterFrom("");
    setRegisterTo("");
  }

  const generateReport = async ()=> {
      let count = 0 ;

      if (!reportRegisterFrom) {
        refRegFrom.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Register Date From !";
        sharedErrorAlert(alertData);
        refRegFrom.current.focus();
        return;
      }

      if (!reportRegisterTo) {
        refRegTo.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Register Date To !";
        sharedErrorAlert(alertData);
        return;
      }

      setReportLoading(true)

      let requestData = {
        registerFrom  : !reportRegisterFrom ? '' :FormatDate(reportRegisterFrom),
        registerTo    : !reportRegisterTo ? '' :FormatDate(reportRegisterTo),
      }

      const respPv = await reportRegisterVsDeposit(requestData);
      let result = await respPv.json();

      if (result.status === "ok") {
          const newData = Object.keys(result.data).length
          ? result.data.map((obj) => {
              setTableLoading(false);
              return {
                key           : count++,
                mobileNo      : obj.mobile,
                bankAcc       : obj.bankAcc,
                totalDep      : obj.totaldep == 0 ? '' : obj.totaldep,
              }
            })
          : [];

          setReportData(newData);
          alertData.title = "Success";
          alertData.msg = result.msg;
          sharedSuccessAlert(alertData);

      }
      else {
          alertData.title = "Failed";
          alertData.msg = result.msg;
          sharedFailAlert(alertData);  
      }

      setReportLoading(false);
  }

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8}}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  const onClickExportBtn = ()=> {
    document.getElementById("export-button-2").click()
  }

  let reportColumns =  [
    {
      align: "center",
      title: "Phone No",
      dataIndex:"mobileNo",
    },
    {
      align: "center",
      title: "Bank Account Name",
      dataIndex:"bankAcc",
    },
    {
      align: "center",
      title: "Total Deposit",
      dataIndex:"totalDep",
    },
  ];

  const feedFields = {
    mobileNo   :"Mobile",
}

  const fields = {
    mobileNo: {
      header: "Phone No.",
      formatter: (_fieldValue, record) => {
        return  record?.mobileNo;
      },
    },
    bankAcc: {
      header: "Bank Account Name",
      formatter: (_fieldValue, record) => {
        return  record?.bankAcc;
      },
    },
    totalDep: {
      header: "Total Deposit",
      formatter: (_fieldValue, record) => {
        return  record?.totalDep;
      },
    },
  };

  return (
    <div>
        {
          repRegVsDepVw(role, compCode)?

          <div style={style.tabelContainer} id="page-operation-report">
          <Tabs defaultActiveKey="1" onChange={onChangeTab} id="operation-report">
              <TabPane tab="Register vs Deposit Report" key="1">
                  <PageHeader ghost={false}>
                          <div style={style.pageHeaderContainer}>
                          <div style={{float:"left"}}>

                                  <Form
                                  form={formPreview}
                                  name="basic"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 24 }}
                                      onFinish={onFinish}
                                  >
                                      <Form.Item
                                          label="Register Date From"
                                          name="Register Date To"
                                      >
                                      <DatePicker
                                          key="1"
                                          style={{...style.datePickerStyle, ...style.refreshBtn}}
                                          onChange={repRegisterFromPicker}
                                          value={reportRegisterFrom}
                                          ref={refRegFrom}
                                          refs
                                          placeholder={"Select Register Date From"}
                                          disabledDate={(current)=> {
                                          return current > moment().endOf('day');
                                          }}/>
                                      <DatePicker
                                          key="2"
                                          style={{...style.datePickerStyle, ...style.refreshBtn, ...style.dateToMarginTop}}
                                          onChange={repRegisterToPicker}
                                          value={reportRegisterTo}
                                          ref={refRegTo}
                                          placeholder={"Select Register Date To"}
                                          disabledDate={(current)=> {
                                            let dateFr = reportRegisterFrom;
                                            if (dateFr) {
                                                return current < dateFr || current > moment().add(1, 'day').add(-1, 'minute') ;
                                            }else {
                                                return  current > moment().subtract(1, 'day');
                                            }
                                          }}
                                          defaultPickerValue={()=>{
                                              let dateFr = reportRegisterTo;
                                              if (dateFr) {
                                                  return dateFr;
                                              }
                                          }}
                                          />
                                      </Form.Item>   
                                      <Form.Item
                                          name={<div>&nbsp;</div>}
                                          label=""
                                          style={{float:"right",marginRight:"180px"}}
                                      >
                                          <Button
                                              style={style.refreshBtn}
                                              type="primary"
                                              onClick={onResetFilters}
                                          >
                                              Reset
                                          </Button>
                                          
                                          <Button
                                              style={style.refreshBtn}
                                              type="primary"
                                              onClick={()=>{
                                                generateReport();
                                              }}
                                          >
                                          Submit
                                          </Button>
                                      </Form.Item>
                                  </Form>
                          </div>
                          </div>
                  </PageHeader>

                  <div>
                      <Button
                          style={{...style.refreshBtn}}
                          type="primary"
                          id="export_button-2"
                          icon={<FileExcelOutlined />}
                          onClick={() => {
                            let time = moment(new Date()).format("YYYY-MM-DD");
                            const excel = new Excel();
                            excel
                              .addSheet('Sheet 1')
                              .addColumns(reportColumns)
                              .addDataSource(reportData)
                              .saveAs("RegVsDep_report-"+time+".xlsx");
                          }}
                          >
                          Export to Excel
                      </Button>
                      <Table
                          className="reportDetailList"
                          loading={reportLoading}
                          style={{marginTop:"10px"}}
                          columns ={reportColumns}
                          dataSource= {reportData}
                          scroll={{ x: isMobile ? "110%" : "100%", y: null }}
                          pagination={{
                            defaultPageSize: 100,
                            showSizeChanger: true,
                            pageSizeOptions: settings.pageSizeOptionsMedium,
                            style: style.paginationStyle,
                            alignmentBottom: 'right',
                          }}
                      />
                  </div>
              </TabPane>
          </Tabs>      
      </div>:
        <CNotAuthorized/>
        }

    </div>
  );
}
