import {settings} from "../config/appConfig";
import { CS_USER_TYPE } from "../common/constants/csuserType";

const FormatData = (num) => {
    num = isNaN(num)? 0 : num;
    return parseFloat(num).toFixed(settings.decimalScale);
  }

const FormatDataMobile = (num) => {
    num = isNaN(num) ? 0 : num;
    return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

const FormatPt =  (num) => {
    num = isNaN(num)? 0 : num;
    return parseFloat(num).toFixed(2);
}

const FormatMask = (acctnum) => {
    let maskString ='';

    if (acctnum.length <= 5 ) {
        maskString = '******'+acctnum;
    }else {
        let findPos = acctnum.length - 5;
        let prepend = '***';
        for (let s=0 ; s <findPos; s++) {
            prepend += '*';
        }
        maskString = prepend + acctnum.slice(-5);
    }

    return maskString;
}

const FormatMaskSuperCS = (issuper, nickname, mobile) => {
    let superCS = issuper == null || issuper == undefined ? false : issuper;
    return superCS? mobile: nickname;
}

const FormatMaskSuperCSV2 = (role, nickname, mobile) => {
    let superCS = !role ? false : role == CS_USER_TYPE.SuperCS ? true : false ;
    return superCS? mobile: nickname;
}

const ShowMobileNumber = (role, nickname , mobile) => {
    let showMobile = !role? false : role == CS_USER_TYPE.SuperCS || role == CS_USER_TYPE.Senior ? true : false;
    return showMobile? mobile : nickname;
}

const FormatNumComm = (num) => {  //Format Number with 1000 separator, using comma
    num = isNaN(num)? 0 : num;
    return new Intl.NumberFormat('en-US', {style: 'decimal', minimumFractionDigits: 2}).format(num);
}

export {FormatData, FormatDataMobile, FormatPt, FormatMask , FormatMaskSuperCS ,FormatMaskSuperCSV2, ShowMobileNumber, FormatNumComm};